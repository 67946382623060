import {
  LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_CAMPING_THEMES,
  LANDING_SUPPORTS_SAVE_REGION_FILTER,
  LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST,
  LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST,
  LANDING_SUPPORTS_SELECTED_SPECIAL_REGION,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID,
} from "@/modules/landing-support/store/mutations-types"

const mutations = {
  [LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST](state, payload) {
    state.countryDestinationsList = payload
  },
  [LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST](state, payload) {
    state.regionDestinationsList = payload
  },
  [LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST](state, payload) {
    state.departmentDestinationsList = payload
  },
  [LANDING_SUPPORTS_FETCH_CAMPING_THEMES](state, payload) {
    state.campingThemesList = payload
  },
  [LANDING_SUPPORTS_SAVE_REGION_FILTER](state, payload) {
    state.saveSelectedRegionFilter = payload
  },
  [LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER](state, payload) {
    state.saveSelectedDepartmentFilter = payload
  },
  [LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST](state, payload) {
    state.specialRegionsList = payload
  },
  [LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST](state, payload) {
    state.countryDepartmentList = payload
  },
  [LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST](state, payload) {
    state.countrySpecialRegionsList = payload
  },
  [LANDING_SUPPORTS_SELECTED_SPECIAL_REGION](state, payload) {
    state.selectedSpecialRegion = payload
  },
  [LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID](state, payload) {
    state.selectedSpecialRegionDetail = payload
  },
}

export default mutations
