<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Search campsites</h4>
      </div>
    </md-card-header>
    <md-card-content>
      <form novalidate class="md-layout" @submit.prevent="handleSubmit">
        <div
          class="md-layout-item md-size-50 md-xsmall-size-100 px-0 pb-4 pr-sm-3"
        >
          <md-field>
            <label>Campsite id</label>
            <md-input v-model="id" type="text"></md-input>
          </md-field>
        </div>

        <div
          class="md-layout-item md-size-50 md-xsmall-size-100 px-0 pb-4 pl-sm-3"
        >
          <md-field>
            <label>Campsite name</label>
            <md-input v-model="name" type="text"></md-input>
          </md-field>
        </div>

        <div
          class="md-layout-item md-size-25 md-medium-size-100 px-0 pb-4 pr-xl-3"
        >
          <md-field>
            <label for="countries-field">
              Countries {{ getLoadingText(isCountriesFetching) }}</label
            >
            <md-select
              v-model="country"
              name="countries"
              id="countries-field"
              :disabled="isCountriesFetching"
              @md-selected="handleCountrySelect"
            >
              <md-option
                v-for="country in countriesList"
                :key="country.key"
                :value="country.key"
              >
                {{ country.value }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div
          class="md-layout-item md-size-25 md-medium-size-100 px-0 pb-4 pl-xl-3 pr-xl-3"
        >
          <md-field>
            <label for="regions-field">
              Regions {{ getLoadingText(isRegionsFetching) }}</label
            >
            <md-select
              v-model="region"
              name="regions"
              id="regions-field"
              :disabled="!country"
              @md-selected="handleRegionSelect"
            >
              <md-option
                v-for="region in regionsList"
                :key="region.key"
                :value="region.key"
              >
                {{ region.value }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div
          class="md-layout-item md-size-25 md-medium-size-100 px-0 pb-4 pl-xl-3"
        >
          <md-field>
            <label for="departments-field">
              Departments {{ getLoadingText(isDepartmentsFetching) }}</label
            >
            <md-select
              v-model="department"
              name="departments"
              id="departments-field"
              :disabled="!country"
            >
              <md-option
                v-for="department in departmentsList"
                :key="department.key"
                :value="department.key"
              >
                {{ department.value }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-100 px-0 pb-4 pl-xl-3"
        >
          <md-field>
            <label for="groupName-field"> Group name</label>
            <md-select v-model="group" name="departments" id="groupName-field">
              <md-option
                v-for="(item, index) in groupNames"
                :key="index"
                :value="item.value"
              >
                {{ item.value }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-xsmall-size-100 px-0 pb-4 pr-sm-3"
        >
          <md-field>
            <label>Campsite Email</label>
            <md-input v-model="campsiteEmail" type="text"></md-input>
          </md-field>
        </div>
        <div class="md-size-100 md-layout-item">
          <Collapse :collapse="['Filters']" :is-open="false">
            <template slot="md-collapse-pane-1">
              <md-card>
                <md-card-content class="row">
                  <div class="filter-checkbox">
                    <md-checkbox
                      class="column"
                      v-for="(filter, index) in searchFilters"
                      :key="index"
                      :value="filter.value"
                      v-model="saveFilters"
                      >{{ filter.value }}</md-checkbox
                    >
                  </div>
                </md-card-content>
              </md-card>
            </template>
          </Collapse>
        </div>

        <div class="md-layout-item md-size-100 px-0 content-right">
          <md-button
            class="md-raised md-success mt-4"
            @click="handleClearButtonClick"
            :disabled="isReadyToFetch"
          >
            Clear Filters
          </md-button>
          <md-button
            style="margin-left:2%"
            type="submit"
            class="md-raised md-success mt-4"
            :disabled="isCampsitesFetching || isReadyToFetch"
          >
            Search
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</template>

<script>
import { isEmpty as _isEmpty } from "lodash"
import { mapActions, mapState } from "vuex"
import {
  CAMPSITES,
  GET_CAMPSITE_GROUP,
} from "@/modules/campsites/store/mutations-types"
import {
  GET_SEARCH_FILTERS,
  SEARCH_PRICING_DATA,
  PRICING_DATA,
} from "@/modules/pricing-data/store/mutations-types"
import Collapse from "@/views/components/ui/Collapse"

const fieldsAnnotation = [
  { key: "campsiteId", name: "id" },
  { key: "campsiteName", name: "name" },
  { key: "country", name: "country" },
  { key: "department", name: "department" },
  { key: "region", name: "region" },
  { key: "campsiteEmail", name: "campsiteEmail" },
]

const initialFieldsData = {
  id: null,
  name: null,
  country: null,
  region: null,
  department: null,
  group: null,
  campsiteEmail: null,
}

export default {
  name: "SearchCampsitesForm",
  props: {
    initialFormData: Object,
    isCampsitesFetching: Boolean,
    isCountriesFetching: Boolean,
    isRegionsFetching: Boolean,
    isDepartmentsFetching: Boolean,
    onCountryChange: Function,
    onRegionChange: Function,
    onFormSubmit: Function,
    countriesList: {
      type: Array,
      default: () => [],
    },
    regionsList: {
      type: Array,
      default: () => [],
    },
    departmentsList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Collapse,
  },
  data: () => ({
    ...initialFieldsData,
    searchFilters: [],
    saveFilters: [],
  }),
  computed: {
    ...mapState(CAMPSITES, {
      groupNames: state => state.groupNames,
    }),
    isReadyToFetch() {
      return !(
        this.country ||
        this.region ||
        this.department ||
        this.id ||
        this.name ||
        this.group ||
        this.campsiteEmail ||
        this.saveFilters.length
      )
    },
  },
  methods: {
    ...mapActions(CAMPSITES, [GET_CAMPSITE_GROUP]),
    ...mapActions(PRICING_DATA, [GET_SEARCH_FILTERS, SEARCH_PRICING_DATA]),
    async getGroupNames() {
      await this[GET_CAMPSITE_GROUP]()
    },
    async getFilters() {
      await this[GET_SEARCH_FILTERS]()
      this.$store.state[PRICING_DATA].searchFilters.splice(0, 1)
      this.searchFilters = this.$store.state[PRICING_DATA].searchFilters
      this.loading = false
    },
    async searchData() {
      let searchQueryObject = {}
      if (this.saveFilters.length) {
        let filterQueryString = ""
        this.saveFilters.forEach(filter => {
          filterQueryString += `${filter.toUpperCase()},`
        })
        filterQueryString = filterQueryString.replaceAll(" ", "_")
        filterQueryString = filterQueryString.substring(
          0,
          filterQueryString.length - 1
        )
        searchQueryObject.filters = filterQueryString
      }
      this.dataFetching = true
      const result = await this[SEARCH_PRICING_DATA](searchQueryObject)
      if (result === 400) {
        this.searchResult = {
          label: "",
          columns: [
            {
              name: "",
              label: "",
              value: "",
            },
          ],
          rows: [],
          numberOfRows: "",
        }
      } else {
        this.searchResult = this.$store.state[PRICING_DATA].searchResult
      }
      this.dataFetching = false
      this.renderKey++
    },
    clearFilterData() {
      this.selectedCountry = ""
      this.selectedRegion = ""
      this.selectedDepartment = ""
      this.selectedNight = ""
      this.selectedAccommodation = ""
      this.selectedCampsiteName = ""
      this.selectedDiscount = null
      this.selectedGroupName = ""
      this.dateFrom = null
      this.dateTo = null
      this.saveFilters = []
      this.searchResult = this.pricingData
      this.renderKey++
    },
    handleClearButtonClick() {
      Object.keys(initialFieldsData).forEach(initFieldKey => {
        this[initFieldKey] = initialFieldsData[initFieldKey]
      })
      this.clearFilterData()
    },
    handleSubmit() {
      let filterQueryString = ""
      if (this.saveFilters.length) {
        this.saveFilters.forEach(filter => {
          filterQueryString += `${filter.toUpperCase()},`
        })
        filterQueryString = filterQueryString.replaceAll(" ", "_")
        filterQueryString = filterQueryString.substring(
          0,
          filterQueryString.length - 1
        )
      }
      this.$emit("onFormSubmit", {
        campsiteId: this.id,
        campsiteName: this.name,
        country: this.country,
        region: this.region,
        department: this.department,
        group: this.group,
        campsiteEmail: this.campsiteEmail,
        filters: filterQueryString,
      })
    },
    getLoadingText(isLoading) {
      return isLoading ? "- Loading ..." : ""
    },
    initFormData(values) {
      if (_isEmpty(values)) return

      Object.keys(values).forEach(key => {
        const fieldToUpdate = fieldsAnnotation.find(field => field.key === key)
        const value = values[key]

        if (fieldToUpdate && value) {
          this[fieldToUpdate.name] = value
        }
      })
    },
    showAlert(name) {
      this.$swal(name)
    },
    resetFields(fieldName = []) {
      fieldName.forEach(fieldName => {
        this[fieldName] = null
      })
    },
    handleCountrySelect(country) {
      this.resetFields(["region", "department"])

      if (country) {
        this.$emit("onCountryChange", country)
      }
    },
    handleRegionSelect(region) {
      this.resetFields(["department"])

      if (region) {
        this.$emit("onRegionChange", { country: this.country, region })
      }
    },
  },
  async created() {
    this.initFormData(this.initialFormData)
    this.getGroupNames()
    await this.getFilters()
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.column {
  width: 15%;
  padding: 2px;
}
.required-field::after {
  content: " *";
  color: red;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>

<style>
.md-checkbox .md-checkbox-label {
  height: auto !important;
}
</style>
