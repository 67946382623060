import JsonCSV from "vue-json-csv"
import JsonExcel from "vue-json-excel"

import Dropdown from "@/views/components/ui/Dropdown"

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", Dropdown)
    Vue.component("downloadCsv", JsonCSV)
    Vue.component("downloadExcel", JsonExcel)
  },
}

export default GlobalComponents
