export const LANDING_SUPPORTS = "LANDING_SUPPORTS"

export const LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST =
  "LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST"
export const LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST =
  "LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST"
export const LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST =
  "LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST"
export const LANDING_SUPPORTS_FETCH_CAMPING_THEMES =
  "LANDING_SUPPORTS_FETCH_CAMPING_THEMES"
export const LANDING_SUPPORTS_SAVE_REGION_FILTER =
  "LANDING_SUPPORTS_SAVE_REGION_FILTER"
export const LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER =
  "LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER"
export const LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST =
  "LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST"
export const LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST =
  "LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST"
export const LANDING_SUPPORTS_FETCH_SPECIALREGION_DEPARTMENT_LIST =
  "LANDING_SUPPORTS_FETCH_SPECIALREGION_DEPARTMENT_LIST"
export const LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST =
  "LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST"
export const LANDING_SUPPORTS_ADD_SPECIAL_REGION =
  "LANDING_SUPPORTS_ADD_SPECIAL_REGION"
export const LANDING_SUPPORTS_UPDATE_SPECIAL_REGION =
  "LANDING_SUPPORTS_UPDATE_SPECIAL_REGION"
export const LANDING_SUPPORTS_SELECTED_SPECIAL_REGION =
  "LANDING_SUPPORTS_SELECTED_SPECIAL_REGION"
export const LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID =
  "LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID"
