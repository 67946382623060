<template>
  <div class="px-5">
    <ProgressSpinner :isProgress="loadingUpdate" />
    <md-dialog-title class="pl-0"
      >Parent Item:
      {{ selectedParent.find(item => item.name === "item_label").value }}
    </md-dialog-title>
    <br />
    <md-dialog-title class="pl-0"
      >Parent Item Key:
      {{ selectedParent.find(item => item.name === "item_key").value }}
    </md-dialog-title>
    <form novalidate class="md-layout" @submit.prevent>
      <template>
        <div class="d-inline-flex flex-column display-area">
          <md-field
            class="mr-4"
            :class="{
              'md-error md-invalid': $v.label.$error,
            }"
          >
            <label class="required">Label</label>
            <md-input v-model="label" type="text"></md-input>
            <div class="md-error" v-if="!$v.label.required">
              Item Label is required.
            </div>
          </md-field>
        </div>
        <div class="d-inline-flex flex-column display-area">
          <md-field
            class="mr-4 display-checkbox"
            v-if="showCategoryFilter"
            :class="{
              'md-error md-invalid': categoryError,
            }"
          >
            <label for="category-field">Category</label>
            <md-select v-model="category" name="category" id="category-field">
              <md-option
                v-for="(category, index) in levelTwoCategories.rows"
                :key="index"
                :value="category.row[1].value"
              >
                {{
                  category.row.find(item => item.name === "category_label")
                    .value
                }}
              </md-option>
            </md-select>
            <div class="md-error" v-if="categoryError">
              Item Category is required.
            </div>
          </md-field>

          <div>
            <label for="category-field">Open in new tab</label>
            <md-checkbox
              class="mr-4 column display-checkbox"
              v-model="newTab"
            ></md-checkbox>
          </div>
          <div>
            <label for="category-field">Is view all link</label>
            <md-checkbox
              class="column display-checkbox"
              v-model="viewLink"
            ></md-checkbox>
          </div>
        </div>
        <div class="d-inline-flex flex-column display-area">
          <md-field class="mr-4 display-checkbox">
            <label>Label Heading</label>
            <md-input v-model="itemLabelHeading" type="text"></md-input>
          </md-field>
          <md-field class="mr-4 display-checkbox">
            <label>Strap</label>
            <md-input v-model="itemStrap" type="text"></md-input>
          </md-field>
        </div>
        <div class="d-inline-flex flex-column display-area">
          <md-field class="mr-4 display-checkbox">
            <label>Icon Key</label>
            <md-input v-model="iconKey" type="text"></md-input>
          </md-field>
        </div>
        <Tabs theme="cyan" class="mt-2">
          <TabItem name="Custom">
            <md-field
              class="mb-4"
              :class="{
                'md-error md-invalid': $v.selectedUrl.$error,
              }"
            >
              <label class="required">Custom Url</label>
              <md-input v-model="selectedUrl" type="text"></md-input>
              <div class="md-error" v-if="!$v.selectedUrl.required">
                Url is required.
              </div>
            </md-field>
          </TabItem>
          <TabItem name="Sitemap">
            <div class="d-inline-flex flex-column display-area">
              <md-field class="mr-4 display-checkbox">
                <label for="category-field">Sitemap level</label>
                <md-select
                  v-model="sitemapLevel"
                  name="category"
                  id="category-field"
                >
                  <md-option
                    v-for="(item, key) of sitemapLevels"
                    :key="key"
                    :value="item.value"
                  >
                    {{ item.value }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field
                class="mr-4 display-checkbox"
                :class="{
                  'md-error md-invalid': sitemapLabelError,
                }"
              >
                <label class="required">Sitemap label</label>
                <md-input v-model="sitemapLabel" type="text"></md-input>
                <div class="md-error" v-if="sitemapLabelError">
                  Sitemap Label is required.
                </div>
              </md-field>
              <md-button
                type="submit"
                class="md-raised md-success go-button"
                @click="getUrls"
              >
                Go
              </md-button>
            </div>
            <div
              class="md-layout-item p-0 pt-4 pb-4"
              style="background: white;"
            >
              <md-field
                :class="{ 'md-error md-invalid': $v.selectedUrl.$error }"
              >
                <label for="selectedUrl-field" class="required">Url</label>
                <md-select
                  style="overflow: auto;background: white;"
                  v-model="selectedUrl"
                  name="selectedUrl"
                  id="selectedUrl-field"
                >
                  <md-option
                    style="background: white;z-index:12;max-width:1000px"
                    v-for="(url, index) in urlsList"
                    :key="index"
                    :value="url.value"
                  >
                    {{ url.value }}
                  </md-option>
                </md-select>

                <div class="md-error" v-if="$v.selectedUrl.$error">
                  Url is required
                </div>
              </md-field>
            </div>
          </TabItem>
        </Tabs>
        <div class="md-layout-item md-size-100 px-0 pb-4 mt-2 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="updateUrlItem()"
          >
            Update
          </md-button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  GET_URLS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_SITEMAP_LEVELS_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import { required } from "vuelidate/lib/validators"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
import { Tabs, TabItem } from "vue-material-tabs"
export default {
  name: "updateURLItemsSmartNavConfigurationForm",
  components: {
    ProgressSpinner,
    Tabs,
    TabItem,
  },
  data: () => ({
    urlItem: {},
    selectedUrl: "",
    label: "",
    category: "",
    newTab: "",
    viewLink: "",
    loadingUpdate: false,
    custom: true,
    defaultVal: false,
    sitemapLevel: "",
    sitemapLabel: "",
    sitemapLabelError: false,
    categoryError: false,
    itemLabelHeading: "",
    iconKey: "",
    itemStrap: "",
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      updateUrlItems: UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
      getAvailableUrlsList: GET_URLS_SMARTNAV_CONFIGURATIONS_LIST,
      getSitemapLevels: GET_SITEMAP_LEVELS_LIST,
    }),
    // Initialize the update URL Items Smart Nav Configuration Modal
    async init() {
      this.loadingUpdate = true
      try {
        await this.getSitemapLevels()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Sitemap Levels",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      let input = {}
      input.label = ""
      await this.getUrlsList(input)
      this.category = this.selectedUrlItem.find(
        item => item.name === "item_category"
      )?.value
      this.selectedUrl = this.selectedUrlItem.find(
        item => item.name === "item_url"
      )?.value
      this.newTab = JSON.parse(
        this.selectedUrlItem.find(item => item.name === "open_in_new_tab")
          ?.value
      )
      this.viewLink = JSON.parse(
        this.selectedUrlItem.find(item => item.name === "view_all_link")?.value
      )
      this.label = this.selectedUrlItem.find(
        item => item.name === "item_label"
      )?.value
      this.loadingUpdate = false
      this.itemLabelHeading = this.selectedUrlItem.find(
        item => item.name === "item_label_heading"
      )?.value
      this.iconKey = this.selectedUrlItem.find(
        item => item.name === "item_icon_key"
      )?.value
      this.itemStrap = this.selectedUrlItem.find(
        item => item.name === "item_strap"
      )?.value
      this.sitemapLabel = this.selectedUrlItem.find(
        item => item.name === "sitemap_label"
      )?.value
    },
    // Save the updated URL Item
    async updateUrlItem() {
      this.sitemapLabelError = false
      this.categoryError = false
      if (this.showCategoryFilter && !this.category) {
        this.categoryError = true
        return
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loadingUpdate = true
        let data = {
          id: this.selectedUrlItem.find(item => item.name === "id").value,
          itemCategory: this.category,
          // itemKey: this.selectedUrlItem.find(item => item.name === "item_key")
          //   .value,
          itemLabel: this.label,
          itemUrl: this.selectedUrl,
          lang: this.selectedLanguage,
          openInNewTab: this.newTab ? true : false,
          parentItem: this.selectedParent.find(item => item.name === "item_key")
            .value,
          viewAllLink: this.viewLink ? true : false,
          itemLabelHeading: this.itemLabelHeading,
          itemIconKey: this.iconKey,
          itemStrap: this.itemStrap,
          sitemapLabel: this.sitemapLabel,
        }
        let input = {}
        if (this.level === 2)
          input.parentItem = this.levelTwoSelectedItem.find(
            item => item.name === "item_key"
          ).value
        else if (this.level === 1)
          input.parentItem = this.levelOneSelectedItem.find(
            item => item.name === "item_key"
          ).value
        input.category = this.selectedCategory
        let final = []
        final.push(input)
        final.push(data)
        try {
          await this.updateUrlItems(final)
        } catch (e) {
          this.$swal.fire({
            position: "top-end",
            html: e,
            icon: "error",
            title: "Update Url Item",
            showConfirmButton: false,
            timer: 4000,
            showCloseButton: true,
          })
        }
        this.$v.$reset()
        this.loadingUpdate = false
        this.$emit("onFormSubmit")
      }
    },
    // Get Available URL's List
    async getUrls() {
      let input = {}
      this.sitemapLabelError = false
      if (!this.sitemapLabel) {
        this.sitemapLabelError = true
        return
      }
      input.label = this.sitemapLabel
      input.sitemapLevel = this.sitemapLevel
      await this.getUrlsList(input)
    },
    async getUrlsList(input) {
      input.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      input.lang = this.selectedLanguage
      try {
        await this.getAvailableUrlsList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Available Urls List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
  },
  validations: {
    label: {
      required,
    },
    selectedUrl: {
      required,
    },
  },
  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      selectedLanguage: state => state.selectedLanguage,
      selectedParent: state => state.selectedParent,
      level: state => state.level,
      levelOneSelectedItem: state => state.levelOneSelectedItem,
      levelTwoSelectedItem: state => state.levelTwoSelectedItem,
      levelTwoCategories: state => state.levelTwoCategories,
      urlsList: state => state.urlsList,
      selectedUrlItem: state => state.selectedUrlItem,
      sitemapLevels: state => state.sitemapLevels,
      selectedCategory: state => state.selectedCategory,
      showCategoryFilter() {
        if (this.selectedParent.length) {
          return JSON.parse(
            this.selectedParent.find(item => item.name === "has_categories")
              ?.value
          )
        }
      },
    }),
  },
}
</script>
<style>
.md-dialog-container {
  max-width: 100% !important;
}
.vmt__nav {
  width: fit-content !important;
}
.md-checkbox {
  margin: 4px 0px 4px 0 !important;
}
.tabs__content {
  margin-top: 16px !important;
}
</style>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
.display-area {
  width: 100% !important;
  display: flex;
  padding-bottom: 20px;
}
.display-checkbox {
  width: 25%;
}
.go-button {
  height: fit-content;
}
@media screen and (max-width: 600px) {
  .display-area {
    display: block !important;
  }
  .display-checkbox {
    width: 100% !important;
  }
}
.customized-datalist-input {
  width: 100%;
  margin-top: 35px;
  border: none;
}
.md-title {
  font-size: 14px !important;
}
</style>
