<template>
  <div>
    <UniversalTable
      :data="normalizedBookingComments"
      :add-button-text="addCommentButtonText"
      :onAddButtonClick="handleAddButtonClick"
      :export-file-name="tableExportFileName"
      @onRowSelect="handleRowSelect"
    >
      <template slot="header-right-controls">
        <md-button
          class="md-button md-sm md-success mr-2"
          @click="handleAddButtonClick"
        >
          Add
        </md-button>
      </template>
    </UniversalTable>
  </div>
</template>

<script>
import BookingsTableMixin from "@/modules/bookings/mixins/bookings-table.mixin"
import { BOOKINGS } from "@/modules/bookings/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { normalizeDateInTable } from "../../../utils/booking-date.utils"
import _ from "lodash"

export default {
  name: "BookingCommentsTable",
  mixins: [BookingsTableMixin],
  props: {
    onCreateButtonClick: Function,
  },
  components: {
    UniversalTable,
  },
  data: () => ({
    tableExportFileName: "booking_comments",
    addCommentButtonText: "Create first comment",
  }),
  computed: {
    bookingComments() {
      return this.$store.state[BOOKINGS].item.comments
    },
    normalizedBookingComments() {
      const data = this.$store.state[BOOKINGS].item.comments

      if (!data.rows) {
        return data
      }

      const d = _.cloneDeep(data)

      d.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return d
    },
  },
  methods: {
    handleAddButtonClick() {
      this.$emit("onCreateButtonClick")
    },
  },
}
</script>

<style scoped></style>
