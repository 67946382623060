<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ title }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item to="/">
              <i class="material-icons">home</i>
              <p class="hidden-lg hidden-md">Home</p>
            </md-list-item>

            <li class="md-list-item">
              <a
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>person</md-icon>
                      <p class="hidden-lg hidden-md">Profile</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="handleLogoutButtonClick">Logout</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { AUTH, AUTH_LOGOUT } from "@/modules/auth/store/mutations-types"
import { CAMPSITES } from "@/modules/campsites/store/mutations-types"
import { REPORTS } from "../../../modules/reports/store/mutations-types"

export default {
  name: "TopNavBar",
  data() {
    return {
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    }
  },

  computed: {
    ...mapState(CAMPSITES, {
      fetchPhotoLabel: state => state.item.photoLabel,
    }),
    ...mapState(REPORTS, {
      fetchReportName: state => state.reportName,
    }),
    title() {
      switch (this.$route.name) {
        case "Campsite Photos": {
          return this.$route.path.startsWith("campsites")
            ? "Campsites Photo"
            : this.fetchPhotoLabel
        }
        case "Reports page": {
          return this.fetchReportName ? this.fetchReportName : "Reports page"
        }
        case "Reports Map": {
          return this.fetchReportName ? this.fetchReportName : "Reports Map"
        }
        default: {
          return this.$route.name
        }
      }
    },
  },
  methods: {
    ...mapActions(AUTH, [AUTH_LOGOUT]),
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    async handleLogoutButtonClick() {
      localStorage.removeItem("resultatsAccommodations")
      await this[AUTH_LOGOUT]()
      this.$router.push("/login")
    },
  },
}
</script>

<style lang="scss"></style>
