export const BOOKINGS = "BOOKINGS"

export const BOOKINGS_FETCH_ALL_ITEM_INFO = "BOOKINGS_FETCH_ALL_ITEM_INFO"

export const BOOKINGS_UPDATE_COMMISSION_STATUSES =
  "BOOKINGS_UPDATE_COMMISSION_STATUSES"
export const BOOKINGS_FETCH_COMMISSION_STATUSES =
  "BOOKINGS_FETCH_COMMISSION_STATUSES"

export const BOOKINGS_FETCH_INVOICE_STATUSES = "BOOKINGS_FETCH_INVOICE_STATUSES"
export const BOOKINGS_UPDATE_INVOICE_STATUSES =
  "BOOKINGS_UPDATE_INVOICE_STATUSES"

export const BOOKINGS_UPDATE_ITEM_STATUS = "BOOKINGS_UPDATE_ITEM_STATUS"

export const BOOKINGS_PROMO_CODE = "BOOKINGS_PROMO_CODE"

export const BOOKINGS_FETCH_ITEM_INVOICE_INFO =
  "BOOKINGS_FETCH_ITEM_INVOICE_INFO"
export const BOOKINGS_UPDATE_ITEM_INVOICE_INFO =
  "BOOKINGS_UPDATE_ITEM_INVOICE_INFO"

export const BOOKINGS_FETCH_ITEM_STATUS_HISTORY =
  "BOOKINGS_FETCH_ITEM_STATUS_HISTORY"
export const BOOKINGS_UPDATE_ITEM_STATUS_HISTORY =
  "BOOKINGS_UPDATE_ITEM_STATUS_HISTORY"

export const BOOKINGS_UPDATE_SEARCH_FORM = "BOOKINGS_UPDATE_SEARCH_FORM"
export const BOOKINGS_FETCH_ITEM_COMMENTS = "BOOKINGS_FETCH_ITEM_COMMENTS"
export const BOOKINGS_UPDATE_ITEM_COMMENTS = "BOOKINGS_UPDATE_ITEM_COMMENTS"
export const BOOKINGS_CREATE_ITEM_COMMENT = "BOOKINGS_CREATE_ITEM_COMMENT"

export const BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS =
  "BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS"
export const BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS =
  "BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS"
export const BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS =
  "BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS"

export const BOOKINGS_CREATE_ITEM_ADJUSTMENTS =
  "BOOKINGS_CREATE_ITEM_ADJUSTMENTS"

export const BOOKINGS_FETCH_ITEM_ADJUSTMENTS = "BOOKINGS_FETCH_ITEM_ADJUSTMENTS"
export const BOOKINGS_UPDATE_ITEM_ADJUSTMENTS =
  "BOOKINGS_UPDATE_ITEM_ADJUSTMENTS"
export const BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT =
  "BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT"

export const BOOKINGS_FETCH_ITEM_PRICING = "BOOKINGS_FETCH_ITEM_PRICING"
export const BOOKINGS_UPDATE_ITEM_PRICING = "BOOKINGS_UPDATE_ITEM_PRICING"
export const BOOKINGS_FETCH_ITEM = "BOOKINGS_FETCH_ITEM"
export const BOOKING_CLEAR_ITEM = "BOOKING_CLEAR_ITEM"
export const BOOKING_SEND_PROPOSAL = "BOOKING_SEND_PROPOSAL"
export const BOOKINGS_FETCH_SPECIFIC_PROPOSAL =
  "BOOKINGS_FETCH_SPECIFIC_PROPOSAL"
export const BOOKINGS_FETCH_ITEM_DETAILS = "BOOKINGS_FETCH_ITEM_DETAILS"
export const BOOKINGS_CLEAR_ITEM = "BOOKINGS_CLEAR_ITEM"

export const BOOKINGS_UPDATE_ITEM_DETAILS = "BOOKINGS_UPDATE_ITEM_DETAILS"

export const BOOKINGS_FETCH_LIST = "BOOKINGS_FETCH_LIST"
export const BOOKINGS_UPDATE_LIST = "BOOKINGS_UPDATE_LIST"

export const BOOKINGS_FETCH_STATUSES = "BOOKINGS_FETCH_STATUSES"
export const BOOKING_UPDATE_SEARCH_STATUSES = "BOOKING_UPDATE_SEARCH_STATUSES"

export const BOOKINGS_FETCH_PROPOSALS = "BOOKINGS_FETCH_PROPOSALS"
export const BOOKINGS_UPDATE_SEARCH_STATUSES = "BOOKINGS_UPDATE_SEARCH_STATUSES"

export const BOOKINGS_FETCH_POST_STATUSES = "BOOKINGS_FETCH_POST_STATUSES"
export const BOOKINGS_FETCH_PROMO_CODES = "BOOKINGS_FETCH_PROMO_CODES"

export const BOOKINGS_PROMO_CODES = "BOOKINGS_PROMO_CODES"

export const BOOKINGS_FETCH_POST_PAYMENT_STATUSES =
  "BOOKINGS_FETCH_POST_PAYMENT_STATUSES"

export const BOOKINGS_UPDATE_POST_STATUSES = "BOOKINGS_UPDATE_POST_STATUSES"

export const BOOKINGS_FETCH_EXTENDED_DATA = "BOOKINGS_FETCH_EXTENDED_DATA"
export const BOOKINGS_UPDATE_EXTENDED_DATA = "BOOKINGS_UPDATE_EXTENDED_DATA"

export const BOOKINGS_FETCH_PAYMENT_ISSUES_LIST =
  "BOOKINGS_FETCH_PAYMENT_ISSUES_LIST"
export const BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST =
  "BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST"

export const BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM =
  "BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM"
export const BOOKINGS_FETCH_ITEM_INSURANCE = "BOOKINGS_FETCH_ITEM_INSURANCE"

export const BOOKINGS_FETCH_LANGUAGE = "BOOKINGS_FETCH_LANGUAGE"
export const BOOKINGS_FETCH_WFA_LANGUAGE = "BOOKINGS_FETCH_WFA_LANGUAGE"
export const BOOKINGS_REMOVE_PROMO_CODE = "BOOKINGS_REMOVE_PROMO_CODE"
export const BOOKINGS_FETCH_WEATHER_INSURANCE =
  "BOOKINGS_FETCH_WEATHER_INSURANCE"
export const BOOKINGS_FETCH_REJECTION_REASONS_LIST =
  "BOOKINGS_FETCH_REJECTION_REASONS_LIST"
export const BOOKINGS_UPDATE_REJECTION_REASON =
  "BOOKINGS_UPDATE_REJECTION_REASON"

export const BOOKINGS_FETCH_TYPE = "BOOKINGS_FETCH_TYPE"
export const BOOKINGS_FETCH_CURRENCE = "BOOKINGS_FETCH_CURRENCE"

export const UPDATE_PROPOSAL_STATUS = "UPDATE_PROPOSAL_STATUS"
