export default $axios => resource => ({
  getCountries() {
    return $axios.get(`${resource}/countries`)
  },
  getRegions(params) {
    return $axios.get(`${resource}/countryregions`, {
      params,
    })
  },
  getDepartments(params) {
    return $axios.get(`${resource}/regiondepartments`, {
      params,
    })
  },
  getNumberOfNights() {
    return $axios.get(`${resource}/numofnights`)
  },
  getGroupNames() {
    return $axios.get(`${resource}/campsitegroupnames`)
  },
  getSearchFilters() {
    return $axios.get(`${resource}/search/filters`)
  },
  getAccommodations() {
    return $axios.get(`${resource}/accommodationtypes`)
  },
  async getSearchPricingData(params) {
    return $axios
      .get(`${resource}/pricing`, {
        params,
      })
      .catch(() => {
        return 400
      })
  },
  getPricingData(params) {
    return $axios.get(`${resource}/pricing`, {
      params,
    })
  },
})
