<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Search Booking Payment Issues</h4>
      </div>
    </md-card-header>
    <md-card-content>
      <form novalidate class="md-layout" @submit.prevent="handleSubmit">
        <div
          class="md-layout-item md-size-50 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-3 pl-lg-0"
        >
          <md-datepicker :md-model-type="String" v-model="startDate">
            <label>Created from</label>
          </md-datepicker>
          <md-checkbox v-model="onlyFailedBooking">
            Only failed bookings
          </md-checkbox>
        </div>

        <div
          class="md-layout-item md-size-50 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-0 pl-lg-3"
        >
          <md-datepicker :md-model-type="String" v-model="endDate">
            <label>Created to</label>
          </md-datepicker>
        </div>
        <div class="px-0 content-left mt-4">
          <md-button
            class="md-raised md-success"
            @click="handlePaymentButtonClick"
          >
            Check Payment Succeeded
          </md-button>
        </div>
        <div class="md-layout-item px-0 content-right mt-4">
          <md-button
            class="md-button md-theme-default md-simple"
            @click="handleClearButtonClick"
          >
            CLEAR
          </md-button>
          <md-button
            type="submit"
            class="md-raised md-success"
            :disabled="isFetching"
          >
            Search
          </md-button>
        </div>
      </form>
    </md-card-content>
    <div>
      <PaymentBookingDialog
        :is-show-dialog="isShowPaymentBookingDialog"
        @onDialogClosed="closeUpdateBookingStatusDialog"
      />
    </div>
  </md-card>
</template>

<script>
import { isEmpty as _isEmpty } from "lodash"
import { DateTime } from "luxon"

import PaymentBookingDialog from "@/modules/bookings/views/components/dialogs/PaymentBookingDialog"

import { toISODate } from "@/utils/date.utils"

const initialFieldsData = {
  startDate: DateTime.now().toFormat("LLLL dd, yyyy"),
  endDate: DateTime.now().toFormat("LLLL dd, yyyy"),
  onlyFailedBooking: false,
}

export default {
  name: "SearchBookingPaymentIssuesForm",
  props: {
    isFetching: Boolean,
    onFormSubmit: Function,
    initialFormData: Object,
  },
  components: {
    PaymentBookingDialog,
  },
  created() {
    this.initFormData()
    this.$material.locale.dateFormat = "LLLL dd, yyyy"
  },
  data: () => ({
    ...initialFieldsData,
    isShowPaymentBookingDialog: false,
  }),
  methods: {
    initFormData() {
      const formData = this.initialFormData

      if (_isEmpty(formData)) return
      Object.keys(formData).forEach(key => {
        if (["startDate", "endDate"].includes(key)) {
          this[key] = DateTime.fromISO(formData[key]).toFormat("LLLL dd, yyyy")
        } else {
          this[key] = formData[key]
        }
      })
    },
    handleSubmit() {
      this.$emit("onFormSubmit", {
        startDate: toISODate(this.startDate),
        endDate: toISODate(this.endDate),
        onlyFailedBooking: this.onlyFailedBooking,
      })
    },
    handleClearButtonClick() {
      Object.keys(initialFieldsData).forEach(initFieldKey => {
        this[initFieldKey] = initialFieldsData[initFieldKey]
      })
    },
    handlePaymentButtonClick() {
      this.isShowPaymentBookingDialog = true
    },
    closeUpdateBookingStatusDialog() {
      this.isShowPaymentBookingDialog = false
    },
  },
}
</script>

<style scoped></style>
