<template>
  <div class="md-layout-item">
    <collapse :collapse="[collapseLabel]" :is-open="isOpen">
      <template slot="md-collapse-pane-1">
        <div v-if="isLoading" class="text-center p-5">
          <md-progress-spinner class="md-primary" md-mode="indeterminate" />
        </div>

        <div v-else class="md-layout pt-1 pb-2">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mt-1 mb-2 p-0"
            v-for="item in elements"
            :key="item.key"
          >
            <div v-if="!item.visible">
              <span class="md-caption">{{ item.label }}</span>
              <div class="row" :data-name="item.label">
                <p v-if="item.normalizedValue" class="p-0 m-0">
                  {{ item.normalizedValue }}
                </p>
                <p v-else-if="item.value.startsWith('http')" class="p-0 m-0">
                  <a :href="item.value" target="_blank">
                    <strong>Click here</strong>
                  </a>
                </p>
                <p v-else class="p-0 m-0">{{ item.value }}</p>

                <div v-for="button in item.buttons" :key="button.text">
                  <md-button
                    class="md-button mr-2 row__link"
                    :class="button.class"
                    @click="button.click"
                  >
                    {{ button.text }}
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasAfterContentSlot" class="after-content">
            <slot name="after-content" />
          </div>
        </div>
        <div class="actions pb-3">
          <div class="others">
            <md-button
              v-if="CampsitePage || showUpdateForAccommodation"
              class="md-raised md-success mr-2"
              @click="$emit('navigate-to-dialog', elements)"
            >
              Update Details
            </md-button>
            <md-button
              v-if="CampsitePage"
              class="md-raised md-success"
              @click="$emit('refresh-cache')"
            >
              Refresh Cache
            </md-button>
          </div>
          <div class="photo">
            <md-button
              @click="gotoPhotosPage"
              class="md-button md-raised md-success md-theme-default"
            >
              PHOTOS
            </md-button>
          </div>
        </div>
      </template>
    </collapse>
  </div>
</template>

<script>
import Collapse from "@/views/components/ui/Collapse"

export default {
  name: "DetailsCollapse",
  components: {
    Collapse,
  },
  props: {
    label: String,
    elements: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showUpdateForAccommodation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasAfterContentSlot() {
      return !!this.$slots["after-content"]
    },
    collapseLabel() {
      return this.isLoading ? "Loading..." : this.label
    },
    CampsitePage() {
      return this.$route.name === "Campsite pages"
    },
    AccommodationPage() {
      return this.$route.name === "Accommodation Details Page"
    },
  },
  methods: {
    gotoPhotosPage() {
      this.$router.push(
        this.CampsitePage
          ? { name: "Campsite Photos" }
          : { name: "Accommodation Photos" }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  &__link {
    margin-left: 16px;
  }
}
.after-content {
  width: 100%;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .photo {
    margin-left: auto;
  }
}
</style>
