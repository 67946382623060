<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>View Proposal</md-dialog-title>

      <md-dialog-content>
        <ProposalDetailPage
          :edit-proposal-id="this.editProposalId"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import ProposalDetailPage from "@/modules/bookings/views/pages/ProposalDetailPage"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "ProposalDetailDialog",
  props: {
    onDialogClose: Function,
    editProposalId: String,
    isShowDialog: Boolean,
  },
  components: {
    ProposalDetailPage,
  },
  mixins: [dialogMixin],
  methods: {
    async handleFormSubmit() {
      this.closeDialog()
    },
  },
}
</script>

<style scoped></style>
