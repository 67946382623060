<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Promo code</md-dialog-title>

      <md-dialog-content>
        <BookingPromoCodeForm
          :is-form-sending="isFormSending"
          :old-booking-status="bookingStatus"
          :bookingPromoList="bookingPromoCodes"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleUpdateFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import BookingPromoCodeForm from "@/modules/bookings/views/components/forms/BookingPromoCodeForm"

import {
  BOOKINGS,
  BOOKINGS_FETCH_PROMO_CODES,
  BOOKINGS_PROMO_CODE,
  BOOKINGS_REMOVE_PROMO_CODE,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"
import dialogMixin from "@/mixins/dialog.mixin"
export default {
  name: "BookingPromoCodeDialog",
  components: { BookingPromoCodeForm },
  props: {
    bookingStatus: String,
    transId: String,
    isUpdate: Boolean,
    isRemove: Boolean,
  },
  mixins: [dialogMixin],
  computed: {
    bookingPromoCodes() {
      return this.$store.state[BOOKINGS].item.promoCodes
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_PROMO_CODES,
      BOOKINGS_PROMO_CODE,
      BOOKINGS_REMOVE_PROMO_CODE,
    ]),
    fetchBookingsPromoCodes() {
      if (this.bookingPromoCodes.length === 0) {
        this[BOOKINGS_FETCH_PROMO_CODES]()
      }
    },
    async handleUpdateFormSubmit(values) {
      this.isFormSending = true
      if (values.isUpdate)
        await this[BOOKINGS_PROMO_CODE]({
          promoCode: values.promoCode,
          transId: this.transId,
        })
      else
        await this[BOOKINGS_REMOVE_PROMO_CODE]({
          promoCode: values.promoCode,
          transId: this.transId,
        })
      this.isFormSending = false
      this.closeDialog()
    },
  },
  mounted() {
    this.fetchBookingsPromoCodes()
  },
}
</script>

<style scoped></style>
