import {
  GET_COUNTRIES,
  GET_REGION_DEPARTMENTS,
  GET_COUNTRY_REGIONS,
  GET_ACCONMMODATIONS,
  GET_NUMBER_OF_NIGHTS,
  GET_GROUP_NAMES,
  GET_SEARCH_FILTERS,
  SEARCH_PRICING_DATA,
} from "@/modules/pricing-data/store/mutations-types"

const actions = {
  async [GET_COUNTRIES]({ commit }) {
    const countries = await this.$repositories.pricingData.getCountries()
    commit(GET_COUNTRIES, countries)
  },
  async [GET_REGION_DEPARTMENTS]({ commit }, params) {
    const regionDepartments = await this.$repositories.pricingData.getDepartments(
      params
    )
    commit(GET_REGION_DEPARTMENTS, regionDepartments)
  },
  async [GET_COUNTRY_REGIONS]({ commit }, params) {
    const countryRegions = await this.$repositories.pricingData.getRegions(
      params
    )
    commit(GET_COUNTRY_REGIONS, countryRegions)
  },
  async [GET_ACCONMMODATIONS]({ commit }) {
    const accommodations = await this.$repositories.pricingData.getAccommodations()
    commit(GET_ACCONMMODATIONS, accommodations)
  },
  async [GET_NUMBER_OF_NIGHTS]({ commit }) {
    const numberOfNights = await this.$repositories.pricingData.getNumberOfNights()
    commit(GET_NUMBER_OF_NIGHTS, numberOfNights)
  },
  async [GET_GROUP_NAMES]({ commit }) {
    const groupNames = await this.$repositories.pricingData.getGroupNames()
    commit(GET_GROUP_NAMES, groupNames)
  },
  async [GET_SEARCH_FILTERS]({ commit }) {
    const searchFilters = await this.$repositories.pricingData.getSearchFilters()
    commit(GET_SEARCH_FILTERS, searchFilters)
  },
  async [SEARCH_PRICING_DATA]({ commit }, params) {
    const searchResult = await this.$repositories.pricingData.getSearchPricingData(
      params
    )
    if (searchResult === 400) return 400
    commit(SEARCH_PRICING_DATA, searchResult)
  },
}

export default actions
