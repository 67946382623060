<template>
  <div class="responsive-fix">
    <Collapse
      :collapse="[collapseTitle]"
      :is-open="isOpen"
      class="mr-3 width-50"
    >
      <template slot="md-collapse-pane-1">
        <md-card-content>
          <md-card style="margin: 0">
            <md-card-content style="padding: 0">
              <div class="md-layout md-alignment-center-space-around sync-job">
                <div
                  class="md-layout-item md-size-30 md-large-size-30 md-medium-size-100 md-small-size-25 md-xsmall-size-100"
                >
                  <md-checkbox
                    style="color: red"
                    v-model="dataSyncJob.includeCampsiteInfos"
                    >Sync Campsite Photos</md-checkbox
                  >
                  <md-checkbox v-model="dataSyncJob.includeAccommInfos"
                    >Sync Accommodation Photos</md-checkbox
                  >
                  <md-checkbox v-model="dataSyncJob.retainCSSelections"
                    >Retain CS Selections</md-checkbox
                  >
                </div>
                <div
                  class="md-layout-item md-size-50 md-large-size-50 md-medium-size-60 md-small-size-50 md-xsmall-size-100"
                >
                  <md-button class="md-success" @click="handleRunJob"
                    >Sync Photos</md-button
                  >
                  <div class="md-layout">
                    <p class="last-update">
                      Last Updated Time: {{ lastUpdatedTime }}
                    </p>
                    <md-button
                      class="height-button md-icon-button md-raised md-success"
                      @click="handleRefreshLastUpdated"
                      style="margin-left: 15px"
                    >
                      <font-awesome-icon :icon="'undo-alt'" />
                      <md-tooltip md-direction="top"
                        >Refresh Last Updated Time</md-tooltip
                      >
                    </md-button>
                    <p class="last-update">
                      Last Updated Status: {{ lastUpdatedStatus }}
                    </p>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </md-card-content>
        <md-snackbar
          :md-position="position"
          :md-duration="duration"
          :md-active.sync="isSync"
          md-persistent
        >
          <h4>Sync Job successfully triggered</h4>
        </md-snackbar>
      </template>
    </Collapse>
    <Collapse
      :collapse="[collapseTitleSH]"
      :is-open="isOpenDetail"
      class="ml-3 width-50"
      style="width:50%"
    >
      <template slot="md-collapse-pane-1">
        <md-card-content>
          <md-card style="margin: 0">
            <md-card-content style="padding: 0">
              <div class="md-layout sync-job">
                <div
                  class="md-layout-item md-size-50 md-large-size-50 md-medium-size-60 md-small-size-50 md-xsmall-size-100"
                >
                  <md-button class="md-success" @click="handleRunJobDetails"
                    >Sync Details</md-button
                  >
                  <div class="md-layout">
                    <p class="last-update">
                      Last Updated Time: {{ lastUpdatedTimeDetail }}
                    </p>
                    <md-button
                      class="height-button md-icon-button md-raised md-success"
                      @click="handleRefreshLastUpdatedDetails"
                      style="margin-left: 15px"
                    >
                      <font-awesome-icon :icon="'undo-alt'" />
                      <md-tooltip md-direction="top"
                        >Refresh Last Updated Time</md-tooltip
                      >
                    </md-button>
                    <p class="last-update">
                      Last Updated Status: {{ lastUpdatedStatusDetail }}
                    </p>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </md-card-content>
        <md-snackbar
          :md-position="position"
          :md-duration="duration"
          :md-active.sync="isSyncDetail"
          md-persistent
        >
          <h4>Sync SH data successfully triggered</h4>
        </md-snackbar>
      </template>
    </Collapse>
  </div>
</template>

<script>
import Collapse from "@/views/components/ui/Collapse"
import { mapActions, mapState } from "vuex"
import {
  JOBS,
  CAMPSITES_DATA_SYNC_JOB_TRIGGER,
} from "@/modules/jobs/store/mutations-types"

import {
  CAMPSITES,
  CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "DataSyncJob",
  components: {
    Collapse,
  },
  data() {
    return {
      isOpen: false,
      isOpenDetail: false,
      collapseTitle: "SH Photos Sync",
      collapseTitleSH: "Sync SH data",
      lastUpdatedTime: "...Loading",
      lastUpdatedStatus: "...Loading",
      lastUpdatedTimeDetail: "...Loading",
      lastUpdatedStatusDetail: "...Loading",
      dataSyncJob: {
        job: "sh_photos_pull",
        estIdDelimitedStr: this.$route.params.id,
        includeCampsiteInfos: true,
        includeAccommInfos: true,
        retainCSSelections: true,
      },
      dataSHJobDetail: {
        job: "sh_campsites_pull",
        jobName: "campsites_summary_map",
        estIdDelimitedStr: this.$route.params.id,
      },
      position: "center",
      duration: 4000,
      isSync: false,
      isSyncDetail: false,
    }
  },
  mounted() {
    this.handleRefreshLastUpdated()
    this.handleRefreshLastUpdatedDetails()
  },
  computed: {
    ...mapState(CAMPSITES, {
      dataSyncJobItem: state => state.dataSyncJobItem,
      dataSyncJobItemDetail: state => state.dataSyncJobItemDetail,
    }),
  },
  methods: {
    ...mapActions(JOBS, {
      runDataSync: CAMPSITES_DATA_SYNC_JOB_TRIGGER,
    }),
    ...mapActions(CAMPSITES, {
      fetchDataSyncJobItem: CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM,
    }),
    async handleRunJob() {
      await this.runDataSync(this.dataSyncJob)
      this.isSync = true
    },
    async handleRefreshLastUpdated() {
      await this.fetchDataSyncJobItem({
        itemId: this.$route.params.id,
        jobName: this.dataSyncJob.job,
      })
      this.lastUpdatedTime = this.dataSyncJobItem[1].value
      this.lastUpdatedStatus = this.dataSyncJobItem[2].value
    },
    async handleRunJobDetails() {
      await this.runDataSync(this.dataSHJobDetail)
      this.isSyncDetail = true
    },
    async handleRefreshLastUpdatedDetails() {
      await this.fetchDataSyncJobItem({
        itemId: this.$route.params.id,
        jobName: this.dataSHJobDetail.jobName,
      })
      this.lastUpdatedTimeDetail = this.dataSyncJobItemDetail[1]?.value
        ? this.dataSyncJobItemDetail[1].value
        : "2000-00-00 00:00"
      this.lastUpdatedStatusDetail = this.dataSyncJobItemDetail[2]?.value
        ? this.dataSyncJobItemDetail[2].value
        : ""
    },
  },
}
</script>

<style scoped>
p.last-update {
  font-size: 15px;
  margin: 5px 0 5px 0;
}

.sync-job {
  padding-bottom: 30px;
  padding-top: 30px;
}
.height-button {
  height: 37px !important;
}
.responsive-fix {
  display: flex;
}
.width-50 {
  width: 50%;
}
@media screen and (max-width: 1280px) {
  .responsive-fix {
    display: block !important;
  }
  .width-50 {
    width: 100% !important;
    margin-bottom: 24px !important;
  }
  .ml-3 {
    margin-left: 0px !important;
  }
  .mr-3 {
    margin-right: 0px !important;
  }
}
</style>
