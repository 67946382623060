<template>
  <md-dialog
    :md-active.sync="isOpen"
    @md-clicked-outside="closeDialog"
    :style="dialogStyles"
    scrollable
  >
    <md-dialog-title>
      <b>Add Feature</b>

      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="closeDialog"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <div v-if="isOpen" style="overflow: overlay;">
      <AddAmenitiesForm @onFormSubmit="handleFormSubmit" :type="type" />
    </div>
  </md-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog.mixin"
import AddAmenitiesForm from "../forms/AddAmenitiesForm.vue"
export default {
  name: "AddAmenitiesDialog",
  props: {
    isShowDialog: Boolean,
    onDialogClose: Function,
    type: String,
  },
  components: {
    AddAmenitiesForm,
  },
  mixins: [dialogMixin],
  data: () => ({
    dialogStyles: {
      "max-width": "600px",
    },
  }),
  methods: {
    async handleFormSubmit() {
      this.closeDialog()
    },
  },
}
</script>
