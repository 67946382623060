import {
  FETCH_CAMPSITE_EXTRAS,
  SELECTED_CAMPSITE_EXTRAS,
  CLEAR_CAMPSITE_EXTRAS_RECORDS,
} from "@/modules/campsite-extras/store/mutations-types"

const mutations = {
  [FETCH_CAMPSITE_EXTRAS](state, payload) {
    state.campsiteExtras = payload
  },
  [SELECTED_CAMPSITE_EXTRAS](state, payload) {
    state.selectedCampsiteExtra = payload
  },
  [CLEAR_CAMPSITE_EXTRAS_RECORDS](state) {
    state.campsiteExtras.rows = []
  },
}

export default mutations
