import actions from "@/modules/campsite-extras/store/actions"
import mutations from "@/modules/campsite-extras/store/mutations"

const state = () => ({
  campsiteExtras: {},
  selectedCampsiteExtra: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
