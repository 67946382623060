<template>
  <div class="report-map-container">
    <GoogleMap
      v-if="mapsMarkers.length"
      :mapStyle="mapStyle"
      :markers="mapsMarkers"
    />
  </div>
</template>

<script>
import GoogleMap from "@/views/components/ui/GoogleMap"
import { REPORTS, REPORTS_FETCH_DETAILS } from "../../store/mutations-types"
import { mapActions } from "vuex"

export default {
  name: "MapsPage",
  data() {
    return {
      mapStyle: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        bottom: 0,
      },
    }
  },
  async created() {
    await this.fetchDetails({ reportKey: this.reportKey })
  },
  components: { GoogleMap },
  computed: {
    mapsMarkers() {
      const data = this.$store.state[REPORTS].markers
      const googleMapsData = data.map(item =>
        this.normalizeDataForGoogleMaps(item)
      )
      return googleMapsData
    },
    reportKey() {
      return this.$route.params.reportKey
    },
  },
  methods: {
    ...mapActions(REPORTS, {
      fetchDetails: REPORTS_FETCH_DETAILS,
    }),
    normalizeDataForGoogleMaps(item) {
      const mapTitle = item.region ? item.region : item.department

      return {
        infoText: `<p><b>${mapTitle}</b></p>Booking value: ${item.booking_total_value}`,
        label: {
          text: item.num_of_bookings,
          color: "#fff",
        },
        position: {
          lat: Number.parseFloat(item.lat),
          lng: Number.parseFloat(item.lng),
        },
      }
    },
  },
}
</script>

<style scoped>
.report-map-container {
  min-height: calc(100vh - 116px);
  position: relative;
}
</style>
