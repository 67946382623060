import {
  SYSTEM_ACTIONS_FETCH_ACTIONS_LIST,
  SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL,
} from "@/modules/system-actions/store/mutations-type"

const mutations = {
  [SYSTEM_ACTIONS_FETCH_ACTIONS_LIST](state, payload) {
    state.actionsList = payload
  },
  [SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL](state, payload) {
    state.datasourcesStatusList = payload
  },
}
export default mutations
