export default $axios => resource => ({
  getLevel1Items(lang) {
    return $axios.get(`${resource}/smartnav/level1items?lang=${lang}`)
  },
  getLevel2Items(input) {
    return $axios.get(
      `${resource}/smartnav/level2items?lang=${input.lang}&level1ItemKey=${input.key}`
    )
  },
  getItemsAssociatedWithLevel(input) {
    return $axios.get(
      `${resource}/smartnav/urlitems?parentItem=${input.parentItem}&category=${input.category}`
    )
  },
  getLevel2Categories(input) {
    return $axios.get(
      `${resource}/smartnav/urlitemcategories?lang=${input.lang}&categoryGroup=${input.category}`
    )
  },
  getLevel2CategoriesRef(input) {
    return $axios.get(
      `${resource}/smartnav/urlitemcategoriesrefdata?lang=${input.language}&parentItem=${input.parentItem}`
    )
  },
  getUrlsList(input) {
    return $axios.get(
      `${resource}/smartnav/urls?label=${input.label}&lang=${input.lang}&parentItem=${input.parentItem}&sitemapLevel=${input.sitemapLevel}`
    )
  },
  getSupportedLanguages() {
    return $axios.get(`${resource}/static/supportedlanguages`)
  },
  getSitemapLevels() {
    return $axios.get(`${resource}/smartnav/refdata/sitemaplevels`)
  },
  getMobileMenuStyles() {
    return $axios.get(`${resource}/smartnav/refdata/mobilemenustyles`)
  },
  addLevel2Item(data) {
    return $axios.post(`${resource}/smartnav/addlevel2item`, data)
  },
  addLevel2Category(data) {
    return $axios.post(`${resource}/smartnav/addurlitemcategory`, data)
  },
  updateLevel2Category(data) {
    return $axios.post(`${resource}/smartnav/updateurlitemcategory`, data)
  },
  addUrlItems(data) {
    return $axios.post(`${resource}/smartnav/addurlitem`, data)
  },
  updateUrlItem(data) {
    return $axios.post(`${resource}/smartnav/updateurlitem`, data)
  },
  updateLevel2Item(data) {
    return $axios.post(`${resource}/smartnav/updatelevel2item`, data)
  },
  swapUrlItem(data) {
    return $axios.post(`${resource}/smartnav/saveurlitempositions`, data)
  },
  swapLevel2Item(data) {
    return $axios.post(`${resource}/smartnav/savelevel2itempositions`, data)
  },
  removeLevel2Item(data) {
    return $axios.delete(
      `${resource}/smartnav/removelevel2item?level2ItemKey=${data.level2ItemKey}`
    )
  },
  removeLevel2Category(id) {
    return $axios.delete(`${resource}/smartnav/removeurlitemcategory?id=${id}`)
  },
  removeUrlItems(id) {
    return $axios.delete(`${resource}/smartnav/removeurlitem?id=${id}`)
  },
  getCategoriesGroup(input) {
    return $axios.get(
      `${resource}/smartnav/urlitemcategorygroups?lang=${input}`
    )
  },
  getCategoriesGroupRef(input) {
    return $axios.get(
      `${resource}/smartnav/urlitemcategorygroupsrefdata?lang=${input}`
    )
  },
  addCategoryGroup(data) {
    return $axios.post(`${resource}/smartnav/addurlitemcategorygroup`, data)
  },
  updateCategoryGroup(data) {
    return $axios.post(`${resource}/smartnav/updateurlitemcategorygroup`, data)
  },
  removeCategoryGroup(id) {
    return $axios.delete(
      `${resource}/smartnav/removeurlitemcategorygroup?id=${id}`
    )
  },
  swapCategoriesPosition(data) {
    return $axios.post(
      `${resource}/smartnav/saveurlitemcategorypositions`,
      data
    )
  },
  getSyncJobs() {
    return $axios.get(`${resource}/syncjob/job?jobName=wp_url_sync`)
  },
})
