import actions from "@/modules/bookings/store/actions"
import mutations from "@/modules/bookings/store/mutations"

export const initBookingItem = {
  details: {},
  pricing: [],
  adjustments: {},
  adjustmentFields: [],
  comments: {},
  statusHistory: {},
  invoiceInfo: {},
  statuses: [],
  postStatuses: [],
  bookingRejectionList: [],
  promoCodes: [],
  invoiceStatuses: [],
  commissionStatuses: [],
  extendedData: [],
  insurance: [],
  weatherInsuranceData: [],
}

const state = () => ({
  bookings: {},
  bookingItem: {},
  bookingItemPricing: [],
  bookingItemAdjustments: {},
  bookingItemAdjustmentFields: [],
  bookingItemComments: {},
  bookingItemStatusHistory: {},
  bookingItemInvoiceInfo: {},
  bookingStatuses: [],
  bookingInvoiceStatuses: [],
  bookingCommissionStatuses: [],
  bookingProposals: [],
  addProposalResponse: [],
  specificProposal: {},
  list: {},
  issuesList: {},
  item: initBookingItem,
  searchForm: {},
  searchPaymentIssuesForm: {},
  statusPaymentBookingForm: {},
  dataJobTrigger: {},
  // dataSyncJobItem: {},
  languageList: [],
  wfaLanguageList: [],
  typeList: [],
  currencyList: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
