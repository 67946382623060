<template>
  <div>
    <div v-for="item in bookingItemInsurance" :key="item.label" class="mt-4">
      <DetailsCollapse
        :is-open="false"
        :is-loading="isFetching"
        :label="item.label"
        :elements="item.dataItems"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_INSURANCE,
} from "@/modules/bookings/store/mutations-types"
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"

export default {
  name: "BookingInsuranceOption",
  components: {
    DetailsCollapse,
  },
  props: {
    transId: String,
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    bookingItemInsurance() {
      return this.$store.state[BOOKINGS].item.insurance
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_INSURANCE]),
    async fetchBookingInsuranceData() {
      if (this.transId) {
        this.isFetching = true
        await this[BOOKINGS_FETCH_ITEM_INSURANCE]({
          transId: this.transId,
        })
        setTimeout(() => {
          this.isFetching = false
        }, 500)
      }
    },
  },
  mounted() {
    this.fetchBookingInsuranceData()
  },
}
</script>

<style scoped></style>
