import AppLayout from "@/views/layouts/AppLayout"
import HomePage from "@/views/pages/app/HomePage"
import CampsitePhotosPage from "@/views/pages/app/CampsitePhotosPage"
import AccomodationPhotosPage from "@/views/pages/app/AccomodationPhotosPage"
import SearchBookingsPage from "@/modules/bookings/views/pages/SearchBookingsPage"
import BookingPage from "@/modules/bookings/views/pages/BookingPage"
import BookingsProposalPage from "@/modules/bookings/views/pages/BookingsProposalPage"
import SearchPromoCodesPage from "@/modules/promo-codes/views/pages/SearchPromoCodesPage"
import SearchCampsitesPage from "@/modules/campsites/views/pages/SearchCampsitesPage"
import CampsitePage from "@/modules/campsites/views/pages/CampsitePage"
import FeedJobs from "@/modules/feed-jobs/views/pages/FeedJobs"
import LandingSupport from "@/modules/landing-support/views/pages/LandingSupport"
import AccommodationDetailsPage from "@/modules/campsites/views/pages/AccommodationDetailsPage"
import TranslationsPage from "@/modules/translations/views/TranslationsPage"
import RankingPage from "@/modules/ranking/views/pages/RankingPage"
import Notifications from "@/modules/notifications/views/pages/Notifications"
import OutstandingBalances from "@/modules/outstanding-balances/views/pages/OutstandingBalances"
import SyncJobs from "@/modules/sync-jobs/views/pages/SyncJobs"
import JobSchedules from "@/modules/job-schedules/views/pages/JobSchedules"
import CampsiteExtrasPage from "@/modules/campsite-extras/views/pages/CampsiteExtras"
import SearchBookingPaymentIssuesPage from "@/modules/bookings/views/pages/SearchBookingPaymentIssuesPage"
import ReportsPage from "@/modules/reports/views/pages/ReportsPage"
import ReportPage from "@/modules/reports/views/pages/ReportPage"
import ReportMapPage from "@/modules/reports/views/pages/ReportMapPage"
import Pricing from "@/modules/pricing-data/views/pages/PricingDataPage"
import ProductConfigurationPage from "@/modules/product-configuration/views/pages/ProductConfigurationPage"
import SupportPage from "@/modules/support/views/pages/SupportPage"
import TechSupportPage from "@/modules/tech-support/views/pages/TechSupportPage"
import SystemActions from "@/modules/system-actions/views/pages/SystemActionsPage"
import SitemapUrls from "@/modules/sitemap-urls/views/pages/SitemapUrlsPage"
import SmartNavConfigurationPage from "@/modules/smartNav-configuration/views/pages/SmartNavConfigurationPage"

export default {
  path: "/",
  component: AppLayout,
  redirect: "/home",
  children: [
    {
      path: "home",
      name: "Home",
      component: HomePage,
      meta: {
        requiresRoutApi: "home",
      },
    },
    {
      path: "bookings",
      name: "Bookings",
      components: { default: SearchBookingsPage },
      meta: {
        requiresRoutApi: "bookings",
      },
    },
    {
      path: "bookings/:transId",
      name: "Booking pages",
      components: { default: BookingPage },
      props: true,
      meta: {
        requiresRoutApi: "bookings",
      },
    },
    {
      path: "booking_issues",
      name: "Booking Payment Issues",
      components: { default: SearchBookingPaymentIssuesPage },
      meta: {
        requiresRoutApi: "bookings",
      },
    },
    {
      path: "promo_codes",
      name: "Promo Codes",
      components: { default: SearchPromoCodesPage },
      meta: {
        requiresRoutApi: "promo_codes",
      },
    },
    {
      path: "campsites",
      name: "Campsites",
      components: { default: SearchCampsitesPage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "campsites/:id",
      name: "Campsite pages",
      components: { default: CampsitePage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "campsite/:id/photos",
      name: "Campsite Photos",
      components: { default: CampsitePhotosPage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "accommodation/:id/photos",
      name: "Accommodation Photos",
      components: { default: AccomodationPhotosPage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "accommodation/:id",
      name: "Accommodation Details Page",
      components: { default: AccommodationDetailsPage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "reports",
      name: "Reports",
      components: { default: ReportsPage },
      meta: {
        requiresRoutApi: "reports",
      },
    },
    {
      path: "proposals",
      name: "Proposals",
      components: { default: BookingsProposalPage },
      meta: {
        requiresRoutApi: "proposals",
      },
    },
    {
      path: "reports/:reportKey",
      name: "Reports page",
      components: { default: ReportPage },
      props: true,
      meta: {
        requiresRoutApi: "reports",
      },
    },
    {
      path: "reports/:reportKey/map",
      name: "Reports Map",
      components: { default: ReportMapPage },
      props: true,
      meta: {
        requiresRoutApi: "reports",
      },
    },
    {
      path: "feed_Jobs",
      name: "Feed Jobs",
      components: { default: FeedJobs },
      meta: {
        requiresRoutApi: "feed_jobs",
      },
    },
    {
      path: "landing_support",
      name: "Landing Support",
      components: { default: LandingSupport },
      meta: {
        requiresRoutApi: "landing_support",
      },
    },
    {
      path: "translations",
      name: "Translations",
      components: { default: TranslationsPage },
      meta: {
        requiresRoutApi: "translations",
      },
    },
    {
      path: "campsite_ranking",
      name: "Ranking",
      components: { default: RankingPage },
      meta: {
        requiresRoutApi: "campsite_ranking",
      },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: {
        requiresRoutApi: "notifications",
      },
    },
    {
      path: "outstanding_balances",
      name: "Outstanding balances",
      components: { default: OutstandingBalances },
      meta: {
        requiresRoutApi: "outstanding_balances",
      },
    },
    {
      path: "tech_support/sync_jobs",
      name: "Sync Jobs",
      components: { default: SyncJobs },
      meta: {
        requiresRoutApi: "sync_jobs",
      },
    },
    {
      path: "tech_support/job_schedules",
      name: "Job Schedules",
      components: { default: JobSchedules },
      meta: {
        requiresRoutApi: "job_schedules",
      },
    },
    {
      path: "pricing",
      name: "Pricing",
      components: { default: Pricing },
      meta: {
        requiresRoutApi: "pricing",
      },
    },
    {
      path: "product_configuration",
      name: "Product Configuration",
      components: { default: ProductConfigurationPage },
      meta: {
        requiresRoutApi: "csproducts",
      },
    },
    {
      path: "support",
      name: "Support",
      components: { default: SupportPage },
      meta: {
        requiresRoutApi: "support",
      },
    },
    {
      path: "tech_support",
      name: "Tech Support",
      components: { default: TechSupportPage },
      meta: {
        requiresRoutApi: "tech_support",
      },
    },
    {
      path: "tech_support/system_actions",
      name: "System Actions",
      components: { default: SystemActions },
      meta: {
        requiresRoutApi: "tech_support",
      },
    },
    {
      path: "tech_support/sitemap_urls",
      name: "Sitemap Urls",
      components: { default: SitemapUrls },
      meta: {
        requiresRoutApi: "sitemap_urls",
      },
    },
    {
      path: "smart_nav_configuration",
      name: "SmartNav Configuration",
      components: { default: SmartNavConfigurationPage },
      meta: {
        requiresRoutApi: "csproducts",
      },
    },
    {
      path: "campsite_extras",
      name: "Campsite Extras",
      components: { default: CampsiteExtrasPage },
      meta: {
        requiresRoutApi: "csproducts",
      },
    },
  ],
}
