<template>
  <div>
    <div v-for="item in bookingItemPricing" :key="item.label" class="mt-4">
      <DetailsCollapse
        :is-open="false"
        :is-loading="isFetching"
        :label="item.label"
        :elements="item.dataItems"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_PRICING,
} from "@/modules/bookings/store/mutations-types"
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"

export default {
  name: "BookingItemPricing",
  components: {
    DetailsCollapse,
  },
  props: {
    transId: String,
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    bookingItemPricing() {
      return this.$store.state[BOOKINGS].item.pricing
    },
    bookingPricingElements() {
      return this.item.bookingItemPricing.dataItems || []
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_PRICING]),
    async fetchBookingPricingData() {
      if (this.transId) {
        this.isFetching = true
        await this[BOOKINGS_FETCH_ITEM_PRICING]({
          transId: this.transId,
        })
        setTimeout(() => {
          this.isFetching = false
        }, 500)
      }
    },
  },
  mounted() {
    this.fetchBookingPricingData()
  },
}
</script>

<style scoped></style>
