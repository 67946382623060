import {
  PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST,
  UPDATE_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION,
  SET_IS_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION_LANGUAGE,
  GET_PRODUCT_CONFIGURATION_PRODUCT_KEY,
  REFRESH_PRODUCT_CONFIGURATION_CACHE,
} from "@/modules/product-configuration/store/mutations-type"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST]({ commit }) {
    const response = await this.$repositories.productConfiguration.fetchProductConfigurationsList()
    const data = getGridDataItemFirstCollection(response)
    commit(PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST, data)
  },
  async [GET_PRODUCT_CONFIGURATION_LANGUAGE]({ commit }) {
    const response = await this.$repositories.productConfiguration.getProductConfigurationLanguages()
    commit(GET_PRODUCT_CONFIGURATION_LANGUAGE, response.data)
  },
  async [GET_PRODUCT_CONFIGURATION_PRODUCT_KEY]({ commit }) {
    const response = await this.$repositories.productConfiguration.getProductConfigurationProductKey()
    commit(GET_PRODUCT_CONFIGURATION_PRODUCT_KEY, response.data)
  },
  async [UPDATE_PRODUCT_CONFIGURATION]({ commit }, data) {
    let payload = data.pop()
    await this.$repositories.productConfiguration.updateProductConfigurations(
      payload,
      data.pop(),
      data.pop()
    )
    commit(UPDATE_PRODUCT_CONFIGURATION, payload)
  },
  async [REFRESH_PRODUCT_CONFIGURATION_CACHE]({ commit }) {
    await this.$repositories.productConfigurationBooking.refreshProductConfigurationBooking()
    commit(REFRESH_PRODUCT_CONFIGURATION_CACHE)
  },
  async [GET_PRODUCT_CONFIGURATION]({ commit }, data) {
    try {
      const response = await this.$repositories.productConfiguration.getProductConfigurations(
        data.pop(),
        data.pop()
      )
      const item = getGridDataItemFirstCollection(response)
      const [
        { value: productKey },
        { value: language },
        { value: title },
        { value: introText },
        { value: primaryCtaLabel },
        { value: primaryCtaUrl },
        { value: primaryCtaNewTab },
        { value: secondaryCtaLabel },
        { value: secondaryCtaUrl },
        { value: secondaryCtaNewTab },
        { value: tooltipText },
        { value: landingHeading },
        { value: landingSubHeading },
        { value: active },
        { value: displayOrder },
        { value: activeLangs },
      ] = item.rows[0].row
      const storeItem = {
        productKey: productKey,
        language: language,
        title: title,
        introText: introText,
        primaryCtaLabel: primaryCtaLabel,
        primaryCtaUrl: primaryCtaUrl,
        primaryCtaNewTab: primaryCtaNewTab,
        secondaryCtaLabel: secondaryCtaLabel,
        secondaryCtaUrl: secondaryCtaUrl,
        secondaryCtaNewTab: secondaryCtaNewTab,
        tooltipText: tooltipText,
        landingHeading: landingHeading,
        landingSubHeading: landingSubHeading,
        active: active,
        displayOrder: displayOrder,
        activeLangs: activeLangs.split(","),
      }

      commit(GET_PRODUCT_CONFIGURATION, storeItem)
      commit(SET_IS_PRODUCT_CONFIGURATION, true)
    } catch (err) {
      commit(SET_IS_PRODUCT_CONFIGURATION, false)
    }
  },
}

export default actions
