import {
  BOOKINGS_CLEAR_ITEM,
  BOOKINGS_UPDATE_SEARCH_STATUSES,
  BOOKINGS_UPDATE_POST_STATUSES,
  BOOKINGS_CREATE_ITEM_ADJUSTMENTS,
  BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_UPDATE_COMMISSION_STATUSES,
  BOOKINGS_UPDATE_INVOICE_STATUSES,
  BOOKINGS_UPDATE_ITEM_DETAILS,
  BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_UPDATE_ITEM_ADJUSTMENTS,
  BOOKINGS_UPDATE_ITEM_COMMENTS,
  BOOKINGS_UPDATE_ITEM_INVOICE_INFO,
  BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT,
  BOOKINGS_UPDATE_ITEM_PRICING,
  BOOKINGS_UPDATE_ITEM_STATUS_HISTORY,
  BOOKINGS_UPDATE_LIST,
  BOOKINGS_PROMO_CODES,
  BOOKINGS_FETCH_PROPOSALS,
  BOOKING_SEND_PROPOSAL,
  BOOKINGS_FETCH_SPECIFIC_PROPOSAL,
  BOOKINGS_UPDATE_SEARCH_FORM,
  BOOKINGS_UPDATE_EXTENDED_DATA,
  BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST,
  BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM,
  BOOKINGS_FETCH_POST_PAYMENT_STATUSES,
  BOOKINGS_FETCH_ITEM_INSURANCE,
  BOOKINGS_FETCH_LANGUAGE,
  BOOKINGS_FETCH_WEATHER_INSURANCE,
  BOOKINGS_FETCH_REJECTION_REASONS_LIST,
  BOOKINGS_FETCH_TYPE,
  BOOKINGS_FETCH_CURRENCE,
  BOOKINGS_FETCH_WFA_LANGUAGE,
} from "@/modules/bookings/store/mutations-types"
import {
  getRowCellByColumnName,
  getRowCellsByColumnNames,
} from "@/utils/table.utils"
import { initBookingItem } from "@/modules/bookings/store/index"

const mutations = {
  [BOOKINGS_CLEAR_ITEM](state) {
    state.item = initBookingItem
  },
  [BOOKINGS_UPDATE_SEARCH_FORM](state, payload) {
    state.searchForm = payload
  },
  [BOOKINGS_UPDATE_ITEM_INVOICE_INFO](state, payload) {
    state.item.invoiceInfo = payload
  },
  [BOOKINGS_UPDATE_INVOICE_STATUSES](state, payload) {
    state.item.invoiceStatuses = payload
  },
  [BOOKINGS_UPDATE_COMMISSION_STATUSES](state, payload) {
    state.item.commissionStatuses = payload
  },
  [BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS](state, payload) {
    const { id: adjustmentId } = payload
    const stateAdjustmentsRows = state.item.adjustments.rows
    const updatedAdjustmentRows = stateAdjustmentsRows.filter(adjRow => {
      const isRowToRemove = adjRow.row.find(row => {
        const { name, value } = row
        return name === "id" && value === adjustmentId
      })
      return !isRowToRemove
    })
    const isRowRemoved =
      updatedAdjustmentRows.length < stateAdjustmentsRows.length

    if (isRowRemoved) {
      state.item.adjustments = {
        ...state.item.adjustments,
        rows: updatedAdjustmentRows,
        numberOfRows: state.item.adjustments.numberOfRows - 1,
      }
    }
  },
  [BOOKINGS_UPDATE_ITEM_STATUS_HISTORY](state, payload) {
    state.item.statusHistory = payload
  },
  [BOOKINGS_UPDATE_ITEM_COMMENTS](state, payload) {
    state.item.comments = payload
  },
  [BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS](state, payload) {
    state.item.adjustmentFields = payload
  },
  [BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT](state, payload) {
    const { transId, id: adjustmentId, newValue, comment } = payload
    const { parentEntityId, rows } = state.item.adjustments
    if (+parentEntityId !== +transId) return

    const rowToUpdate = rows.find(({ row }) => {
      const idCell = getRowCellByColumnName(row, "id")
      return idCell.value === adjustmentId
    })
    const cellsToUpdate = getRowCellsByColumnNames(rowToUpdate.row, [
      "new_value",
      "comment",
    ])
    cellsToUpdate.comment.value = comment
    cellsToUpdate.new_value.value = newValue
  },
  [BOOKINGS_UPDATE_ITEM_ADJUSTMENTS](state, payload) {
    state.item.adjustments = payload
  },
  [BOOKINGS_CREATE_ITEM_ADJUSTMENTS](state, payload) {
    state.item.adjustments = payload
  },
  [BOOKINGS_UPDATE_ITEM_PRICING](state, payload) {
    state.item.pricing = payload
  },
  [BOOKINGS_UPDATE_ITEM_DETAILS](state, payload) {
    state.item.details = payload
  },
  [BOOKINGS_UPDATE_LIST](state, payload) {
    state.list = payload
  },
  [BOOKINGS_UPDATE_SEARCH_STATUSES](state, payload) {
    state.item.statuses = payload
  },
  [BOOKINGS_UPDATE_POST_STATUSES](state, payload) {
    state.item.postStatuses = payload
  },
  [BOOKINGS_PROMO_CODES](state, payload) {
    state.item.promoCodes = payload
  },
  [BOOKINGS_UPDATE_EXTENDED_DATA](state, payload) {
    state.item.extendedData = payload
  },
  [BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM](state, payload) {
    state.searchPaymentIssuesForm = payload
  },
  [BOOKINGS_FETCH_POST_PAYMENT_STATUSES](state, payload) {
    state.statusPaymentBookingForm = payload
  },
  [BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST](state, payload) {
    state.issuesList = payload
  },
  [BOOKINGS_FETCH_PROPOSALS](state, payload) {
    state.bookingProposals = payload
  },
  [BOOKING_SEND_PROPOSAL](state, payload) {
    state.addProposalResponse = payload
  },
  [BOOKINGS_FETCH_SPECIFIC_PROPOSAL](state, payload) {
    state.specificProposal = payload
  },
  [BOOKINGS_FETCH_ITEM_INSURANCE](state, payload) {
    state.item.insurance = payload
  },
  [BOOKINGS_FETCH_LANGUAGE](state, payload) {
    state.languageList = payload
  },
  [BOOKINGS_FETCH_WFA_LANGUAGE](state, payload) {
    state.wfaLanguageList = payload
  },
  [BOOKINGS_FETCH_WEATHER_INSURANCE](state, payload) {
    state.weatherInsuranceData = payload
  },
  [BOOKINGS_FETCH_REJECTION_REASONS_LIST](state, payload) {
    state.item.bookingRejectionList = payload
  },
  [BOOKINGS_FETCH_TYPE](state, payload) {
    state.typeList = payload
  },
  [BOOKINGS_FETCH_CURRENCE](state, payload) {
    state.currencyList = payload
  },
}

export default mutations
