<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Update invoice info</md-dialog-title>

      <md-dialog-content>
        <UpdateBookingInvoiceInfoForm
          :invoice-info="invoiceInfo"
          :is-form-sending="isFormSending"
          :invoice-statuses-list="bookingInvoiceStatuses"
          :commission-statuses-list="bookingCommissionStatuses"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleUpdateFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_COMMISSION_STATUSES,
  BOOKINGS_FETCH_INVOICE_STATUSES,
  BOOKINGS_UPDATE_ITEM_INVOICE_INFO,
} from "@/modules/bookings/store/mutations-types"
import UpdateBookingInvoiceInfoForm from "@/modules/bookings/views/components/forms/UpdateBookingInvoiceInfoForm"
import dialogMixin from "@/mixins/dialog.mixin"
import { getInvoiceRowData } from "@/modules/bookings/utils/booking-invoice.utils"

export default {
  name: "UpdateBookingInvoiceInfoDialog",
  props: {
    invoiceInfo: {
      type: Array,
      default: () => [],
    },
    transId: String,
  },
  mixins: [dialogMixin],
  components: {
    UpdateBookingInvoiceInfoForm,
  },
  computed: {
    bookingInvoiceStatuses() {
      return this.$store.state[BOOKINGS].item.invoiceStatuses
    },
    bookingCommissionStatuses() {
      return this.$store.state[BOOKINGS].item.commissionStatuses
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_UPDATE_ITEM_INVOICE_INFO,
      BOOKINGS_FETCH_INVOICE_STATUSES,
      BOOKINGS_FETCH_COMMISSION_STATUSES,
    ]),
    fetchInvoiceStatuses() {
      const { invoiceStatuses, commissionStatuses } = this.$store.state[
        BOOKINGS
      ].item

      if (invoiceStatuses.length === 0) {
        this[BOOKINGS_FETCH_INVOICE_STATUSES]()
      }

      if (commissionStatuses.length === 0) {
        this[BOOKINGS_FETCH_COMMISSION_STATUSES]()
      }
    },
    async handleUpdateFormSubmit(values) {
      this.isFormSending = true
      const {
        commissionAmt,
        invoiceDueDate,
        invoicePaidDate,
      } = getInvoiceRowData(this.invoiceInfo)

      await this[BOOKINGS_UPDATE_ITEM_INVOICE_INFO]({
        transId: this.transId,
        commissionAmt,
        invoiceDueDate,
        invoicePaidDate,
        ...values,
      })
      this.isFormSending = false
      this.closeDialog()
    },
  },
  mounted() {
    this.fetchInvoiceStatuses()
  },
}
</script>

<style scoped></style>
