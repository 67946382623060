import {
  GET_COUNTRIES,
  GET_REGION_DEPARTMENTS,
  GET_COUNTRY_REGIONS,
  GET_ACCONMMODATIONS,
  GET_NUMBER_OF_NIGHTS,
  GET_GROUP_NAMES,
  GET_SEARCH_FILTERS,
  SEARCH_PRICING_DATA,
} from "@/modules/pricing-data/store/mutations-types"

const mutations = {
  [GET_COUNTRIES](state, payload) {
    const {
      data: {
        result: { csRefDataList: countries },
      },
    } = payload
    state.countries = countries
  },
  [GET_REGION_DEPARTMENTS](state, payload) {
    const {
      data: {
        result: { csRefDataList: regionDepartments },
      },
    } = payload
    state.regionDepartments = regionDepartments
  },
  [GET_COUNTRY_REGIONS](state, payload) {
    const {
      data: {
        result: { csRefDataList: countryRegions },
      },
    } = payload
    state.countryRegions = countryRegions
  },
  [GET_ACCONMMODATIONS](state, payload) {
    const {
      data: {
        result: { csRefDataList: accommodations },
      },
    } = payload
    state.accommodations = accommodations
  },
  [GET_NUMBER_OF_NIGHTS](state, payload) {
    const {
      data: {
        result: { csRefDataList: numberOfNights },
      },
    } = payload
    state.numberOfNights = numberOfNights
  },
  [GET_GROUP_NAMES](state, payload) {
    const {
      data: {
        result: { csRefDataList: groupNames },
      },
    } = payload
    state.groupNames = groupNames
  },
  [GET_SEARCH_FILTERS](state, payload) {
    const {
      data: {
        result: { csRefDataList: searchFilters },
      },
    } = payload
    state.searchFilters = searchFilters
  },
  [SEARCH_PRICING_DATA](state, payload) {
    const {
      data: {
        result: { gridDataItemCollectionList },
      },
    } = payload
    const [searchResponse] = gridDataItemCollectionList
    state.searchResult = searchResponse
  },
}

export default mutations
