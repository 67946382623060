<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <AppSidebar
      :background-color="sidebarBackgroundColor"
      :background-image="sidebarBackgroundImage"
    />

    <div class="main-panel">
      <top-navbar></top-navbar>

      <theme-settings
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </theme-settings>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { ZoomCenterTransition } from "vue2-transitions"

import TopNavbar from "@/views/components/ui/TopNavBar"
import ContentFooter from "@/views/components/ui/ContentFooter"
import ThemeSettings from "@/views/components/ui/ThemeSettings.vue"
import AppSidebar from "@/views/components/ui/SideBar/AppSidebar"
import appSidebarMixin from "@/mixins/app-sidebar.mixin"
import initAuthUser from "@/mixins/init-user.mixin"

export default {
  name: "AppLayout",
  components: {
    AppSidebar,
    TopNavbar,
    ContentFooter,
    ThemeSettings,
    ZoomCenterTransition,
  },
  mixins: [initAuthUser, appSidebarMixin],
}
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
