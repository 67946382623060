import { CAMPSITES_DATA_SYNC_JOB_TRIGGER } from "@/modules/jobs/store/mutations-types"

const actions = {
  async [CAMPSITES_DATA_SYNC_JOB_TRIGGER]({ commit }, data) {
    await this.$repositories.jobs.campsitedJobTrigger(data)
    commit(CAMPSITES_DATA_SYNC_JOB_TRIGGER, data)
  },
}

export default actions
