<template>
  <div class="px-5">
    <div class="label-field pl-0 mb-2">Lang: {{ language }}</div>
    <form novalidate class="md-layout " @submit.prevent>
      <template>
        <div class="label-field" v-if="!mutipleAmenitiesCategoires">
          Category: {{ getAmenitiesCategoires[0] }}
        </div>
        <md-field v-else :class="showValidationErros()" @md-selected="validate">
          <label>Category</label>
          <md-select
            v-model="category"
            name="catgory"
            id="catgory"
            @md-selected="onCategoryChange"
          >
            <md-option
              v-for="category in getAmenitiesCategoires"
              :key="category"
              :value="category"
              >{{ category }}
            </md-option>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.category"
          >
            This field is required !
          </span>
        </md-field>

        <md-field>
          <label>Sub Category</label>
          <md-select v-model="subcategory" name="subcategory" id="subcategory">
            <template v-if="!loadingSubCategories">
              <template v-if="categorySelected">
                <md-option value="">
                  None
                </md-option>
                <md-option
                  v-for="subcategory in subcategoryOptions"
                  :key="subcategory"
                  :value="subcategory"
                  >{{ subcategory || "None" }}
                </md-option>
              </template>
              <md-option value="" v-else disabled>
                Select Category
              </md-option>
            </template>
            <md-option value="" v-else disabled>
              Loading Sub-Categories
            </md-option>
          </md-select>
        </md-field>

        <md-field :class="showValidationErros()">
          <label>Label</label>
          <md-select
            v-model="label"
            name="label"
            id="label"
            @md-selected="validate"
          >
            <template v-if="!loadingLabels">
              <template v-if="categorySelected">
                <md-option value="">
                  None
                </md-option>
                <md-option
                  v-for="label in labelOptions"
                  :key="label"
                  :value="label"
                  >{{ label || "None" }}
                </md-option>
              </template>
              <md-option value="" v-else disabled>
                Select Category
              </md-option>
            </template>
            <md-option value="" v-else disabled>
              Loading Labels
            </md-option>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.label"
          >
            This field is required !
          </span>
        </md-field>

        <md-field>
          <label>
            Distance KM
          </label>
          <md-input v-model="distance" type="number"> </md-input>
        </md-field>

        <md-field>
          <label>
            Additional Info
          </label>
          <md-input v-model="additionalInfo" type="text"> </md-input>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="addAmenities()"
          >
            ADD
          </md-button>
        </div>
      </template>
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="apiInProgress"
      />
    </form>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import {
  CAMPSITES,
  ADD_STAY_FACILITIES_AMENITIES,
  SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
  SET_FACILITIES_AMENITIES_LABELS,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "AddAmenitiesForm",

  props: {
    type: String,
  },
  data: () => ({
    loadingLabels: false,
    loadingSubCategories: false,
    apiInProgress: false,

    language: "EN",
    category: "",
    subcategory: "",
    label: "",
    distance: "",
    additionalInfo: "",

    validation: {
      category: false,
      label: false,
      showErrors: false,
    },
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
    }),
    ...mapActions(CAMPSITES, {
      addStayFacilitiesAmenities: ADD_STAY_FACILITIES_AMENITIES,
      setAmenitiesSubCategories: SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
      setAmenitiesLabels: SET_FACILITIES_AMENITIES_LABELS,
    }),

    // Initialize the Level 2 Items
    async init() {
      this.amenityData = this.selectedAmenity
      await this.getSupportedLanguages()
      if (!this.mutipleAmenitiesCategoires) {
        const category = this.getAmenitiesCategoires[0]
        this.category = category
        await this.fetchSubCategories(category)
        await this.fetchLabels(category)
      }
    },
    // Add Amenities View
    async addAmenities() {
      this.validate()
      if (!this.validation.category || !this.validation.label) return

      const payload = {
        stayFacilityId: this.$route.params.id,
        data: {
          stayFacilityId: this.$route.params.id,
          category: this.category,
          subcategory: this.subcategory,
          lang: this.language,
          name: this.label,
          distanceKm: this.distance,
          additionalInfo: this.additionalInfo,
        },
        type: this.type,
      }

      this.apiInProgress = true
      await this.addStayFacilitiesAmenities(payload)
      this.apiInProgress = false

      this.$emit("onFormSubmit")
    },
    async onCategoryChange(category) {
      await this.fetchSubCategories(category)
      await this.fetchLabels(category)
    },

    async fetchSubCategories(category) {
      this.loadingSubCategories = true
      this.subcategory = ""

      await this.setAmenitiesSubCategories({ category: category })

      this.loadingSubCategories = false
    },

    async fetchLabels(category) {
      this.loadingLabels = true
      this.label = ""

      await this.setAmenitiesLabels({ category: category })

      // enable if we want to set the dropdwon with the 1st element of the list
      //this.label = this.labelOptions[0]
      this.loadingLabels = false
    },

    validate() {
      this.validation.category = !!this.category
      this.validation.label = !!this.label
      this.validation.showErrors = true
    },
    showValidationErros() {
      return { "md-invalid mb-3": this.validation.showErrors }
    },
  },
  computed: {
    ...mapState(CAMPSITES, {
      selectedAmenity: state => state.selectedAmenity,
      selectedRow: state => state.selectedRow,
    }),
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
    }),

    ...mapGetters(CAMPSITES, [
      "mutipleAmenitiesCategoires",
      "getAmenitiesCategoires",
      "getAmenitiesSubCategories",
      "getAmenitiesLabels",
    ]),

    subcategoryOptions() {
      return this.getAmenitiesSubCategories.filter(item => item !== null)
    },
    labelOptions() {
      return this.getAmenitiesLabels.filter(item => item !== null)
    },
    categorySelected() {
      return this.mutipleAmenitiesCategoires ? !!this.category.length > 0 : true
    },
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
@media screen and (max-width: 600px) {
  .checkbox-area-sm {
    width: 100% !important;
    flex-basis: 100%;
  }
}
.md-title {
  font-size: 14px !important;
}
.label-field {
  font-size: 14px;
  font-weight: 500;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
