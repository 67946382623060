import { get as _get } from "lodash"

import { getRowCellByColumnName } from "@/utils/table.utils"

export default {
  props: {
    onRowSelect: Function,
  },
  data() {
    return {
      selectedRow: null,
      currentSort: "name",
      currentSortOrder: "asc",
      refreshKey: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200],
        total: 0,
      },
    }
  },
  computed: {
    tableRows() {
      return this.dataTable.rows || []
    },
    tableColumns() {
      return this.dataTable.columns || []
    },
    visibleTableColumns() {
      return this.getVisibleColumns(this.tableColumns)
    },
    isDataTableReady() {
      return (
        this.dataTable &&
        this.tableRows.length > 0 &&
        this.tableColumns.length > 0
      )
    },
    paginationTo() {
      let highBound = this.paginationFrom + this.pagination.perPage
      if (this.paginationTotal < highBound) {
        highBound = this.paginationTotal
      }
      return highBound
    },
    paginationFrom() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    paginationTotal() {
      return this.tableRows.length
    },
    queriedData() {
      this.refreshKey
      return this.tableRows.slice(this.paginationFrom, this.paginationTo)
    },
  },
  methods: {
    getVisibleColumns(columns = []) {
      return columns.filter(item => {
        const isHiddenHeaderCell = item.visible === "false"
        if (isHiddenHeaderCell) return !isHiddenHeaderCell

        const columnCell = getRowCellByColumnName(this.tableColumns, item.name)
        const isHiddenBodyCell = columnCell && columnCell.visible === "false"

        return !isHiddenBodyCell
      })
    },
    handleRowSelect(item) {
      const row = _get(item, "row", null)

      this.selectedRow = row
      this.$emit("onRowSelect", row)
    },
    getTableRowData(rowData, colName) {
      const normalizedRow = this.getVisibleColumns(rowData.row)
      const column = normalizedRow.find(row => row.name === colName)
      return column ? column.value : null
    },
    handleRowsSort() {
      this.dataTable.rows = this.dataTable.rows.sort((a, b) => {
        const sortBy = "value"
        const sortableColA = getRowCellByColumnName(a.row, this.currentSort)
        const sortableColB = getRowCellByColumnName(b.row, this.currentSort)

        const aValue = _get(sortableColA, sortBy, "")
        const bValue = _get(sortableColB, sortBy, "")

        const aType = _get(sortableColA, "type", "")
        const bType = _get(sortableColB, "type", "")

        const locales = undefined

        let sortingOptions = {}
        if (
          (aType === "Double" && bType === "Double") ||
          (aType === "Long" && bType === "Long")
        ) {
          sortingOptions = {
            numeric: true,
            sensitivity: "base",
          }
        }

        if (this.currentSortOrder === "desc") {
          return aValue.localeCompare(bValue, locales, sortingOptions)
        }

        return bValue.localeCompare(aValue, locales, sortingOptions)
      })
      this.refreshKey++
    },
  },
}
