<template>
  <form novalidate class="md-layout" @submit.prevent="handleFilesUpload">
    <div class="md-layout-item md-size-100 mb-4 p-0 file-up">
      <md-field :class="{ 'md-error md-invalid': $v.itemId.$error }">
        <label class="file-up-lab">{{ nameFile }}</label>
        <input
          id="fileUpload"
          type="file"
          hidden
          ref="file"
          @blur="$v.itemId.$touch"
          v-on:change="handleFileUpload()"
        />
        <md-button
          class="md-success md-icon-button md-raised button-upload"
          @click="handleUploadImage"
          v-if="!uploaded.length"
        >
          <font-awesome-icon :icon="'upload'" />
          <md-tooltip md-direction="top">Upload file CSV</md-tooltip>
        </md-button>
        <div class="md-error" v-if="!$v.itemId.required">
          Field is required.
        </div>
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field :class="{ 'md-error md-invalid': $v.itemType.$error }">
        <label>Item Type</label>
        <md-select
          v-model="itemType"
          name="item_type"
          id="item_type"
          @blur="$v.itemType.$touch"
          md-dense
        >
          <md-option
            v-for="(item, key) in itemTypesList"
            :key="key"
            :value="item.key"
            >{{ item.value }}</md-option
          >
        </md-select>
        <div class="md-error" v-if="!$v.itemType.required">
          Field is required.
        </div>
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field>
        <label>Job Name</label>
        <md-input type="text" :value="selectedJob" disabled />
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="this.$v.$invalid"
      >
        CREATE
      </md-button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  FEED_JOBS_FETCH_ITEM_TYPES_LIST,
  CAMPSITES_UPLOAD_CSV_FILE,
} from "@/modules/feed-jobs/store/mutations-types"
export default {
  name: "CreateFeedJobForm",
  props: {
    onDialogClose: Function,
    isFormSending: Boolean,
    onFormSubmit: Function,
  },
  validations: {
    itemId: {
      required,
    },
    itemType: {
      required,
    },
  },
  data() {
    return {
      itemId: "",
      file: "",
      nameFile: "File",
      uploadingFile: false,
      uploaded: false,
      itemType: null,
    }
  },
  computed: {
    ...mapState(FEED_JOBS, {
      itemTypesList: state => state.itemTypesList,
      selectedJob: state => state.selectedJob,
    }),
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      fetchItemTypesList: FEED_JOBS_FETCH_ITEM_TYPES_LIST,
      uploadNewCsvFile: CAMPSITES_UPLOAD_CSV_FILE,
    }),
    handleFileUpload() {
      this.nameFile = this.$refs.file.files[0].name
      this.itemId = this.$refs.file.files[0]
      this.file = this.$refs.file.files[0]
    },
    async handleFilesUpload() {
      let formData = new FormData()

      formData.append("file", this.file)
      this.uploadingFile = true

      try {
        await this.uploadNewCsvFile({
          itemType: this.itemType,
          jobName: this.selectedJob,
          formData: formData,
        })

        this.$notify({
          timeout: 3000,
          message: "CSV file uploaded successfully",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        })
        this.$emit("form-submit", { status: "successfully" })
      } catch (e) {
        this.$notify({
          timeout: 3000,
          message: "Error during uploading CSV file",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        })
        this.$emit("form-submit", { status: "danger" })
      } finally {
        this.uploadingFile = false
      }
    },
    handleUploadImage() {
      document.getElementById("fileUpload").click()
    },
  },
  async mounted() {
    await this.fetchItemTypesList()
  },
}
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  padding: 10px;
}

.gallery-item {
  padding: 10px;
  position: relative;
}

.top-items {
  background: #8080805c;
}

h4.items-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.buttons-top-items {
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
}

.button-top {
  font-size: 11px;
  height: 26px;
}
.icons-top {
  width: 50px;
  height: 25px;
}
.button-upload {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.file-up .file-up-lab {
  left: 70px;
}
</style>
