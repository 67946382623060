export default $axios => resource => ({
  refreshProductConfigurationBooking() {
    return $axios.post(`${resource}/cache/flushcsproducts`, {
      auth: {
        username: process.env.VUE_APP_API_BOOKING_USERNAME,
        password: process.env.VUE_APP_API_BOOKING_PASSWORD,
      },
    })
  },
})
