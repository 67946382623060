<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Report Details</h4>
      </div>
    </md-card-header>
    <div class="md-layout">
      <div
        v-for="item in dataItems"
        :key="item.label"
        class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-20 mt-4"
      >
        <h4 class="title">{{ item.label }}</h4>
        <div>{{ item.value }}</div>
      </div>
      <div
        v-if="isReportMapAvailable"
        class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-20 mt-4"
      >
        <md-button class="md-raised md-success" @click="handleGoMapsPage">
          Show on map
        </md-button>
      </div>
    </div>
    <md-card-content>
      <ReportsTable />
    </md-card-content>
  </md-card>
</template>

<script>
import ReportsTable from "@/modules/reports/views/components/tables/ReportsTable"
import { mapActions } from "vuex"
import { REPORTS, REPORTS_FETCH_DETAILS } from "../../store/mutations-types"
export default {
  name: "ReportPage",
  components: {
    ReportsTable,
  },
  async created() {
    await this.fetchDetails({ reportKey: this.reportKey })
  },
  computed: {
    detailsMeta() {
      return this.$store.state[REPORTS].reportDetailsMeta
    },
    dataItems() {
      return this.detailsMeta.dataItems || []
    },
    isReportMapAvailable() {
      return this.$store.state[REPORTS].markers.length > 0
    },
    reportKey() {
      return this.$route.params.reportKey
    },
  },
  methods: {
    ...mapActions(REPORTS, {
      fetchDetails: REPORTS_FETCH_DETAILS,
    }),
    handleGoMapsPage() {
      this.$router.push({
        name: "Reports Map",
        params: { reportKey: this.reportKey },
      })
    },
  },
}
</script>

<style scoped></style>
