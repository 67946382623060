export default $axios => resource => ({
  fetchList(params) {
    return $axios.get(`${resource}/syncjob/executions`, {
      params,
    })
  },
  fetchJobItems(params) {
    return $axios.get(`${resource}/syncjob/jobitems?jobId=${params}`)
  },
})
