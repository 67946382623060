<template>
  <div class="md-layout">
    <!-- CS Selected photos -->
    <div class="md-layout-item md-size-20 md-medium-size-25 md-small-size-30">
      <h4 class="items-title md-layout md-alignment-top-center md-title">
        CS Selected photos
      </h4>
      <div class="sticky top-items">
        <div v-if="!$screen.xxl" class="md-layout buttons-top-items">
          <md-button
            class="md-icon-button md-raised icons-top "
            @click="save"
            :disabled="!isChanged"
            :class="{
              'md-success': isChanged,
            }"
          >
            <font-awesome-icon :icon="'check'" />
            <md-tooltip md-direction="top">Apply CS Selected photos</md-tooltip>
          </md-button>
          <md-button
            class="md-icon-button md-raised md-success icons-top "
            @click="handleRestore"
          >
            <font-awesome-icon :icon="'undo-alt'" />
            <md-tooltip md-direction="top">Revert to SH photo order</md-tooltip>
          </md-button>
        </div>
        <div v-else class="md-layout buttons-top-items">
          <md-button
            :disabled="!isChanged"
            @click="save"
            class="md-button mt-30 button-top"
            :class="{
              'md-success': isChanged,
            }"
          >
            Apply
          </md-button>
          <md-button
            class="md-button md-success mt-30 button-top"
            @click="handleRestore"
          >
            Restore
          </md-button>
        </div>
        <draggable
          :list="topItems"
          group="photos"
          class="md-gutter md-alignment-center"
          :move="handleMove"
          @end="handleTopMoveEnd"
          ref="selectedPhotos"
        >
          <div
            class="md-layout md-layout-item md-size-100 gallery-item"
            v-for="(item, idx) in topItems"
            :key="idx"
          >
            <image-component
              :item="item"
              :ready-to-drop="idx === futureIndex"
            />
          </div>
        </draggable>
      </div>
    </div>
    <!-- CS Uploaded Images -->
    <template v-if="uploadedPhotos.length >= 1">
      <div class="md-layout-item md-size-20 md-medium-size-25 md-small-size-30">
        <h4 class="items-title md-layout md-alignment-top-center md-title">
          CS Uploaded Images
        </h4>

        <div class="sticky top-items">
          <div v-if="!$screen.xxl" class="md-layout buttons-top-items">
            <md-button
              class="md-icon-button md-raised icons-top "
              @click="handleSaveCsUploadedPhotosPosition"
              :disabled="!isChangedUploadPhotos"
              :class="{
                'md-success': isChangedUploadPhotos,
              }"
            >
              <font-awesome-icon :icon="'save'" />
              <md-tooltip md-direction="top">Save positions</md-tooltip>
            </md-button>

            <div style="position: relative">
              <md-button
                class="md-icon-button md-raised md-success icons-top"
                @click="handleUploadImage"
                :disabled="uploadingPhoto"
              >
                <font-awesome-icon :icon="'upload'" />
                <md-tooltip md-direction="top">Upload images</md-tooltip>
              </md-button>

              <div
                v-if="uploadingPhoto"
                class="md-layout md-alignment-center-center"
                style="position: absolute; inset: 0; z-index: 6;"
              >
                <pulse-loader color="#004cff" size="10px" />
              </div>
            </div>
          </div>

          <div v-else class="md-layout buttons-top-items">
            <md-button
              @click="handleSaveCsUploadedPhotosPosition"
              class="md-icon-button md-raised icons-top"
              :disabled="!isChangedUploadPhotos"
              :class="{
                'md-success': isChangedUploadPhotos,
              }"
            >
              Save
            </md-button>

            <div style="position: relative">
              <md-button
                @click="handleUploadImage"
                class="md-raised md-success icons-top"
                :disabled="uploadingPhoto"
              >
                Upload
              </md-button>

              <div
                v-if="uploadingPhoto"
                class="md-layout md-alignment-center-center"
                style="position: absolute; inset: 0;"
              >
                <pulse-loader color="#004cff" style="margin-top: 4px" />
              </div>
            </div>
          </div>

          <draggable
            :list="uploadedItems"
            group="photos"
            class="md-gutter md-alignment-center"
            :move="handleMoveUploadPhotos"
            @end="handleUploadMoveEnd"
          >
            <div
              class="md-layout md-layout-item md-size-100 gallery-item"
              v-for="(item, idx) in uploadedItems"
              :key="idx"
              @click.prevent="show(item.urlmedium)"
            >
              <image-component
                @deleteUploadPhoto="deleteUploadedPhoto"
                @changeUploadPhotoStatus="handleChangeUploadPhotoStatus"
                :item="item"
                :ready-to-drop="idx === uploadPhotosIndexes.futureIndex"
              />
            </div>
          </draggable>
        </div>
      </div>
    </template>
    <!-- Secure Holiday Photos -->
    <template v-if="allItems.length >= 0">
      <div
        class="md-layout-item md-medium-size-75 md-small-size-70"
        :class="{
          'md-size-60': uploadedPhotos.length,
          'md-size-80': !uploadedPhotos.length,
        }"
      >
        <div class="md-layout">
          <input
            id="fileUpload"
            type="file"
            hidden
            ref="files"
            multiple
            v-on:change="handleFilesUpload()"
          />
          <md-button
            class="md-success md-icon-button md-raised button-upload"
            @click="handleUploadImage"
            v-if="!uploadedPhotos.length"
          >
            <font-awesome-icon :icon="'upload'" />
            <md-tooltip md-direction="top">Upload images</md-tooltip>
          </md-button>
          <h4 class="items-title md-title">Secure Holiday Photos</h4>
          <md-button
            class="md-button md-success mt-30 button-top ml-2"
            @click="showCarousel = true"
          >
            View in carousel
          </md-button>
          <md-dialog class="carousel-dialog" :md-active.sync="showCarousel">
            <ImageCarousel :items="allItems" />
          </md-dialog>
        </div>
        <div>
          <draggable
            :list="allItems"
            :group="{ name: 'photos', pull: 'clone', put: false }"
            :move="handleMove"
            class="md-layout"
            @end="handleDragEnd"
            handle=".draggable"
            ref="shPhotos"
          >
            <div
              class="md-layout md-layout-item md-medium-size-25 md-small-size-30 gallery-item"
              v-for="(item, idx) in allItems"
              :key="idx"
              @click.prevent="show(item.urlmedium)"
              :class="{
                'md-size-25': uploadedPhotos.length,
                'md-size-20': !uploadedPhotos.length,
              }"
            >
              <image-component
                @change="handleChangePhoto"
                editable
                :item="item"
                :selected="!!item.cs_selected_position"
              />
              <span>{{ item.caption }}</span>
            </div>
          </draggable>
        </div>
      </div>
    </template>
    <viewer
      :options="options"
      :images="images"
      @inited="inited"
      class="viewer"
      ref="viewer"
    >
      <img v-show="false" v-for="src in images" :src="src" :key="src" />
    </viewer>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isSave"
      md-persistent
    >
      <h4>CS Selected Photos Successfully saved</h4>
    </md-snackbar>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isUploaded"
      md-persistent
    >
      <h4>CS Uploaded Images Successfully saved</h4>
    </md-snackbar>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isRestore"
      md-persistent
    >
      <h4>CS Selected Photos reset to SH photo order</h4>
    </md-snackbar>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isRestore"
      md-persistent
    >
      <h4>CS Selected Photos reset to SH photo order</h4>
    </md-snackbar>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { mapActions, mapState } from "vuex"
import {
  CAMPSITES,
  CAMPSITES_FETCH_PHOTOS,
  CAMPSITES_RE_SORT_PHOTOS,
  CAMPSITES_RE_SORT_UPLOAD_PHOTOS,
  CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO,
  CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS,
  CAMPSITES_RESET_PHOTO_POSITION,
  CAMPSITES_UPDATE_CS_SELECTED_PHOTOS,
  CAMPSITES_UPLOAD_NEW_PHOTO,
  CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS,
  CAMPSITES_DELETE_CS_UPLOADED_PHOTO,
} from "@/modules/campsites/store/mutations-types"
import ImageComponent from "@/views/components/ui/ImageComponent"
import Viewer from "v-viewer/src/component"
import ImageCarousel from "../../components/ui/ImageCarousel"
import "viewerjs/dist/viewer.css"

export default {
  name: "PhotosPage",
  components: {
    draggable,
    ImageComponent,
    Viewer,
    ImageCarousel,
  },
  data() {
    return {
      movingIndex: undefined,
      futureIndex: undefined,
      uploadPhotosIndexes: {
        movingIndex: undefined,
        futureIndex: undefined,
      },
      images: [],
      options: {
        button: false,
        title: false,
        tooltip: false,
        toolbar: false,
        navbar: false,
        movable: false,
        zoomable: false,
      },
      position: "center",
      duration: 4000,
      isSave: false,
      isRestore: false,
      isUploaded: false,
      uploadingPhoto: false,
      showCarousel: false,
    }
  },
  async created() {
    await this.fetchPhotos({ campsiteId: this.$route.params.id })
  },
  watch: {
    $refs: {
      deep: true,
    },
  },
  computed: {
    ...mapState(CAMPSITES, {
      itemPhotos: state => state.item.photos,
      uploadedPhotos: state => state.item.uploadedPhotos,
    }),
    topItems() {
      return this.itemPhotos
        .filter(({ cs_selected_position }) => !!cs_selected_position)
        .sort(
          (a, b) =>
            Number(a.cs_selected_position) - Number(b.cs_selected_position)
        )
    },
    uploadedItems() {
      return this.uploadedPhotos
        .filter(({ cs_position }) => !!cs_position)
        .sort((a, b) => Number(a.cs_position) - Number(b.cs_position))
    },
    allItems() {
      return [...this.itemPhotos]
    },
    isChanged() {
      return this.itemPhotos.some(({ changed }) => !!changed)
    },
    isChangedUploadPhotos() {
      return this.uploadedPhotos.some(({ changed }) => !!changed)
    },
  },
  methods: {
    ...mapActions(CAMPSITES, {
      fetchPhotos: CAMPSITES_FETCH_PHOTOS,
      reselectTop: CAMPSITES_RE_SORT_PHOTOS,
      reselectUploadPhoto: CAMPSITES_RE_SORT_UPLOAD_PHOTOS,
      updatePhoto: CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO,
      updateUploadPhoto: CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS,
      deleteUploadPhoto: CAMPSITES_DELETE_CS_UPLOADED_PHOTO,
      resetPhoto: CAMPSITES_RESET_PHOTO_POSITION,
      savePositions: CAMPSITES_UPDATE_CS_SELECTED_PHOTOS,
      saveUploadedPhotosPosition: CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS,
      uploadNewPhotos: CAMPSITES_UPLOAD_NEW_PHOTO,
    }),
    handleDragEnd() {
      if ([this.movingIndex, this.futureIndex].includes(undefined)) {
        return false
      }
      const item1 = this.allItems[this.movingIndex]
      const item2 = this.topItems[this.futureIndex]
      const modifiedList = this.allItems.map(item => {
        if (item.key === item1.key) {
          return {
            ...item,
            cs_selected_position: item2.cs_selected_position,
            cs_position: item2.cs_position,
            changed: true,
          }
        } else if (item.key === item2.key) {
          return {
            ...item,
            cs_selected_position: item1.cs_selected_position,
            cs_position: item1.cs_position,
            changed: true,
          }
        }
        return item
      })
      this.movingIndex = undefined
      this.futureIndex = undefined
      this.reselectTop(modifiedList)
    },
    handleMove(event) {
      const fromRightToLeftSection =
        event.from === this.$refs.shPhotos.$el &&
        event.to === this.$refs.selectedPhotos.$el

      const insideLeftSection =
        event.from === this.$refs.selectedPhotos.$el &&
        event.to === this.$refs.selectedPhotos.$el

      if (fromRightToLeftSection || insideLeftSection) {
        const { index, futureIndex } = event.draggedContext
        this.movingIndex = index
        this.futureIndex =
          futureIndex > this.topItems.length - 1
            ? this.topItems.length - 1
            : futureIndex
      } else {
        this.movingIndex = undefined
        this.futureIndex = undefined
      }

      return false
    },
    handleMoveUploadPhotos(event) {
      if (this.allItems.length === event.relatedContext.list.length) {
        return false
      }
      const { index, futureIndex } = event.draggedContext
      this.uploadPhotosIndexes.movingIndex = index
      this.uploadPhotosIndexes.futureIndex =
        futureIndex > this.topItems.length - 1
          ? this.topItems.length - 1
          : futureIndex
      return false
    },
    handleTopMoveEnd() {
      if ([this.movingIndex, this.futureIndex].includes(undefined)) {
        return false
      }
      const list = [...this.topItems]
      const item1 = list[this.movingIndex]
      const item2 = list[this.futureIndex]
      const modifiedList = this.allItems.map(item => {
        if (item.key === item1.key) {
          return {
            ...item,
            cs_selected_position: item2.cs_selected_position,
            cs_position: item2.cs_position,
            changed: true,
          }
        } else if (item.key === item2.key) {
          return {
            ...item,
            cs_selected_position: item1.cs_selected_position,
            cs_position: item1.cs_position,
            changed: true,
          }
        }
        return item
      })
      this.movingIndex = undefined
      this.futureIndex = undefined
      this.reselectTop(modifiedList)
    },
    handleUploadMoveEnd() {
      if (
        [
          this.uploadPhotosIndexes.movingIndex,
          this.uploadPhotosIndexes.futureIndex,
        ].includes(undefined)
      ) {
        return false
      }
      const list = [...this.uploadedItems]
      const item1 = list[this.uploadPhotosIndexes.movingIndex]
      const item2 = list[this.uploadPhotosIndexes.futureIndex]
      const modifiedList = this.uploadedItems.map(item => {
        if (item.key === item1.key) {
          return {
            ...item,
            cs_position: item2.cs_position,
            changed: true,
          }
        } else if (item.key === item2.key) {
          return {
            ...item,
            cs_position: item1.cs_position,
            changed: true,
          }
        }
        return item
      })
      this.uploadPhotosIndexes.movingIndex = undefined
      this.uploadPhotosIndexes.futureIndex = undefined
      this.reselectUploadPhoto(modifiedList)
    },
    handleRestore() {
      this.resetPhoto({ campsiteId: this.$route.params.id })
      this.isRestore = true
    },
    handleChangePhoto(item) {
      this.updatePhoto(item)
    },
    handleChangeUploadPhotoStatus(item) {
      this.updateUploadPhoto(item)
    },
    deleteUploadedPhoto(item) {
      this.deleteUploadPhoto(item)
    },
    save() {
      const campsiteId = this.$route.params.id
      const items = this.topItems.map(item => {
        return {
          key: item.key,
          position: Number(item.cs_selected_position),
        }
      })
      this.savePositions({ campsiteId, items })
      this.isSave = true
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    show(url) {
      this.images = [url]
      this.$viewer.show()
    },
    async handleSaveCsUploadedPhotosPosition() {
      const campsiteId = this.$route.params.id
      const items = this.uploadedItems.map(item => {
        return {
          key: item.key,
          position: Number(item.cs_position),
        }
      })
      this.saveUploadedPhotosPosition({ campsiteId, items })
      this.isUploaded = true
    },
    handleUploadImage() {
      document.getElementById("fileUpload").click()
    },
    async handleFilesUpload() {
      let formData = new FormData()

      for (let i = 0; i < this.$refs.files.files.length; i++) {
        let file = this.$refs.files.files[i]
        formData.append("file", file)
      }

      this.uploadingPhoto = true

      try {
        await this.uploadNewPhotos({
          campsiteId: this.$route.params.id,
          formData: formData,
        })

        this.$notify({
          timeout: 3000,
          message: "Photo uploaded successfully",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        })
      } catch (e) {
        this.$notify({
          timeout: 3000,
          message: "Error during uploading photo",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        })
      } finally {
        this.uploadingPhoto = false
      }
    },
  },
}
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  padding: 10px;
}

.gallery-item {
  padding: 10px;
  position: relative;
}

.top-items {
  background: #8080805c;
}

h4.items-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.buttons-top-items {
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
}

.button-top {
  font-size: 11px;
  height: 26px;
}
.icons-top {
  width: 50px;
  height: 25px;
}
.button-upload {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
</style>

<style>
.carousel-dialog .md-dialog-container {
  position: relative;
  flex: 1;
}
</style>
