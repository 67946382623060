<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Add campsited comment</md-dialog-title>

      <md-dialog-content>
        <CreateBookingCommentForm
          :is-form-sending="isFormSending"
          :init-trans-id="transId"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import CreateBookingCommentForm from "@/modules/bookings/views/components/forms/CreateBookingCommentForm"
import {
  BOOKINGS,
  BOOKINGS_CREATE_ITEM_COMMENT,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "CreateBookingCommentDialog",
  props: {
    transId: String,
    isShowDialog: Boolean,
    onDialogClosed: Function,
  },
  mixins: [dialogMixin],
  components: {
    CreateBookingCommentForm,
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_CREATE_ITEM_COMMENT]),
    async handleFormSubmit(values) {
      this.isFormSending = true
      await this[BOOKINGS_CREATE_ITEM_COMMENT]({
        transId: this.transId,
        ...values,
      })
      this.isFormSending = false
      this.closeDialog()
    },
  },
}
</script>

<style scoped></style>
