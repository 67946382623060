<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.comment.$error }"
    >
      <label>Comment</label>
      <md-textarea v-model="$v.comment.$model" />

      <div class="md-error" v-if="!$v.comment.required">Field is required.</div>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="isFormSending"
      >
        ADD COMMENT
      </md-button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  name: "CreateBookingCommentForm",
  props: {
    onDialogClose: Function,
    initTransId: String,
    isFormSending: Boolean,
    onFormSubmit: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {
      comment: "",
    }
  },
  computed: {},
  methods: {
    async handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit("onFormSubmit", {
          comment: this.comment,
        })
      }
    },
  },
  validations: {
    comment: {
      required,
    },
  },
}
</script>

<style scoped></style>
