<template>
  <div>
    <div v-if="loading" class="text-center p-5">
      <md-progress-spinner class="md-primary" md-mode="indeterminate" />
    </div>
    <md-card v-if="!loading">
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Search Pricing Data</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <form novalidate class="md-layout">
          <div class="md-layout-item md-size-30 pr-0 dropdown">
            <md-field>
              <label class="required-field" for="Country">Country</label>
              <md-select
                v-model="selectedCountry"
                name="Country"
                id="Country"
                @input="getCountryRegions"
              >
                <md-option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.value"
                >
                  {{ country.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-30 pr-0 dropdown">
            <md-field>
              <label for="Region">Region</label>
              <md-select
                v-if="selectedCountry"
                v-model="selectedRegion"
                name="Region"
                id="Region"
                @input="getRegionDepartments"
              >
                <md-option
                  v-for="(region, index) in regions"
                  :key="index"
                  :value="region.value"
                >
                  {{ region.value }}
                </md-option>
              </md-select>
              <md-select v-else name="Region" id="Region">
                <md-option disabled>
                  Please Select Country
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-30 pr-0 dropdown">
            <md-field>
              <label for="Department">Department</label>
              <md-select
                v-if="selectedRegion"
                v-model="selectedDepartment"
                name="Department"
                id="Department"
              >
                <md-option
                  v-for="(department, index) in departments"
                  :key="index"
                  :value="department.value"
                >
                  {{ department.value }}
                </md-option>
              </md-select>
              <md-select v-else name="Department" id="Department">
                <md-option disabled>
                  Please Select Region
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-30 sm-size-50 pr-0">
            <md-datepicker
              :md-model-type="String"
              v-model="dateFrom"
              v-on:input="setDateTo()"
            >
              <label class="required-field">Date From</label>
            </md-datepicker>
          </div>

          <div
            class="md-layout-item md-size-30 sm-size-50 pr-0"
            :key="renderDatePicker"
          >
            <md-datepicker
              :md-model-type="String"
              v-model="dateTo"
              v-on:input="setDateTo()"
            >
              <label class="required-field">Date To</label>
            </md-datepicker>
          </div>

          <div class="md-layout-item md-size-30 sm-size-50 pr-0 dropdown">
            <md-field>
              <label class="required-field" for="Accommodation"
                >Accommodation Type</label
              >
              <md-select
                v-model="selectedAccommodation"
                name="Accommodation"
                id="Accommodation"
              >
                <md-option
                  v-for="(accommodation, index) in accommodations"
                  :key="index"
                  :value="accommodation.value"
                >
                  {{ accommodation.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-30 pr-0">
            <md-field>
              <label>% Discount</label>
              <md-input v-model="selectedDiscount" type="number"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-30 sm-size-50 pr-0 dropdown">
            <md-field>
              <label for="Nights">Number Of Nights</label>
              <md-select v-model="selectedNight" name="Nights" id="Nights">
                <md-option
                  v-for="(night, index) in numberOfNights"
                  :key="index"
                  :value="night.value"
                >
                  {{ night.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50 pr-0">
            <md-field>
              <label>Campsite Name</label>
              <md-input type="text" v-model="selectedCampsiteName"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50 pr-0">
            <md-field>
              <label>Group Name</label>
              <md-select
                v-model="selectedGroupName"
                name="GroupNames"
                id="GroupNames"
              >
                <md-option
                  v-for="(group, index) in groupNames"
                  :key="index"
                  :value="group.value"
                >
                  {{ group.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-size-100 md-layout-item">
            <Collapse :collapse="['Filters']" :is-open="false">
              <template slot="md-collapse-pane-1">
                <md-card>
                  <md-card-content class="row">
                    <div class="filter-checkbox">
                      <md-checkbox
                        class="column"
                        v-for="(filter, index) in searchFilters"
                        :key="index"
                        :value="filter.value"
                        v-model="saveFilters"
                        >{{ filter.value }}</md-checkbox
                      >
                    </div>
                  </md-card-content>
                </md-card>
              </template>
            </Collapse>
          </div>

          <div class="md-layout-item md-size-100 px-0 content-right">
            <md-button
              class="md-raised md-success mt-4"
              @click="clearFilterData()"
            >
              Clear Filters
            </md-button>
            <md-button
              style="margin-left:2%"
              class="md-raised md-success mt-4"
              @click="searchData()"
            >
              Search
            </md-button>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <div v-if="dataFetching" class="text-center p-5">
      <md-progress-spinner class="md-primary" md-mode="indeterminate" />
    </div>
    <UniversalTable
      :key="renderKey"
      v-if="!loading && !dataFetching"
      :export-file-name="tableExportFileName"
      :data="searchResult"
      emptyTitleText="No data to show"
    >
    </UniversalTable>
  </div>
</template>

<script>
import {
  GET_COUNTRIES,
  GET_REGION_DEPARTMENTS,
  GET_COUNTRY_REGIONS,
  GET_ACCONMMODATIONS,
  GET_NUMBER_OF_NIGHTS,
  GET_GROUP_NAMES,
  GET_SEARCH_FILTERS,
  SEARCH_PRICING_DATA,
  PRICING_DATA,
} from "@/modules/pricing-data/store/mutations-types"
import Collapse from "@/views/components/ui/Collapse"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions } from "vuex"

export default {
  name: "Pricing",
  components: {
    UniversalTable,
    Collapse,
  },
  data() {
    return {
      countries: [],
      tableExportFileName: "Pricing_Data",
      searchResult: {},
      selectedCountry: "France",
      selectedRegion: "",
      selectedDepartment: "",
      selectedAccommodation: "Rental",
      selectedNight: "",
      selectedGroupName: "",
      selectedCampsiteName: "",
      selectedDiscount: null,
      dateFrom: null,
      dateTo: null,
      searchFilters: [],
      saveFilters: [],
      regions: [],
      departments: [],
      accommodations: [],
      numberOfNights: [],
      groupNames: [],
      loading: false,
      pricingData: {},
      renderKey: 0,
      dataFetching: false,
      renderDatePicker: 0,
    }
  },
  async created() {
    this.loading = true
    await this.getCountries()
    await this.getCountryRegions()
    await this.getAccommodation()
    await this.getNumberOfNights()
    await this.getGroupNames()
    await this.getFilters()
  },
  methods: {
    ...mapActions(PRICING_DATA, [
      GET_COUNTRIES,
      GET_COUNTRY_REGIONS,
      GET_REGION_DEPARTMENTS,
      GET_ACCONMMODATIONS,
      GET_NUMBER_OF_NIGHTS,
      GET_GROUP_NAMES,
      GET_SEARCH_FILTERS,
      SEARCH_PRICING_DATA,
    ]),
    async getCountries() {
      await this[GET_COUNTRIES]()
      this.countries = this.$store.state[PRICING_DATA].countries
    },
    async getCountryRegions() {
      this.selectedRegion = ""
      await this[GET_COUNTRY_REGIONS]({ country: this.selectedCountry })
      this.regions = this.$store.state[PRICING_DATA].countryRegions
    },
    async getRegionDepartments() {
      this.selectedDepartment = ""
      await this[GET_REGION_DEPARTMENTS]({
        country: this.selectedCountry,
        region: this.selectedRegion,
      })
      this.departments = this.$store.state[PRICING_DATA].regionDepartments
    },
    async getAccommodation() {
      await this[GET_ACCONMMODATIONS]()
      this.accommodations = this.$store.state[PRICING_DATA].accommodations
    },
    async getNumberOfNights() {
      await this[GET_NUMBER_OF_NIGHTS]()
      this.numberOfNights = this.$store.state[PRICING_DATA].numberOfNights
    },
    async getGroupNames() {
      await this[GET_GROUP_NAMES]()
      this.groupNames = this.$store.state[PRICING_DATA].groupNames
    },
    async getFilters() {
      await this[GET_SEARCH_FILTERS]()
      this.$store.state[PRICING_DATA].searchFilters.splice(0, 1)
      this.searchFilters = this.$store.state[PRICING_DATA].searchFilters
      this.loading = false
    },
    async searchData() {
      if (
        !this.selectedCountry ||
        !this.selectedAccommodation ||
        !this.dateFrom ||
        !this.dateTo
      ) {
        this.showAlert(
          `Fill all the fields marked with (<span style='color:red'>*</span>)`
        )
        return
      }
      let searchQueryObject = {}
      if (this.selectedCountry) searchQueryObject.country = this.selectedCountry
      if (this.selectedRegion) searchQueryObject.region = this.selectedRegion
      if (this.selectedDepartment)
        searchQueryObject.department = this.selectedDepartment
      if (this.selectedAccommodation)
        searchQueryObject.accommodationtype = this.selectedAccommodation
      if (this.selectedNight)
        searchQueryObject.staynumberofnights = this.selectedNight
      if (this.selectedGroupName)
        searchQueryObject.campsitegroupname = this.selectedGroupName
      if (this.selectedCampsiteName)
        searchQueryObject.campsitename = this.selectedCampsiteName
      if (this.selectedDiscount)
        searchQueryObject.percentagesaving = `${this.selectedDiscount}.0`
      if (this.dateFrom) searchQueryObject.from = this.dateFrom
      if (this.dateTo) searchQueryObject.to = this.dateTo
      if (this.saveFilters.length) {
        let filterQueryString = ""
        this.saveFilters.forEach(filter => {
          filterQueryString += `${filter.toUpperCase()},`
        })
        filterQueryString = filterQueryString.replaceAll(" ", "_")
        filterQueryString = filterQueryString.substring(
          0,
          filterQueryString.length - 1
        )
        searchQueryObject.filters = filterQueryString
      }
      this.dataFetching = true
      const result = await this[SEARCH_PRICING_DATA](searchQueryObject)
      if (result === 400) {
        this.searchResult = {
          label: "",
          columns: [
            {
              name: "",
              label: "",
              value: "",
            },
          ],
          rows: [],
          numberOfRows: "",
        }
      } else {
        this.searchResult = this.$store.state[PRICING_DATA].searchResult
      }
      this.dataFetching = false
      this.renderKey++
    },
    clearFilterData() {
      this.selectedCountry = ""
      this.selectedRegion = ""
      this.selectedDepartment = ""
      this.selectedNight = ""
      this.selectedAccommodation = ""
      this.selectedCampsiteName = ""
      this.selectedDiscount = null
      this.selectedGroupName = ""
      this.dateFrom = null
      this.dateTo = null
      this.saveFilters = []
      this.searchResult = this.pricingData
      this.renderKey++
    },
    showAlert(name) {
      this.$swal(name)
    },
    setDateTo() {
      if (!this.dateTo || this.dateTo < this.dateFrom) {
        this.dateTo = this.dateFrom
        this.renderDatePicker++
      }
    },
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.column {
  width: 15%;
  padding: 2px;
}
.required-field::after {
  content: " *";
  color: red;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
