import actions from "@/modules/sync-jobs/store/actions"
import mutations from "@/modules/sync-jobs/store/mutations"

const state = () => ({
  allSyncJobList: {},
  jobItems: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
