import { DateTime } from "luxon"

export const normalizeDateInTable = (item = {}) => {
  //Format datetime
  if (
    item.type === "Date" ||
    (item.name === "effective_date" &&
      DateTime.fromFormat(item.value.split(" ")[0], "yyyy-LL-dd").isValid)
  ) {
    //prepare date for Luxon
    const dt = item.value.split(" ")[0]
    item.value = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
  }
  return item
}
