<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Notifications</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <form
          novalidate
          class="md-layout md-alignment-left"
          @submit.prevent="handleSubmit"
        >
          <div
            class="md-layout-item md-size-25 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-3 pl-lg-0"
          >
            <md-field>
              <label>Campsite id</label>
              <md-input v-model="campsiteId" type="text" />
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-25 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-3 pl-lg-0"
          >
            <md-datepicker :md-model-type="String" v-model="dateFrom">
              <label>From</label>
            </md-datepicker>
          </div>
          <div
            class="md-layout-item md-size-25 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-3 pl-lg-0"
          >
            <md-datepicker :md-model-type="String" v-model="dateTo">
              <label>To</label>
            </md-datepicker>
          </div>
          <md-button type="submit" class="md-raised md-success button-search">
            Search
          </md-button>
        </form>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-content>
        <UniversalTable
          :data="normalizedNotificationsList"
          :export-file-name="notifications.exportName"
          :empty-title-text="notifications.emptyTitleText"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
import {
  NOTIFICATIONS,
  NOTIFICATIONS_FETCH_LIST,
} from "@/modules/notifications/store/mutations-types"
import { normalizeDateInTable } from "@/modules/notifications/utils/notifications-date.utils"
import _ from "lodash"
import dayjs from "dayjs"

export default {
  name: "Notifications",
  components: {
    UniversalTable,
  },
  data() {
    return {
      campsiteId: "",
      dateFrom: "",
      dateTo: "",
      notifications: {
        exportName: "Notifications",
        emptyTitleText: "Notification table is empty",
      },
    }
  },
  mounted() {
    this.fetchNotificationsList()
  },
  methods: {
    ...mapActions(NOTIFICATIONS, {
      fetchNotificationsList: NOTIFICATIONS_FETCH_LIST,
    }),
    async handleSubmit() {
      try {
        await this.fetchNotificationsList({
          campsiteId: this.campsiteId,
          date_from: dayjs(this.dateFrom).format("YYYY-MM-DD"),
          date_to: dayjs(this.dateTo).format("YYYY-MM-DD"),
        })

        this.$notify({
          timeout: 3000,
          message: "Successfully",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        })
      } catch (e) {
        this.$notify({
          timeout: 4500,
          message: e,
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        })
      }
    },
  },
  computed: {
    ...mapState(NOTIFICATIONS, {
      notificationsList: state => state.notificationsList,
    }),
    normalizedNotificationsList() {
      if (
        Object.keys(this.notificationsList).length === 0 ||
        !this.notificationsList.rows
      ) {
        return {}
      }

      let data = _.cloneDeep(this.notificationsList)

      data.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return data
    },
  },
}
</script>

<style scoped>
.button-search {
  height: 35px;
  margin-top: 20px;
}
</style>
