<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.campsitesStatus.$error }"
    >
      <label for="status-field">Campsites status</label>
      <md-select
        v-model="$v.campsitesStatus.$model"
        name="campsites-status"
        id="status-field"
      >
        <md-option
          v-for="option in statusesList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
      <div class="md-error" v-if="!$v.campsitesStatus.required">
        Status is required.
      </div>
    </md-field>

    <md-field
      class="mb-4"
      v-if="$v.campsitesStatus.$model == 'DISABLED'"
      :class="{ 'md-error md-invalid': $v.disableReason.$error }"
    >
      <label for="disabled-reason-field">Disabled reason</label>
      <md-select
        v-model="$v.disableReason.$model"
        name="campsites-disabled-reason"
        id="disabled-reason-field"
      >
        <md-option
          v-for="option in disableReasonList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
      <div class="md-error" v-if="$v.disableReason.$error">
        Disable Reason is required.
      </div>
    </md-field>
    <md-field
      class="mb-4"
      v-if="
        $v.campsitesStatus.$model == 'DISABLED' &&
          $v.disableReason.$model == 'OTHER'
      "
      :class="{
        'md-error md-invalid':
          $v.disableReason.$model == 'OTHER' && comments == null,
      }"
    >
      <label>Comments</label>
      <md-input type="text" v-model="comments" />
      <div
        class="md-error"
        v-if="
          $v.campsitesStatus.$model == 'DISABLED' &&
            $v.disableReason.$model == 'OTHER' &&
            comments == null
        "
      >
        Comments is required.
      </div>
    </md-field>

    <md-field class="mb-4">
      <label for="group-names-field">Group Name</label>

      <md-select
        name="GroupNames"
        id="group-names-field"
        v-model="actualGroupName"
      >
        <md-option
          v-for="(group, index) in groupNames"
          :key="index"
          :value="group.value"
        >
          {{ group.value }}
        </md-option>
      </md-select>
    </md-field>
    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.actualEmailAddress.$error }"
    >
      <label for="email-address-field">Email Address</label>

      <md-input
        v-model="actualEmailAddress"
        id="email-address-field"
        type="email"
      ></md-input>
      <div class="md-error" v-if="!$v.actualEmailAddress.error">
        Invalid email address
      </div>
    </md-field>
    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.ranking.$error }"
    >
      <label for="ranking-field">Ranking</label>
      <md-select
        v-model="$v.ranking.$model"
        name="campsite-ranking"
        id="ranking-field"
      >
        <md-option
          v-for="option in rankingOptions"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
      <div class="md-error" v-if="!$v.ranking.required">
        Ranking is required.
      </div>
    </md-field>

    <md-field
      v-if="!$v.shProperty"
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.actualDescription.$error }"
    >
      <label for="description-field">Description</label>
      <md-textarea
        v-model="actualDescription"
        id="description-field"
        md-autogrow
      ></md-textarea>
      <div class="md-error" v-if="!$v.actualDescription.required">
        Description is required
      </div>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button type="submit" class="md-raised md-success">
        UPDATE
      </md-button>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import { mapActions, mapState } from "vuex"
import {
  CAMPSITES,
  GET_CAMPSITE_GROUP,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "UpdateCampsiteForm",
  props: {
    onDialogClose: Function,
    isFormSending: Boolean,
    actualStatus: String,
    actualEmailAddress: String,
    actualGroupName: String,
    onFormSubmit: Function,
    campsiteDetail: null,
    disableReason: String,
    comments: String,
    ranking: String,
    actualDescription: String,
    shProperty: Boolean,
  },
  data: () => ({
    booking_id: "",
    transition_id: "",
    statusResult: "",
    campsitesStatus: null,
    paymentStatus: null,
    // TODO : get this from RefData API (to be created)
    statusesList: [
      { value: "Enabled", key: "ENABLED" },
      { value: "Disabled", key: "DISABLED" },
    ],
    // TODO : get this from RefData API (to be created)
    disableReasonList: [
      { value: "No Availability", key: "NO_AVAILABILITY" },
      { value: "Not Accepting Bookings", key: "NOT_ACCEPTING_BOOKINGS" },
      { value: "Other (See comments)", key: "OTHER" },
    ],
    // TODO : get this from RefData API (to be created)
    rankingOptions: [
      { value: "Normal", key: "NORMAL" },
      { value: "De-Ranked", key: "DE_RANKED" },
    ],
  }),
  created() {
    this.setCampsiteStatus()
    this.getGroupNames()
  },
  computed: {
    ...mapState(CAMPSITES, {
      groupNames: state => state.groupNames,
    }),
  },
  methods: {
    ...mapActions(CAMPSITES, [GET_CAMPSITE_GROUP]),
    async getGroupNames() {
      await this[GET_CAMPSITE_GROUP]()
    },
    async setCampsiteStatus() {
      let status = ""
      if (this.actualStatus) {
        status = this.actualStatus
      }
      this.campsitesStatus = status || ""
    },
    async handleSubmit() {
      this.$v.$touch()
      let isFormValid = true
      if (
        this.campsitesStatus == "DISABLED" &&
        (this.disableReason == "NONE" ||
          (this.disableReason == "OTHER" && this.comments.length == 0))
      ) {
        isFormValid = false
        this.comments = null
      }

      if (this.campsitesStatus == "ENABLED") {
        this.disableReason = "NO_AVAILABILITY"
      }
      if (!this.$v.$invalid && isFormValid) {
        if (this.campsitesStatus == "ENABLED") {
          this.disableReason = null
          this.comments = null
        }

        this.paymentStatus = true
        this.statusResult = "Status Success"
        await this.$emit("onFormSubmit", {
          campsitesStatus: this.campsitesStatus,
          disableReason: this.disableReason,
          comments: this.comments,
          group: this.actualGroupName,
          email: this.actualEmailAddress,
          ranking: this.ranking,
          description: this.actualDescription,
        })
      }
    },
  },
  mounted() {},
  validations: {
    campsitesStatus: {
      required,
    },
    disableReason: {
      required,
    },
    actualEmailAddress: { email },
    ranking: {
      required,
    },
    actualDescription: {
      required,
    },
  },
}
</script>

<style scoped></style>
