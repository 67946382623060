export const PRICING_DATA = "PRICING_DATA"

export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_REGION_DEPARTMENTS = "GET_REGION_DEPARTMENTS"
export const GET_COUNTRY_REGIONS = "GET_COUNTRY_REGIONS"
export const GET_NUMBER_OF_NIGHTS = "GET_NUMBER_OF_NIGHTS"
export const GET_ACCONMMODATIONS = "GET_ACCONMMODATIONS"
export const GET_GROUP_NAMES = "GET_GROUP_NAMES"
export const GET_SEARCH_FILTERS = "GET_SEARCH_FILTERS"
export const SEARCH_PRICING_DATA = "SEARCH_PRICING_DATA"
