import actions from "@/modules/smartNav-configuration/store/actions"
import mutations from "@/modules/smartNav-configuration/store/mutations"

const state = () => ({
  levelOneItems: {},
  levelTwoItems: {},
  levelTwoCategories: {},
  itemsAssociatedToLevel: {},
  urlsList: {},
  levelOneSelectedItem: {},
  levelTwoSelectedItem: {},
  levelTwoDoubleClick: {},
  selectedLanguage: "",
  selectedParent: {},
  selectedUrlItem: {},
  categoryGroupList: {},
  categoryGroupRefList: {},
  level: 0,
  supportedLanguages: [],
  sitemapLevels: [],
  mobileMenuStyles: [],
  levelTwoCategoriesRef: {},
  selectedCategory: "",
  syncJobs: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
