<template>
  <div>
    <div>
      <div class="responsive-view">
        <ProgressSpinner :isProgress="loadingModal" />
        <div class="language-card ">
          <md-card class="position-relative">
            <md-card-header class="md-card-header-text md-card-header-green">
              <div class="card-text">
                <h4 class="title">SmartNav Configuration Language search</h4>
              </div>
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-alignment-right dropdown-position">
                <div class="md-layout-item md-size-50 select-form">
                  <md-field>
                    <label>Choose Lang</label>
                    <md-select
                      v-model="language"
                      name="language"
                      id="language"
                      @input="getLevelOneItems"
                    >
                      <md-option
                        v-for="langName in supportedLanguages"
                        :key="langName"
                        :value="langName"
                        >{{ langName }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <div class="sitemapCard">
          <md-card class="position-relative">
            <md-card-header class="md-card-header-text md-card-header-green">
              <div class="card-text">
                <h4 class="title">Sync Sitemap Elements</h4>
              </div>
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-alignment-right dropdown-position">
                <div class="md-layout-item">
                  <div
                    class="md-layout"
                    style="justify-content: space-between;"
                  >
                    <md-button
                      class="md-success syncButton"
                      @click="handleRunJob"
                      >SYNC</md-button
                    >
                    <div class="ml-2" style="width:60%;">
                      <div style="display:flex;">
                        <span class="last-update">
                          Last Updated Time:
                          {{ finishDateTime }}
                        </span>
                        <md-button
                          class="refreshButton md-icon-button md-raised md-success"
                          @click="handleRefreshLastUpdated"
                        >
                          <font-awesome-icon :icon="'undo-alt'" />
                          <md-tooltip md-direction="top"
                            >Refresh Last Updated Time</md-tooltip
                          >
                        </md-button>
                      </div>
                      <span class="last-update">
                        Last Updated Status:
                        {{ finishStatus }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div>
        <md-card v-show="selectedLanguage">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-text">
              <h4 class="title">Level 1 Items</h4>
            </div>
          </md-card-header>
          <ProgressSpinner :isProgress="loadingLevel1" />
          <md-card-content>
            <UniversalTable
              :data="levelOneItems"
              :export-file-name="smartNavConfigurationTableOptions.exportName"
              :empty-title-text="'Empty Smart Nav Configurations Table'"
              @navigate-to-row="openLevelTwo"
            >
            </UniversalTable>
          </md-card-content>
        </md-card>
      </div>
      <div>
        <md-card v-show="isShowLevelTwoCard">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-text">
              <h4 class="title">Level 2 Items (associated with Level 1)</h4>
            </div>
          </md-card-header>
          <ProgressSpinner :isProgress="loadingLevel2 || loadingCategory" />
          <md-card-content>
            <addLevelTwoItemSmartNavConfigurationDialog
              :is-show-dialog="isAddLevelTwoItem"
              @onDialogClosed="isAddLevelTwoItem = false"
            />
            <addCategoriesSmartNavConfigurationDialog
              style="width: 100%"
              :is-show-dialog="isShowCategoryDialog"
              @onDialogClosed="hideCategoryDialog"
            />
            <updateItemsSmartNavConfigurationDialog
              :is-show-dialog="isUpdateLevel2Item"
              @onDialogClosed="closeUpdateLevel2ItemsModal"
            />
            <UniversalTable
              :data="levelTwoItems"
              :export-file-name="'Level 2 Items'"
              :empty-title-text="'Empty level 2 Smart Nav Configurations Table'"
              @navigate-to-row-item="isUpdateLevel2Item = true"
              @navigate-to-row="openLevelThree"
              :onAddButtonClick="openAddLevelTwoItem"
              :add-button-text="'Add First Level 2 Item'"
              :key="level2ReRender"
              :levelTwoSelectedItem="levelTwoSelectedItem"
              @onRowSelect="handleLevel2RowSelect"
            >
              <template
                slot="header-right-controls"
                slot-scope="{ selectedRow }"
              >
                <div>
                  <md-button
                    class="md-info md-sm level-two-buttons"
                    @click="openAddLevelTwoItem(selectedRow)"
                  >
                    Add
                  </md-button>
                  <md-button
                    v-if="swappingLevel2ItemArray"
                    class="md-info md-sm level-two-buttons"
                    @click="saveLevel2ItemPosition()"
                  >
                    Save
                  </md-button>
                  <md-button
                    v-if="
                      !!selectedRow ||
                        !!(Object.entries(levelTwoSelectedItem).length || null)
                    "
                    class="md-info md-sm level-two-buttons"
                    @click.stop.prevent="
                      moveUpLevel2Item(
                        levelTwoSelectedItem
                          ? levelTwoSelectedItem
                          : selectedRow
                      )
                    "
                  >
                    Move Up
                  </md-button>
                  <md-button
                    v-if="
                      !!selectedRow ||
                        !!(Object.entries(levelTwoSelectedItem).length || null)
                    "
                    class="md-info md-sm level-two-buttons"
                    @click.stop.prevent="
                      moveDownLevel2Item(
                        levelTwoSelectedItem
                          ? levelTwoSelectedItem
                          : selectedRow
                      )
                    "
                  >
                    Move Down
                  </md-button>
                  <md-button
                    v-if="
                      !!selectedRow ||
                        !!(Object.entries(levelTwoSelectedItem).length || null)
                    "
                    class="md-info md-sm level-two-buttons"
                    @click="
                      updateSmartNavConfigurtionLevel2Items(
                        levelTwoSelectedItem
                          ? levelTwoSelectedItem
                          : selectedRow
                      )
                    "
                  >
                    Update
                  </md-button>
                  <md-button
                    v-if="
                      !!selectedRow ||
                        !!(Object.entries(levelTwoSelectedItem).length || null)
                    "
                    class="md-info md-sm level-two-buttons"
                    @click="
                      removeLevelTwoSmartNavConfigurationItem(
                        levelTwoSelectedItem
                          ? levelTwoSelectedItem
                          : selectedRow
                      )
                    "
                  >
                    Remove
                  </md-button>
                  <md-button
                    class="md-info md-sm level-two-buttons"
                    @click="openAddCategoryItem()"
                  >
                    Categories
                  </md-button>
                </div>
              </template>
            </UniversalTable>
          </md-card-content>
        </md-card>
      </div>
      <div>
        <md-card v-show="isShowUrlItemsCard" style="overflow: hidden;">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-text">
              <h4 class="title">Url Items</h4>
            </div>
          </md-card-header>
          <div v-if="urlItemLoading" class="text-center p-5">
            <md-progress-spinner class="md-primary" md-mode="indeterminate" />
          </div>
          <md-card-content v-if="!urlItemLoading">
            <div
              class="md-layout md-alignment-right dropdown-position1"
              v-if="showCategoryFilter"
            >
              <div
                class="md-layout-item md-size-15 select-form"
                style="z-index: 999;"
              >
                <md-field>
                  <label>Choose Category</label>
                  <md-select
                    v-model="category"
                    name="language"
                    id="language"
                    @input="filterByCategory()"
                  >
                    <md-option
                      v-for="(category, index) in levelTwoCategoriesRef"
                      :key="index"
                      :value="category.value"
                      >{{ category.value }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>
            <addURLItemsSmartNavConfigurationDialog
              :is-show-dialog="isAddLevelThreeItem"
              @onDialogClosed="closeAddLevel3Modal"
            />
            <updateURLItemsSmartNavConfigurationDialog
              :is-show-dialog="isUpdateUrlItem"
              @onDialogClosed="closeUpdateUrlItemsModal"
            />
            <UniversalTable
              :data="getLevel3Data"
              :onAddButtonClick="openAddUrlItems"
              :class="showCategoryFilter ? 'margin-adjust' : ''"
              :export-file-name="'URL Items'"
              :empty-title-text="
                'Empty url items Smart Nav Configurations Table'
              "
              @navigate-to-row-item="
                updateURLSmartNavConfigurationItem(
                  selectedUrlItem ? selectedUrlItem : selectedRow
                )
              "
              :add-button-text="'Add First Url Item'"
              :key="level3ReRender"
              :levelTwoSelectedItem="selectedUrlItem"
              @onRowSelect="handleRowSelect"
            >
              <template
                slot="header-right-controls"
                slot-scope="{ selectedRow }"
              >
                <md-button
                  class="md-info md-sm level-two-buttons"
                  @click="openAddCategoryItem()"
                >
                  Categories
                </md-button>
                <md-button
                  class="md-info md-sm level-two-buttons"
                  @click="openAddUrlItems()"
                >
                  Add
                </md-button>
                <md-button
                  v-if="
                    !!selectedRow ||
                      !!(Object.entries(selectedUrlItem).length || null)
                  "
                  class="md-info md-sm level-two-buttons"
                  @click="
                    updateURLSmartNavConfigurationItem(
                      selectedUrlItem ? selectedUrlItem : selectedRow
                    )
                  "
                >
                  Update
                </md-button>
                <md-button
                  v-if="swappingUrlItemArray"
                  class="md-info md-sm level-two-buttons"
                  @click="saveUrlItemPosition()"
                >
                  Save
                </md-button>
                <md-button
                  v-if="
                    !!selectedRow ||
                      !!(Object.entries(selectedUrlItem).length || null)
                  "
                  class="md-info md-sm level-two-buttons"
                  @click="
                    moveUpUrlItem(
                      selectedUrlItem ? selectedUrlItem : selectedRow
                    )
                  "
                >
                  Move Up
                </md-button>
                <md-button
                  v-if="
                    !!selectedRow ||
                      !!(Object.entries(selectedUrlItem).length || null)
                  "
                  class="md-info md-sm level-two-buttons"
                  @click="
                    moveDownUrlItem(
                      selectedUrlItem ? selectedUrlItem : selectedRow
                    )
                  "
                >
                  Move Down
                </md-button>
                <md-button
                  v-if="
                    !!selectedRow ||
                      !!(Object.entries(selectedUrlItem).length || null)
                  "
                  class="md-info md-sm level-two-buttons"
                  @click="
                    removeURLSmartNavConfigurationItem(
                      selectedUrlItem ? selectedUrlItem : selectedRow
                    )
                  "
                >
                  Remove
                </md-button>
              </template>
            </UniversalTable>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SMARTNAV_CONFIGURATIONS,
  GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
  SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION,
  SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION,
  SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION,
  SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION,
  REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
  REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
  SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION,
  SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION,
  SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS,
  SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION,
  GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST,
  SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS,
  FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS,
} from "@/modules/smartNav-configuration/store/mutations-type"
import {
  JOBS,
  CAMPSITES_DATA_SYNC_JOB_TRIGGER,
} from "@/modules/jobs/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import addLevelTwoItemSmartNavConfigurationDialog from "@/modules/smartNav-configuration/views/components/dialogs/addLevelTwoItemSmartNavConfigurationDialog"
import updateURLItemsSmartNavConfigurationDialog from "@/modules/smartNav-configuration/views/components/dialogs/updateURLItemsSmartNavConfigurationDialog"
import updateItemsSmartNavConfigurationDialog from "@/modules/smartNav-configuration/views/components/dialogs/updateItemsSmartNavConfigurationDialog"
import addURLItemsSmartNavConfigurationDialog from "@/modules/smartNav-configuration/views/components/dialogs/addURLItemsSmartNavConfigurationDialog"
import addCategoriesSmartNavConfigurationDialog from "@/modules/smartNav-configuration/views/components/dialogs/addCategoriesSmartNavConfigurationDialog"
import { mapActions, mapState } from "vuex"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "SmartNavConfigurations",
  components: {
    UniversalTable,
    ProgressSpinner,
    addLevelTwoItemSmartNavConfigurationDialog,
    updateItemsSmartNavConfigurationDialog,
    addURLItemsSmartNavConfigurationDialog,
    addCategoriesSmartNavConfigurationDialog,
    updateURLItemsSmartNavConfigurationDialog,
  },
  data() {
    return {
      level3ReRender: 1,
      level2ReRender: 1,
      loadingLevel1: false,
      loadingLevel2: false,
      isAddLevelTwoItem: false,
      isAddLevelThreeItem: false,
      isShowLevelTwoCard: false,
      isShowUrlItemsCard: false,
      isShowCategoryDialog: false,
      isUpdateLevel2Item: false,
      isUpdateUrlItem: false,
      loadingModal: false,
      level3Data: {},
      level2Data: {},
      swappingUrlItemArray: {},
      swappingLevel2ItemArray: {},
      language: "",
      loadingLevel3: false,
      loadingCategory: false,
      smartNavConfigurationTableOptions: {
        exportName: "SmartNav Configurations",
      },
      category: "",
      prevCategory: "",
      urlItemLoading: false,
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getlevelOneItemsList: GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
      getlevelTwoItemsList: GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
      getlevelTwoCategoriesList: GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
      getlevelTwoCategoriesRefList: GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST,
      getlevelAssociatedItemsList: GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
      setlevel1SelectedItem: SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION,
      setlevel2SelectedItem: SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION,
      setSelectedCategory: SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS,
      setLevel2DoubleClickItem: SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION,
      seturlItemSelected: SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION,
      setLanguageSelected: SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
      removeLevel2Item: REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
      removeUrlItem: REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
      setParentItem: SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION,
      setParentLevel: SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION,
      setLevelTwoItems: SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION,
      setItemsAssociatedToLevels: SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION,
      swapUrlItems: SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
      swapLevel2Item: SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS,
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
      fetchSyncJobs: FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS,
    }),
    ...mapActions(JOBS, {
      CAMPSITES_DATA_SYNC_JOB_TRIGGER,
    }),
    // Page Initialization
    async init() {
      this.loadingModal = true
      if (sessionStorage.getItem("pagination")) {
        sessionStorage.setItem("pagination", "5")
      }
      if (sessionStorage.getItem("paginationlevel2")) {
        sessionStorage.setItem("paginationlevel2", "5")
      }
      try {
        await this.getSupportedLanguages()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Supported Languages",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.selectedLanguage ? (this.language = this.selectedLanguage) : ""
      await this.fetchSyncJobs()
      this.loadingModal = false
    },
    closeAddLevel3Modal() {
      this.isAddLevelThreeItem = false
      this.level3Data = this.itemsAssociatedToLevel
      this.level3ReRender++
    },
    filterByCategory() {
      if (this.prevCategory === this.category) return
      this.getItemsAssociatedToLevel(`level${this.level}`, this.category)
      this.prevCategory = this.category
    },
    closeUpdateUrlItemsModal() {
      this.level3Data = this.itemsAssociatedToLevel
      this.level3ReRender++
      this.isUpdateUrlItem = false
    },
    closeUpdateLevel2ItemsModal() {
      this.isUpdateLevel2Item = false
      this.level2Data = this.levelTwoItems
      this.level2ReRender++
    },
    async getLevelOneItems() {
      this.setLanguageSelected(this.language)
      this.loadingLevel1 = true
      this.isShowUrlItemsCard = false
      this.isShowLevelTwoCard = false
      try {
        await this.getlevelOneItemsList(this.language)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Level 1 Items",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingLevel1 = false
    },
    // Get Level Two Items related to Level One
    async getLevelTwoItems() {
      this.loadingLevel2 = true
      let input = {}
      input.lang = this.language
      input.key = this.rowData.find(item => item.name === "item_key")?.value
      try {
        await this.getlevelTwoItemsList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Level 2 Items",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      await this.setLevelTwoItems()
      this.level2Data = this.levelTwoItems
      this.loadingLevel2 = false
    },
    async getItemsAssociatedToLevel(level, category) {
      this.urlItemLoading = true
      let input = {}
      input.parentItem = ""
      if (level === "level2")
        input.parentItem = this.levelTwoSelectedItem.find(
          item => item.name === "item_key"
        )?.value
      else if (level === "level1")
        input.parentItem = this.levelOneSelectedItem.find(
          entry => entry.name === "item_key"
        )?.value
      input.category = category
      try {
        await this.getlevelAssociatedItemsList(input)
        this.level3Data = this.itemsAssociatedToLevel
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Url Items",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.urlItemLoading = false
    },
    async openLevelTwo({ row }) {
      if (
        (this.isShowLevelTwoCard || this.isShowUrlItemsCard) &&
        row.find(item => item.name === "item_key")?.value ===
          this.levelOneSelectedItem.find(item => item.name === "item_key")
            ?.value
      ) {
        this.isShowLevelTwoCard = false
        this.isShowUrlItemsCard = false
        this.setlevel1SelectedItem([])
        this.setlevel2SelectedItem([])
        this.setParentItem([])
        this.setParentLevel(0)
        return
      }
      try {
        let input = {
          lang: this.selectedLanguage,
          category: "",
        }
        await this.getlevelTwoCategoriesList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Categories List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.rowData = row
      this.setlevel1SelectedItem(row)
      await this.setParentItem(row)
      let inputObject = {}
      inputObject.language = this.language
      inputObject.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      try {
        await this.getlevelTwoCategoriesRefList(inputObject)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Categories Ref List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.setParentLevel(1)
      if (
        this.rowData.find(item => item.name === "has_level_2")?.value === "true"
      ) {
        this.isShowUrlItemsCard = false
        this.isShowLevelTwoCard = true
        await this.getLevelTwoItems()
        this.level2Data = this.levelTwoItems
      } else {
        this.isShowLevelTwoCard = false
        this.isShowUrlItemsCard = true
        await this.getItemsAssociatedToLevel("level1", this.category)
        this.level3Data = this.itemsAssociatedToLevel
      }
    },
    async openLevelThree({ row }) {
      if (this.levelTwoSelectedItem.length) {
        if (
          row.find(item => item.name === "item_key")?.value ===
          this.levelTwoSelectedItem.find(item => item.name === "item_key")
            ?.value
        ) {
          this.setParentItem(this.levelOneSelectedItem)
          this.setParentLevel(1)
          this.setLevel2DoubleClickItem(row)
          this.setlevel2SelectedItem([])
          this.isShowUrlItemsCard = false
          return
        }
      }
      try {
        let input = {
          lang: this.selectedLanguage,
          category: "",
        }
        await this.getlevelTwoCategoriesList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Categories List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.isShowUrlItemsCard = true
      this.setlevel2SelectedItem(row)
      await this.setParentItem(row)
      let inputObject = {}
      inputObject.language = this.language
      inputObject.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      try {
        await this.getlevelTwoCategoriesRefList(inputObject)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Categories Ref List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.setParentLevel(2)
      this.setSelectedCategory("")
      this.category = ""
      this.getItemsAssociatedToLevel("level2", "")
      this.level3Data = this.itemsAssociatedToLevel
    },
    // Open Add Level Two Items Modal
    openAddLevelTwoItem() {
      this.isAddLevelTwoItem = true
    },
    // open Add/Display Category Items Modal
    async openAddCategoryItem() {
      this.loadingCategory = true
      try {
        let input = {
          lang: this.selectedLanguage,
          category: "",
        }
        await this.getlevelTwoCategoriesList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Url Items",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingCategory = false
      this.isShowCategoryDialog = true
    },
    async hideCategoryDialog() {
      await this.getItemsAssociatedToLevel(`level${this.level}`, this.category)
      this.isShowCategoryDialog = false
    },
    async openAddUrlItems() {
      await this.setSelectedCategory(this.category)
      this.isAddLevelThreeItem = true
    },
    // Remove Level Two Smart Nav Configuration Item
    async removeLevelTwoSmartNavConfigurationItem(selectedRow) {
      this.loadingLevel2 = true
      const data = {
        level1Item: this.levelOneSelectedItem.find(
          item => item.name === "item_key"
        )?.value,
        level2ItemKey: selectedRow.find(item => item.name === "item_key")
          ?.value,
      }
      let reomveApiInput = []
      let inputObj = {}
      inputObj.lang = this.selectedLanguage
      inputObj.key = this.levelOneSelectedItem.find(
        item => item.name === "item_key"
      )?.value
      reomveApiInput.push(inputObj)
      reomveApiInput.push(data)
      try {
        await this.removeLevel2Item(reomveApiInput)
        this.$swal.fire({
          position: "top-end",
          html: "Level 2 item is removed successfully",
          icon: "success",
          title: "Remove Level 2 Item",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Remove Level 2 Item",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.setParentItem(this.levelOneSelectedItem)
      this.setParentLevel(1)
      this.setlevel2SelectedItem([])
      this.loadingLevel2 = false
      this.isShowUrlItemsCard = false
    },
    // Remove Smart Nav Configuration URL Item
    async removeURLSmartNavConfigurationItem(selectedRow) {
      this.loadingLevel3 = true
      let final = []
      let obj = {}
      if (this.level === 2)
        obj.parentItem = this.levelTwoSelectedItem.find(
          item => item.name === "item_key"
        )?.value
      else if (this.level === 1)
        obj.parentItem = this.levelOneSelectedItem.find(
          item => item.name === "item_key"
        )?.value
      obj.category = this.category
      final.push(obj)
      final.push(selectedRow.find(item => item.name === "id")?.value)
      try {
        await this.removeUrlItem(final)
        this.$swal.fire({
          position: "top-end",
          html: "Url item is removed successfully",
          icon: "success",
          title: "Remove Url Item",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Remove Url Item",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.level3Data = this.itemsAssociatedToLevel
      this.level3ReRender++
    },
    // Update Smart Nav Configuartion URL Items
    async updateURLSmartNavConfigurationItem(selectedRow) {
      this.loadingLevel3 = true
      try {
        let input = {
          lang: this.selectedLanguage,
          category: "",
        }
        await this.getlevelTwoCategoriesList(input)
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Categories List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.setSelectedCategory(this.category)
      this.isUpdateUrlItem = true
      this.seturlItemSelected(selectedRow)
      this.loadingLevel3 = false
      this.isUpdateUrlItem = true
    },
    // Move URL Item Up
    updateSmartNavConfigurtionLevel2Items(selectedRow) {
      this.isUpdateLevel2Item = true
      this.setlevel2SelectedItem(selectedRow)
      this.setLevel2DoubleClickItem(selectedRow)
    },
    async handleRunJob() {
      let payload = {
        job: "wp_url_sync",
        chainJob: false,
      }
      await this[CAMPSITES_DATA_SYNC_JOB_TRIGGER](payload)
      this.$swal.fire({
        position: "top-end",
        html: "wp_url_sync job triggered",
        icon: "success",
        title: "wp_url_sync job",
        showConfirmButton: false,
        timer: 4000,
        showCloseButton: true,
      })
    },
    async handleRefreshLastUpdated() {
      await this.fetchSyncJobs()
      this.$swal.fire({
        position: "top-end",
        html: "Refreshed Sync Time successfully",
        icon: "success",
        title: "Refresh Sync Time",
        showConfirmButton: false,
        timer: 4000,
        showCloseButton: true,
      })
    },
    moveUpUrlItem(selectedRow) {
      for (let index = 0; index < this.level3Data.rows.length; index++) {
        if (
          selectedRow.find(item => item.name === "id")?.value ===
            this.level3Data.rows[index].row.find(item => item.name === "id")
              ?.value &&
          index !== 0
        ) {
          const temp = this.level3Data.rows[index - 1]
          this.level3Data.rows[index - 1] = this.level3Data.rows[index]
          this.level3Data.rows[index] = temp
          const pos = this.level3Data.rows[index].row.findIndex(
            x => x.name == "position"
          )
          const item = this.level3Data.rows[index - 1].row[pos].value
          this.level3Data.rows[index - 1].row[pos].value = this.level3Data.rows[
            index
          ].row[pos].value
          this.level3Data.rows[index].row[pos].value = item
          this.level3ReRender++
          return
        }
      }
    },
    // Save the updated Position of URL Items
    async saveUrlItemPosition() {
      this.loadingLevel3 = true
      this.swappingUrlItemArray.items = []
      this.swappingUrlItemArray.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      for (let index = 0; index < this.level3Data.rows.length; index++) {
        let item = {
          id: this.level3Data.rows[index].row.find(item => item.name === "id")
            .value,
          position: this.level3Data.rows[index].row.find(
            item => item.name === "position"
          )?.value,
        }
        this.swappingUrlItemArray.items.push(item)
      }
      const level3SwapInput = []
      const inputObject = {}
      inputObject.lang = this.selectedLanguage
      inputObject.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      inputObject.category = this.category
      level3SwapInput.push(inputObject)
      level3SwapInput.push(this.swappingUrlItemArray)
      try {
        await this.swapUrlItems(level3SwapInput)
        this.$swal.fire({
          position: "top-end",
          html: "Url item position updated successfully",
          icon: "success",
          title: "Save Url Item Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Save Url Item Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingLevel3 = false
      this.level3Data = this.itemsAssociatedToLevel
      this.level3ReRender++
      this.swappingUrlItemArray = {}
    },
    // Move URL Item Down
    moveDownUrlItem(selectedRow) {
      for (let i = 0; i < this.level3Data.rows.length; i++) {
        if (
          selectedRow.find(item => item.name === "id")?.value ===
            this.level3Data.rows[i].row.find(item => item.name === "id")
              ?.value &&
          i + 1 < this.level3Data.rows.length
        ) {
          const temp = this.level3Data.rows[i + 1]
          this.level3Data.rows[i + 1] = this.level3Data.rows[i]
          this.level3Data.rows[i] = temp
          const pos = this.level3Data.rows[i].row.findIndex(
            x => x.name == "position"
          )
          const item = this.level3Data.rows[i + 1].row[pos].value
          this.level3Data.rows[i + 1].row[pos].value = this.level3Data.rows[
            i
          ].row[pos].value
          this.level3Data.rows[i].row[pos].value = item
          this.level3ReRender++
          return
        }
      }
    },
    // Move Up Level Two Smart Nav Configuartion Item
    moveUpLevel2Item(selectedRow) {
      for (let index = 0; index < this.level2Data.rows.length; index++) {
        if (
          selectedRow.find(item => item.name === "item_key")?.value ===
            this.level2Data.rows[index].row.find(
              item => item.name === "item_key"
            )?.value &&
          index !== 0
        ) {
          const pos = this.level2Data.rows[index].row.findIndex(
            x => x.name == "position"
          )
          let temp = this.level2Data.rows[index - 1].row[pos].value
          this.level2Data.rows[index - 1].row[pos].value = this.level2Data.rows[
            index
          ].row[pos].value
          this.level2Data.rows[index].row[pos].value = temp
          let item = this.level2Data.rows[index - 1]
          this.level2Data.rows[index - 1] = this.level2Data.rows[index]
          this.level2Data.rows[index] = item
          this.level2ReRender++
          return
        }
      }
    },
    // Save the updated position of Level Two Items
    async saveLevel2ItemPosition() {
      this.loadingLevel2 = true
      this.swappingLevel2ItemArray.items = []
      this.swappingLevel2ItemArray.parentItem = this.selectedParent.find(
        item => item.name === "item_key"
      )?.value
      for (let index = 0; index < this.level2Data.rows.length; index++) {
        let item = {
          id: this.level2Data.rows[index].row.find(item => item.name === "id")
            ?.value,
          position: this.level2Data.rows[index].row.find(
            item => item.name === "position"
          )?.value,
        }
        this.swappingLevel2ItemArray.items.push(item)
      }
      const level2SwapInput = []
      const inputObject = {}
      inputObject.lang = this.selectedLanguage
      inputObject.key = this.levelOneSelectedItem.find(
        item => item.name === "item_key"
      )?.value
      level2SwapInput.push(inputObject)
      level2SwapInput.push(this.swappingLevel2ItemArray)
      try {
        await this.swapLevel2Item(level2SwapInput)
        this.$swal.fire({
          position: "top-end",
          html: "Level 2 item position updated successfully",
          icon: "success",
          title: "Save Level 2 Item Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Save Level 2 Item Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingLevel2 = false
      this.level2Data = this.levelTwoItems
      this.level2ReRender++
      this.swappingLevel2ItemArray = {}
    },
    // Move Down Level Two Smart Nav Configuartion Item
    moveDownLevel2Item(selectedRow) {
      for (let index = 0; index < this.level2Data.rows.length; index++) {
        if (
          selectedRow.find(item => item.name === "item_key")?.value ===
            this.level2Data.rows[index].row.find(
              item => item.name === "item_key"
            )?.value &&
          index + 1 < this.level2Data.rows.length
        ) {
          const pos = this.level2Data.rows[index].row.findIndex(
            x => x.name == "position"
          )
          const temp = this.level2Data.rows[index + 1].row[pos].value
          this.level2Data.rows[index + 1].row[pos].value = this.level2Data.rows[
            index
          ].row[pos].value
          this.level2Data.rows[index].row[pos].value = temp
          const item = this.level2Data.rows[index + 1]
          this.level2Data.rows[index + 1] = this.level2Data.rows[index]
          this.level2Data.rows[index] = item
          this.level2ReRender++
          return
        }
      }
    },
    // Save the selected URL Item
    handleRowSelect(selectedRow) {
      selectedRow ? this.seturlItemSelected(selectedRow) : ""
    },
    handleLevel2RowSelect(selectedRow) {
      selectedRow ? this.setlevel2SelectedItem(selectedRow) : ""
    },
  },
  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      levelOneItems: state => state.levelOneItems,
      levelTwoItems: state => state.levelTwoItems,
      levelTwoCategories: state => state.levelTwoCategories,
      itemsAssociatedToLevel: state => state.itemsAssociatedToLevel,
      levelOneSelectedItem: state => state.levelOneSelectedItem,
      levelTwoSelectedItem: state => state.levelTwoSelectedItem,
      selectedUrlItem: state => state.selectedUrlItem,
      selectedLanguage: state => state.selectedLanguage,
      selectedParent: state => state.selectedParent,
      level: state => state.level,
      supportedLanguages: state => state.supportedLanguages,
      levelTwoCategoriesRef: state => state.levelTwoCategoriesRef,
      syncJobs: state => state.syncJobs,
      finishDateTime() {
        return (
          this.syncJobs.find(item => item.name === "finish_datetime")?.value ||
          ""
        )
      },
      finishStatus() {
        return (
          this.syncJobs.find(item => item.name === "last_update_status")
            ?.value || ""
        )
      },
      showCategoryFilter() {
        if (this.selectedParent.length) {
          return JSON.parse(
            this.selectedParent.find(item => item.name === "has_categories")
              ?.value
          )
        }
      },
    }),
    getLevel3Data() {
      return this.level3Data
    },
  },
}
</script>

<style scoped>
.dropdown-position1 {
  justify-content: left;
  margin-left: 300px;
}
.margin-adjust {
  margin-top: -70px;
}
.dropdown-position {
  justify-content: center;
}
.level-two-buttons {
  margin: 10px !important;
}
@media screen and (max-width: 767px) {
  .margin-adjust {
    margin-top: 0px !important;
  }
  .md-layout-item.md-size-15 {
    min-width: 100%;
    max-width: 100%;
  }
  .dropdown-position1 {
    justify-content: center !important;
    margin-left: 0px !important;
  }
}
.spinner-customization {
  position: absolute;
  top: 0;
  width: 100%;
}
.position-relative {
  position: relative;
}
.language-card {
  display: inline-flex;
  width: 40%;
}
.sitemapCard {
  display: inline-flex;
  width: 55%;
  float: right;
}
.syncButton {
  height: 37px;
  margin: auto 0px;
}
.refreshButton {
  margin: 0px;
  margin-left: 15px;
  height: 37px;
}
@media screen and (max-width: 1280px) {
  .responsive-view {
    display: grid;
    width: 100% !important;
  }
  .language-card {
    width: 100% !important;
  }
  .sitemapCard {
    width: 100% !important;
  }
}
.md-card-header {
  padding: 16px !important;
}
</style>
