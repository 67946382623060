<template>
  <div class="px-5">
    <div class="label-field pl-0 mb-2">Lang: {{ amenityData.lang }}</div>
    <form novalidate class="md-layout " @submit.prevent>
      <template>
        <div class="label-field" v-if="!mutipleAmenitiesCategoires">
          Category: {{ amenityData.category }}
        </div>

        <md-field v-else :class="showValidationErros()" @md-selected="validate">
          <label>Category</label>
          <md-select
            v-model="amenityData.category"
            name="catgory"
            id="catgory"
            disabled
          >
            <md-option
              v-for="category in getAmenitiesCategoires"
              :key="category"
              :value="category"
              >{{ category }}
            </md-option>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.category"
          >
            This field is required !
          </span>
        </md-field>

        <md-field>
          <label>Sub Category</label>
          <md-select
            v-model="amenityData.sub_category"
            name="subcategory"
            id="subcategory"
          >
            <template v-if="!loadingSubCategories">
              <template v-if="categorySelected">
                <md-option value="">
                  None
                </md-option>
                <md-option
                  v-for="subcategory in subcategoryOptions"
                  :key="subcategory"
                  :value="subcategory"
                  >{{ subcategory || "None" }}
                </md-option>
              </template>
            </template>
          </md-select>
        </md-field>

        <md-field :class="showValidationErros()">
          <label>Label</label>
          <md-select
            v-model="amenityData.label"
            name="label"
            id="label"
            @md-selected="validate"
          >
            <template v-if="!loadingLabels">
              <template v-if="categorySelected">
                <md-option value="">
                  None
                </md-option>
                <md-option
                  v-for="label in labelOptions"
                  :key="label"
                  :value="label"
                  >{{ label || "None" }}
                </md-option>
              </template>
            </template>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.label"
          >
            This field is required !
          </span>
        </md-field>

        <md-field>
          <label>
            Distance KM
          </label>
          <md-input v-model="amenityData.distance" type="number"> </md-input>
        </md-field>

        <md-field>
          <label>
            Additional Info
          </label>
          <md-input v-model="amenityData.additional_info" type="text">
          </md-input>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="updateAmenities()"
          >
            UPDATE
          </md-button>
        </div>
      </template>
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="apiInProgress"
      />
    </form>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import {
  CAMPSITES,
  UPDATE_STAY_FACILITIES_AMENITIES,
  SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
  SET_FACILITIES_AMENITIES_LABELS,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "EditAmenitiesForm",

  props: {
    type: String,
  },
  data: () => ({
    amenityData: {},
    loadingLabels: false,
    loadingSubCategories: false,
    apiInProgress: false,

    validation: {
      category: false,
      label: false,
      showErrors: false,
    },
  }),
  created() {
    this.init()
  },
  methods: {
    // Initialize the Level 2 Items
    async init() {
      this.amenityData = this.prepareAmenityData(this.selectedAmenity)
      await this.fetchSubCategories(this.amenityData.category)
      await this.fetchLabels(this.amenityData.category)
      await this.getSupportedLanguages()
    },
    // Update Amenities View
    async updateAmenities() {
      this.validate()
      if (!this.validation.category || !this.validation.label) return
      const { id } = this.$route.params
      const {
        amenity_id,
        lang,
        category,
        sub_category,
        label,
        distance,
        additional_info,
      } = this.amenityData

      const payload = {
        stayFacilityId: id,
        data: {
          amenityId: amenity_id,
          stayFacilityId: id,
          lang: lang,
          name: label,
          category: category,
          subcategory: sub_category,
          distanceKm: distance,
          additionalInfo: additional_info,
          featureKey: this.selectedRow.find(
            entity => entity.name === "feature_key"
          ).value,
        },
        type: this.type,
      }
      this.apiInProgress = true
      await this.updateStayFacilitiesAmenities(payload)
      this.apiInProgress = false

      this.$emit("onFormSubmit")
    },
    prepareAmenityData(array) {
      return array.reduce((obj, item) => {
        obj[item.name.replace(/-([a-z])/g, (_, c) => c.toUpperCase())] =
          item.value
        return obj
      }, {})
    },
    async onCategoryChange(category) {
      await this.fetchSubCategories(category)
      await this.fetchLabels(category)
    },

    async fetchSubCategories(category) {
      this.loadingSubCategories = true
      await this.setAmenitiesSubCategories({ category: category })
      this.loadingSubCategories = false
    },

    async fetchLabels(category) {
      this.loadingLabels = true
      await this.setAmenitiesLabels({ category: category })
      this.loadingLabels = false
    },

    validate() {
      this.validation.category = !!this.amenityData.category
      this.validation.label = !!this.amenityData.label
      this.validation.showErrors = true
    },
    showValidationErros() {
      return { "md-invalid mb-3": this.validation.showErrors }
    },

    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
    }),
    ...mapActions(CAMPSITES, {
      updateStayFacilitiesAmenities: UPDATE_STAY_FACILITIES_AMENITIES,
      setAmenitiesSubCategories: SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
      setAmenitiesLabels: SET_FACILITIES_AMENITIES_LABELS,
    }),
  },
  computed: {
    ...mapState(CAMPSITES, {
      selectedAmenity: state => state.selectedAmenity,
      selectedRow: state => state.selectedRow,
    }),
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
    }),

    ...mapGetters(CAMPSITES, [
      "mutipleAmenitiesCategoires",
      "getAmenitiesCategoires",
      "getAmenitiesSubCategories",
      "getAmenitiesLabels",
    ]),

    subcategoryOptions() {
      return this.getAmenitiesSubCategories.filter(item => item !== null)
    },

    labelOptions() {
      return this.getAmenitiesLabels.filter(item => item !== null)
    },
    categorySelected() {
      return this.mutipleAmenitiesCategoires
        ? !!this.amenityData.category.length > 0
        : true
    },
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
@media screen and (max-width: 600px) {
  .checkbox-area-sm {
    width: 100% !important;
    flex-basis: 100%;
  }
}
.md-title {
  font-size: 14px !important;
}

.label-field {
  font-size: 14px;
  font-weight: 500;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
