export const SYSTEM_ACTIONS = "SYSTEM_ACTIONS"

export const SYSTEM_ACTIONS_FETCH_ACTIONS_LIST =
  "SYSTEM_ACTIONS_FETCH_ACTIONS_LIST"

export const SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL =
  "SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL"

export const SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS =
  "SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS"
