export const CAMPSITES = "CAMPSITES"

export const CAMPSITES_FETCH_LIST = "CAMPSITES_FETCH_LIST"
export const CAMPSITES_UPDATE_LIST = "CAMPSITES_UPDATE_LIST"

export const CAMPSITES_FETCH_ITEM = "CAMPSITES_FETCH_ITEM"
export const CAMPSITES_UPDATE_ITEM = "CAMPSITES_UPDATE_ITEM"
export const CAMPSITES_CLEAR_ITEM = "CAMPSITES_CLEAR_ITEM"
export const CAMPSITES_UPDATE_SEARCH_FORM = "CAMPSITES_UPDATE_SEARCH_FORM"

export const CAMPSITES_FETCH_ITEM_WELLBEING = "CAMPSITES_FETCH_ITEM_WELLBEING"
export const CAMPSITES_UPDATE_ITEM_WELLBEING = "CAMPSITES_UPDATE_ITEM_WELLBEING"

export const CAMPSITES_FETCH_ITEM_LOCATION_INFO =
  "CAMPSITES_FETCH_ITEM_LOCATION_INFO"
export const CAMPSITES_FETCH_ITEM_LEISURE = "CAMPSITES_FETCH_ITEM_LEISURE"
export const CAMPSITES_UPDATE_ITEM_LEISURE = "CAMPSITES_UPDATE_ITEM_LEISURE"

export const CAMPSITES_FETCH_ITEM_ACCOMMODATIONS =
  "CAMPSITES_FETCH_ITEM_ACCOMMODATIONS"
export const CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS =
  "CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS"

export const CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES =
  "CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES"
export const CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES =
  "CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES"

export const CAMPSITES_FETCH_ITEM_FACILITIES = "CAMPSITES_FETCH_ITEM_FACILITIES"
export const CAMPSITES_UPDATE_ITEM_FACILITIES =
  "CAMPSITES_UPDATE_ITEM_FACILITIES"

export const CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO =
  "CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO"
export const CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO =
  "CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO"

export const CAMPSITES_UPDATE_ITEM_LOCATION_INFO =
  "CAMPSITES_UPDATE_ITEM_LOCATION_INFO"

export const CAMPSITES_FETCH_ITEM_GENERAL_INFO =
  "CAMPSITES_FETCH_ITEM_GENERAL_INFO"
export const CAMPSITES_UPDATE_ITEM_GENERAL_INFO =
  "CAMPSITES_UPDATE_ITEM_GENERAL_INFO"

export const CAMPSITES_CREATE_ITEM_COMMENT = "CAMPSITES_CREATE_ITEM_COMMENT"

export const CAMPSITES_FETCH_ITEM_COMMENTS = "CAMPSITES_FETCH_ITEM_COMMENTS"

export const CAMPSITES_UPDATE_ITEM_COMMENTS = "CAMPSITES_UPDATE_ITEM_COMMENTS"

export const CAMPSITES_FETCH_ITEM_DETAILS = "CAMPSITES_FETCH_ITEM_DETAILS"
export const CAMPSITES_UPDATE_ITEM_DETAILS = "CAMPSITES_UPDATE_ITEM_DETAILS"

export const CAMPSITES_FETCH_PHOTOS = "CAMPSITES_FETCH_PHOTOS"
export const CAMPSITES_FETCH_PHOTO_LABEL = "CAMPSITES_FETCH_PHOTO_LABEL"
export const CAMPSITES_FETCH_UPLOADED_PHOTOS = "CAMPSITES_FETCH_UPLOADED_PHOTOS"
export const CAMPSITES_UPLOAD_NEW_PHOTO = "CAMPSITES_UPLOAD_NEW_PHOTO"
export const CAMPSITES_UPDATE_PHOTOS = "CAMPSITES_UPDATE_PHOTOS"
export const CAMPSITES_UPDATE_UPLOAD_PHOTOS = "CAMPSITES_UPDATE_UPLOAD_PHOTOS"
export const CAMPSITES_DELETE_CS_UPLOADED_PHOTO =
  "CAMPSITES_DELETE_CS_UPLOADED_PHOTO"
export const CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS =
  "CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS"

export const CAMPSITES_RE_SORT_PHOTOS = "CAMPSITES_RE_SORT_PHOTOS"
export const CAMPSITES_RE_SORT_UPLOAD_PHOTOS = "CAMPSITES_RE_SORT_UPLOAD_PHOTOS"

export const CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO =
  "CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO"

export const CAMPSITES_RESET_PHOTO_POSITION = "CAMPSITES_RESET_PHOTO_POSITION"

export const CAMPSITES_UPDATE_CS_SELECTED_PHOTOS =
  "CAMPSITES_UPDATE_CS_SELECTED_PHOTOS"

export const CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS =
  "CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS"

export const CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM =
  "CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM"

export const CAMPSITES_FETCH_DATA_STATUS = "CAMPSITES_FETCH_DATA_STATUS"

export const ACCOMMODATION_FETCH_DETAILS = "ACCOMMODATION_FETCH_DETAILS"
export const ACCOMMODATION_FETCH_FEATURES = "ACCOMMODATION_FETCH_FEATURES"

export const ACCOMMODATION_FETCH_PHOTOS = "ACCOMMODATION_FETCH_PHOTOS"
export const ACCOMMODATION_UPDATE_PHOTOS = "ACCOMMODATION_UPDATE_ALL_PHOTOS"
export const ACCOMMODATION_FETCH_PHOTOS_BY_ID =
  "ACCOMMODATION_FETCH_PHOTOS_BY_ID"
export const ACCOMMODATION_UPDATE_PHOTOS_BY_ID =
  "ACCOMMODATION_UPDATE_PHOTOS_BY_ID"
export const ACCOMMODATION_RE_SORT_PHOTOS = "ACCOMMODATION_RE_SORT_PHOTOS"
export const ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO =
  "ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO"
export const ACCOMMODATION_RESET_PHOTO_POSITION =
  "ACCOMMODATION_RESET_PHOTO_POSITION"
export const ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS =
  "ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS"
export const GET_CAMPSITE_GROUP = "GET_CAMPSITE_GROUP"
export const CAMPSITES_UPDATE_DETAIL = "CAMPSITES_UPDATE_DETAIL"
export const CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM =
  "CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM"
export const REFRESH_CAMPSITES_CACHE = "REFRESH_CAMPSITES_CACHE"
export const FLUSH_CACHE_CAMPSITE_SEARCH_APP = "FLUSH_CACHE_CAMPSITE_SEARCH_APP"

export const FETCH_STAY_FACILITIES_AMENITIES = "FETCH_STAY_FACILITIES_AMENITIES"
export const ADD_STAY_FACILITIES_AMENITIES = "ADD_STAY_FACILITIES_AMENITIES"
export const UPDATE_STAY_FACILITIES_AMENITIES =
  "UPDATE_STAY_FACILITIES_AMENITIES"
export const DELETE_STAY_FACILITIES_AMENITIES =
  "DELETE_STAY_FACILITIES_AMENITIES"
export const SET_SELECTED_AMENITY_ROW = "SET_SELECTED_AMENITY_ROW"

export const SET_FACILITIES_AMENITIES_CATEGOIRES =
  "SET_FACILITIES_AMENITIES_CATEGOIRES"
export const SET_FACILITIES_AMENITIES_SUB_CATEGORIES =
  "SET_FACILITIES_AMENITIES_SUB_CATEGORIES"
export const SET_FACILITIES_AMENITIES_LABELS = "SET_FACILITIES_AMENITIES_LABELS"

export const ADD_ACCOMMODATION_AMENITIES = "ADD_ACCOMMODATION_AMENITIES"
export const DELETE_ACCOMMODATION_AMENITY = "DELETE_ACCOMMODATION_AMENITY"
export const SET_ACCOMMODATION_AMENITIES_CATEGORIES =
  "SET_ACCOMMODATION_AMENITIES_CATEGORIES"
export const SET_ACCOMMODATION_AMENITIES_LABELS =
  "SET_ACCOMMODATION_AMENITIES_LABELS"

export const FETCH_ACCOMMODATION_AMENITY_DETAILS =
  "FETCH_ACCOMMODATION_AMENITY_DETAILS"
export const UPDATE_ACCOMMODATION_AMENITY = "UPDATE_ACCOMMODATION_AMENITY"

export const UPDATE_ACCOMMODATION_DETAILS = "UPDATE_ACCOMMODATION_DETAILS"
