export default {
  props: {
    isShowDialog: Boolean,
    onDialogClosed: Function,
  },
  data: () => ({
    isOpen: false,
    isFormSending: false,
    dialogStyles: {
      width: "100%",
      "max-width": "600px",
      margin: "0 auto",
    },
  }),
  methods: {
    closeDialog() {
      this.$emit("onDialogClosed")
    },
  },
  watch: {
    isShowDialog(value) {
      this.isOpen = value
      this.updateShowByProps = true
    },
    isOpen(value) {
      if (!this.updateShowByProps && !value) {
        this.closeDialog()
      }
      this.updateShowByProps = false
    },
  },
}
