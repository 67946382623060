import {
  REPORTS_UPDATE_LIST,
  REPORTS_UPDATE_DETAILS_LIST,
  REPORTS_UPDATE_DETAILS_META,
  REPORTS_UPDATE_MAP_MARKERS,
  REPORTS_UPDATE_REPORT_NAME,
} from "@/modules/reports/store/mutations-types"

const mutations = {
  [REPORTS_UPDATE_LIST](state, payload) {
    state.reportsList = payload
  },
  [REPORTS_UPDATE_DETAILS_META](state, payload) {
    state.reportDetailsMeta = payload
  },
  [REPORTS_UPDATE_DETAILS_LIST](state, payload) {
    state.reportDetailsList = payload
  },
  [REPORTS_UPDATE_MAP_MARKERS](state, payload) {
    state.markers = payload
  },
  [REPORTS_UPDATE_REPORT_NAME](state, payload) {
    state.reportName = payload
  },
}

export default mutations
