import {
  REPORTS_FETCH_LIST,
  REPORTS_UPDATE_LIST,
  REPORTS_FETCH_DETAILS,
  REPORTS_UPDATE_DETAILS_META,
  REPORTS_UPDATE_DETAILS_LIST,
  REPORTS_UPDATE_MAP_MARKERS,
  REPORTS_UPDATE_REPORT_NAME,
} from "@/modules/reports/store/mutations-types"
import {
  getGridDataItemFirstCollection,
  getDataItemFirstCollection,
} from "@/utils/api.utils"
import _ from "lodash"

const actions = {
  async [REPORTS_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.reports.fetchReports(params)
    const { rows } = getGridDataItemFirstCollection(response)

    commit(REPORTS_UPDATE_LIST, rows)
  },
  async [REPORTS_FETCH_DETAILS]({ commit }, params) {
    const response = await this.$repositories.reports.fetchReportDetails(params)

    const list = getGridDataItemFirstCollection(response)
    const meta = getDataItemFirstCollection(response)
    const rows = list.rows

    let name
    meta.dataItems.map(item => {
      if (item.name === "report_name") {
        name = item.value
      }
    })

    const markers = _.map(rows, "row")
      .map(row =>
        row.reduce(
          (accumulator, { name, value }) => (
            (accumulator[name] = value), accumulator
          ),
          {}
        )
      )
      .filter(item => !_.isEmpty(item.lat))

    commit(REPORTS_UPDATE_DETAILS_LIST, list)
    commit(REPORTS_UPDATE_DETAILS_META, meta)
    commit(REPORTS_UPDATE_MAP_MARKERS, markers)
    commit(REPORTS_UPDATE_REPORT_NAME, name)
  },
}

export default actions
