<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Feed Job Exclusions/Inclusions</h4>
      </div>
    </md-card-header>
    <div class="md-layout md-alignment-left">
      <div class="md-layout-item md-size-25 select-form">
        <md-field>
          <label>Job Name</label>
          <md-select
            v-model="feedName"
            name="jobName"
            id="jobName"
            placeholder="Job Name"
            @input="search"
          >
            <md-option
              v-for="(jobName, key) in jobNamesList"
              :key="key"
              :value="jobName.value"
              >{{ jobName.value }}</md-option
            >
          </md-select>
        </md-field>
      </div>
    </div>

    <md-card-content>
      <UniversalTable
        :data="list"
        :isFetching="isFetching"
        @onRowSelect="handleRowSelect"
        :empty-title-text="emptySearchTableTitle"
        :export-file-name="dataExportFileName"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <md-button
            class="md-button md-sm md-info mr-2"
            @click="handleUploadButtonClick"
          >
            Upload batch
          </md-button>
          <md-button
            class="md-button md-sm md-info mr-2"
            @click="handleAddButtonClick"
          >
            Add item
          </md-button>
          <md-button
            class="md-button md-sm md-theme-default mr-2"
            :disabled="!selectedRow"
            @click="handleDeleteButtonClick"
          >
            Remove item
          </md-button>
        </template>
      </UniversalTable>
    </md-card-content>

    <md-snackbar
      :md-position="snackBar.position"
      :md-duration="snackBar.duration"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>Item removed</span>
    </md-snackbar>

    <CreateFeedJobDialog
      :is-show-dialog="isCreateDialogShow"
      @onDialogClosed="handleCreateDialogClosed"
    />
    <CreateFeedJobUploadDialog
      :is-show-dialog="isCreateUploadDialogShow"
      @onDialogClosed="handleCreateUploadDialogClosed"
    />
  </md-card>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  FEED_JOBS_ADD_ITEM,
  FEED_JOBS_DELETE_ITEM,
  FEED_JOBS_FETCH_ITEMS,
  FEED_JOBS_FETCH_LIST,
  FEED_JOBS_FETCH_JOB_NAMES_LIST,
} from "@/modules/feed-jobs/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import CreateFeedJobDialog from "@/modules/feed-jobs/views/components/dialogs/CreateFeedJobDialog"
import CreateFeedJobUploadDialog from "@/modules/feed-jobs/views/components/dialogs/CreateFeedJobUploadDialog"

export default {
  name: "SearchFeedJobsForm",
  components: {
    UniversalTable,
    CreateFeedJobDialog,
    CreateFeedJobUploadDialog,
  },
  data() {
    return {
      snackBar: {
        duration: 4000,
        position: "center",
      },
      isFetching: false,
      isCreateDialogShow: false,
      isCreateUploadDialogShow: false,
      feedJob: null,
      selectedItem: {},
      emptySearchTableTitle: "Feed Job table is empty",
      dataExportFileName: "Feed Job Exclusions/Inclusions",
      showSnackbar: false,
      feedName: "",
    }
  },

  computed: {
    ...mapState(FEED_JOBS, {
      items: state => state.allFeedJobList,
      list: state => state.itemsFeedJobsList,
      jobNamesList: state => state.jobNamesList,
    }),
    ...mapState(FEED_JOBS, {
      selectedJob: state => state.selectedJob,
    }),
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      feedJobsNames: FEED_JOBS_FETCH_JOB_NAMES_LIST,
      feedJobsList: FEED_JOBS_FETCH_LIST,
      feedJobsItemsByJobName: FEED_JOBS_FETCH_ITEMS,
      feedJobsAddItem: FEED_JOBS_ADD_ITEM,
      feedJobsDeleteItem: FEED_JOBS_DELETE_ITEM,
    }),
    handleAddButtonClick() {
      this.isCreateDialogShow = true
    },
    handleCreateDialogClosed() {
      this.isCreateDialogShow = false
    },
    handleUploadButtonClick() {
      this.isCreateUploadDialogShow = true
    },
    handleCreateUploadDialogClosed() {
      this.isCreateUploadDialogShow = false
    },
    handleDeleteButtonClick() {
      this.showSnackbar = true
      this.feedJobsDeleteItem(this.selectedItem)
    },
    handleRowSelect(row) {
      this.feedJob = row
      const params = {
        jobName: this.selectedJob,
      }
      if (!row) return
      row.forEach(column => {
        const key = column.name.replace("item_", "")
        params[key] = column.value
      })
      this.selectedItem = {
        itemId: params.id,
        itemType: params.type,
        jobName: params.jobName,
      }
    },
    async search() {
      await this.feedJobsItemsByJobName(this.feedName)
    },
  },
}
</script>

<style scoped>
.select-form {
  padding-top: 10px;
}
</style>
