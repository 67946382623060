<template>
  <div>
    <GoBackBar @onGoBack="handleGoBack" :back-button-text="backButtonText" />

    <BookingDetails :trans-id="transId" />

    <div class="mt-4">
      <BookingItemPricing :trans-id="transId" />
    </div>

    <div class="mt-4">
      <BookingInsuranceOption :trans-id="transId" />
    </div>

    <div class="mt-4 mx-3">
      <BookingWeatherInsurance :trans-id="transId" />
    </div>

    <div class="mt-4">
      <BookingItemAdjustments :trans-id="transId" />
    </div>

    <div class="mt-4">
      <BookingItemComments :trans-id="transId" />
    </div>

    <div class="mt-4">
      <BookingStatusHistory :trans-id="transId" />
    </div>

    <div class="mt-4">
      <BookingInvoiceInfo :trans-id="transId" />
    </div>
  </div>
</template>

<script>
import {
  BOOKINGS_CLEAR_ITEM,
  BOOKINGS,
} from "@/modules/bookings/store/mutations-types"
import BookingItemPricing from "@/modules/bookings/views/components/BookingItemPricing"
import BookingItemAdjustments from "@/modules/bookings/views/components/BookingItemAdjustments"
import BookingWeatherInsurance from "@/modules/bookings/views/components/tables/BookingWeatherInsuranceTable"
import BookingItemComments from "@/modules/bookings/views/components/BookingItemComments"
import BookingStatusHistory from "@/modules/bookings/views/components/BookingStatusHistory"
import BookingInvoiceInfo from "@/modules/bookings/views/components/BookingInvoiceInfo"
import BookingInsuranceOption from "@/modules/bookings/views/components/BookingInsuranceOption"
import BookingDetails from "@/modules/bookings/views/components/BookingDetails"
import { mapActions } from "vuex"
import GoBackBar from "@/views/components/ui/GoBackBar"

export default {
  name: "BookingPage",
  components: {
    GoBackBar,
    BookingStatusHistory,
    BookingItemComments,
    BookingItemPricing,
    BookingDetails,
    BookingItemAdjustments,
    BookingInvoiceInfo,
    BookingInsuranceOption,
    BookingWeatherInsurance,
  },
  data: () => ({
    backButtonText: "Search bookings",
  }),
  computed: {
    transId() {
      return this.$route.params.transId
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_CLEAR_ITEM]),

    handleGoBack() {
      this.$router.push("/bookings")
    },
  },
  destroyed() {
    this[BOOKINGS_CLEAR_ITEM]()
  },
}
</script>

<style scoped></style>
