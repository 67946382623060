<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Payment Succeeded Status Check</md-dialog-title>

      <md-dialog-content>
        <BookingPaymentStatusForm
          :is-form-sending="isFormSending"
          :old-booking-status="bookingStatus"
          :onDialogClose="closeDialog"
          :onStatusPaymentFormData="initialBookingsPaymentFormData"
          @onFormSubmit="handleUpdateFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import BookingPaymentStatusForm from "@/modules/bookings/views/components/forms/BookingPaymentStatusForm"
import {
  BOOKINGS,
  BOOKINGS_FETCH_POST_PAYMENT_STATUSES,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"
import dialogMixin from "@/mixins/dialog.mixin"
export default {
  name: "PaymentBookingDialog",
  components: { BookingPaymentStatusForm },
  props: {
    bookingStatus: String,
  },
  data: () => ({
    statusPaymentFormData: {},
  }),
  mixins: [dialogMixin],
  computed: {
    bookingStatuses() {
      return this.$store.state[BOOKINGS].item.postStatuses
    },
    initialBookingsPaymentFormData() {
      // return this.$store.state[BOOKINGS].statusPaymentBookingForm
      return this.statusPaymentFormData
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_POST_PAYMENT_STATUSES]),
    async handleUpdateFormSubmit(values) {
      this.isFormSending = true

      try {
        await this[BOOKINGS_FETCH_POST_PAYMENT_STATUSES](values)
        this.isFetching = true
        this.isFormSending = true

        this.statusPaymentFormData = this.$store.state[
          BOOKINGS
        ].statusPaymentBookingForm
        return this.$store.state[BOOKINGS].statusPaymentBookingForm
      } catch (e) {
        this.isFetching = true
        this.isFormSending = true
      }

      this.closeDialog()
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
