import Vue from "vue"
import VueRouter from "vue-router"
import { get as _get } from "lodash"

import guestRoutes from "@/router/guest.routes"
import appRoutes from "@/router/app.routes"
import { AUTH } from "@/modules/auth/store/mutations-types"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [guestRoutes, appRoutes],
  scrollBehavior(to, from, savedPosition) {
    let newScroll = { x: 0, y: 0 }

    if (to.hash) {
      newScroll = {
        selector: to.hash,
      }
    } else if (savedPosition) {
      newScroll = savedPosition
    }

    return newScroll
  },
})

export const routerHooksSetup = $store => {
  router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public)
    const isOnlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
    )
    const loggedIn = _get($store, `getters["${AUTH}/isAuthenticated"]`)
    if (!isPublic && !loggedIn) {
      return next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    } else if (loggedIn && isOnlyWhenLoggedOut) {
      return next("/home")
    } else {
      next()
    }
  })
}

export default router
