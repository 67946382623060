import { get as _get } from "lodash"

import {
  BOOKINGS_CLEAR_ITEM,
  BOOKINGS_UPDATE_SEARCH_STATUSES,
  BOOKINGS_CREATE_ITEM_ADJUSTMENTS,
  BOOKINGS_CREATE_ITEM_COMMENT,
  BOOKINGS_FETCH_ALL_ITEM_INFO,
  BOOKINGS_FETCH_COMMISSION_STATUSES,
  BOOKINGS_FETCH_INVOICE_STATUSES,
  BOOKINGS_FETCH_ITEM_DETAILS,
  BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_FETCH_ITEM_ADJUSTMENTS,
  BOOKINGS_FETCH_ITEM_COMMENTS,
  BOOKINGS_FETCH_ITEM_INVOICE_INFO,
  BOOKINGS_FETCH_ITEM_PRICING,
  BOOKINGS_FETCH_ITEM_STATUS_HISTORY,
  BOOKINGS_FETCH_LIST,
  BOOKINGS_FETCH_STATUSES,
  BOOKINGS_FETCH_POST_STATUSES,
  BOOKINGS_FETCH_POST_PAYMENT_STATUSES,
  BOOKINGS_FETCH_EXTENDED_DATA,
  BOOKINGS_PROMO_CODES,
  BOOKINGS_PROMO_CODE,
  BOOKINGS_FETCH_PROMO_CODES,
  BOOKINGS_UPDATE_EXTENDED_DATA,
  BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_UPDATE_COMMISSION_STATUSES,
  BOOKINGS_UPDATE_INVOICE_STATUSES,
  BOOKINGS_UPDATE_ITEM_DETAILS,
  BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_UPDATE_ITEM_ADJUSTMENTS,
  BOOKINGS_UPDATE_ITEM_COMMENTS,
  BOOKINGS_UPDATE_ITEM_INVOICE_INFO,
  BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT,
  BOOKINGS_UPDATE_ITEM_PRICING,
  BOOKINGS_UPDATE_ITEM_STATUS,
  BOOKINGS_UPDATE_ITEM_STATUS_HISTORY,
  BOOKINGS_UPDATE_LIST,
  BOOKINGS_FETCH_PROPOSALS,
  BOOKING_SEND_PROPOSAL,
  BOOKINGS_FETCH_SPECIFIC_PROPOSAL,
  BOOKINGS_UPDATE_SEARCH_FORM,
  BOOKINGS_UPDATE_POST_STATUSES,
  BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST,
  BOOKINGS_FETCH_PAYMENT_ISSUES_LIST,
  BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM,
  BOOKINGS_FETCH_ITEM_INSURANCE,
  BOOKINGS_FETCH_LANGUAGE,
  BOOKINGS_REMOVE_PROMO_CODE,
  BOOKINGS_FETCH_WEATHER_INSURANCE,
  BOOKINGS_FETCH_REJECTION_REASONS_LIST,
  BOOKINGS_UPDATE_REJECTION_REASON,
  BOOKINGS_FETCH_TYPE,
  BOOKINGS_FETCH_CURRENCE,
  BOOKINGS_FETCH_WFA_LANGUAGE,
  UPDATE_PROPOSAL_STATUS,
} from "@/modules/bookings/store/mutations-types"
import {
  getDataItemCollectionList,
  getDataItemFirstCollection,
  getGridDataItemFirstCollection,
} from "@/utils/api.utils"

const actions = {
  async [BOOKINGS_CLEAR_ITEM]({ commit }) {
    commit(BOOKINGS_CLEAR_ITEM)
  },
  async [BOOKINGS_UPDATE_SEARCH_FORM]({ commit }, payload) {
    commit(BOOKINGS_UPDATE_SEARCH_FORM, payload)
  },
  async [BOOKINGS_FETCH_INVOICE_STATUSES]({ commit }) {
    const response = await this.$repositories.bookings.fetchInvoiceStatuses()
    const bookingInvoiceStatuses = _get(
      response,
      "data.result.csRefDataList",
      []
    )

    commit(BOOKINGS_UPDATE_INVOICE_STATUSES, bookingInvoiceStatuses)
  },
  async [BOOKINGS_FETCH_REJECTION_REASONS_LIST]({ commit }) {
    const response = await this.$repositories.bookings.fetchRejectionReasonsList()
    const bookingRejectionList = _get(response, "data.result.csRefDataList", [])

    commit(BOOKINGS_FETCH_REJECTION_REASONS_LIST, bookingRejectionList)
  },
  async [BOOKINGS_FETCH_COMMISSION_STATUSES]({ commit }) {
    const response = await this.$repositories.bookings.fetchCommissionStatuses()
    const bookingCommissionStatuses = _get(
      response,
      "data.result.csRefDataList",
      []
    )

    commit(BOOKINGS_UPDATE_COMMISSION_STATUSES, bookingCommissionStatuses)
  },
  async [BOOKINGS_UPDATE_ITEM_INVOICE_INFO]({ dispatch }, payload) {
    await this.$repositories.bookings.updateItemInvoiceInfo(payload)
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ITEM_INVOICE_INFO, { transId })
  },
  async [BOOKINGS_UPDATE_REJECTION_REASON]({ dispatch }, payload) {
    await this.$repositories.bookings.updateBookingRejectionReason(payload)
    const { transId } = payload
    dispatch(BOOKINGS_FETCH_ITEM_DETAILS, { transId })
  },
  async [BOOKINGS_FETCH_ITEM_INVOICE_INFO]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemInvoiceInfo(
      params
    )
    const bookingItemInvoiceInfo = getDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_ITEM_INVOICE_INFO, bookingItemInvoiceInfo)
  },
  async [BOOKINGS_FETCH_ITEM_STATUS_HISTORY]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemStatusHistory(
      params
    )
    const bookingItemStatusHistory = getGridDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_ITEM_STATUS_HISTORY, bookingItemStatusHistory)
  },
  async [BOOKINGS_FETCH_WEATHER_INSURANCE]({ commit }, param) {
    const response = await this.$repositories.bookings.fetchWeatherInsuranceOptions(
      param
    )
    const data = getDataItemCollectionList(response)
    commit(BOOKINGS_FETCH_WEATHER_INSURANCE, data)
  },
  async [BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS]({ commit }, payload) {
    await this.$repositories.bookings.removeItemAdjustments(payload)

    commit(BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS, payload)
  },
  async [BOOKINGS_CREATE_ITEM_COMMENT]({ dispatch }, payload) {
    await this.$repositories.bookings.createItemComments(payload)
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ITEM_COMMENTS, { transId })
  },
  async [BOOKINGS_UPDATE_ITEM_ADJUSTMENTS]({ commit }, payload) {
    await this.$repositories.bookings.updateItemAdjustments(payload)

    commit(BOOKINGS_UPDATE_ITEM_ONE_ADJUSTMENT, payload)
  },
  async [BOOKINGS_FETCH_ITEM_COMMENTS]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemComments(params)
    const bookingItemComments = getGridDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_ITEM_COMMENTS, bookingItemComments)
  },
  async [BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS]({ commit }) {
    const response = await this.$repositories.bookings.fetchItemAdjustmentFields()
    const bookingItemAdjustmentFields = _get(
      response,
      "data.result.csRefDataList",
      []
    )

    commit(BOOKINGS_UPDATE_ITEM_ADJUSTMENT_FIELDS, bookingItemAdjustmentFields)
  },
  async [BOOKINGS_CREATE_ITEM_ADJUSTMENTS]({ dispatch }, payload) {
    await this.$repositories.bookings.createItemAdjustments(payload)

    const { transId } = payload
    dispatch(BOOKINGS_FETCH_ITEM_ADJUSTMENTS, { transId })
  },
  async [BOOKINGS_FETCH_ITEM_ADJUSTMENTS]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemAdjustments(
      params
    )
    const bookingItemAdjustments = getGridDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_ITEM_ADJUSTMENTS, bookingItemAdjustments)
  },
  async [BOOKINGS_FETCH_ALL_ITEM_INFO]({ dispatch }, payload) {
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ITEM_DETAILS, { transId })
    dispatch(BOOKINGS_FETCH_ITEM_STATUS_HISTORY, { transId })
    dispatch(BOOKINGS_FETCH_ITEM_INVOICE_INFO, { transId })
  },
  async [BOOKINGS_UPDATE_ITEM_STATUS]({ dispatch }, payload) {
    await this.$repositories.bookings.updateItemStatus(payload)
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ALL_ITEM_INFO, { transId })
  },
  async [BOOKINGS_PROMO_CODE]({ dispatch }, payload) {
    await this.$repositories.bookings.updateItemPromo(payload)
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ALL_ITEM_INFO, { transId })
  },
  async [BOOKINGS_REMOVE_PROMO_CODE]({ dispatch }, payload) {
    await this.$repositories.bookings.removeItemPromo(payload)
    const { transId } = payload

    dispatch(BOOKINGS_FETCH_ALL_ITEM_INFO, { transId })
  },
  async [BOOKINGS_FETCH_ITEM_PRICING]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemPricing(params)
    const bookingItemPricing = getDataItemCollectionList(response)

    commit(BOOKINGS_UPDATE_ITEM_PRICING, bookingItemPricing)
  },
  async [BOOKINGS_FETCH_ITEM_DETAILS]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemDetails(params)
    const bookingDetails = getDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_ITEM_DETAILS, bookingDetails)
  },
  async [BOOKINGS_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchListForMultipleStatuses(
      params
    )
    const list = getGridDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_LIST, list)
  },
  async [BOOKINGS_FETCH_STATUSES]({ commit }) {
    const response = await this.$repositories.bookings.fetchStatuses()
    const statuses = _get(response, "data.result.csRefDataList", [])

    commit(BOOKINGS_UPDATE_SEARCH_STATUSES, statuses)
  },
  async [BOOKINGS_FETCH_POST_STATUSES]({ commit }) {
    const response = await this.$repositories.bookings.fetchPostStatuses()
    const statuses = _get(response, "data.result.csRefDataList", [])

    commit(BOOKINGS_UPDATE_POST_STATUSES, statuses)
  },
  async [BOOKINGS_FETCH_POST_PAYMENT_STATUSES]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchPostPaymentStatuses(
      params
    )
    const data = getDataItemFirstCollection(response)
    commit(BOOKINGS_FETCH_POST_PAYMENT_STATUSES, data)
  },
  async [BOOKINGS_FETCH_PROMO_CODES]({ commit }) {
    const response = await this.$repositories.bookings.fetchPromoCodes()
    const statuses = _get(response, "data.result.csRefDataList", [])
    commit(BOOKINGS_PROMO_CODES, statuses)
  },
  async [BOOKINGS_FETCH_EXTENDED_DATA]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchExtendedData(params)
    const { rows } = getGridDataItemFirstCollection(response)
    commit(BOOKINGS_UPDATE_EXTENDED_DATA, rows)
  },
  async [BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM]({ commit }, payload) {
    commit(BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM, payload)
  },
  async [BOOKINGS_FETCH_PAYMENT_ISSUES_LIST]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchPaymentIssuesList(
      params
    )
    const list = getGridDataItemFirstCollection(response)

    commit(BOOKINGS_UPDATE_PAYMENT_ISSUES_LIST, list)
  },
  async [BOOKINGS_FETCH_PROPOSALS]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchBookingProposals(
      params
    )
    const bookingProposals = getGridDataItemFirstCollection(response)
    commit(BOOKINGS_FETCH_PROPOSALS, bookingProposals)
  },
  async [BOOKING_SEND_PROPOSAL]({ commit }, payload) {
    const response = await this.$repositories.bookings.sendBookingProposal(
      payload
    )
    commit(BOOKING_SEND_PROPOSAL, response)
  },
  async [BOOKINGS_FETCH_SPECIFIC_PROPOSAL]({ commit }, id) {
    const response = await this.$repositories.bookings.getSpecificProposal(id)
    commit(BOOKINGS_FETCH_SPECIFIC_PROPOSAL, response)
  },
  async [BOOKINGS_FETCH_ITEM_INSURANCE]({ commit }, params) {
    const response = await this.$repositories.bookings.fetchItemInsurance(
      params
    )
    const bookingItemInsurance = getDataItemCollectionList(response)

    commit(BOOKINGS_FETCH_ITEM_INSURANCE, bookingItemInsurance)
  },
  async [BOOKINGS_FETCH_LANGUAGE]({ commit }) {
    const response = await this.$repositories.bookings.getProposalLanguages()
    commit(BOOKINGS_FETCH_LANGUAGE, response.data)
  },
  async [BOOKINGS_FETCH_WFA_LANGUAGE]({ commit }) {
    const response = await this.$repositories.bookings.getProposalWfaLanguages()
    commit(BOOKINGS_FETCH_WFA_LANGUAGE, response.data)
  },
  async [BOOKINGS_FETCH_TYPE]({ commit }) {
    const response = await this.$repositories.bookings.getProposalType()
    commit(BOOKINGS_FETCH_TYPE, response.data)
  },
  async [BOOKINGS_FETCH_CURRENCE]({ commit }) {
    const response = await this.$repositories.bookings.getCurrencyList()
    commit(BOOKINGS_FETCH_CURRENCE, response.data)
  },
  async [UPDATE_PROPOSAL_STATUS]({ commit }, input) {
    await this.$repositories.bookings.updateProposalStatus(input)
    commit(UPDATE_PROPOSAL_STATUS)
  },
}

export default actions
