<template>
  <div id="app">
    <router-view />
    <notifications />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
