<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <login-card header-color="green">
      <h4 slot="title" class="title">Log in</h4>
      <md-field
        class="md-form-group mb-4"
        :class="{ 'md-error md-invalid': $v.username.$error }"
        slot="inputs"
      >
        <md-icon>person</md-icon>
        <label>Name...</label>
        <md-input type="email" v-model.trim="$v.username.$model" />

        <div class="md-error" v-if="!$v.username.required">
          Name is required.
        </div>
        <div class="md-error" v-if="!$v.username.minLength">
          Email must have at least
          {{ $v.username.$params.minLength.min }} letters.
        </div>
      </md-field>

      <md-field
        class="md-form-group"
        :class="{ 'md-error md-invalid': $v.password.$error }"
        slot="inputs"
      >
        <md-icon>lock_outline</md-icon>
        <label>Password...</label>
        <md-input v-model="$v.password.$model" type="password"></md-input>
        <div class="md-error" v-if="!$v.password.required">
          Password is required.
        </div>
        <div class="md-error" v-if="!$v.password.minLength">
          Password must have at least
          {{ $v.password.$params.minLength.min }} letters.
        </div>
        <div class="error" v-if="!$v.password.maxLength">
          Password must have at most
          {{ $v.password.$params.maxLength.max }} letters.
        </div>
      </md-field>

      <md-button
        type="submit"
        slot="footer"
        class="md-simple md-success md-lg"
        :disabled="isFormSending"
      >
        Lets Go
      </md-button>
    </login-card>
  </form>
</template>

<script>
import LoginCard from "@/modules/auth/views/components/LoginCard"
import { maxLength, minLength, required } from "vuelidate/lib/validators"

export default {
  name: "LoginForm",
  props: {
    isFormSending: Boolean,
    onFormSubmit: Function,
  },
  components: {
    LoginCard,
  },
  data() {
    return {
      username: "",
      password: "",
    }
  },
  methods: {
    handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit("onFormSubmit", {
          username: this.username,
          password: this.password,
        })
      }
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(64),
    },
  },
}
</script>

<style scoped></style>
