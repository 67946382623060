import { NOTIFICATIONS_FETCH_LIST } from "@/modules/notifications/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [NOTIFICATIONS_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.notifications.fetchNotificationsList(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(NOTIFICATIONS_FETCH_LIST, data)
  },
}

export default actions
