<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.booking_id.$error }"
    >
      <label>Secure Holiday Booking id</label>
      <md-input v-model="$v.booking_id.$model" type="text"></md-input>
      <div class="md-error" v-if="!$v.booking_id.required">
        Field is required.
      </div>
    </md-field>
    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button type="submit" class="md-raised md-success">
        Check
      </md-button>
    </div>
    <div v-if="paymentStatus" class="px-0 content-left mt-4">
      <p>
        <b>{{ onStatusPaymentFormData.label }}</b>
      </p>
      <p>{{ setInitialStatusFormData }}</p>
      <p>{{ setInitialStatusPaymentFormData }}</p>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  name: "BookingPaymentStatusForm",
  props: {
    // onStatusPaymentFormData: Object,
    onDialogClose: Function,
    oldBookingStatus: String,
    isFormSending: Boolean,
    onStatusPaymentFormData: {
      type: Object,
      default: () => {},
    },
    onFormSubmit: Function,
  },
  data: () => ({
    booking_id: "",
    transition_id: "",
    paymentStatusResult: "",
    bookingStatus: null,
    paymentStatus: null,
  }),
  computed: {
    setInitialStatusFormData() {
      let statusData = ""
      if (this.onStatusPaymentFormData.dataItems) {
        statusData = this.onStatusPaymentFormData.dataItems[0]
      }
      return statusData.value || null
    },
    setInitialStatusPaymentFormData() {
      let statusData = ""
      if (this.onStatusPaymentFormData.dataItems) {
        statusData = this.onStatusPaymentFormData.dataItems[1]
      }
      return statusData.value || null
    },
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.paymentStatus = true
        this.paymentStatusResult = "Status Success"
        await this.$emit("onFormSubmit", {
          sh_booking_reference: this.booking_id,
        })
      }
    },
  },
  mounted() {},
  validations: {
    booking_id: {
      required,
    },
  },
}
</script>

<style scoped></style>
