import Vue from "vue"
import Vuex from "vuex"

import auth from "@/modules/auth/store"
import { AUTH } from "@/modules/auth/store/mutations-types"
import bookings from "@/modules/bookings/store"
import { BOOKINGS } from "@/modules/bookings/store/mutations-types"
import pricingData from "@/modules/pricing-data/store"
import { PRICING_DATA } from "@/modules/pricing-data/store/mutations-types"
import promoCodes from "@/modules/promo-codes/store"
import { PROMO_CODES } from "@/modules/promo-codes/store/mutations-types"
import user from "@/modules/user/store"
import { USER } from "@/modules/user/store/mutations-types"

import geo from "@/modules/geo/store"
import { GEO } from "@/modules/geo/store/mutations-types"

import campsites from "@/modules/campsites/store"
import { CAMPSITES } from "@/modules/campsites/store/mutations-types"

import feedJobs from "@/modules/feed-jobs/store"
import { FEED_JOBS } from "@/modules/feed-jobs/store/mutations-types"

import landingSupport from "@/modules/landing-support/store"
import { LANDING_SUPPORTS } from "@/modules/landing-support/store/mutations-types"

import OutstandingBalances from "@/modules/outstanding-balances/store"
import { OUTSTANDING_BALANCES } from "@/modules/outstanding-balances/store/mutations-types"

import translations from "@/modules/translations/store"
import { TRANSLATIONS } from "@/modules/translations/store/mutations-type"

import jobs from "@/modules/jobs/store"
import { JOBS } from "@/modules/jobs/store/mutations-types"

import ranking from "@/modules/ranking/store"
import { RANKING } from "@/modules/ranking/store/mutations-types"

import notifications from "@/modules/notifications/store"
import { NOTIFICATIONS } from "@/modules/notifications/store/mutations-types"

import syncJobs from "@/modules/sync-jobs/store"
import { SYNC_JOBS } from "@/modules/sync-jobs/store/mutations-types"

import jobSchedules from "@/modules/job-schedules/store"
import { JOB_SCHEDULES } from "@/modules/job-schedules/store/mutations-types"

import reports from "@/modules/reports/store"
import { REPORTS } from "@/modules/reports/store/mutations-types"

import productConfigurations from "@/modules/product-configuration/store"
import { PRODUCT_CONFIGURATIONS } from "@/modules/product-configuration/store/mutations-type"
import supports from "@/modules/support/store"
import { SUPPORTS } from "@/modules/support/store/mutations-type"

import systemActions from "@/modules/system-actions/store"
import { SYSTEM_ACTIONS } from "@/modules/system-actions/store/mutations-type"

import sitemapUrls from "@/modules/sitemap-urls/store"
import { SITEMAP_URLS } from "@/modules/sitemap-urls/store/mutations-type"

import smartNavConfigurations from "@/modules/smartNav-configuration/store"
import { SMARTNAV_CONFIGURATIONS } from "@/modules/smartNav-configuration/store/mutations-type"

import campsiteExtras from "@/modules/campsite-extras/store"
import { CAMPSITE_EXTRAS } from "@/modules/campsite-extras/store/mutations-types"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    [AUTH]: auth,
    [BOOKINGS]: bookings,
    [PRICING_DATA]: pricingData,
    [PROMO_CODES]: promoCodes,
    [USER]: user,
    [GEO]: geo,
    [CAMPSITES]: campsites,
    [FEED_JOBS]: feedJobs,
    [LANDING_SUPPORTS]: landingSupport,
    [OUTSTANDING_BALANCES]: OutstandingBalances,
    [TRANSLATIONS]: translations,
    [JOBS]: jobs,
    [RANKING]: ranking,
    [NOTIFICATIONS]: notifications,
    [SYNC_JOBS]: syncJobs,
    [JOB_SCHEDULES]: jobSchedules,
    [REPORTS]: reports,
    [PRODUCT_CONFIGURATIONS]: productConfigurations,
    [SMARTNAV_CONFIGURATIONS]: smartNavConfigurations,
    [SUPPORTS]: supports,
    [SYSTEM_ACTIONS]: systemActions,
    [SITEMAP_URLS]: sitemapUrls,
    [CAMPSITE_EXTRAS]: campsiteExtras,
  },
  state: {},
  mutations: {},
  actions: {},
})
