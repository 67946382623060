import { get as _get } from "lodash"

import actions from "@/modules/auth/store/actions"
import mutations from "@/modules/auth/store/mutations"
import TokenService from "@/services/token.service"

const state = () => ({
  user: null,
  token: TokenService.getAccessTokenFromStorage(),
})

const getters = {
  isAuthenticated: state => !!state.token,
  userName: state => _get(state, "user.name", null),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
