import {
  FETCH_CAMPSITE_EXTRAS,
  SELECTED_CAMPSITE_EXTRAS,
  UPDATE_CAMPSITE_EXTRAS,
} from "@/modules/campsite-extras/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [FETCH_CAMPSITE_EXTRAS]({ commit }, payload) {
    const response = await this.$repositories.campsiteExtras.fetchCampsiteExtras(
      payload
    )
    const data = getGridDataItemFirstCollection(response)

    commit(FETCH_CAMPSITE_EXTRAS, data)
  },
  [SELECTED_CAMPSITE_EXTRAS]({ commit }, payload) {
    commit(SELECTED_CAMPSITE_EXTRAS, payload)
  },
  async [UPDATE_CAMPSITE_EXTRAS]({ dispatch }, payload) {
    await this.$repositories.campsiteExtras.updateCampsiteExtras(payload.pop())
    dispatch(FETCH_CAMPSITE_EXTRAS)
  },
}

export default actions
