import actions from "@/modules/system-actions/store/actions"
import mutations from "@/modules/system-actions/store/mutations"

const state = () => ({
  actionsList: {},
  datasourcesStatusList: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
