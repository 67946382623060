<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field class="mb-4">
      <label for="invoice-field">Invoice status</label>
      <md-select
        v-model="$v.invoiceStatus.$model"
        name="invoice-status"
        id="invoice-field"
      >
        <md-option
          v-for="option in invoiceStatusesList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
    </md-field>

    <md-field class="mb-4">
      <label for="commission-field">Commission status</label>
      <md-select
        v-model="$v.commissionStatus.$model"
        name="commission-status"
        id="commission-field"
      >
        <md-option
          v-for="option in commissionStatusesList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="isFormSending"
      >
        UPDATE INVOICE
      </md-button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import { getInvoiceRowData } from "@/modules/bookings/utils/booking-invoice.utils"

export default {
  name: "UpdateBookingInvoiceInfoForm",
  props: {
    invoiceInfo: {
      type: Array,
      default: () => [],
    },
    invoiceStatusesList: {
      type: Array,
      default: () => [],
    },
    commissionStatusesList: {
      type: Array,
      default: () => [],
    },
    isFormSending: Boolean,
    onFormSubmit: Function,
    onDialogClose: Function,
  },
  data: () => ({
    commissionStatus: "",
    invoiceStatus: "",
  }),
  methods: {
    setInitialFormData() {
      const { commissionStatus, invoiceStatus } = getInvoiceRowData(
        this.invoiceInfo
      )

      this.commissionStatus = commissionStatus
      this.invoiceStatus = invoiceStatus
    },
    handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit("onFormSubmit", {
          commissionStatus: this.commissionStatus,
          invoiceStatus: this.invoiceStatus,
        })
      }
    },
  },
  validations: {
    commissionStatus: {
      required,
    },
    invoiceStatus: {
      required,
    },
  },
  mounted() {
    this.setInitialFormData()
  },
}
</script>

<style scoped></style>
