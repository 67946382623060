<template>
  <md-card>
    <md-card-content>
      <UniversalTable
        :export-file-name="tableExportFileName"
        :data="normalizedBookingIssuesList"
        :empty-title-text="emptyTableText"
        @navigate-to-row-item="navigateToTowItem"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <div v-if="!!selectedRow">
            <md-button
              class="md-info md-sm"
              @click="handleDetailsButtonClick(selectedRow)"
            >
              View details
              <md-icon>keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </template>
      </UniversalTable>
    </md-card-content>
  </md-card>
</template>

<script>
import BookingsTableMixin from "@/modules/bookings/mixins/bookings-table.mixin"
import { BOOKINGS } from "@/modules/bookings/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { getRowCellByColumnName } from "@/utils/table.utils"
import { normalizeDateInTable } from "../../../utils/booking-date.utils"
import _ from "lodash"

export default {
  name: "BookingSearchPaymentIssuesTable",
  mixins: [BookingsTableMixin],
  components: {
    UniversalTable,
  },
  data: () => ({
    tableExportFileName: "booking_payment_issues",
    emptyTableText: "Booking Payment Issues table is empty",
  }),
  computed: {
    bookingsList() {
      return this.$store.state[BOOKINGS].issuesList
    },
    normalizedBookingIssuesList() {
      const data = this.$store.state[BOOKINGS].issuesList

      if (Object.keys(data).length === 0 || !data.rows) {
        return {}
      }

      let d = _.cloneDeep(data)

      d.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return d
    },
  },
  methods: {
    handleDetailsButtonClick(selectedRow) {
      const transIdCell = getRowCellByColumnName(selectedRow, "trans_id")
      const transId = transIdCell.value

      this.$router.push({ name: "Booking pages", params: { transId } })
    },
    navigateToTowItem({ row }) {
      const transIdCell = getRowCellByColumnName(row, "trans_id")
      const transId = transIdCell.value

      this.$router.push({ name: "Booking pages", params: { transId } })
    },
  },
}
</script>

<style lang="scss" scoped>
.md-table + .md-table {
  margin-top: 16px;
}
</style>
