import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from "@/modules/auth/store/mutations-types"
import TokenService from "@/services/token.service"
import HttpService from "@/services/http.service"
import { USER, USER_UPDATE } from "@/modules/user/store/mutations-types"
import { getUserFromToken } from "@/modules/user/utils/user.utils"

const actions = {
  async [AUTH_LOGIN]({ dispatch }, credentials) {
    return this.$repositories.auth.login(credentials).then(res => {
      const accessToken = TokenService.getAccessTokenFromHeader(res)
      const user = getUserFromToken(accessToken)

      if (accessToken) {
        dispatch(AUTH_INIT, { user, token: accessToken })
      }
    })
  },

  [AUTH_LOGOUT]({ commit }) {
    TokenService.clearStorageTokens()
    HttpService.removeAuthorizationHeader()
    commit(AUTH_LOGOUT)
  },
  [AUTH_INIT]({ commit }) {
    const token = TokenService.getAccessTokenFromStorage()
    HttpService.setAuthorizationHeader(token)
    const user = getUserFromToken(token)

    commit(AUTH_LOGIN, token)
    commit(`${USER}/${USER_UPDATE}`, user, { root: true })
  },
}

export default actions
