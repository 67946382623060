import {
  GEO_UPDATE_COUNTRIES,
  GEO_UPDATE_DEPARTMENTS,
  GEO_UPDATE_REGIONS,
} from "@/modules/geo/store/mutations-types"

const mutations = {
  [GEO_UPDATE_COUNTRIES](state, payload) {
    state.countries = payload
  },
  [GEO_UPDATE_REGIONS](state, payload) {
    state.regions = payload
  },
  [GEO_UPDATE_DEPARTMENTS](state, payload) {
    state.departments = payload
  },
}

export default mutations
