import {
  CAMPSITES_CLEAR_ITEM,
  CAMPSITES_FETCH_ITEM,
  CAMPSITES_FETCH_ITEM_ACCOMMODATIONS,
  CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES,
  CAMPSITES_FETCH_ITEM_DETAILS,
  CAMPSITES_FETCH_ITEM_FACILITIES,
  CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO,
  CAMPSITES_FETCH_ITEM_GENERAL_INFO,
  CAMPSITES_FETCH_ITEM_LEISURE,
  CAMPSITES_FETCH_ITEM_LOCATION_INFO,
  CAMPSITES_FETCH_ITEM_WELLBEING,
  CAMPSITES_FETCH_LIST,
  CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS,
  CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES,
  CAMPSITES_UPDATE_ITEM_DETAILS,
  CAMPSITES_UPDATE_ITEM_FACILITIES,
  CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO,
  CAMPSITES_UPDATE_ITEM_GENERAL_INFO,
  CAMPSITES_UPDATE_ITEM_LEISURE,
  CAMPSITES_UPDATE_ITEM_LOCATION_INFO,
  CAMPSITES_UPDATE_ITEM_WELLBEING,
  CAMPSITES_UPDATE_LIST,
  CAMPSITES_UPDATE_SEARCH_FORM,
  CAMPSITES_FETCH_PHOTOS,
  CAMPSITES_UPDATE_PHOTOS,
  CAMPSITES_UPDATE_UPLOAD_PHOTOS,
  CAMPSITES_RE_SORT_PHOTOS,
  CAMPSITES_RE_SORT_UPLOAD_PHOTOS,
  CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO,
  CAMPSITES_RESET_PHOTO_POSITION,
  CAMPSITES_UPDATE_CS_SELECTED_PHOTOS,
  CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM,
  CAMPSITES_FETCH_DATA_STATUS,
  CAMPSITES_FETCH_PHOTO_LABEL,
  CAMPSITES_FETCH_UPLOADED_PHOTOS,
  CAMPSITES_UPLOAD_NEW_PHOTO,
  CAMPSITES_DELETE_CS_UPLOADED_PHOTO,
  CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS,
  CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS,
  ACCOMMODATION_FETCH_DETAILS,
  ACCOMMODATION_FETCH_FEATURES,
  ACCOMMODATION_FETCH_PHOTOS,
  ACCOMMODATION_FETCH_PHOTOS_BY_ID,
  ACCOMMODATION_UPDATE_PHOTOS,
  ACCOMMODATION_RE_SORT_PHOTOS,
  ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO,
  ACCOMMODATION_RESET_PHOTO_POSITION,
  ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS,
  ACCOMMODATION_UPDATE_PHOTOS_BY_ID,
  CAMPSITES_CREATE_ITEM_COMMENT,
  CAMPSITES_FETCH_ITEM_COMMENTS,
  CAMPSITES_UPDATE_ITEM_COMMENTS,
  GET_CAMPSITE_GROUP,
  CAMPSITES_UPDATE_DETAIL,
  CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM,
  REFRESH_CAMPSITES_CACHE,
  FLUSH_CACHE_CAMPSITE_SEARCH_APP,
  FETCH_STAY_FACILITIES_AMENITIES,
  ADD_STAY_FACILITIES_AMENITIES,
  UPDATE_STAY_FACILITIES_AMENITIES,
  DELETE_STAY_FACILITIES_AMENITIES,
  SET_SELECTED_AMENITY_ROW,
  SET_FACILITIES_AMENITIES_CATEGOIRES,
  SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
  SET_FACILITIES_AMENITIES_LABELS,
  ADD_ACCOMMODATION_AMENITIES,
  UPDATE_ACCOMMODATION_AMENITY,
  DELETE_ACCOMMODATION_AMENITY,
  SET_ACCOMMODATION_AMENITIES_CATEGORIES,
  SET_ACCOMMODATION_AMENITIES_LABELS,
  FETCH_ACCOMMODATION_AMENITY_DETAILS,
  UPDATE_ACCOMMODATION_DETAILS,
} from "@/modules/campsites/store/mutations-types"

import {
  getDataItemCollectionList,
  getGridDataItemCollection,
  getGridDataItemFirstCollection,
  transformSegment,
  getDataItemFirstCollectionWithDataItems,
  getDataItemFirstCollection,
  getGridDataItemCollectionLabel,
} from "@/utils/api.utils"

const actions = {
  async [CAMPSITES_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchList(params)
    const campsitesList = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_LIST, campsitesList)
  },
  async [CAMPSITES_UPDATE_SEARCH_FORM]({ commit }, payload) {
    commit(CAMPSITES_UPDATE_SEARCH_FORM, payload)
  },
  async [CAMPSITES_FETCH_ITEM_WELLBEING]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemWellbeing(
      params
    )
    const campsiteWellbeing = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_WELLBEING, campsiteWellbeing)
  },
  async [CAMPSITES_FETCH_ITEM_LOCATION_INFO]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemLocationInfo(
      params
    )
    const campsiteLocationInfo = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_LOCATION_INFO, campsiteLocationInfo)
  },
  async [CAMPSITES_FETCH_ITEM_GENERAL_INFO]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemGeneralInfo(
      params
    )
    const campsiteGeneralInfo = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_GENERAL_INFO, campsiteGeneralInfo)
  },
  async [CAMPSITES_FETCH_ITEM_DETAILS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemDetails(params)
    const campsiteDetails = getDataItemCollectionList(response)

    commit(CAMPSITES_UPDATE_ITEM_DETAILS, campsiteDetails)
  },
  async [CAMPSITES_FETCH_ITEM_LEISURE]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemLeisure(params)
    const campsiteLeisure = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_LEISURE, campsiteLeisure)
  },
  async [CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemFoodDrinkInfo(
      params
    )
    const campsiteFoodDrinkInfo = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO, campsiteFoodDrinkInfo)
  },
  async [CAMPSITES_FETCH_ITEM_FACILITIES]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemFacilities(
      params
    )
    const campsiteFacilities = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_FACILITIES, campsiteFacilities)
  },
  async [CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemChildrenActivities(
      params
    )
    const campsiteChildrenActivities = getGridDataItemFirstCollection(response)

    commit(
      CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES,
      campsiteChildrenActivities
    )
  },
  async [CAMPSITES_FETCH_ITEM_ACCOMMODATIONS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemAccommodations(
      params
    )
    const campsiteAccommodations = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS, campsiteAccommodations)
  },
  async [CAMPSITES_FETCH_ITEM]({ dispatch }, params) {
    dispatch(CAMPSITES_FETCH_ITEM_DETAILS, params)
    dispatch(CAMPSITES_FETCH_ITEM_GENERAL_INFO, params)
    dispatch(CAMPSITES_FETCH_ITEM_LOCATION_INFO, params)
    dispatch(CAMPSITES_FETCH_ITEM_WELLBEING, params)
    dispatch(CAMPSITES_FETCH_ITEM_LEISURE, params)
    dispatch(CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO, params)
    dispatch(CAMPSITES_FETCH_ITEM_FACILITIES, params)
    dispatch(CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES, params)
    dispatch(CAMPSITES_FETCH_ITEM_ACCOMMODATIONS, params)
  },
  async [CAMPSITES_FETCH_PHOTOS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchPhotosByCampsiteId(
      params
    )
    const data = getGridDataItemCollection(response)
    const photoLabel = getGridDataItemCollectionLabel(response)
    if (data[1].rows) {
      const allItems = data[1].rows.map(transformSegment)
      commit(CAMPSITES_UPDATE_PHOTOS, allItems)
    }
    if (data[2].rows) {
      const uploadedPhotos = data[2].rows.map(transformSegment)
      commit(CAMPSITES_FETCH_UPLOADED_PHOTOS, uploadedPhotos)
    }
    commit(CAMPSITES_FETCH_PHOTO_LABEL, photoLabel)
  },
  async [CAMPSITES_UPLOAD_NEW_PHOTO]({ dispatch }, { formData, campsiteId }) {
    await this.$repositories.campsites.uploadNewPhoto(formData, campsiteId)
    dispatch(CAMPSITES_FETCH_PHOTOS, { campsiteId })
  },
  async [CAMPSITES_DELETE_CS_UPLOADED_PHOTO]({ commit }, photo) {
    await this.$repositories.campsites.deleteCsUploadedPhoto({
      photo_key: photo.key,
    })
    commit(CAMPSITES_DELETE_CS_UPLOADED_PHOTO, { photo })
  },
  [CAMPSITES_RE_SORT_PHOTOS]({ commit }, photos) {
    commit(CAMPSITES_UPDATE_PHOTOS, photos)
  },
  [CAMPSITES_RE_SORT_UPLOAD_PHOTOS]({ commit }, photos) {
    commit(CAMPSITES_UPDATE_UPLOAD_PHOTOS, photos)
  },
  [CAMPSITES_CLEAR_ITEM]({ commit }) {
    commit(CAMPSITES_CLEAR_ITEM)
  },
  async [CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO]({ commit }, photo) {
    await this.$repositories.campsites.updatePhotoActiveStatus({
      activeStatus: photo.active,
      photokey: photo.key,
    })

    commit(CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO, photo)
  },
  async [CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS]({ commit }, photo) {
    await this.$repositories.campsites.updateUploadPhotoActiveStatus({
      activeStatus: photo.active,
      photokey: photo.key,
    })

    commit(CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS, photo)
  },

  async [CAMPSITES_RESET_PHOTO_POSITION]({ dispatch }, params) {
    await this.$repositories.campsites.resetPhotoPosition(params)
    dispatch(CAMPSITES_FETCH_PHOTOS, params)
  },
  async [CAMPSITES_UPDATE_CS_SELECTED_PHOTOS](
    { dispatch },
    { campsiteId, items }
  ) {
    await this.$repositories.campsites.updateCsSelectedPhotos(campsiteId, items)
    dispatch(CAMPSITES_FETCH_PHOTOS, { campsiteId })
  },
  async [CAMPSITES_UPDATE_CS_SELECTED_UPLOADED_PHOTOS](
    { dispatch },
    { campsiteId, items }
  ) {
    await this.$repositories.campsites.updateCsSelectedUploadPhotos(
      campsiteId,
      items
    )
    dispatch(CAMPSITES_FETCH_PHOTOS, { campsiteId })
  },

  async [ACCOMMODATION_FETCH_DETAILS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchAccommodationDetails(
      params
    )
    const data = getDataItemFirstCollectionWithDataItems(response)

    commit(ACCOMMODATION_FETCH_DETAILS, data)
  },
  async [ACCOMMODATION_FETCH_FEATURES]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchAccommodationFeatures(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(ACCOMMODATION_FETCH_FEATURES, data)
  },
  async [ACCOMMODATION_FETCH_PHOTOS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchAllAccommodationPhotos(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    const allItems = data.rows ? data.rows.map(transformSegment) : []
    commit(ACCOMMODATION_UPDATE_PHOTOS, allItems)
  },
  async [ACCOMMODATION_FETCH_PHOTOS_BY_ID]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchPhotosByAccommodationId(
      params
    )
    const data = getGridDataItemCollection(response)

    const allItems = data[1].rows.map(transformSegment)
    commit(ACCOMMODATION_UPDATE_PHOTOS_BY_ID, allItems)
  },

  [ACCOMMODATION_RE_SORT_PHOTOS]({ commit }, photos) {
    commit(ACCOMMODATION_UPDATE_PHOTOS_BY_ID, photos)
  },
  [CAMPSITES_CLEAR_ITEM]({ commit }) {
    commit(CAMPSITES_CLEAR_ITEM)
  },
  async [ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO]({ commit }, photo) {
    await this.$repositories.campsites.updateAccommodationPhotoActiveStatus({
      activeStatus: photo.active,
      photokey: photo.key,
    })

    commit(ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO, photo)
  },
  async [ACCOMMODATION_RESET_PHOTO_POSITION]({ dispatch }, params) {
    await this.$repositories.campsites.resetAccommodationPhotoPosition(params)
    dispatch(ACCOMMODATION_FETCH_PHOTOS_BY_ID, params)
  },
  async [ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS](
    { dispatch },
    { accommodationId, items }
  ) {
    await this.$repositories.campsites.updateAccommodationCsSelectedPhotos(
      accommodationId,
      items
    )
    dispatch(ACCOMMODATION_FETCH_PHOTOS_BY_ID, { accommodationId })
  },
  async [CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchDataSyncJobItem(
      params
    )
    const data = getDataItemFirstCollection(response)
    if (params.jobName === "campsites_summary_map")
      commit(CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM, data["dataItems"])
    commit(CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM, data["dataItems"])
  },
  async [CAMPSITES_FETCH_DATA_STATUS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchDataStatusItem(
      params
    )
    const data = getDataItemFirstCollection(response)
    commit(CAMPSITES_FETCH_DATA_STATUS, data["dataItems"])
  },

  async [CAMPSITES_CREATE_ITEM_COMMENT]({ dispatch }, payload) {
    await this.$repositories.campsites.createItemComments(payload)
    const { campsiteId } = payload

    dispatch(CAMPSITES_FETCH_ITEM_COMMENTS, { campsiteId })
  },

  async [CAMPSITES_FETCH_ITEM_COMMENTS]({ commit }, params) {
    const response = await this.$repositories.campsites.fetchItemComments(
      params
    )
    const bookingItemComments = getGridDataItemFirstCollection(response)

    commit(CAMPSITES_UPDATE_ITEM_COMMENTS, bookingItemComments)
  },
  async [GET_CAMPSITE_GROUP]({ commit }) {
    const groupNames = await this.$repositories.pricingData.getGroupNames()
    commit(GET_CAMPSITE_GROUP, groupNames)
  },
  async [CAMPSITES_UPDATE_DETAIL]({ dispatch }, payload) {
    await this.$repositories.campsites.updateCampsiteDetail(payload)
    dispatch(CAMPSITES_FETCH_ITEM_DETAILS, { campsiteId: payload.campsiteId })
  },
  async [REFRESH_CAMPSITES_CACHE]({ commit }, payload) {
    await this.$repositories.campsites.refreshCampsiteCache(payload)
    commit(REFRESH_CAMPSITES_CACHE, { payload })
  },
  async [FLUSH_CACHE_CAMPSITE_SEARCH_APP]({ commit }) {
    await this.$repositories.campsiteSearch.flushFullMemCache()
    commit(FLUSH_CACHE_CAMPSITE_SEARCH_APP)
  },
  async [FETCH_STAY_FACILITIES_AMENITIES]({ commit }, payload) {
    const response = await this.$repositories.campsites.fetchStayFacilityAmenities(
      payload.stayFacilityId,
      payload.data
    )
    const selectedAmenity = getDataItemCollectionList(response)
    commit(FETCH_STAY_FACILITIES_AMENITIES, selectedAmenity[0].dataItems)
  },
  async [UPDATE_STAY_FACILITIES_AMENITIES]({ dispatch }, payload) {
    await this.$repositories.campsites.updateStayFacilityAmenities(
      payload.stayFacilityId,
      payload.data
    )
    if (payload.type === "WELLBEING")
      dispatch(CAMPSITES_FETCH_ITEM_WELLBEING, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LOCATION")
      dispatch(CAMPSITES_FETCH_ITEM_LOCATION_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LEISURE")
      dispatch(CAMPSITES_FETCH_ITEM_LEISURE, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "GENERAL_INFO")
      dispatch(CAMPSITES_FETCH_ITEM_GENERAL_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FOOD_AND_DRINK")
      dispatch(CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FACILITIES")
      dispatch(CAMPSITES_FETCH_ITEM_FACILITIES, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "CHILDRENS_ACTIVITIES")
      dispatch(CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES, {
        campsiteId: payload.stayFacilityId,
      })
    // dispatch(UPDATE_STAY_FACILITIES_AMENITIES, payload)
  },
  async [ADD_STAY_FACILITIES_AMENITIES]({ dispatch }, payload) {
    await this.$repositories.campsites.addStayFacilityAmenities(
      payload.stayFacilityId,
      payload.data
    )
    if (payload.type === "WELLBEING")
      dispatch(CAMPSITES_FETCH_ITEM_WELLBEING, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LOCATION")
      dispatch(CAMPSITES_FETCH_ITEM_LOCATION_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LEISURE")
      dispatch(CAMPSITES_FETCH_ITEM_LEISURE, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "GENERAL_INFO")
      dispatch(CAMPSITES_FETCH_ITEM_GENERAL_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FOOD_AND_DRINK")
      dispatch(CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FACILITIES")
      dispatch(CAMPSITES_FETCH_ITEM_FACILITIES, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "CHILDRENS_ACTIVITIES")
      dispatch(CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES, {
        campsiteId: payload.stayFacilityId,
      })
  },
  async [DELETE_STAY_FACILITIES_AMENITIES]({ dispatch }, payload) {
    await this.$repositories.campsites.deleteStayFacilityAmenities(
      payload.stayFacilityId,
      payload.data
    )
    if (payload.type === "WELLBEING")
      dispatch(CAMPSITES_FETCH_ITEM_WELLBEING, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LOCATION")
      dispatch(CAMPSITES_FETCH_ITEM_LOCATION_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "LEISURE")
      dispatch(CAMPSITES_FETCH_ITEM_LEISURE, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "GENERAL_INFO")
      dispatch(CAMPSITES_FETCH_ITEM_GENERAL_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FOOD_AND_DRINK")
      dispatch(CAMPSITES_FETCH_ITEM_FOOD_DRINK_INFO, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "FACILITIES")
      dispatch(CAMPSITES_FETCH_ITEM_FACILITIES, {
        campsiteId: payload.stayFacilityId,
      })
    else if (payload.type === "CHILDRENS_ACTIVITIES")
      dispatch(CAMPSITES_FETCH_ITEM_CHILDREN_ACTIVITIES, {
        campsiteId: payload.stayFacilityId,
      })
    // dispatch(DELETE_STAY_FACILITIES_AMENITIES, {
    //   campsiteId: payload.stayFacilityId,
    // })
  },
  [SET_SELECTED_AMENITY_ROW]({ commit }, payload) {
    commit(SET_SELECTED_AMENITY_ROW, payload)
  },

  async [SET_FACILITIES_AMENITIES_CATEGOIRES]({ commit }, payload) {
    const {
      data,
      status,
    } = await this.$repositories.campsites.fetchStayFacilityAmenitiesCategoires(
      payload
    )
    if (status === 200) {
      commit(SET_FACILITIES_AMENITIES_CATEGOIRES, data)
    } else {
      commit(SET_FACILITIES_AMENITIES_CATEGOIRES, [])
    }
  },
  async [SET_FACILITIES_AMENITIES_SUB_CATEGORIES]({ commit }, payload) {
    const {
      data,
      status,
    } = await this.$repositories.campsites.fetchStayFacilityAmenitiesSubCategories(
      payload
    )
    if (status === 200) {
      commit(SET_FACILITIES_AMENITIES_SUB_CATEGORIES, data)
    } else {
      commit(SET_FACILITIES_AMENITIES_SUB_CATEGORIES, [])
    }
  },
  async [SET_FACILITIES_AMENITIES_LABELS]({ commit }, payload) {
    const {
      data,
      status,
    } = await this.$repositories.campsites.fetchStayFacilityAmenitiesLabels(
      payload
    )
    if (status === 200) {
      commit(SET_FACILITIES_AMENITIES_LABELS, data)
    } else {
      commit(SET_FACILITIES_AMENITIES_LABELS, [])
    }
  },

  async [ADD_ACCOMMODATION_AMENITIES]({ dispatch }, payload) {
    const { accommodationId, data } = payload
    await this.$repositories.campsites.addAccommodationAmenity(
      accommodationId,
      data
    )

    dispatch(ACCOMMODATION_FETCH_FEATURES, { accommodationId })
  },
  async [UPDATE_ACCOMMODATION_AMENITY]({ dispatch }, payload) {
    const { accommodationId, data } = payload
    await this.$repositories.campsites.updateAccommodationAmenity(
      accommodationId,
      data
    )

    dispatch(ACCOMMODATION_FETCH_FEATURES, { accommodationId })
  },
  async [SET_ACCOMMODATION_AMENITIES_CATEGORIES]({ commit }) {
    const {
      data,
      status,
    } = await this.$repositories.campsites.fetchAccommodationsAmenitiesCategoires()
    if (status === 200) {
      commit(SET_ACCOMMODATION_AMENITIES_CATEGORIES, data)
    } else {
      commit(SET_ACCOMMODATION_AMENITIES_CATEGORIES, [])
    }
  },
  async [SET_ACCOMMODATION_AMENITIES_LABELS]({ commit }, payload) {
    const {
      data,
      status,
    } = await this.$repositories.campsites.fetchAccommodationsAmenitiesLabels(
      payload
    )
    if (status === 200) {
      commit(SET_ACCOMMODATION_AMENITIES_LABELS, data)
    } else {
      commit(SET_ACCOMMODATION_AMENITIES_LABELS, [])
    }
  },
  async [DELETE_ACCOMMODATION_AMENITY]({ dispatch }, payload) {
    const { accommodationId, category, featureKey, featureId } = payload
    await this.$repositories.campsites.deleteAccommodationsAmenity(
      accommodationId,
      {
        accommodationId: featureId,
        category,
        featureKey,
      }
    )

    dispatch(ACCOMMODATION_FETCH_FEATURES, { accommodationId })
  },
  async [FETCH_ACCOMMODATION_AMENITY_DETAILS]({ commit }, payload) {
    commit(FETCH_ACCOMMODATION_AMENITY_DETAILS, [])

    const { accommodationId, category, featureKey } = payload
    const {
      data: { result },
      status,
    } = await this.$repositories.campsites.fetchAccommodationFeatureDetails(
      accommodationId,
      { category, featureKey }
    )

    if (status === 200) {
      const featureDetails = result.dataItemCollectionList[0]?.dataItems
      commit(FETCH_ACCOMMODATION_AMENITY_DETAILS, featureDetails)
      commit(FETCH_STAY_FACILITIES_AMENITIES, featureDetails)
    } else {
      commit(FETCH_ACCOMMODATION_AMENITY_DETAILS, [])
      commit(FETCH_STAY_FACILITIES_AMENITIES, [])
    }
  },
  async [UPDATE_ACCOMMODATION_DETAILS]({ dispatch }, payload) {
    const { form } = payload
    const { accommodationId } = form

    await this.$repositories.campsites.updateAccommodationDetails(form)

    await dispatch("ACCOMMODATION_FETCH_DETAILS", { accommodationId })
  },
}

export default actions
