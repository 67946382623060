import { render, staticRenderFns } from "./addCategoriesGroupSmartNavConfigurationForm.vue?vue&type=template&id=5310f4a2&scoped=true&"
import script from "./addCategoriesGroupSmartNavConfigurationForm.vue?vue&type=script&lang=js&"
export * from "./addCategoriesGroupSmartNavConfigurationForm.vue?vue&type=script&lang=js&"
import style0 from "./addCategoriesGroupSmartNavConfigurationForm.vue?vue&type=style&index=0&id=5310f4a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5310f4a2",
  null
  
)

export default component.exports