import {
  USER_FETCH_ENTITIES,
  USER_UPDATE_ENTITIES,
} from "@/modules/user/store/mutations-types"
import { getDataItemCollectionList } from "@/utils/api.utils"

const actions = {
  async [USER_FETCH_ENTITIES]({ commit }) {
    try {
      const response = await this.$repositories.user.fetchEntities()
      const entities = getDataItemCollectionList(response)
      commit(USER_UPDATE_ENTITIES, entities)
    } catch (e) {
      // TODO: if error or response == null then redirect to login page
      console.log("TODO here: if error, redirect to login page")
    }
  },
}

export default actions
