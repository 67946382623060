<template>
  <div>
    <ProgressSpinner :isProgress="loadingModal" />
    <md-card v-if="!loadingModal">
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Product Configuration</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UpdateProductConfigurationDialog
          :editProductConfiguration="editProductConfiguration"
          :is-show-dialog="showUpdateProductConfigurationModal"
          @onDialogClosed="closeCreateDialog"
        />
        <div class="refresh-button">
          <md-button
            class="md-raised md-success refresh-button-index"
            @click="refreshCache"
          >
            Refresh Cache
          </md-button>
        </div>
        <UniversalTable
          class="refresh-button-table"
          :data="productConfigurationList"
          :export-file-name="productConfigurationTableOptions.exportName"
          :empty-title-text="productConfigurationTableOptions.emptyTitleText"
          @navigate-to-row-item="openViewProposalPopup"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div v-if="!!selectedRow">
              <md-button
                class="md-info md-sm"
                @click="openUpdateConfigurtionModal(selectedRow)"
              >
                View/Update
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import {
  PRODUCT_CONFIGURATIONS,
  PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST,
  REFRESH_PRODUCT_CONFIGURATION_CACHE,
} from "@/modules/product-configuration/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import UpdateProductConfigurationDialog from "@/modules/product-configuration/views/components/dialogs/UpdateProductConfigurationDialog"
import { mapActions, mapState } from "vuex"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "ProductConfigurations",
  components: {
    UniversalTable,
    ProgressSpinner,
    UpdateProductConfigurationDialog,
  },
  data() {
    return {
      showUpdateProductConfigurationModal: false,
      editProductConfiguration: null,
      productConfigurationTableOptions: {
        exportName: "Product Configurations",
        emptyTitleText: "Empty Product Configurations Table",
      },
      loadingModal: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions(PRODUCT_CONFIGURATIONS, {
      fetchProductConfigurationsList: PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST,
      refrechProductConfigurationCache: REFRESH_PRODUCT_CONFIGURATION_CACHE,
    }),
    // Initialize the Product Configurations
    async init() {
      this.loadingModal = true
      try {
        await this.fetchProductConfigurationsList()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Fetch Product Configurations List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingModal = false
    },
    // Open Update Product Configuration Modal
    openUpdateConfigurtionModal(selectedRow) {
      this.editProductConfiguration = selectedRow
      this.showUpdateProductConfigurationModal = true
    },
    async refreshCache() {
      try {
        await this.refrechProductConfigurationCache()
        this.$swal.fire({
          position: "top-end",
          html: "Refresh Product Configuration cache successfully",
          icon: "success",
          title: "Refresh Cache",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Refresh Cache",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
        })
      }
    },
    // Close Update Product Configuration Modal
    async closeCreateDialog() {
      this.showUpdateProductConfigurationModal = false
      try {
        await this.fetchProductConfigurationsList()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Fetch Product Configurations List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
    // Open View Proposal Modal
    openViewProposalPopup({ row }) {
      this.editProductConfiguration = row
      this.showUpdateProductConfigurationModal = true
    },
  },
  computed: {
    ...mapState(PRODUCT_CONFIGURATIONS, {
      productConfigurationList: state => state.productConfigurationList,
    }),
  },
}
</script>

<style scoped>
.refresh-button {
  margin-left: 300px;
}
.refresh-button-table {
  margin-top: -70px;
}
.refresh-button-index {
  z-index: 999;
}
@media screen and (max-width: 600px) {
  .refresh-button {
    margin-left: 0px !important;
  }
  .refresh-button-table {
    margin-top: 0px !important;
  }
}
</style>
