import VueMaterial from "vue-material"
import "vue-material/dist/vue-material.min.css"
import Vuelidate from "vuelidate"

import SideBar from "@/plugins/sidebar.plugin"
import GlobalComponents from "@/utils/global-components"
import GlobalDirectives from "@/utils/global-directives"
import "@/assets/scss/material-dashboard.scss"
import Notifications from "@/plugins/NotificationPlugin"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faEyeSlash,
  faEye,
  faSearch,
  faCheck,
  faUndoAlt,
  faCopy,
  faUpload,
  faCloud,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import VueScreen from "vue-screen"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import PulseLoader from "vue-spinner/src/PulseLoader"
import * as VueGoogleMaps from "vue2-google-maps"

const options = {
  confirmButtonColor: "#00C8AA",
  cancelButtonColor: "#ff7674",
  confirmButtonText: "OK",
}

const googleMapsOptions = {
  load: {
    key: process.env.VUE_APP_API_GOOGLE_MAPS_KEY,
    libraries: "places",
  },
}

library.add(
  faEyeSlash,
  faEye,
  faSearch,
  faCheck,
  faUndoAlt,
  faCopy,
  faUpload,
  faCloud,
  faSave,
  faTrash
)

export default {
  install(Vue) {
    Vue.use(VueMaterial)
    Vue.use(SideBar)
    Vue.use(Vuelidate)
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(Notifications)
    Vue.use(VueScreen, {
      xxl: 1700,
    })
    Vue.use(VueSweetalert2, options)
    Vue.use(VueGoogleMaps, googleMapsOptions)

    Vue.component("font-awesome-icon", FontAwesomeIcon)
    Vue.component("pulse-loader", PulseLoader)
  },
}
