var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-20 md-medium-size-25 md-small-size-30"},[_c('h4',{staticClass:"items-title md-layout md-alignment-top-center md-title"},[_vm._v(" CS Selected photos ")]),_c('div',{staticClass:"sticky top-items"},[(!_vm.$screen.xxl)?_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-icon-button md-raised icons-top ",class:{
            'md-success': _vm.isChanged,
          },attrs:{"disabled":!_vm.isChanged},on:{"click":_vm.save}},[_c('font-awesome-icon',{attrs:{"icon":'check'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Apply CS Selected photos")])],1),_c('md-button',{staticClass:"md-icon-button md-raised md-success icons-top ",on:{"click":_vm.handleRestore}},[_c('font-awesome-icon',{attrs:{"icon":'undo-alt'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Revert to SH photo order")])],1)],1):_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-button mt-30 button-top",class:{
            'md-success': _vm.isChanged,
          },attrs:{"disabled":!_vm.isChanged},on:{"click":_vm.save}},[_vm._v(" Apply ")]),_c('md-button',{staticClass:"md-button md-success mt-30 button-top",on:{"click":_vm.handleRestore}},[_vm._v(" Restore ")])],1),_c('draggable',{staticClass:"md-gutter md-alignment-center",attrs:{"list":_vm.topItems,"group":"photos","move":_vm.handleTopItemsMove},on:{"end":_vm.handleTopMoveEnd}},_vm._l((_vm.topItems),function(item,idx){return _c('div',{key:idx,staticClass:"md-layout md-layout-item md-size-100 gallery-item"},[_c('image-component',{class:{
              'ready-to-drop': idx === _vm.futureIndex,
            },attrs:{"item":item}})],1)}),0)],1)]),_c('div',{staticClass:"md-layout-item md-size-80 md-medium-size-75 md-small-size-70"},[_c('h4',{staticClass:"items-title md-title"},[_vm._v("Secure Holiday Photos")]),_c('div',[_c('draggable',{staticClass:"md-layout",attrs:{"list":_vm.allItems,"group":{ name: 'photos', pull: 'clone', put: false },"move":_vm.handleMove,"handle":".draggable"},on:{"end":_vm.handleDragEnd}},_vm._l((_vm.allItems),function(item,idx){return _c('div',{key:idx,staticClass:"md-layout md-layout-item md-size-20 md-medium-size-25 md-small-size-30 gallery-item",on:{"click":function($event){$event.preventDefault();return _vm.show(item.urlmedium)}}},[_c('image-component',{class:{
              selected: !!item.cs_selected_position,
            },attrs:{"editable":"","item":item},on:{"change":_vm.handleChangePhoto}})],1)}),0)],1)]),_c('viewer',{ref:"viewer",staticClass:"viewer",attrs:{"options":_vm.options,"images":_vm.images},on:{"inited":_vm.inited}},_vm._l((_vm.images),function(src){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:src,attrs:{"src":src}})}),0),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isSave,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isSave=$event},"update:md-active":function($event){_vm.isSave=$event}}},[_c('h4',[_vm._v("CS Selected Photos Successfully saved")])]),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isRestore,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isRestore=$event},"update:md-active":function($event){_vm.isRestore=$event}}},[_c('h4',[_vm._v("CS Selected Photos reset to SH photo order")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }