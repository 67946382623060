export default $axios => resource => ({
  fetchItemDetails(params) {
    return $axios.get(`${resource}/campsites/details`, {
      params,
    })
  },
  fetchItemGeneralInfo(params) {
    return $axios.get(`${resource}/campsites/generalinfo`, {
      params,
    })
  },
  fetchItemLocationInfo(params) {
    return $axios.get(`${resource}/campsites/locationinfo`, {
      params,
    })
  },
  fetchItemWellbeing(params) {
    return $axios.get(`${resource}/campsites/welbeing`, {
      params,
    })
  },
  fetchItemLeisure(params) {
    return $axios.get(`${resource}/campsites/leisure`, {
      params,
    })
  },
  fetchItemFoodDrinkInfo(params) {
    return $axios.get(`${resource}/campsites/fooddrinkinfo`, {
      params,
    })
  },
  fetchItemFacilities(params) {
    return $axios.get(`${resource}/campsites/facilities`, {
      params,
    })
  },
  fetchItemChildrenActivities(params) {
    return $axios.get(`${resource}/campsites/childrensactivities`, {
      params,
    })
  },
  fetchItemAccommodations(params) {
    return $axios.get(`${resource}/campsites/accommodations`, {
      params,
    })
  },
  fetchList(params) {
    return $axios.get(`${resource}/campsites`, {
      params,
    })
  },
  fetchPhotosByCampsiteId(params) {
    return $axios.get(`${resource}/campsite/photos`, {
      params,
    })
  },
  updateUploadPhotoActiveStatus(data) {
    return $axios.post(`${resource}/campsite/csphoto/setactivestatus`, data)
  },
  updatePhotoActiveStatus(data) {
    return $axios.post(`${resource}/campsite/photo/setactivestatus`, data)
  },
  uploadNewPhoto(formData, campsiteId) {
    return $axios.post(`${resource}/campsite/photo/upload`, formData, {
      params: {
        campsiteId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  deleteCsUploadedPhoto(params) {
    return $axios.post(`${resource}/campsite/csphoto/delete`, {}, { params })
  },
  updateCsSelectedPhotos(campsiteId, items) {
    const data = { campsiteId, csSelectedPhotos: items }
    return $axios.post(`${resource}/campsite/photos/updatecsselected`, data)
  },
  updateCsSelectedUploadPhotos(campsiteId, items) {
    const data = { campsiteId, csSelectedPhotos: items }
    return $axios.post(`${resource}/campsite/csphotos/updatepositions`, data)
  },
  resetPhotoPosition(params) {
    return $axios.post(
      `${resource}/campsite/photos/resetcspositions`,
      {},
      { params }
    )
  },
  fetchAccommodationDetails(params) {
    return $axios.get(`${resource}/accommodation/details`, {
      params,
    })
  },
  fetchAccommodationFeatures(params) {
    return $axios.get(`${resource}/accommodation/features`, {
      params,
    })
  },
  fetchPhotosByAccommodationId(params) {
    return $axios.get(`${resource}/accommodation/photos`, {
      params,
    })
  },
  fetchAllAccommodationPhotos(params) {
    return $axios.get(`${resource}/campsite/allaccommodationphotos`, {
      params,
    })
  },
  updateAccommodationPhotoActiveStatus(data) {
    return $axios.post(`${resource}/accommodation/photo/setactivestatus`, data)
  },
  updateAccommodationCsSelectedPhotos(accommodationId, items) {
    const data = { accommodationId, csSelectedPhotos: items }
    return $axios.post(
      `${resource}/accommodation/photos/updatecsselected`,
      data
    )
  },
  resetAccommodationPhotoPosition(params) {
    return $axios.post(
      `${resource}/accommodation/photos/resetcspositions`,
      {},
      { params }
    )
  },
  fetchDataSyncJobItem(params) {
    return $axios.get(`${resource}/syncjob/item`, {
      params,
    })
  },
  fetchDataStatusItem(params) {
    return $axios.post(`${resource}/campsite/statusupdate`, {}, { params })
  },
  createItemComments(payload) {
    return $axios.post(`${resource}/campsite/comments/addcomment`, payload)
  },
  fetchItemComments(params) {
    return $axios.get(`${resource}/campsite/comments`, {
      params,
    })
  },
  updateCampsiteDetail(params) {
    return $axios.put(`${resource}/campsites/details`, params)
  },
  refreshCampsiteCache(stayFacilityId) {
    return $axios.post(`${resource}/campsites/${stayFacilityId}/refreshCache`)
  },
  fetchStayFacilityAmenities(stayFacilityId, params) {
    return $axios.get(
      `${resource}/stayfacilities/${stayFacilityId}/amenities`,
      {
        params,
      }
    )
  },
  updateStayFacilityAmenities(stayFacilityId, data) {
    return $axios.put(
      `${resource}/stayfacilities/${stayFacilityId}/amenities`,
      data
    )
  },
  deleteStayFacilityAmenities(stayFacilityId, data) {
    return $axios.delete(
      `${resource}/stayfacilities/${stayFacilityId}/amenities`,
      {
        data: data,
      }
    )
  },
  addStayFacilityAmenities(stayFacilityId, data) {
    return $axios.post(
      `${resource}/stayfacilities/${stayFacilityId}/amenities`,
      data
    )
  },

  fetchStayFacilityAmenitiesCategoires(params) {
    return $axios.get(`${resource}/stayfacilities/amenities/categories`, {
      params,
    })
  },
  fetchStayFacilityAmenitiesSubCategories(params) {
    return $axios.get(`${resource}/stayfacilities/amenities/subcategories`, {
      params,
    })
  },
  fetchStayFacilityAmenitiesLabels(params) {
    return $axios.get(`${resource}/stayfacilities/amenities/labels`, {
      params,
    })
  },

  addAccommodationAmenity(accommodationId, payload) {
    return $axios.post(
      `${resource}/accommodations/${accommodationId}/amenities`,
      payload
    )
  },
  updateAccommodationAmenity(accommodationId, payload) {
    return $axios.put(
      `${resource}/accommodations/${accommodationId}/amenities`,
      payload
    )
  },
  deleteAccommodationsAmenity(accommodationId, payload) {
    return $axios.delete(
      `${resource}/accommodations/${accommodationId}/amenities`,
      {
        data: payload,
      }
    )
  },
  fetchAccommodationsAmenitiesCategoires() {
    return $axios.get(`${resource}/accommodations/amenities/categories`)
  },
  fetchAccommodationsAmenitiesLabels(params) {
    return $axios.get(`${resource}/accommodations/amenities/labels`, {
      params,
    })
  },
  fetchAccommodationFeatureDetails(accommodationId, data) {
    return $axios.get(
      `${resource}/accommodations/${accommodationId}/amenities?category=${data.category}&featureKey=${data.featureKey}`
    )
  },
  updateAccommodationDetails(payload) {
    return $axios.put(`${resource}/accommodation/details`, payload)
  },
})
