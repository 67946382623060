<template>
  <div class="home">
    <div>
      <div class="header">
        <h3 class="title mt-0">Your entities</h3>
      </div>
      <div
        class="md-layout"
        v-for="(entityList, index) in userEntities"
        :key="index"
      >
        <div
          v-for="entity in entityList.dataItems"
          :key="entity.label"
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <router-link :to="link(entity.name)">
            <md-card class="mt-0">
              <md-card-content>
                <h4>{{ entity.label }}</h4>
              </md-card-content>
            </md-card>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { USER } from "@/modules/user/store/mutations-types"

export default {
  name: "HomePage",
  computed: {
    userEntities() {
      return this.$store.state[USER].userEntities
    },
  },
  methods: {
    link(name) {
      if (name == "sync_jobs") {
        return `/tech_support/${name}`
      }
      return `/${name}`
    },
  },
}
</script>

<style scoped></style>
