export default {
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
    backgroundColor(value) {
      this.sidebarBackgroundColor = value
    },
    backgroundImage(value) {
      this.sidebarBackgroundImage = value
    },
  },
}
