<template>
  <div>
    <CampsiteExtrasSearch
      :loading="loading"
      @onSearch="onSearch"
      @onLanguageSelect="setLanguage"
    />

    <md-card>
      <ProgressSpinner :isProgress="loading" />
      <md-card-content v-if="!loading">
        <UniversalTable
          :data="campsiteExtras"
          :export-file-name="dataExportFileName"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div>
              <md-button
                v-if="!!selectedRow"
                class="md-info md-sm level-two-buttons"
                @click="onUpdate(selectedRow)"
              >
                Update
              </md-button>
            </div>
          </template>
        </UniversalTable>
        <UpdateCampsiteExtrasDialog
          :is-show-dialog="updateItem"
          @onDialogClosed="updateItem = false"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"

import {
  CAMPSITE_EXTRAS,
  FETCH_CAMPSITE_EXTRAS,
  SELECTED_CAMPSITE_EXTRAS,
  CLEAR_CAMPSITE_EXTRAS_RECORDS,
} from "@/modules/campsite-extras/store/mutations-types"

import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
  SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
} from "@/modules/smartNav-configuration/store/mutations-type"

import UniversalTable from "@/views/components/tables/UniversalTable"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
import UpdateCampsiteExtrasDialog from "@/modules/campsite-extras/views/components/dialogs/updateCampsiteExtrasDialog"
import CampsiteExtrasSearch from "@/modules/campsite-extras/views/components/CampsiteExtrasSearch.vue"

export default {
  name: "CampsiteExtras",

  components: {
    UniversalTable,
    ProgressSpinner,
    UpdateCampsiteExtrasDialog,
    CampsiteExtrasSearch,
  },

  data() {
    return {
      dataExportFileName: "Campsite Extras",
      loading: false,
      updateItem: false,
    }
  },

  computed: {
    ...mapState(CAMPSITE_EXTRAS, {
      campsiteExtras: state => state.campsiteExtras,
      selectedCampsiteExtra: state => state.selectedCampsiteExtra,
    }),
  },

  async created() {
    this.loading = true

    const initalLanguage = "EN"

    await this.fetchRecords(initalLanguage)
    await this.getSupportedLanguages()

    this.setSelectedLanguage(initalLanguage)

    this.loading = false
  },

  methods: {
    ...mapActions(CAMPSITE_EXTRAS, {
      fetchExtras: FETCH_CAMPSITE_EXTRAS,
      setSelectedCampsiteExtra: SELECTED_CAMPSITE_EXTRAS,
    }),
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
      setSelectedLanguage: SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
    }),
    ...mapMutations(CAMPSITE_EXTRAS, {
      clearCampsiteExtrasRecords: CLEAR_CAMPSITE_EXTRAS_RECORDS,
    }),

    async fetchRecords(lang, extra) {
      const payload = {
        lang,
        extra,
      }
      await this.fetchExtras(payload)
    },

    // * action methods
    async onSearch(payload) {
      this.loading = true

      this.clearCampsiteExtrasRecords()

      const { lang, label } = payload
      await this.fetchRecords(lang, label)

      this.setLanguage(lang)

      this.loading = false
    },
    onUpdate(row) {
      this.setSelectedCampsiteExtra(row)
      this.updateItem = true
    },
    setLanguage(lang) {
      this.setSelectedLanguage(lang)
    },
  },
}
</script>

<style scoped></style>
