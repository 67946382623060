import { get as _get } from "lodash"
import {
  LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_CAMPING_THEMES,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID,
  LANDING_SUPPORTS_SAVE_REGION_FILTER,
  LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST,
  LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST,
  LANDING_SUPPORTS_ADD_SPECIAL_REGION,
  LANDING_SUPPORTS_UPDATE_SPECIAL_REGION,
  LANDING_SUPPORTS_SELECTED_SPECIAL_REGION,
} from "@/modules/landing-support/store/mutations-types"
import {
  getGridDataItemFirstCollection,
  getDataItemCollectionList,
} from "@/utils/api.utils"

const actions = {
  async [LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST]({ commit }) {
    const response = await this.$repositories.landingSupport.fetchCountryDestinationsList()
    const data = getGridDataItemFirstCollection(response)
    commit(LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST, data)
  },
  async [LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST]({ commit }) {
    const response = await this.$repositories.landingSupport.fetchSpecialRegionsList()
    const data = getGridDataItemFirstCollection(response)
    commit(LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST, data)
  },
  async [LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID]({ commit }, input) {
    const response = await this.$repositories.landingSupport.fetchSpecialRegionById(
      input
    )
    const data = getDataItemCollectionList(response)
    commit(LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID, data)
  },
  async [LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST]({ commit }, country) {
    const response = await this.$repositories.landingSupport.fetchRegionDestinationsList(
      country
    )
    const data = getGridDataItemFirstCollection(response)

    commit(LANDING_SUPPORTS_SAVE_REGION_FILTER, country)
    commit(LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST, data)
  },
  async [LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST](
    { commit },
    params
  ) {
    const response = await this.$repositories.landingSupport.fetchDepartmentDestinationsList(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(LANDING_SUPPORTS_SAVE_DEPARTMENT_FILTER, params)
    commit(LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST, data)
  },
  async [LANDING_SUPPORTS_FETCH_CAMPING_THEMES]({ commit }) {
    const response = await this.$repositories.landingSupport.fetchCampingThemesList()
    const data = getGridDataItemFirstCollection(response)
    commit(LANDING_SUPPORTS_FETCH_CAMPING_THEMES, data)
  },
  async [LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST]({ commit }, params) {
    const response = await this.$repositories.landingSupport.fetchDepartmentsCountryList(
      params
    )
    const data = _get(response, "data.result.csRefDataList", [])
    commit(LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST, data)
  },
  async [LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST](
    { commit },
    params
  ) {
    const response = await this.$repositories.landingSupport.fetchSpecialRegionsforDepartmentList(
      params
    )
    const data = _get(response, "data.result.csRefDataList", [])
    commit(LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST, data)
  },
  async [LANDING_SUPPORTS_ADD_SPECIAL_REGION]({ dispatch }, data) {
    await this.$repositories.landingSupport.addSpecialRegion(data)
    await dispatch(LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST)
  },
  async [LANDING_SUPPORTS_UPDATE_SPECIAL_REGION]({ dispatch }, data) {
    await this.$repositories.landingSupport.updateSpecialRegion(data)
    await dispatch(LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST)
  },
  async [LANDING_SUPPORTS_SELECTED_SPECIAL_REGION]({ commit }, data) {
    commit(LANDING_SUPPORTS_SELECTED_SPECIAL_REGION, data)
  },
}

export default actions
