<template>
  <md-dialog
    :md-active.sync="value"
    @md-clicked-outside="closeDialog"
    scrollable
  >
    <md-dialog-title>
      <b>{{ isEdit ? "Edit Feature" : "Add Feature" }}</b>

      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="closeDialog"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <div v-if="value" style="overflow: overlay;">
      <UpsertAccommodationFeatureForm
        @onFormSubmit="handleFormSubmit"
        :is-edit="isEdit"
      />
    </div>
  </md-dialog>
</template>

<script>
import UpsertAccommodationFeatureForm from "../forms/UpsertAccommodationFeatureForm.vue"
export default {
  name: "UpsertAccommodationFeatureDialog",
  props: {
    value: Boolean,
    isEdit: Boolean,
  },
  components: {
    UpsertAccommodationFeatureForm,
  },
  data: () => ({}),
  methods: {
    async handleFormSubmit() {
      this.closeDialog()
    },
    closeDialog() {
      this.$emit("input", false)
      this.$emit("onClose")
    },
  },
}
</script>
<style scoped></style>
