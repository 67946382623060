import Vue from "vue"
import router, { routerHooksSetup } from "@/router"

import App from "@/App.vue"
import store from "@/store"
import repositories from "@/repository/repositories-factory"
import appPlugin from "@/plugins/app.plugin"
import { axiosInterceptorsSetup } from "@/config/axios.config"
import "./assets/custom.scss"

// setup
Vue.use(appPlugin)
store.$repositories = repositories

axiosInterceptorsSetup(store)
routerHooksSetup(store)

const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")

export default vm
