import {
  FEED_JOBS_FETCH_LIST,
  FEED_JOBS_FETCH_ITEMS,
  FEED_JOBS_SET_SELECTED_JOB,
  FEED_JOBS_FETCH_ITEM_TYPES_LIST,
  FEED_JOBS_FETCH_JOB_NAMES_LIST,
  FEED_JOBS_FETCH_ERRORS_LIST,
  CAMPSITES_UPLOAD_CSV_FILE,
} from "@/modules/feed-jobs/store/mutations-types"

const mutations = {
  [FEED_JOBS_FETCH_LIST](state, payload) {
    state.allFeedJobList = payload
  },
  [FEED_JOBS_FETCH_ITEMS](state, payload) {
    state.itemsFeedJobsList = payload
  },
  [FEED_JOBS_SET_SELECTED_JOB](state, payload) {
    state.selectedJob = payload
  },
  [FEED_JOBS_FETCH_ITEM_TYPES_LIST](state, payload) {
    state.itemTypesList = payload
  },
  [FEED_JOBS_FETCH_JOB_NAMES_LIST](state, payload) {
    state.jobNamesList = payload
  },
  [FEED_JOBS_FETCH_ERRORS_LIST](state, payload) {
    state.errorsList = payload
  },
  [CAMPSITES_UPLOAD_CSV_FILE](state, payload) {
    state.uploadedCsvFile = payload
  },
}

export default mutations
