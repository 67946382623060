<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Support</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="supportList"
          :export-file-name="supportTableOptions.exportName"
          :empty-title-text="supportTableOptions.emptyTitleText"
          @navigate-to-row-item="openLinkOnDoubleClick"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div v-if="!!selectedRow">
              <md-button class="md-info md-sm" @click="openLink(selectedRow)">
                Visit Website
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import {
  SUPPORTS,
  SUPPORT_FETCH_SUPPORT_LIST,
} from "@/modules/support/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
export default {
  name: "Supports",
  components: {
    UniversalTable,
  },
  data() {
    return {
      showUpdateProductConfigurationModal: false,
      editProductConfiguration: null,
      supportTableOptions: {
        exportName: "Support",
        emptyTitleText: "Empty Support Table",
      },
    }
  },
  created() {
    this.fetchSupportsList()
  },
  methods: {
    ...mapActions(SUPPORTS, {
      fetchSupportsList: SUPPORT_FETCH_SUPPORT_LIST,
    }),
    openLink(selectedRow) {
      this.editProductConfiguration = selectedRow
      const [, , { value: link }] = selectedRow
      window.open(link)
    },
    async closeCreateDialog() {
      this.showUpdateProductConfigurationModal = false
      this.fetchSupportsList()
    },
    openLinkOnDoubleClick({ row }) {
      const [, , { value: link }] = row
      window.open(link)
    },
  },
  computed: {
    ...mapState(SUPPORTS, {
      supportList: state => state.supportList,
    }),
  },
}
</script>

<style scoped></style>
