import {
  CAMPSITES_UPLOAD_CSV_FILE,
  FEED_JOBS_ADD_ITEM,
  FEED_JOBS_DELETE_ITEM,
  FEED_JOBS_FETCH_ERRORS_LIST,
  FEED_JOBS_FETCH_ITEM_TYPES_LIST,
  FEED_JOBS_FETCH_ITEMS,
  FEED_JOBS_FETCH_JOB_NAMES_LIST,
  FEED_JOBS_FETCH_LIST,
  FEED_JOBS_SET_SELECTED_JOB,
} from "@/modules/feed-jobs/store/mutations-types"
import {
  getGridDataItemFirstCollection,
  getItemsTypesCollection,
} from "@/utils/api.utils"

const actions = {
  async [FEED_JOBS_FETCH_LIST]({ commit }, search) {
    const response = await this.$repositories.feedJobs.fetchList(search)
    const data = getGridDataItemFirstCollection(response)
    commit(FEED_JOBS_FETCH_LIST, data)
  },
  async [FEED_JOBS_FETCH_ITEMS]({ commit }, jobName) {
    const response = await this.$repositories.feedJobs.fetchItems({ jobName })
    const data = getGridDataItemFirstCollection(response)
    commit(FEED_JOBS_FETCH_ITEMS, data)
    commit(FEED_JOBS_SET_SELECTED_JOB, jobName)
  },
  async [FEED_JOBS_DELETE_ITEM]({ dispatch }, params) {
    await this.$repositories.feedJobs.removeItem(params)
    dispatch(FEED_JOBS_FETCH_ITEMS, params.jobName)
  },
  async [FEED_JOBS_ADD_ITEM]({ dispatch, commit }, params) {
    const response = await this.$repositories.feedJobs.addItem(params)
    commit(FEED_JOBS_FETCH_ERRORS_LIST, response["data"])
    dispatch(FEED_JOBS_FETCH_ITEMS, params.jobName)
  },
  async [FEED_JOBS_FETCH_ITEM_TYPES_LIST]({ commit }) {
    const response = await this.$repositories.feedJobs.fetchItemTypesList()
    const data = getItemsTypesCollection(response)
    commit(FEED_JOBS_FETCH_ITEM_TYPES_LIST, data)
  },
  async [FEED_JOBS_FETCH_JOB_NAMES_LIST]({ commit }) {
    const response = await this.$repositories.feedJobs.fetchJobNamesList()
    const data = getItemsTypesCollection(response)
    commit(FEED_JOBS_FETCH_JOB_NAMES_LIST, data)
  },
  async [CAMPSITES_UPLOAD_CSV_FILE](
    { commit },
    { itemType, jobName, formData }
  ) {
    const response = await this.$repositories.feedJobs.uploadNewCsvFile(
      itemType,
      jobName,
      formData
    )
    commit(CAMPSITES_UPLOAD_CSV_FILE, response)
  },
}

export default actions
