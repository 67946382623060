import actions from "@/modules/campsites/store/actions"
import mutations from "@/modules/campsites/store/mutations"

export const INITIAL_ITEM_STATE = {
  details: [],
  generalInfo: {},
  locationInfo: {},
  wellbeing: {},
  leisure: {},
  foodDrinkInfo: {},
  facilities: {},
  childrenActivities: {},
  accommodations: {},
  photos: [],
  comments: {},
  photoLabel: "",
  uploadedPhotos: [],
  accommodationsPhotos: [],
  accommodationPhotos: [],
  selectedAmenity: {},
  selectedRow: [],
}

const state = () => ({
  list: {},
  item: { ...INITIAL_ITEM_STATE },
  searchForm: {},
  accommodationDetails: [],
  accommodationFeatures: {},
  dataJobTrigger: {},
  dataSyncJobItem: {},
  dataSyncJobItemDetail: {},
  dataStatusItem: {},
  groupNames: [],
  amenitiesCategoires: [],
  amenitiesSubCategories: [],
  amenitiesLabels: [],
  accommodationFeaturDetails: [],
})

const getters = {
  mutipleAmenitiesCategoires: state => state.amenitiesCategoires.length > 1,
  getAmenitiesCategoires: state => state.amenitiesCategoires,
  getAmenitiesSubCategories: state => state.amenitiesSubCategories,
  amenitiesSubCategoriesFieldDisabled: state =>
    state.amenitiesSubCategoires.length === 0,
  getAmenitiesLabels: state => state.amenitiesLabels,
  getAccommodationFeaturDetails: state => state.accommodationFeaturDetails,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
