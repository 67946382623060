<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Update booking rejection reason</md-dialog-title>

      <md-dialog-content>
        <UpdateBookingRejectionReasonForm
          :is-form-sending="isFormSending"
          :old-booking-status="bookingStatus"
          :bookingStatusesList="bookingStatuses"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleUpdateFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import UpdateBookingRejectionReasonForm from "@/modules/bookings/views/components/forms/UpdateBookingRejectionReasonForm"
import {
  BOOKINGS,
  BOOKINGS_FETCH_REJECTION_REASONS_LIST,
  BOOKINGS_UPDATE_REJECTION_REASON,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"
import dialogMixin from "@/mixins/dialog.mixin"
export default {
  name: "UpdateBookingStatusDialog",
  components: { UpdateBookingRejectionReasonForm },
  props: {
    bookingStatus: String,
    transId: String,
  },
  mixins: [dialogMixin],
  computed: {
    bookingStatuses() {
      return this.$store.state[BOOKINGS].item.bookingRejectionList
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_REJECTION_REASONS_LIST,
      BOOKINGS_UPDATE_REJECTION_REASON,
    ]),
    fetchBookingsPostStatuses() {
      if (this.bookingStatuses?.length === 0) {
        this[BOOKINGS_FETCH_REJECTION_REASONS_LIST]()
      }
    },
    async handleUpdateFormSubmit(values) {
      this.isFormSending = true
      const payload = {
        reason: values.bookingStatus,
        transId: this.transId,
      }
      await this[BOOKINGS_UPDATE_REJECTION_REASON](payload)
      this.isFormSending = false
      this.closeDialog()
    },
  },
  mounted() {
    this.fetchBookingsPostStatuses()
  },
}
</script>

<style scoped></style>
