<template>
  <div class="width-adjust">
    <ProgressSpinner :isProgress="getLoader" />
    <div style="display:flex;">
      <md-dialog-title class="pl-0 pt-0"
        >{{
          showAddCategoryGroupForm || showUpdateCategoryGroupForm
            ? showUpdateCategoryGroupForm
              ? "Update Category Group"
              : "Add Category Group"
            : "Category Groups"
        }}
      </md-dialog-title>
    </div>
    <form
      novalidate
      class="md-layout"
      @submit.prevent
      v-if="showAddCategoryGroupForm"
    >
      <template>
        <md-field
          class="mb-4"
          :class="{
            'md-error md-invalid': $v.categoryGroup.$error,
          }"
        >
          <label class="required">Category Group</label>
          <md-input v-model="categoryGroup" type="text"></md-input>
          <div class="md-error" v-if="!$v.categoryGroup.required">
            Category Group is required.
          </div>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="button"
            class="close-button md-raised mt-4 "
            @click="removeForm()"
          >
            Close
          </md-button>
          <md-button
            type="submit"
            class="md-raised md-success mt-4 ml-4"
            @click="addCategoryGroupApi()"
          >
            {{ showUpdateCategoryGroupForm ? "Update" : "Add" }}
          </md-button>
        </div>
      </template>
    </form>
    <UniversalTable
      v-show="!showAddCategoryGroupForm && !showUpdateCategoryGroupForm"
      :data="categoryGroupList"
      :export-file-name="productConfigurationTableOptions.exportName"
      :empty-title-text="productConfigurationTableOptions.emptyTitleText"
      @navigate-to-row-item="updateCategory(selectedCategoryGroup)"
      @onRowSelect="handleCategoryRowSelect"
      :onAddButtonClick="addCategoryGroup"
      :add-button-text="'Add First Category Group'"
    >
      <template slot="header-right-controls" slot-scope="{ selectedRow }">
        <div class="buttons-direction">
          <md-button
            class="md-info md-sm level-two-buttons"
            @click="addCategoryGroup"
          >
            Add
          </md-button>
          <md-button
            v-if="
              !!selectedRow ||
                !!(Object.entries(selectedCategoryGroup).length || null)
            "
            class="md-info md-sm level-two-buttons"
            @click="updateCategory"
          >
            Update
          </md-button>
          <md-button
            v-if="!!selectedRow"
            class="md-info md-sm level-two-buttons"
            @click="removeCategory(selectedRow)"
          >
            Remove
          </md-button>
        </div>
      </template>
    </UniversalTable>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { required } from "vuelidate/lib/validators"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"

export default {
  name: "addCategoriesSmartNavConfigurationForm",
  components: {
    UniversalTable,
    ProgressSpinner,
  },
  data: () => ({
    showAddCategoryGroupForm: false,
    showUpdateCategoryGroupForm: false,
    selectedCategoryGroup: [],
    categoryGroup: "",
    productConfigurationTableOptions: {
      exportName: "Category Groups",
      emptyTitleText:
        "Catgory Groups are not available for selected Item.Please add.",
    },
  }),
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, [
      ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
    ]),
    // Save New Category of Smart Nav Configuration
    async addCategoryGroupApi() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loadingAdd = true
        let data = {}
        if (!this.showUpdateCategoryGroupForm) {
          data = {
            categoryGroup: this.categoryGroup,
            lang: this.selectedLanguage,
          }
        } else {
          data = {
            categoryGroup: this.categoryGroup,
            lang: this.selectedLanguage,
            id: this.selectedCategoryGroup.find(item => item.name === "id")
              ?.value,
          }
        }
        let final = []
        final.push(this.selectedLanguage)
        final.push(data)
        if (this.showUpdateCategoryGroupForm)
          try {
            await this[UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST](
              final
            )
          } catch (e) {
            this.$swal.fire({
              position: "top-end",
              html: e,
              icon: "error",
              title: "Update Category",
              showConfirmButton: false,
              timer: 4000,
              showCloseButton: true,
            })
          }
        else {
          try {
            await this[ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST](final)
          } catch (e) {
            this.$swal.fire({
              position: "top-end",
              html: e,
              icon: "error",
              title: "Add Category Group",
              showConfirmButton: false,
              timer: 4000,
              showCloseButton: true,
            })
          }
        }
        this.$v.$reset()
        this.loadingAdd = false
        this.removeForm()
      }
    },
    // Open Add New Category Form
    addCategoryGroup() {
      this.showAddCategoryGroupForm = true
    },
    // Close Add New Category Form
    removeForm() {
      this.categoryGroup = ""
      this.showAddCategoryGroupForm = false
      this.showUpdateCategoryGroupForm = false
    },
    updateCategory() {
      this.loadingAdd = true
      this.categoryGroup = this.selectedCategoryGroup.find(
        item => item.name === "category_group"
      )?.value
      this.showAddCategoryGroupForm = true
      this.showUpdateCategoryGroupForm = true
      this.loadingAdd = false
    },
    removeUpdateForm() {
      this.showUpdateCategoryGroupForm = false
    },
    // Remove selected Category from the list
    async removeCategory(selectedRow) {
      this.loadingAdd = true
      let final = []
      final.push(this.selectedLanguage)
      final.push(selectedRow.find(item => item.name === "id").value)
      try {
        await this[REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST](final)
        this.$swal.fire({
          position: "top-end",
          html: "Category removed successfully",
          icon: "success",
          title: "Remove Category",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Remove Category",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingAdd = false
    },
    handleCategoryRowSelect(selectedRow) {
      this.selectedCategoryGroup = selectedRow
        ? selectedRow
        : this.selectedCategoryGroup
    },
  },
  validations: {
    categoryGroup: {
      required,
    },
  },
  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      categoryGroupList: state => state.categoryGroupList,
      selectedLanguage: state => state.selectedLanguage,
    }),
    getLoader() {
      return this.loadingAdd
    },
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
.md-card-actions {
  display: none !important;
}
.width-adjust {
  min-width: 600px;
}
.close-button {
  box-shadow: 0 2px 2px 0 rgb(217 221 220 / 14%),
    0 3px 1px -2px rgb(217 221 220 / 20%), 0 1px 5px 0 rgb(217 221 220 / 12%) !important;
}
@media screen and (max-width: 600px) {
  .width-adjust {
    min-width: 100%;
    display: flex !important;
  }
}
.md-title {
  font-size: 14px !important;
}
</style>
