<template>
  <FadeTransition :duration="200" mode="out-in">
    <div v-if="isDataTableReady">
      <md-card-content class="px-0">
        <TableHeader
          :export-file-name="exportFileName"
          :export-data="exportData"
          :pagination="pagination"
        >
          <slot name="header-right-controls" :selectedRow="selectedRow" />
        </TableHeader>

        <md-table
          @md-selected="handleRowSelect"
          :value="queriedData"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="handleRowsSort"
          class="paginated-table table-striped table-hover"
        >
          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            class="md-primary"
            md-selectable="single"
            @dblclick="$emit('navigate-to-row-item', item)"
            :class="{
              'read-Accommodation': isIdRowData(item),
              'md-selected-single': selectRow(item),
            }"
            @click="$emit('navigate-to-row', item)"
          >
            <md-table-cell
              v-for="column in visibleTableColumns"
              :key="column.name"
              :md-label="column.label"
              :md-sort-by="column.name"
            >
              {{ getTableRowData(item, column.name) }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <TableFooter
        :pagination="pagination"
        :from="paginationFrom"
        :to="paginationTo"
        :total="paginationTotal"
      />
    </div>
    <md-empty-state
      v-else
      md-icon="backup_table"
      :md-label="emptyTitleText"
      :md-description="emptyDescriptionText"
    >
      <md-button
        v-if="onAddButtonClick"
        class="md-info mt-4"
        @click="onAddButtonClick"
      >
        {{ addButtonText }}
      </md-button>
    </md-empty-state>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions"
/* TODO: move table's mixin as global */
import BookingsTableMixin from "@/modules/bookings/mixins/bookings-table.mixin"
import TableFooter from "@/views/components/tables/TableFooter"
import TableHeader from "@/views/components/tables/TableHeader"

export default {
  name: "UniversalTable",
  mixins: [BookingsTableMixin],
  props: {
    levelTwoSelectedItem: null,
    exportFileName: String,
    dataAccommodations: String,
    data: {
      type: Object,
      default: () => {},
    },
    onAddButtonClick: Function,
    emptyTitleText: {
      type: String,
      default: "Table is empty",
    },
    emptyDescriptionText: {
      type: String,
      default: "",
    },
    addButtonText: {
      type: String,
      default: "Create first item",
    },
  },
  components: {
    TableFooter,
    TableHeader,
    FadeTransition,
  },
  methods: {
    isIdRowData(data_row) {
      if (this.dataAccommodations) {
        const key = data_row.row[0].value || null
        const resultatsAccommodations = JSON.parse(
          localStorage.getItem("resultatsAccommodations") || "[]"
        )
        return resultatsAccommodations.find(item => item === key)
      }
    },
    selectRow(data_row) {
      return (
        this.levelTwoSelectedItem &&
        this.levelTwoSelectedItem[0] &&
        this.levelTwoSelectedItem[0].value == data_row.row[0].value
      )
    },
  },
  computed: {
    dataTable() {
      return this.data
    },
    exportData() {
      return this.tableRows.map(({ row }) => {
        const rowData = {}
        row.forEach(rowCell => {
          rowData[rowCell.name] = rowCell.value || null
        })

        return rowData
      })
    },
  },
}
</script>

<style scoped lang="scss">
.paginated-table table > tbody > tr > td {
  max-width: none;
}

tr.read-Accommodation {
  background: #bdbdbd !important;
  color: #ffffff;
}
</style>
