<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Translations</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="translationsList"
          :export-file-name="translationTableOptions.exportName"
          :empty-title-text="translationTableOptions.emptyTitleText"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import {
  TRANSLATIONS,
  TRANSLATIONS_FETCH_TRANSLATE_LIST,
} from "@/modules/translations/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
export default {
  name: "Translations",
  components: {
    UniversalTable,
  },
  data() {
    return {
      translationTableOptions: {
        exportName: "Translations",
        emptyTitleText: "Empty Translations Table",
      },
    }
  },
  created() {
    this.fetchTranslationsList()
  },
  methods: {
    ...mapActions(TRANSLATIONS, {
      fetchTranslationsList: TRANSLATIONS_FETCH_TRANSLATE_LIST,
    }),
  },
  computed: {
    ...mapState(TRANSLATIONS, {
      translationsList: state => state.translationsList,
    }),
  },
}
</script>

<style scoped></style>
