import {
  SYNC_JOBS_FETCH_LIST,
  SYNC_JOBS_ITEMS_FETCH_LIST,
} from "@/modules/sync-jobs/store/mutations-types"

const mutations = {
  [SYNC_JOBS_FETCH_LIST](state, payload) {
    state.allSyncJobList = payload
  },
  [SYNC_JOBS_ITEMS_FETCH_LIST](state, payload) {
    state.jobItems = payload
  },
}

export default mutations
