import actions from "@/modules/jobs/store/actions"
import mutations from "@/modules/jobs/store/mutations"

const state = () => ({
  dataJobTrigger: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
