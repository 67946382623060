import actions from "@/modules/feed-jobs/store/actions"
import mutations from "@/modules/feed-jobs/store/mutations"

const state = () => ({
  allFeedJobList: {},
  itemsFeedJobsList: {},
  selectedJob: "",
  itemTypesList: [],
  jobNamesList: [],
  errorsList: {},
  uploadedCsvFile: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
