import {
  SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST,
  FEED_JOB_SHEDULES_ITEMS_FETCH_LIST,
} from "@/modules/job-schedules/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST]({ commit }, payload) {
    const response = await this.$repositories.jobSchedules.fetchSchedules(
      payload
    )
    const data = getGridDataItemFirstCollection(response)
    if (payload === "sync") commit(SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST, data)
    else commit(FEED_JOB_SHEDULES_ITEMS_FETCH_LIST, data)
  },
}

export default actions
