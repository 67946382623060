import {
  PROMO_CODES_CREATE_ITEM,
  PROMO_CODES_FETCH_LIST,
  PROMO_CODES_UPDATE_ITEM,
  PROMO_CODES_UPDATE_LIST,
} from "@/modules/promo-codes/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [PROMO_CODES_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.promoCodes.fetchList(params)
    const promoCodes = getGridDataItemFirstCollection(response)

    commit(PROMO_CODES_UPDATE_LIST, promoCodes)
  },
  async [PROMO_CODES_UPDATE_ITEM]({ commit }, payload) {
    return this.$repositories.promoCodes.updateItem(payload).then(() => {
      commit(PROMO_CODES_UPDATE_ITEM, payload)
    })
  },

  async [PROMO_CODES_CREATE_ITEM]({ dispatch }, payload) {
    await this.$repositories.promoCodes.createItem(payload)

    dispatch(PROMO_CODES_FETCH_LIST)
  },
}

export default actions
