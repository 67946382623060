<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <LoginForm @onFormSubmit="submitForm" :isFormSending="isFormSending" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { get as _get } from "lodash"

import LoginForm from "@/modules/auth/views/components/forms/LoginForm"
import { AUTH, AUTH_LOGIN } from "@/modules/auth/store/mutations-types"

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  data() {
    return {
      isFormSending: false,
    }
  },
  methods: {
    ...mapActions(AUTH, [AUTH_LOGIN]),
    notifyWrongLogin() {
      this.$notify({
        timeout: 3000,
        message: "Username or password is incorrect",
        icon: "verified_user",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      })
    },
    async submitForm(values) {
      this.isFormSending = true
      try {
        await this[AUTH_LOGIN](values)
        this.isFormSending = false
        const redirectTo = _get(this.$route, "query.redirect", "/home")
        this.$router.push(redirectTo)
      } catch (e) {
        this.notifyWrongLogin()
        this.isFormSending = false
      }
    },
  },
}
</script>
