<template>
  <div>
    <DetailsCollapse
      :label="accommodationDetailsLabel"
      :elements="accommodationDetailsItemElements"
      :show-update-for-accommodation="accomodationEditable"
      @navigate-to-dialog="navigateToDialog"
      class="accommodation_details"
    />
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Accommodation Features</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="accommodationFeatures"
          :export-file-name="accommodationFeaturesOptions.exportName"
          :onAddButtonClick="addFeatureDialog"
          :add-button-text="'Add New Feature'"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div>
              <md-button
                v-if="accomodationEditable"
                @click="addFeatureDialog"
                class="md-info md-sm mr-2"
              >
                Add
              </md-button>
              <md-button
                v-if="accomodationEditable && !!selectedRow"
                @click="openFeatureDialog(selectedRow)"
                class="md-info md-sm mr-2"
              >
                View details
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
              <md-button
                v-if="accomodationEditable && !!selectedRow"
                @click="updateFeature(selectedRow)"
                class="md-sm mr-2"
              >
                Edit
                <md-icon>edit</md-icon>
              </md-button>
              <md-button
                v-if="accomodationEditable && !!selectedRow"
                @click="deleteFeature(selectedRow)"
                class="md-danger md-sm"
              >
                Delete
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
    <ViewAmenitiesDialog
      :is-show-dialog="showDetailsDialog"
      @onDialogClosed="onDialogClose"
    />

    <UpsertAccommodationFeatureDialog
      v-model="showUpsertDialog"
      :is-edit="isEditModal"
      @onClose="onDialogClose"
    />

    <UpdateAccommodationDialog
      v-model="showUpdateDetailsDialog"
      :details="accommodationDetailsItemElements"
    />
  </div>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"
import ViewAmenitiesDialog from "@/modules/campsites/views/components/dialogs/ViewAmenitiesDialog.vue"
import UpsertAccommodationFeatureDialog from "@/modules/campsites/views/components/dialogs/UpsertAccommodationFeatureDialog"
import UpdateAccommodationDialog from "@/modules/campsites/views/components/dialogs/UpdateAccommodationDialog.vue"

import { mapActions, mapState } from "vuex"
import {
  ACCOMMODATION_FETCH_DETAILS,
  ACCOMMODATION_FETCH_FEATURES,
  CAMPSITES,
  DELETE_ACCOMMODATION_AMENITY,
  SET_ACCOMMODATION_AMENITIES_CATEGORIES,
  FETCH_ACCOMMODATION_AMENITY_DETAILS,
} from "@/modules/campsites/store/mutations-types"
import { getItemCollection } from "@/utils/api.utils"

export default {
  name: "AccommodationDetailsPage",
  components: {
    UniversalTable,
    DetailsCollapse,
    ViewAmenitiesDialog,
    UpsertAccommodationFeatureDialog,
    UpdateAccommodationDialog,
  },
  data() {
    return {
      accommodationFeaturesOptions: {
        exportName: "Accommodation Features",
      },
      showUpsertDialog: false,
      isEditModal: false,
      showDetailsDialog: false,
      showUpdateDetailsDialog: false,
    }
  },
  async created() {
    await this.fetchAccommodationDetails({
      accommodationId: this.$route.params.id,
    })
    await this.fetchAccommodationFeatures({
      accommodationId: this.$route.params.id,
    })
  },
  methods: {
    ...mapActions(CAMPSITES, {
      fetchAccommodationDetails: ACCOMMODATION_FETCH_DETAILS,
      fetchAccommodationFeatures: ACCOMMODATION_FETCH_FEATURES,
      deleteAccommodationAmenity: DELETE_ACCOMMODATION_AMENITY,
      fetchAccommodationCategories: SET_ACCOMMODATION_AMENITIES_CATEGORIES,
      fetchAccommodationAmenityDetails: FETCH_ACCOMMODATION_AMENITY_DETAILS,
    }),

    navigateToDialog() {
      this.showUpdateDetailsDialog = true
    },

    async openFeatureDialog(selectedRow) {
      await this.getAccommodationAmenityDetails(selectedRow)
      this.showDetailsDialog = true
    },
    async addFeatureDialog() {
      await this.fetchAccommodationCategories()

      this.isEditModal = false
      this.showUpsertDialog = true
    },
    async updateFeature(selectedRow) {
      await this.fetchAccommodationCategories()
      await this.getAccommodationAmenityDetails(selectedRow)

      this.isEditModal = true
      this.showUpsertDialog = true
    },
    async deleteFeature(selectedRow) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this feature?",
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          showConfirmButton: true,
          confirmButtonText: "Yes",
        })
        .then(async result => {
          if (result.isConfirmed) {
            const payload = {
              accommodationId: this.getObjectValue(
                selectedRow,
                "accommodation_id"
              ),
              category: this.getObjectValue(selectedRow, "category"),
              featureKey: this.getObjectValue(selectedRow, "feature_key"),
            }
            await this.deleteAccommodationAmenity(payload)
          }
        })
    },
    onDialogClose() {
      this.showDetailsDialog = false
      this.isEditModal = false
    },

    async getAccommodationAmenityDetails(selectedRow) {
      const payload = {
        accommodationId: this.getObjectValue(selectedRow, "accommodation_id"),
        category: this.getObjectValue(selectedRow, "category"),
        featureKey: this.getObjectValue(selectedRow, "feature_key"),
      }
      await this.fetchAccommodationAmenityDetails(payload)
    },
    getObjectValue(arr, objName) {
      const obj = arr.find(obj => obj.name === objName)
      return obj?.value
    },
  },
  computed: {
    ...mapState(CAMPSITES, {
      accommodationDetails: state => state.accommodationDetails,
      accommodationFeatures: state => state.accommodationFeatures,
    }),
    accommodationDetailsLabel() {
      return this.accommodationDetails.label || "Loading..."
    },
    accommodationDetailsList() {
      return this.accommodationDetails.dataItems || []
    },
    accomodationEditable() {
      if (this.accommodationDetails?.dataItems) {
        const feature = getItemCollection(
          this.accommodationDetails.dataItems,
          "features_editable"
        )
        if (feature && feature.value === "true") {
          return true
        }
      }
      return false
    },
    accommodationDetailsItemElements() {
      const visibleElements = this.accommodationDetailsList.filter(
        item => item.visible !== "false"
      )
      return visibleElements.map(item => {
        const itemWithButtons = this.accommodationDetailsList.find(
          el => el.name === item.name
        )
        if (itemWithButtons) {
          item.buttons = itemWithButtons.buttons
        }
        return item
      })
    },
  },
}
</script>

<style scoped>
.accommodation_details {
  padding: 0;
}
</style>
