export default $axios => resource => ({
  fetchList(params) {
    return $axios.get(`${resource}`, {
      params,
    })
  },
  updateItem(payload) {
    return $axios.post(`${resource}/updatepromocode`, payload)
  },
  createItem(payload) {
    return $axios.post(`${resource}/addpromocode`, payload)
  },
})
