<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Country Destinations</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="countryDestinationsList"
          :export-file-name="campingCountryDestinations.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Region Destinations</h4>
        </div>
      </md-card-header>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Country</label>
            <md-select
              v-model="campingRegionDestinations.country"
              name="country"
              id="country"
              md-dense
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <md-button
          class="md-success button-search"
          @click="findRegionDestination"
          >SEARCH</md-button
        >
      </div>
      <md-card-content>
        <UniversalTable
          :data="regionDestinationsList"
          :export-file-name="campingRegionDestinations.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Department Destinations</h4>
        </div>
      </md-card-header>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Country</label>
            <md-select
              v-model="campingDepartmentDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Region</label>
            <md-select
              v-model="campingDepartmentDestinations.region"
              name="regions"
              id="regions"
              md-dense
              :disabled="!campingDepartmentDestinations.isFetchCountry"
            >
              <md-option
                v-for="(region, key) in regions"
                :key="key"
                :value="region.key"
                >{{ region.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <md-button
          class="button-search"
          @click="findDepartmentDestination"
          :disabled="!campingDepartmentDestinations.isFetchCountry"
          :class="{
            'md-success': campingDepartmentDestinations.isFetchCountry,
          }"
          >SEARCH</md-button
        >
      </div>
      <md-card-content>
        <UniversalTable
          :data="departmentDestinationsList"
          :export-file-name="campingDepartmentDestinations.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Town Destinations</h4>
        </div>
      </md-card-header>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Country</label>
            <md-select
              v-model="campingTownDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Region</label>
            <md-select
              v-model="campingTownDestinations.country"
              name="regions"
              id="regions"
              md-dense
            >
              <md-option
                v-for="(region, key) in countries"
                :key="key"
                :value="region.key"
                >{{ region.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Department</label>
            <md-select
              v-model="campingDepartmentDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <md-button
          class="button-search"
          @click="findDepartmentDestination"
          :disabled="!campingTownDestinations.isFetchCountry"
          :class="{
            'md-success': campingTownDestinations.isFetchCountry,
          }"
          >SEARCH</md-button
        >
      </div>
      <md-card-content>
        <UniversalTable
          :data="departmentDestinationsList"
          :export-file-name="campingTownDestinations.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Name Destinations</h4>
        </div>
      </md-card-header>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Country</label>
            <md-select
              v-model="campingDepartmentDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Region</label>
            <md-select
              v-model="campingDepartmentDestinations.region"
              name="regions"
              id="regions"
              md-dense
              :disabled="!campingDepartmentDestinations.isFetchCountry"
            >
              <md-option
                v-for="(region, key) in regions"
                :key="key"
                :value="region.key"
                >{{ region.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Department</label>
            <md-select
              v-model="campingDepartmentDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Town</label>
            <md-select
              v-model="campingTownDestinations.country"
              name="country"
              id="country"
              md-dense
              @input="updateRegionsForDepartmentList"
            >
              <md-option
                v-for="(country, key) in countries"
                :key="key"
                :value="country.key"
                >{{ country.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <md-button
          class="button-search"
          @click="findDepartmentDestination"
          :disabled="!campingDepartmentDestinations.isFetchCountry"
          :class="{
            'md-success': campingDepartmentDestinations.isFetchCountry,
          }"
          >SEARCH</md-button
        >
      </div>
      <md-card-content>
        <UniversalTable
          :data="departmentDestinationsList"
          :export-file-name="campingDepartmentDestinations.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Camping Themes</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="campingThemesList"
          :export-file-name="campingThemes.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <ProgressSpinner :isProgress="loadingSpecialRegion" />
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Special Regions</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <addSpecialRegionsDialog
          :is-show-dialog="addSpecialRegion"
          @onDialogClosed="closeCreateDialog"
        />
        <updateSpecialRegionsDialog
          :is-show-dialog="updateSpecialRegion"
          @onDialogClosed="closeUpdateDialog"
        />
        <UniversalTable
          :data="specialRegionsList"
          :export-file-name="'Special Regions'"
          :onAddButtonClick="addSpecialRegionModal"
          @onRowSelect="handleRowSelect"
          :add-button-text="'Add Special Region'"
          @navigate-to-row-item="updateSpecialRegionModal"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div class="buttons-direction">
              <md-button
                class="md-info md-sm level-two-buttons"
                @click="addSpecialRegionModal()"
              >
                Add
              </md-button>
              <md-button
                v-if="!!selectedRow"
                class="md-info md-sm level-two-buttons"
                @click="updateSpecialRegionModal"
              >
                Update
              </md-button>
            </div>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import UniversalTable from "@/views/components/tables/UniversalTable"
import addSpecialRegionsDialog from "@/modules/landing-support/views/components/dialogs/addSpecialRegionsDialog"
import updateSpecialRegionsDialog from "@/modules/landing-support/views/components/dialogs/updateSpecialRegionsDialog"
import {
  LANDING_SUPPORTS,
  LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST,
  LANDING_SUPPORTS_FETCH_CAMPING_THEMES,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST,
  LANDING_SUPPORTS_SELECTED_SPECIAL_REGION,
} from "@/modules/landing-support/store/mutations-types"
import {
  GEO,
  GEO_FETCH_COUNTRIES,
  GEO_FETCH_REGIONS,
} from "@/modules/geo/store/mutations-types"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"

export default {
  name: "LandingSupport",
  components: {
    UniversalTable,
    addSpecialRegionsDialog,
    updateSpecialRegionsDialog,
    ProgressSpinner,
  },
  data() {
    return {
      selectedTableRow: {},
      loadingSpecialRegion: false,
      campingCountryDestinations: {
        exportName: "Camping Country Destinations",
      },
      campingRegionDestinations: {
        exportName: "Camping Region Destinations",
        country: "",
      },
      campingDepartmentDestinations: {
        exportName: "Camping Department Destinations",
        country: "",
        region: "",
        isFetchCountry: false,
      },
      campingTownDestinations: {
        exportName: "Camping Town Destinations",
        country: "",
        region: "",
        department: "",
        isFetchCountry: false,
      },
      campingThemes: {
        exportName: "Camping Themes",
      },
      addSpecialRegion: false,
      updateSpecialRegion: false,
    }
  },
  computed: {
    ...mapState(LANDING_SUPPORTS, {
      countryDestinationsList: state => state.countryDestinationsList,
      regionDestinationsList: state => state.regionDestinationsList,
      departmentDestinationsList: state => state.departmentDestinationsList,
      campingThemesList: state => state.campingThemesList,
      saveSelectedRegionFilter: state => state.saveSelectedRegionFilter,
      saveSelectedDepartmentFilter: state => state.saveSelectedDepartmentFilter,
      specialRegionsList: state => state.specialRegionsList,
      selectedSpecialRegion: state => state.selectedSpecialRegion,
    }),
    ...mapState(GEO, {
      countries: state => state.countries,
      regions: state => state.regions,
    }),
  },
  methods: {
    ...mapActions(LANDING_SUPPORTS, {
      fetchCountryDestinationsList: LANDING_SUPPORTS_FETCH_COUNTRY_DESTINATIONS_LIST,
      fetchRegionDestinationsList: LANDING_SUPPORTS_FETCH_REGION_DESTINATIONS_LIST,
      fetchDepartmentDestinationsList: LANDING_SUPPORTS_FETCH_DEPARTMENT_DESTINATIONS_LIST,
      fetchCampingThemes: LANDING_SUPPORTS_FETCH_CAMPING_THEMES,
      fetchSpecialRegionsList: LANDING_SUPPORTS_FETCH_SPECIAL_REGIONS_LIST,
      setSelectedSpecialRegion: LANDING_SUPPORTS_SELECTED_SPECIAL_REGION,
    }),
    ...mapActions(GEO, {
      fetchCountriesList: GEO_FETCH_COUNTRIES,
      fetchRegionsList: GEO_FETCH_REGIONS,
    }),
    async findRegionDestination() {
      await this.fetchRegionDestinationsList({
        country: this.campingRegionDestinations.country,
      })
    },
    async updateRegionsForDepartmentList() {
      if (this.campingDepartmentDestinations.country) {
        await this.fetchRegionsList({
          country: this.campingDepartmentDestinations.country,
        })
        this.campingDepartmentDestinations.isFetchCountry = true
      }
    },
    async findDepartmentDestination() {
      this.campingRegionDestinations.isFetchCountry = true
      await this.fetchDepartmentDestinationsList({
        country: this.campingDepartmentDestinations.country,
        region: this.campingDepartmentDestinations.region,
      })
      localStorage.setItem(
        "country",
        this.campingDepartmentDestinations.country
      )
      localStorage.setItem("region", this.campingDepartmentDestinations.region)
    },
    handleRowSelect(row) {
      if (row) this.selectedTableRow = row
    },
    // Open Add Special Region Modal
    addSpecialRegionModal() {
      this.addSpecialRegion = true
    },
    // Open Update SPecial Region Modal
    updateSpecialRegionModal() {
      this.setSelectedSpecialRegion(this.selectedTableRow)
      this.updateSpecialRegion = true
    },
    // Close add Special Region Modal
    closeCreateDialog() {
      this.addSpecialRegion = false
    },
    // Close Update Special Region Modal
    closeUpdateDialog() {
      this.updateSpecialRegion = false
    },
    // Initialize Component at mounted
    async init() {
      await this.fetchCountryDestinationsList({
        country: this.campingRegionDestinations.country,
      })
      await this.fetchCountriesList({
        country: this.campingRegionDestinations.country,
      })
      this.loadingSpecialRegion = true
      await this.fetchSpecialRegionsList()
      this.loadingSpecialRegion = false
      await this.fetchCampingThemes()
      if (this.saveSelectedRegionFilter)
        this.campingRegionDestinations.country = this.saveSelectedRegionFilter.country
      if (this.saveSelectedDepartmentFilter)
        this.campingDepartmentDestinations.country = this.saveSelectedDepartmentFilter.country
      this.campingDepartmentDestinations.region = this.saveSelectedDepartmentFilter.region
    },
  },
  async mounted() {
    await this.init()
  },
}
</script>

<style scoped>
.select-form {
  padding-top: 10px;
}
.button-search {
  height: 35px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .md-layout-item.md-size-25 {
    min-width: 50% !important;
    max-width: 50% !important;
  }
}
</style>
