<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Feed Job Executions</h4>
        </div>
      </md-card-header>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item md-size-25 select-form">
          <md-field>
            <label>Search Feed Job</label>
            <md-select
              v-model="searchFeedJob"
              name="job_name"
              id="job_name"
              @input="search"
            >
              <md-option
                v-for="(jobName, key) in jobNamesList"
                :key="key"
                :value="jobName.key"
                >{{ jobName.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
      <md-card-content>
        <UniversalTable
          :data="normalizedFeedJobsList"
          :export-file-name="dataExportFileName"
        >
        </UniversalTable>
      </md-card-content>
    </md-card>
    <div>
      <search-feed-jobs-form />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  FEED_JOBS_FETCH_ITEMS,
  FEED_JOBS_FETCH_LIST,
  FEED_JOBS_FETCH_JOB_NAMES_LIST,
} from "@/modules/feed-jobs/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import SearchFeedJobsForm from "@/modules/feed-jobs/views/components/forms/SearchFeedJobsForm"
import { normalizeDateInTable } from "@/modules/feed-jobs/utils/feed-jobs-date.utils"
import _ from "lodash"

export default {
  name: "FeedJobs",
  components: {
    UniversalTable,
    SearchFeedJobsForm,
  },
  data() {
    return {
      feedJob: null,
      isFetching: false,
      searchFeedJob: "",
      dataExportFileName: "Feed Job Executions",
    }
  },
  mounted() {
    this.feedJobsList()
    this.feedJobsNames()
  },
  computed: {
    ...mapState(FEED_JOBS, {
      items: state => state.allFeedJobList,
      list: state => state.itemsFeedJobsList,
      jobNamesList: state => state.jobNamesList,
    }),
    feedJobs() {
      return this.items
    },
    normalizedFeedJobsList() {
      if (Object.keys(this.items).length === 0 || !this.items.rows) {
        return {}
      }

      let data = _.cloneDeep(this.items)

      data.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return data
    },
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      feedJobsList: FEED_JOBS_FETCH_LIST,
      feedJobsItemsByJobName: FEED_JOBS_FETCH_ITEMS,
      feedJobsNames: FEED_JOBS_FETCH_JOB_NAMES_LIST,
    }),
    async search() {
      await this.feedJobsList({
        jobName: this.searchFeedJob,
      })
    },
  },
}
</script>

<style scoped>
.select-form {
  padding-top: 10px;
}
</style>
