import actions from "@/modules/landing-support/store/actions"
import mutations from "@/modules/landing-support/store/mutations"

const state = () => ({
  countryDestinationsList: {},
  regionDestinationsList: {},
  departmentDestinationsList: {},
  campingThemesList: {},
  saveSelectedRegionFilter: {},
  saveSelectedDepartmentFilter: {},
  specialRegionsList: {},
  countryDepartmentList: {},
  countrySpecialRegionsList: {},
  selectedSpecialRegion: "",
  selectedSpecialRegionDetail: "",
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
