<template>
  <collapse :collapse="[collapseTitle]" :is-open="isOpen">
    <template slot="md-collapse-pane-1">
      <UniversalTable
        :export-file-name="exportTableFileName"
        :data="data"
        :onAddButtonClick="addFeatureDialog"
        :add-button-text="'Add New Feature'"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <div>
            <md-button
              v-if="featureEditable"
              @click="addFeatureDialog"
              class="md-info md-sm mr-2"
            >
              Add
            </md-button>
            <md-button
              v-if="featureEditable && !!selectedRow"
              @click="openFeatureDialog(selectedRow)"
              class="md-info md-sm mr-2"
            >
              View details
              <md-icon>keyboard_arrow_right</md-icon>
            </md-button>
            <md-button
              v-if="featureEditable && !!selectedRow"
              @click="updateFeature(selectedRow)"
              class="md-sm mr-2"
            >
              Edit
              <md-icon>edit</md-icon>
            </md-button>
            <md-button
              v-if="featureEditable && !!selectedRow"
              @click="deleteFeature(selectedRow)"
              class="md-danger md-sm"
            >
              Delete
              <md-icon>delete</md-icon>
            </md-button>
          </div>
        </template>
      </UniversalTable>
      <ViewAmenitiesDialog
        :is-show-dialog="showFeatureDialog"
        @onDialogClosed="closeFeatureDialog"
      />
      <EditAmenitiesDialog
        :is-show-dialog="showEditFeatureDialog"
        @onDialogClosed="closeEditFeatureDialog"
        type="GENERAL_INFO"
      />
      <AddAmenitiesDialog
        :is-show-dialog="showAddFeatureDialog"
        @onDialogClosed="closeAddFeatureDialog"
        type="GENERAL_INFO"
      />
    </template>
  </collapse>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import Collapse from "@/views/components/ui/Collapse"
import ViewAmenitiesDialog from "./dialogs/ViewAmenitiesDialog.vue"
import EditAmenitiesDialog from "./dialogs/EditAmenitiesDialog.vue"
import AddAmenitiesDialog from "./dialogs/AddAmenitiesDialog.vue"
import { mapActions } from "vuex"
import {
  CAMPSITES,
  FETCH_STAY_FACILITIES_AMENITIES,
  DELETE_STAY_FACILITIES_AMENITIES,
  SET_SELECTED_AMENITY_ROW,
  SET_FACILITIES_AMENITIES_CATEGOIRES,
  SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "CampsiteGeneralInfo",
  props: {
    isOpen: Boolean,
    data: Object,
    featureEditable: Boolean,
  },
  components: {
    Collapse,
    UniversalTable,
    ViewAmenitiesDialog,
    EditAmenitiesDialog,
    AddAmenitiesDialog,
  },
  methods: {
    ...mapActions(CAMPSITES, {
      fetchStayFacilitiesAmenities: FETCH_STAY_FACILITIES_AMENITIES,
      deleteStayFacilitiesAmenities: DELETE_STAY_FACILITIES_AMENITIES,
      setSelectedRow: SET_SELECTED_AMENITY_ROW,
      setAmenitiesCategoires: SET_FACILITIES_AMENITIES_CATEGOIRES,
      setAmenitiesSubCategories: SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
    }),
    async openFeatureDialog(selectedRow) {
      const payload = {
        stayFacilityId: selectedRow[0].value,
        data: {
          category: selectedRow.find(entity => entity.name === "category")
            ? selectedRow.find(entity => entity.name === "category").value
            : "",
          subcategory: selectedRow.find(
            entity => entity.name === "sub_category"
          )
            ? selectedRow.find(entity => entity.name === "sub_category").value
            : "",
          featureKey: selectedRow.find(entity => entity.name === "feature_key")
            ? selectedRow.find(entity => entity.name === "feature_key").value
            : "",
        },
      }
      await this.fetchStayFacilitiesAmenities(payload)
      this.showFeatureDialog = true
    },
    closeFeatureDialog() {
      this.showFeatureDialog = false
    },
    async deleteFeature(selectedRow) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this feature?",
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          showConfirmButton: true,
          confirmButtonText: "Yes",
        })
        .then(async result => {
          if (result.isConfirmed) {
            const payload = {
              stayFacilityId: selectedRow[0].value,
              data: {
                category: selectedRow.find(entity => entity.name === "category")
                  ? selectedRow.find(entity => entity.name === "category").value
                  : "",
                subcategory: selectedRow.find(
                  entity => entity.name === "sub_category"
                )
                  ? selectedRow.find(entity => entity.name === "sub_category")
                      .value
                  : "",
                featureKey: selectedRow.find(
                  entity => entity.name === "feature_key"
                )
                  ? selectedRow.find(entity => entity.name === "feature_key")
                      .value
                  : "",
              },
              type: "GENERAL_INFO",
            }
            await this.deleteStayFacilitiesAmenities(payload)
          }
        })
    },
    async updateFeature(selectedRow) {
      const payload = {
        stayFacilityId: selectedRow[0].value,
        data: {
          category: selectedRow.find(entity => entity.name === "category")
            ? selectedRow.find(entity => entity.name === "category").value
            : "",
          subcategory: selectedRow.find(
            entity => entity.name === "sub_category"
          )
            ? selectedRow.find(entity => entity.name === "sub_category").value
            : "",
          featureKey: selectedRow.find(entity => entity.name === "feature_key")
            ? selectedRow.find(entity => entity.name === "feature_key").value
            : "",
        },
      }
      await this.fetchStayFacilitiesAmenities(payload)
      await this.setSelectedRow(selectedRow)
      await this.setAmenitiesCategoires({ key: "GENERAL_INFO" })

      this.showEditFeatureDialog = true
    },
    closeEditFeatureDialog() {
      this.showEditFeatureDialog = false
    },
    async addFeatureDialog() {
      await this.setAmenitiesCategoires({ key: "GENERAL_INFO" })
      this.showAddFeatureDialog = true
    },
    closeAddFeatureDialog() {
      this.showAddFeatureDialog = false
    },
  },
  data: () => ({
    collapseTitle: "General info",
    exportTableFileName: "campsite_general_info",
    showFeatureDialog: false,
    selectedId: "",
    showEditFeatureDialog: false,
    showAddFeatureDialog: false,
  }),
}
</script>

<style scoped></style>
