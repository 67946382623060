<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field class="mb-4">
      <label for="adjustment-field">Adjustment field</label>
      <md-select
        v-model="adjustmentField"
        name="booking-status"
        id="booking-status"
        disabled
      >
        <md-option
          v-for="option in adjustmentFieldList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
    </md-field>

    <div
      class="md-layout-item mb-4 p-0"
      :class="{ 'md-error md-invalid': $v.newValue.$error }"
      v-if="isDateFieldType"
    >
      <md-datepicker :md-model-type="String" v-model="$v.newValue.$model">
        <label>New value</label>
      </md-datepicker>
    </div>

    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.newValue.$error }"
      v-else
    >
      <label class="mb-2">New value</label>
      <md-input
        v-show="inputType == 'text'"
        type="text"
        v-model.trim="$v.newValue.$model"
      />
      <md-input
        v-show="inputType == 'number'"
        type="number"
        v-model.trim="$v.newValue.$model"
      />
      <md-input
        v-show="inputType == 'date'"
        type="date"
        v-model.trim="$v.newValue.$model"
      />

      <div class="md-error" v-if="!$v.newValue.required">
        Field is required.
      </div>
    </md-field>

    <md-field
      class="mb-4"
      :class="{ 'md-error md-invalid': $v.comment.$error }"
    >
      <label>Comment</label>

      <md-input type="text" v-model.trim="$v.comment.$model" />

      <div class="md-error" v-if="!$v.comment.maxLength">
        Comment must have at most
        {{ $v.comment.$params.maxLength.max }} letters.
      </div>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button type="submit" class="md-raised md-success">
        Update adjustment
      </md-button>
    </div>
  </form>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators"

import { dateInISOString } from "@/utils/date.utils"
import bookingsAdjustments from "@/modules/bookings/mixins/bookingAdjustments.mixin"
import { isValidDate } from "../../../../../utils/date.utils"
import { toISODate } from "@/utils/date.utils"

export default {
  name: "UpdateBookingAdjustmentForm",
  mixins: [bookingsAdjustments],
  props: {
    onDialogClose: Function,
    adjustmentRow: Array,
    isFormSending: Boolean,
    adjustmentFieldList: {
      type: Array,
      default: () => [],
    },
    onFormSubmit: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {
      newValue: null,
      adjustmentField: "",
      comment: "",
    }
  },
  computed: {
    adjustmentsRowData() {
      return this.getAdjustmentRowData(this.adjustmentRow)
    },
    adjustmentFieldType() {
      const field = this.adjustmentFieldList.find(
        field => field.key === this.adjustmentField
      )
      return field?.type
    },
    adjustmentFieldTypes() {
      const types = {}
      this.adjustmentFieldList.map(field => {
        const fieldType = field.type
        types[fieldType] = fieldType
      })
      return types
    },
    isDateFieldType() {
      return this.adjustmentFieldType === this.adjustmentFieldTypes.Date
    },
  },
  methods: {
    setInitialFormData() {
      const rowData = this.adjustmentsRowData || []

      if (this.isDateFieldType) {
        rowData.newValue = isValidDate(rowData.newValue)
          ? dateInISOString(rowData.newValue)
          : ""
      }

      Object.keys(rowData).map(valueKey => {
        this[valueKey] = rowData[valueKey]
      })
      this.updateType()
    },
    async handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit("onFormSubmit", {
          adjustmentField: this.adjustmentField,
          newValue: isValidDate(this.newValue)
            ? toISODate(this.newValue)
            : this.newValue,
          comment: this.comment,
        })
      }
    },
    updateType() {
      switch (this.adjustmentFieldType) {
        case "STRING":
          this.inputType = "text"
          break

        case "DOUBLE":
        case "INTEGER":
          this.inputType = "number"
          break

        case "Date":
        default:
          this.inputType = "date"
          break
      }
    },
  },
  validations: {
    newValue: {
      required,
    },
    comment: {
      maxLength: maxLength(255),
    },
  },
  mounted() {
    this.$material.locale.dateFormat = "LLLL dd, yyyy"

    this.setInitialFormData()
  },
}
</script>

<style scoped></style>
