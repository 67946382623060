<template>
  <drop-down>
    <md-button
      slot="title"
      data-toggle="dropdown"
      class="md-success md-just-icon md-round"
    >
      <md-icon>save_alt</md-icon>
      <md-tooltip md-direction="top">
        Export extended booking data to excel
      </md-tooltip>
    </md-button>

    <ul class="dropdown-menu dropdown-menu-right">
      <li class="md-info">
        <a href="#" class="md-info p-0 m-0 md-primary md-sm md-simple">
          <download-excel
            :name="`data_${exportFileName}.xls`"
            :fetch="fetchExtendedData"
            class="md-layout-item md-success px-3 py-2"
          >
            Export to Excel
          </download-excel>
        </a>
      </li>
    </ul>
  </drop-down>
</template>

<script>
import {
  BOOKINGS,
  BOOKINGS_FETCH_EXTENDED_DATA,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"

export default {
  name: "ExportTableDropdown",
  props: {
    exportFileName: {
      type: String,
      default: "booking_extended",
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_EXTENDED_DATA]),
    async fetchExtendedData() {
      await this[BOOKINGS_FETCH_EXTENDED_DATA](this.searchFormData)
      return this.exportBookingData
    },
  },
  computed: {
    resultTableRows() {
      return this.$store.state[BOOKINGS].item.extendedData || []
    },
    searchFormData() {
      return this.$store.state[BOOKINGS].searchForm
    },
    exportBookingData() {
      return this.resultTableRows.map(({ row }) => {
        const rowData = {}
        row.forEach(rowCell => {
          rowData[rowCell.name] = rowCell.value || null
        })

        return rowData
      })
    },
  },
}
</script>

<style scoped></style>
