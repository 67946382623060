<template>
  <div class="system-actions-page">
    <div class="system-actions-coontainer">
      <div class="table-tab md-card">
        <ProgressSpinner :isProgress="loading" />
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">System Actions</h4>
          </div>
        </md-card-header>
        <UniversalTable :data="datasourcesStatusList"> </UniversalTable>
      </div>

      <div class="form-tab md-card">
        <ProgressSpinner :isProgress="loadingActions" />
        <div>
          <md-field :class="{ 'md-error md-invalid': $v.systemaction.$error }">
            <label for="product-key-field">Action</label>
            <md-select
              v-model="systemaction"
              name="systemaction"
              id="product-key-field"
            >
              <md-option
                v-for="item in actionsList"
                :key="item.key"
                :value="item.key"
              >
                {{ item.value }}
              </md-option>
            </md-select>

            <div class="md-error" v-if="$v.systemaction.$error">
              Action is required
            </div>
          </md-field>
        </div>

        <div>
          <md-button class="md-raised md-info mt-4" @click="changeActions()">
            Submit
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SYSTEM_ACTIONS,
  SYSTEM_ACTIONS_FETCH_ACTIONS_LIST,
  SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL,
  SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS,
} from "@/modules/system-actions/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
import { required } from "vuelidate/lib/validators"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"

export default {
  name: "SystemActions",
  components: {
    UniversalTable,
    ProgressSpinner,
  },
  data: () => ({
    systemaction: "",
    loading: false,
    loadingActions: false,
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SYSTEM_ACTIONS, {
      fetchActionsList: SYSTEM_ACTIONS_FETCH_ACTIONS_LIST,
      fetchDatasourceStatusDetail: SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL,
      changeDatasourceStatus: SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS,
    }),
    // Initialize the System Actions
    async init() {
      this.loading = true
      this.loadingActions = true
      try {
        await this.fetchActionsList()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Fetch Actions List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      try {
        await this.fetchDatasourceStatusDetail()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Fetch Data Source Status Detail",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loading = false
      this.loadingActions = false
    },
    // Change System Actions
    async changeActions() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.loadingActions = true
        await this.changeDatasourceStatus(this.systemaction)
        this.$notify({
          timeout: 3000,
          message: "Data source system action change successfully",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        })
        this.loadingActions = false
      }
    },
  },
  computed: {
    ...mapState(SYSTEM_ACTIONS, {
      actionsList: state => state.actionsList,
      datasourcesStatusList: state => state.datasourcesStatusList,
    }),
  },
  validations: {
    systemaction: {
      required,
    },
  },
}
</script>

<style scoped>
.system-actions-coontainer {
  display: flex;
  justify-content: space-between;
}
.table-tab {
  width: 60%;
  padding: 0px 15px;
}

.form-tab {
  width: 40%;
  margin-left: 16px;
  justify-content: space-evenly;
  padding: 0px 15px;
}
@media screen and (max-width: 767px) {
  .system-actions-coontainer {
    flex-direction: column !important;
    padding: 0px 16px !important;
  }
  .table-tab {
    width: 100% !important;
  }

  .form-tab {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
</style>
