import {
  PROMO_CODES_UPDATE_ITEM,
  PROMO_CODES_UPDATE_LIST,
} from "@/modules/promo-codes/store/mutations-types"
import { getRowCellByColumnName } from "@/utils/table.utils"
import { getPromoCodesFieldsKeys } from "@/modules/promo-codes/utils/promo-codes.utils"

const mutations = {
  [PROMO_CODES_UPDATE_LIST](state, payload) {
    state.list = payload
  },
  [PROMO_CODES_UPDATE_ITEM](state, payload) {
    const { promoCodeLabel } = payload
    const promoCodeList = state.list.rows || []
    const promoCodeToUpdate = promoCodeList.find(({ row }) => {
      const labelCell = getRowCellByColumnName(row, "promo_code_label")
      return labelCell && labelCell.value === promoCodeLabel
    })
    const fieldKeysToUpdate = Object.keys(payload)
    const promoCodesKeys = getPromoCodesFieldsKeys()

    fieldKeysToUpdate.forEach(keyToUpdate => {
      const cellToUpdate = promoCodeToUpdate.row.find(cell => {
        const cellKey = promoCodesKeys[keyToUpdate]
        return cell.name === cellKey
      })
      if (!cellToUpdate) return

      cellToUpdate.value = payload[keyToUpdate]
    })
  },
}

export default mutations
