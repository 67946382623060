export default $axios => resource => ({
  fetchItemInvoiceInfo(params) {
    return $axios.get(`${resource}/booking/invoicesupportinfo`, {
      params,
    })
  },
  fetchPostPaymentStatuses(params) {
    return $axios.get(`${resource}/booking/checkpaymentsucceeded`, {
      params,
    })
  },
  fetchInvoiceStatuses() {
    return $axios.get(`${resource}/bookinginvoicestatusvalues`)
  },
  fetchCommissionStatuses() {
    return $axios.get(`${resource}/bookingcommissionstatusvalues`)
  },
  updateItemInvoiceInfo(payload) {
    return $axios.post(`${resource}/booking/updateinvoicesupportinfo`, payload)
  },
  updateItemStatus(payload) {
    return $axios.post(`${resource}/booking/updatebookingstatus`, payload)
  },
  createItemComments(payload) {
    return $axios.post(`${resource}/bookingcomments/addcomment`, payload)
  },
  removeItemAdjustments(payload) {
    return $axios.delete(`${resource}/bookingadjustments/removeadjustment`, {
      data: payload,
    })
  },
  updateItemAdjustments(payload) {
    return $axios.post(
      `${resource}/bookingadjustments/updateadjustment`,
      payload
    )
  },
  createItemAdjustments(payload) {
    return $axios.post(`${resource}/bookingadjustments/addadjustment`, payload)
  },
  fetchItemAdjustmentFields() {
    return $axios.get(`${resource}/adjustmentfields`)
  },
  fetchItemAdjustments(params) {
    return $axios.get(`${resource}/bookingadjustments`, {
      params,
    })
  },
  fetchItemPricing(params) {
    return $axios.get(`${resource}/booking/pricingdetails`, {
      params,
    })
  },
  fetchItemDetails(params) {
    return $axios.get(`${resource}/booking/details`, {
      params,
    })
  },
  fetchList(params) {
    return $axios.get(`${resource}/bookings`, {
      params,
    })
  },
  fetchListForMultipleStatuses(params) {
    return $axios.get(`${resource}/bookingsforstatuses`, {
      params,
    })
  },
  fetchExtendedData(params) {
    return $axios.get(`${resource}/bookingsextended`, {
      params,
    })
  },
  fetchStatuses() {
    return $axios.get(`${resource}/bookingstatusvalues`)
  },
  fetchPostStatuses() {
    return $axios.get(`${resource}/postbookingstatusvalues`)
  },
  fetchPromoCodes() {
    return $axios.get(`${resource}/activepromocodes`)
  },
  updateItemPromo(params) {
    return $axios.post(`${resource}/booking/updatepromocode`, {}, { params })
  },
  removeItemPromo(params) {
    return $axios.post(`${resource}/booking/removepromocode`, {}, { params })
  },
  fetchItemStatusHistory(params) {
    return $axios.get(`${resource}/booking/statushistory`, {
      params,
    })
  },
  fetchItemComments(params) {
    return $axios.get(`${resource}/bookingcomments`, {
      params,
    })
  },
  fetchBookingProposals(params) {
    return $axios.get(`${resource}/proposals`, { params })
  },
  sendBookingProposal(payload) {
    return $axios.post(`${resource}/proposals`, payload)
  },
  getSpecificProposal(id) {
    return $axios.get(`${resource}/proposals/${id}`)
  },
  // fetchDataSyncJobItem(params) {
  //   return $axios.get(`${resource}/syncjob/item`, {
  //     params,
  //   })
  // },
  fetchPaymentIssuesList(params) {
    return $axios.get(`${resource}/failedpaymentbookings`, {
      params,
    })
  },
  fetchItemInsurance(params) {
    return $axios.get(`${resource}/booking/csinsuranceoptin`, {
      params,
    })
  },
  getProposalLanguages() {
    return $axios.get(`${resource}/proposals/supportedlanguages`)
  },
  getProposalWfaLanguages() {
    return $axios.get(`${resource}/proposals/wfa/supportedlanguages`)
  },
  fetchWeatherInsuranceOptions(param) {
    return $axios.get(
      `${resource}/booking/csweatherinsuranceoptin?transId=${param}`
    )
  },
  fetchRejectionReasonsList() {
    return $axios.get(`${resource}/bookingcampsiterejectionreasons`)
  },
  updateBookingRejectionReason(param) {
    return $axios.post(
      `${resource}/booking/updatebookingcampsiterejectionreason?transId=${param.transId}&reason=${param.reason}`
    )
  },
  getProposalType() {
    return $axios.get(`${resource}/proposals/types`)
  },
  getCurrencyList() {
    return $axios.get(`${resource}/static/supportedcurrencies`)
  },
  updateProposalStatus(input) {
    let endpoint = `${resource}/proposals/wfa/${input.id}?newStatus=${input.status}`
    if (input.amountDeposit != undefined && input.dateDeposit != undefined) {
      endpoint += `&amountDepositPaid=${input.amountDeposit}&dateDepositPaid=${input.dateDeposit}`
    }
    return $axios.put(endpoint)
  },
})
