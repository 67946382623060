<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Upload file CSV</md-dialog-title>

      <md-dialog-content>
        <CreateUploadFeedJobForm
          :is-form-sending="isFormSending"
          :on-dialog-close="closeDialog"
          @form-submit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import CreateUploadFeedJobForm from "@/modules/feed-jobs/views/components/forms/CreateUploadFeedJobForm"
import dialogMixin from "@/mixins/dialog.mixin"
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  // FEED_JOBS_ADD_ITEM,
} from "@/modules/feed-jobs/store/mutations-types"
export default {
  name: "CreateFeedJobDialog",
  mixins: [dialogMixin],
  components: {
    CreateUploadFeedJobForm,
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      // addItem: FEED_JOBS_ADD_ITEM,
    }),
    showAlert(name) {
      this.$swal(name)
    },
    async handleFormSubmit(values) {
      this.isFormSending = true
      try {
        if (values.status === "successfully") {
          this.closeDialog()
        }
      } catch (e) {
        this.isFormSending = false
      }
    },
  },
  computed: {
    ...mapState(FEED_JOBS, {
      errorsList: state => state.errorsList,
    }),
  },
}
</script>

<style scoped></style>
