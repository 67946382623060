import { dateInISOString } from "@/utils/date.utils"

export const promoCodesFieldsNames = {
  active: "active",
  valid_from_stay_date: "validFromStayDate",
  valid_to_stay_date: "validToStayDate",
  promo_code_label: "promoCodeLabel",
  value: "promoValue",
  valid_from: "validFromDate",
  valid_to: "validToDate",
  min_basket_value: "minBasketValue",
  valid_message_en: "validMessageEN",
  valid_message_fr: "validMessageFR",
  invalid_message_en: "invalidMessageEN",
  invalid_message_fr: "invalidMessageFR",
}

export const getPromoCodesFieldsKeys = () => {
  const keys = {}
  Object.keys(promoCodesFieldsNames).forEach(key => {
    const fieldValue = promoCodesFieldsNames[key]
    keys[fieldValue] = key
  })
  return keys
}

export const getPromoCodeFormValues = data => {
  return {
    active: data.active,
    promoCodeLabel: data.promoCodeLabel,
    validFromDate: dateInISOString(data.validFromDate),
    validToDate: dateInISOString(data.validToDate),
    validFromStayDate: dateInISOString(data.validFromStayDate),
    validToStayDate: dateInISOString(data.validToStayDate),
    promoValue: data.toFloat(data.promoValue),
    minBasketValue: data.toFloat(data.minBasketValue),
    validMessageEN: data.validMessageEN,
    validMessageFR: data.validMessageFR,
    invalidMessageEN: data.invalidMessageEN,
    invalidMessageFR: data.invalidMessageFR,
  }
}

export const getPromoCodeFieldValueByType = (types, field) => {
  const fieldValue = field.value || null

  switch (field.type) {
    case types.Date: {
      return dateInISOString(fieldValue)
    }
    case types.Boolean: {
      return fieldValue === "true" || !!fieldValue
    }
    default: {
      return fieldValue
    }
  }
}
