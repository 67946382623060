<template>
  <div class="px-5">
    <ProgressSpinner :isProgress="loadingAdd" />
    <md-dialog-title class="pl-0"
      >Level 1 Item:
      {{
        levelOneSelectedItem.find(item => item.name === "item_key").value
      }}</md-dialog-title
    >
    <br />
    <form novalidate class="md-layout mt-4" @submit.prevent>
      <template>
        <md-field
          :class="{
            'md-error md-invalid': $v.label.$error,
          }"
        >
          <label class="required">Label</label>
          <md-input v-model="label" type="text"></md-input>
          <div class="md-error" v-if="!$v.label.required">
            Level 2 Item Label is required.
          </div>
        </md-field>

        <div class="filter-checkbox">
          <md-checkbox
            class="column checkbox-area-sm"
            v-model="hasCategories"
            value="true"
            >Has Categories</md-checkbox
          >
        </div>
        <md-field>
          <label for="mobileMenuStyle-field">Moile Menu Style</label>
          <md-select
            v-model="mobileMenuStyle"
            name="mobileMenuStyle"
            id="mobileMenuStyle-field"
          >
            <md-option
              v-for="(style, index) in mobileMenuStyles"
              :key="index"
              :value="style.value"
            >
              {{ style.value }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Icon</label>
          <md-input v-model="icon" type="text"></md-input>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="addLevelTwoItem()"
          >
            Add
          </md-button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
  GET_MOBILE_MENU_STYLES_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import { required } from "vuelidate/lib/validators"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "addLevelTwoItemSmartNavConfigurationForm",
  components: {
    ProgressSpinner,
  },
  data: () => ({
    label: "",
    loadingAdd: false,
    mobileMenuStyle: "",
    icon: "",
    hasCategories: false,
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, [
      ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
      GET_MOBILE_MENU_STYLES_LIST,
      ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
    ]),
    // Initialize the Level 2 Items
    async init() {
      this.loadingAdd = true
      try {
        await this[GET_MOBILE_MENU_STYLES_LIST]()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Mobile Menu Style List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingAdd = false
    },
    // Add Level Two Smart Nav Configuration Item
    async addLevelTwoItem() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.loadingAdd = true
        if (!this.hasCategories) this.hasCategories = false
        const data = {
          itemLabel: this.label,
          level1Item: this.levelOneSelectedItem.find(
            item => item.name === "item_key"
          ).value,
          hasCategories: this.hasCategories,
          lang: this.selectedLanguage,
          iconKey: this.icon,
          mobileMenuStyle: this.mobileMenuStyle,
        }
        let final = []
        let input = {}
        input.lang = this.selectedLanguage
        input.key = this.levelOneSelectedItem.find(
          item => item.name === "item_key"
        ).value
        final.push(input)
        final.push(data)
        try {
          await this[ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS](final)
        } catch (e) {
          this.$swal.fire({
            position: "top-end",
            html: e,
            icon: "error",
            title: "Add Level 2 Item",
            showConfirmButton: false,
            timer: 4000,
            showCloseButton: true,
          })
        }
        this.$v.$reset()
        this.loadingAdd = false
        this.$emit("onFormSubmit")
      }
    },
  },
  validations: {
    label: {
      required,
    },
  },
  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      levelOneSelectedItem: state => state.levelOneSelectedItem,
      selectedLanguage: state => state.selectedLanguage,
      mobileMenuStyles: state => state.mobileMenuStyles,
    }),
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
@media screen and (max-width: 600px) {
  .checkbox-area-sm {
    width: 100% !important;
    flex-basis: 100%;
  }
}
.md-title {
  font-size: 14px !important;
}
</style>
