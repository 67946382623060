<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field :class="{ 'md-error md-invalid': $v.promoCodeLabel.$error }">
        <label>Code label</label>
        <md-input type="text" v-model="$v.promoCodeLabel.$model" />

        <div class="md-error" v-if="!$v.promoCodeLabel.required">
          Field is required.
        </div>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 pl-0 mb-4">
      <md-field :class="{ 'md-error md-invalid': $v.promoValue.$error }">
        <label>Value</label>
        <md-input type="number" v-model="$v.promoValue.$model" />

        <div class="md-error" v-if="!$v.promoValue.required">
          Field is required.
        </div>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 pr-0 mb-4">
      <md-field :class="{ 'md-error md-invalid': $v.minBasketValue.$error }">
        <label>Min basket value</label>
        <md-input type="number" v-model="$v.minBasketValue.$model" />

        <div class="md-error" v-if="!$v.minBasketValue.required">
          Field is required.
        </div>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 mb-2 pl-0">
      <md-datepicker
        :class="{ 'md-error md-invalid': $v.validFromDate.$error }"
        :md-model-type="String"
        v-model="$v.validFromDate.$model"
      >
        <label>Valid from</label>

        <div class="md-error" v-if="!$v.validFromDate.required">
          Field is required.
        </div>
      </md-datepicker>
    </div>

    <div class="md-layout-item md-size-50 mb-2 pr-0">
      <md-datepicker
        :class="{ 'md-error md-invalid': $v.validToDate.$error }"
        :md-model-type="String"
        v-model="$v.validToDate.$model"
      >
        <label>Valid to</label>

        <div class="md-error" v-if="!$v.validToDate.required">
          Field is required.
        </div>
      </md-datepicker>
    </div>

    <div class="md-layout-item md-size-50 mb-2 pl-0">
      <md-datepicker
        :class="{ 'md-error md-invalid': $v.validFromStayDate.$error }"
        :md-model-type="String"
        v-model="$v.validFromStayDate.$model"
      >
        <label>Valid stay from</label>

        <div class="md-error" v-if="!$v.validFromStayDate.required">
          Field is required.
        </div>
      </md-datepicker>
    </div>

    <div class="md-layout-item md-size-50 mb-2 pr-0">
      <md-datepicker
        :class="{ 'md-error md-invalid': $v.validToStayDate.$error }"
        :md-model-type="String"
        v-model="$v.validToStayDate.$model"
      >
        <label>Valid stay to</label>

        <div class="md-error" v-if="!$v.validToStayDate.required">
          Field is required.
        </div>
      </md-datepicker>
    </div>

    <div class="md-layout-item md-size-100 p-0">
      <md-field>
        <label>Valid message EN</label>
        <md-input type="text" v-model="validMessageEN" />
      </md-field>
    </div>

    <div class="md-layout-item md-size-100 p-0">
      <md-field>
        <label>Valid message FR</label>
        <md-input type="text" v-model="validMessageFR" />
      </md-field>
    </div>

    <div class="md-layout-item md-size-100 p-0">
      <md-field>
        <label>Invalid message EN</label>
        <md-input type="text" v-model="invalidMessageEN" />
      </md-field>
    </div>

    <div class="md-layout-item md-size-100 p-0">
      <md-field>
        <label>Invalid message FR</label>
        <md-input type="text" v-model="invalidMessageFR" />
      </md-field>
    </div>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="isFormSending"
      >
        CREATE
      </md-button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { dateInISOString } from "@/utils/date.utils"
import { getPromoCodeFormValues } from "@/modules/promo-codes/utils/promo-codes.utils"

const nextMonthNum = ((new Date().getMonth() + 1) % 12) + 1
const nextMonth = new Date().setMonth(nextMonthNum)

export default {
  name: "CreatePromoCodeForm",
  props: {
    onDialogClose: Function,
    isFormSending: Boolean,
    onFormSubmit: Function,
  },
  data: () => ({
    active: true,
    promoCodeLabel: "",
    promoValue: "",
    minBasketValue: "",
    validFromDate: dateInISOString(new Date()),
    validToDate: dateInISOString(nextMonth),
    validFromStayDate: null,
    validToStayDate: null,
    validMessageEN: "",
    validMessageFR: "",
    invalidMessageEN: "",
    invalidMessageFR: "",
  }),
  methods: {
    toFloat(num) {
      return Number.parseFloat(num).toFixed(1)
    },
    handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const normalizedFormValues = getPromoCodeFormValues(this)
        this.$emit("onFormSubmit", normalizedFormValues)
      }
    },
  },
  validations: {
    promoCodeLabel: {
      required,
    },
    promoValue: {
      required,
    },
    minBasketValue: {
      required,
    },
    validFromDate: {
      required,
    },
    validToDate: {
      required,
    },
    validFromStayDate: {
      required,
    },
    validToStayDate: {
      required,
    },
  },
}
</script>

<style scoped></style>
