import actions from "@/modules/product-configuration/store/actions"
import mutations from "@/modules/product-configuration/store/mutations"

const state = () => ({
  productConfigurationList: {},
  updateProductConfigurationForm: {},
  productkey: "",
  language: "",
  productConfiguration: {},
  isProductConfiguration: false,
  languageList: [],
  productKeyList: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
