<template>
  <div class="app__search-promo-codes">
    <div class="md-layout-item">
      <SearchPromoCodesForm
        :is-fetching="isFetchingPromoCodes"
        @onFormSubmit="handleFormSubmit"
      />
    </div>

    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <UniversalTable
            :data="normalizedPromoCodes"
            :onAddButtonClick="handleAddButtonClick"
            :export-file-name="tableExportFileName"
            :add-button-text="addPromoCodeButtonText"
            @onRowSelect="handleRowSelect"
            @navigate-to-row-item="handleEditButtonClick"
          >
            <template slot="header-right-controls" slot-scope="{ selectedRow }">
              <md-button
                class="md-button md-sm md-info mr-2"
                @click="handleAddButtonClick"
              >
                Add
              </md-button>
              <md-button
                class="md-button md-sm md-theme-default mr-2"
                :disabled="!selectedRow"
                @click="handleEditButtonClick"
              >
                Edit
              </md-button>
            </template>
          </UniversalTable>
        </md-card-content>
      </md-card>
    </div>

    <CreatePromoCodeDialog
      :is-show-dialog="isCreateDialogShow"
      @onDialogClosed="handleCreateDialogClosed"
    />

    <UpdatePromoCodeDialog
      :is-show-dialog="isUpdateDialogShow"
      :promo-code="promoCode"
      @onDialogClosed="handleUpdateDialogClosed"
    />
  </div>
</template>

<script>
import SearchPromoCodesForm from "@/modules/promo-codes/views/components/forms/SearchPromoCodesForm"
import { mapActions } from "vuex"
import {
  PROMO_CODES,
  PROMO_CODES_FETCH_LIST,
} from "@/modules/promo-codes/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import UpdatePromoCodeDialog from "@/modules/promo-codes/views/components/dialogs/UpdatePromoCodeDialog"
import CreatePromoCodeDialog from "@/modules/promo-codes/views/components/dialogs/CreatePromoCodeDialog"
import { normalizeDateInTable } from "../../utils/promo-code-date.utils"
import _ from "lodash"

export default {
  name: "SearchPromoCodesPage",
  components: {
    CreatePromoCodeDialog,
    UpdatePromoCodeDialog,
    UniversalTable,
    SearchPromoCodesForm,
  },
  data: () => ({
    promoCode: null,
    isFetchingPromoCodes: false,
    isUpdateDialogShow: false,
    isCreateDialogShow: false,
    tableExportFileName: "promo_codes",
    addPromoCodeButtonText: "Create first promo code",
  }),
  computed: {
    promoCodes() {
      return this.$store.state[PROMO_CODES].list
    },
    normalizedPromoCodes() {
      const data = this.$store.state[PROMO_CODES].list

      if (Object.keys(data).length === 0 || !data.rows) {
        return {}
      }

      let d = _.cloneDeep(data)

      d.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return d
    },
  },
  methods: {
    ...mapActions(PROMO_CODES, [PROMO_CODES_FETCH_LIST]),
    async handleFormSubmit(values) {
      this.isFetchingPromoCodes = true
      await this[PROMO_CODES_FETCH_LIST](values)
      this.isFetchingPromoCodes = false
    },
    handleAddButtonClick() {
      this.isCreateDialogShow = true
    },
    handleCreateDialogClosed() {
      this.isCreateDialogShow = false
    },
    handleEditButtonClick() {
      this.isUpdateDialogShow = true
    },
    handleUpdateDialogClosed() {
      this.isUpdateDialogShow = false
    },
    handleRowSelect(row) {
      if (row) this.promoCode = row
    },
  },
}
</script>

<style scoped></style>
