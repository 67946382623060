<template>
  <div>
    <DetailsCollapse
      :is-loading="isFetching"
      :label="bookingLabel"
      :elements="bookingItemElements"
    />

    <UpdateBookingStatusDialog
      :trans-id="transId"
      :booking-status="currentBookingStatus"
      :is-show-dialog="isShowUpdateBookingStatusDialog"
      @onDialogClosed="closeUpdateBookingStatusDialog"
    />
    <UpdateBookingRejectionReasonDialog
      :trans-id="transId"
      :booking-status="currentBookingRejectionReason"
      :is-show-dialog="isShowBookingRejectionReasonDialog"
      @onDialogClosed="closeUpdateRejectionReasonDialog"
    />
    <BookingPromoCodeDialog
      :trans-id="transId"
      :booking-status="currentBookingPromo"
      :is-show-dialog="isShowBookingPromoCodeDialog"
      @onDialogClosed="closeBookingPromoCodeDialog"
    />
  </div>
</template>

<script>
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"
import { mapActions } from "vuex"
import {
  BOOKINGS_CLEAR_ITEM,
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_DETAILS,
} from "@/modules/bookings/store/mutations-types"
import UpdateBookingStatusDialog from "@/modules/bookings/views/components/dialogs/UpdateBookingStatusDialog"
import UpdateBookingRejectionReasonDialog from "@/modules/bookings/views/components/dialogs/UpdateBookingRejectionReasonDialog"
import BookingPromoCodeDialog from "@/modules/bookings/views/components/dialogs/BookingPromoCodeDialog"
import { DateTime } from "luxon"

const BOOKING_STATUS_NAME = "booking_status"
const BOOKING_REJECTION_REASON = "booking_rejection_reason"
const PROMO_CODE_NAME = "promo_code"

export default {
  name: "BookingDetails",
  props: {
    transId: String,
  },
  components: {
    UpdateBookingStatusDialog,
    UpdateBookingRejectionReasonDialog,
    BookingPromoCodeDialog,
    DetailsCollapse,
  },
  data: () => ({
    isFetching: false,
    isShowUpdateBookingStatusDialog: false,
    isShowBookingPromoCodeDialog: false,
    isShowBookingRejectionReasonDialog: false,
  }),
  computed: {
    currentBookingStatus() {
      const statusData = this.bookingDetailsList.find(status => {
        return status.name === BOOKING_STATUS_NAME
      })
      return statusData?.value
    },
    currentBookingRejectionReason() {
      const statusData = this.bookingDetailsList.find(status => {
        return status.name === BOOKING_REJECTION_REASON
      })
      return statusData?.value ? statusData?.value : "N/A"
    },
    currentBookingPromo() {
      const statusData = this.bookingDetailsList.find(status => {
        return status.name === PROMO_CODE_NAME
      })
      return statusData?.value
    },
    bookingDetails() {
      return this.$store.state[BOOKINGS].item.details
    },
    bookingLabel() {
      return this.bookingDetails.label || "Loading..."
    },
    bookingDetailsList() {
      return this.bookingDetails.dataItems || []
    },
    bookingElementsButtons() {
      return [
        {
          name: BOOKING_STATUS_NAME,
          buttons: [
            {
              class: "md-sm md-info md-theme-default",
              text: "UPDATE",
              click: this.handleUpdateBookingStatusButtonClick,
            },
          ],
        },
      ]
    },
    bookingElementsRejectionReasonButtons() {
      return [
        {
          name: BOOKING_REJECTION_REASON,
          buttons: [
            {
              class: "md-sm md-info md-theme-default",
              text: "UPDATE",
              click: this.handleUpdateBookingRejectionReasonDialog,
            },
          ],
        },
      ]
    },
    promoElementsButtons() {
      return [
        {
          name: PROMO_CODE_NAME,
          buttons: [
            {
              class: "md-info promo-btn",
              text: "UPDATE",
              click: this.handlePromoCodeButtonClick,
            },
          ],
        },
      ]
    },
    bookingItemElements() {
      const visibleElements = this.bookingDetailsList.filter(
        item => item.visible !== "false"
      )
      const elementsWithButtons = visibleElements.map(item => {
        const itemWithButtons = this.bookingElementsButtons.find(
          el => el.name === item.name
        )
        if (itemWithButtons) {
          item.buttons = itemWithButtons.buttons
        }

        const itemWithRejectionButtons = this.bookingElementsRejectionReasonButtons.find(
          el => el.name === item.name
        )
        if (itemWithRejectionButtons) {
          item.buttons = itemWithRejectionButtons.buttons
        }
        const itemPromoWithButtons = this.promoElementsButtons.find(
          el => el.name === item.name
        )
        if (itemPromoWithButtons) {
          item.buttons = itemPromoWithButtons.buttons
        }
        if (!item.value) {
          return item
        }

        //Format datetime
        if (item.type === "Date") {
          //prepare date for Luxon
          const dt = item.value.split(" ")[0]
          item.value = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
        } else if (item.type === "Datetime") {
          //prepare date for Luxon
          const dt = item.value
          item.normalizedValue = DateTime.fromSQL(dt).toFormat(
            "LLLL dd, yyyy HH:mm"
          )
        }
        return item
      })

      return elementsWithButtons
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_DETAILS, BOOKINGS_CLEAR_ITEM]),
    handleUpdateBookingStatusButtonClick() {
      this.isShowUpdateBookingStatusDialog = true
    },
    handleUpdateBookingRejectionReasonDialog() {
      this.isShowBookingRejectionReasonDialog = true
    },
    handlePromoCodeButtonClick() {
      this.isShowBookingPromoCodeDialog = true
    },
    async fetchBookingDetails() {
      const { transId } = this.$route.params
      if (transId) {
        this.isFetching = true
        await this[BOOKINGS_FETCH_ITEM_DETAILS]({
          transId,
        })
        setTimeout(() => {
          this.isFetching = false
        }, 1000)
      }
    },
    handleBackButtonClick() {
      this.$router.replace("/bookings")
    },
    closeUpdateBookingStatusDialog() {
      this.isShowUpdateBookingStatusDialog = false
    },
    closeUpdateRejectionReasonDialog() {
      this.isShowBookingRejectionReasonDialog = false
    },
    closeBookingPromoCodeDialog() {
      this.isShowBookingPromoCodeDialog = false
    },
  },
  mounted() {
    this.fetchBookingDetails()
  },
}
</script>

<style scoped></style>
