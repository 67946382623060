import {
  SITEMAP_URLS_FETCH_URLS_LIST,
  SITEMAP_URLS_FETCH_URLS_LEVEL_LIST,
} from "@/modules/sitemap-urls/store/mutations-type"
import { get as _get } from "lodash"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [SITEMAP_URLS_FETCH_URLS_LEVEL_LIST]({ commit }) {
    const response = await this.$repositories.sitemapUrls.fetchSitemapLevels()
    const data = _get(response, "data.result.csRefDataList", [])
    commit(SITEMAP_URLS_FETCH_URLS_LEVEL_LIST, data)
  },
  async [SITEMAP_URLS_FETCH_URLS_LIST]({ commit }, payload) {
    const response = await this.$repositories.sitemapUrls.fetchSitemapUrls(
      payload
    )
    const data = getGridDataItemFirstCollection(response)
    commit(SITEMAP_URLS_FETCH_URLS_LIST, data)
  },
}

export default actions
