<template>
  <md-dialog
    :md-active.sync="isOpen"
    @md-clicked-outside="closeDialog"
    :style="dialogStyles"
    scrollable
  >
    <md-dialog-title
      ><b>Update Level 2 Item</b>

      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="closeDialog"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <div v-if="isOpen" style="overflow: overlay;">
      <updateItemsSmartNavConfigurationForm
        @onFormSubmit="handleFormSubmit"
      ></updateItemsSmartNavConfigurationForm>
    </div>
  </md-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog.mixin"
import updateItemsSmartNavConfigurationForm from "@/modules/smartNav-configuration/views/components/forms/updateItemsSmartNavConfigurationForm"
export default {
  name: "addItemsSmartNavConfigurationDialog",
  props: {
    isShowDialog: Boolean,
    onDialogClose: Function,
  },
  components: {
    updateItemsSmartNavConfigurationForm,
  },
  mixins: [dialogMixin],
  data: () => ({
    dialogStyles: {
      "max-width": "800px",
    },
  }),
  created() {},
  methods: {
    async handleFormSubmit() {
      this.closeDialog()
    },
  },
}
</script>
