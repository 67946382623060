<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Campsite Extras</h4>
      </div>
    </md-card-header>
    <md-card-content class="input-group">
      <md-field class="label-input">
        <label>Extra label</label>
        <md-input v-model="campsiteLabel" type="text" @keyup.enter="onSearch" />
      </md-field>
      <md-field class="lang-input">
        <label>Choose Lang</label>
        <md-select
          v-model="language"
          name="language"
          id="language"
          @input="setLanguage"
        >
          <md-option
            v-for="langName in supportedLanguages"
            :key="langName"
            :value="langName"
            >{{ langName }}</md-option
          >
        </md-select>
      </md-field>
    </md-card-content>
    <md-card-actions>
      <md-button
        class="md-raised md-success"
        :disabled="loading"
        @click="onSearch"
      >
        Search
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
import { mapState } from "vuex"

import { SMARTNAV_CONFIGURATIONS } from "@/modules/smartNav-configuration/store/mutations-type"

export default {
  name: "CampsiteExtrasSearch",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      language: "EN",
      campsiteLabel: null,
    }
  },

  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
      selectedLanguage: state => state.selectedLanguage,
    }),
  },

  methods: {
    async onSearch() {
      const payload = {
        lang: this.language,
        label: this.campsiteLabel,
      }

      this.$emit("onSearch", payload)
    },

    setLanguage(lang) {
      this.$emit("onLanguageSelect", lang)
    },
  },

  watch: {
    selectedLanguage() {
      this.language = this.selectedLanguage
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  display: flex;

  .label-input {
    max-width: 70%;
    width: 70%;
    margin-right: 16px;
  }
  .lang-input {
    max-width: 30%;
    width: 30%;
  }
}

.md-card {
  .md-card-actions {
    border-top: 0;
  }
}
</style>
