import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from "@/modules/auth/store/mutations-types"

const mutations = {
  [AUTH_LOGIN](state, accessToken) {
    state.token = accessToken
  },
  [AUTH_LOGOUT](state) {
    state.token = null
  },
  [AUTH_INIT](state, payload) {
    const { user, token } = payload

    state.user = user
    state.token = token
  },
}

export default mutations
