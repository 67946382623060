import {
  GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
  GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
  GET_URLS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
  SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION,
  SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION,
  SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION,
  SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION,
  SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION,
  SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION,
  GET_SITEMAP_LEVELS_LIST,
  GET_MOBILE_MENU_STYLES_LIST,
  SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION,
  GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST,
  SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS,
  GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST,
  FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS,
} from "@/modules/smartNav-configuration/store/mutations-type"

const mutations = {
  [GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.levelOneItems = payload
  },
  [GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.levelTwoItems = payload
  },
  [GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.levelTwoCategories = payload
  },
  [GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.itemsAssociatedToLevel = payload
  },
  [GET_URLS_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.urlsList = payload
  },
  [GET_STATIC_SUPPORTED_LANGUAGES_LIST](state, payload) {
    state.supportedLanguages = payload
  },
  [SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION](state, payload) {
    state.levelOneSelectedItem = payload
  },
  [SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION](state, payload) {
    state.levelTwoSelectedItem = payload
  },
  [SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION](state, payload) {
    state.selectedUrlItem = payload
  },
  [SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION](state, payload) {
    state.selectedLanguage = payload
  },
  [SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION](state, payload) {
    state.selectedParent = payload
  },
  [SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION](state, payload) {
    state.level = payload
  },
  [SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION](state) {
    state.levelTwoSelectedItem = {}
  },
  [SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION](state, payload) {
    state.levelTwoDoubleClick = payload
  },
  [SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION](state, payload) {
    state.itemsAssociatedToLevel = payload
  },
  [GET_SITEMAP_LEVELS_LIST](state, payload) {
    state.sitemapLevels = payload
  },
  [GET_MOBILE_MENU_STYLES_LIST](state, payload) {
    state.mobileMenuStyles = payload
  },
  [GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.levelTwoCategoriesRef = payload
  },
  [SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS](state, payload) {
    state.selectedCategory = payload
  },
  [GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST](state, payload) {
    state.categoryGroupList = payload
  },
  [GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST](state, payload) {
    state.categoryGroupRefList = payload
  },
  [FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS](state, payload) {
    state.syncJobs = payload[0]?.dataItems
  },
}
export default mutations
