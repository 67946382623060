export default $axios => resource => ({
  refreshCampsiteCache(stayFacilityId) {
    return $axios.post(`${resource}/util/refreshCache/${stayFacilityId}`, {
      auth: {
        username: process.env.VUE_APP_API_SEARCH_USERNAME,
        password: process.env.VUE_APP_API_SEARCH_PASSWORD,
      },
    })
  },
  flushMemCache(stayFacilityId) {
    return $axios.post(`${resource}/util/flushmemcache/${stayFacilityId}`, {
      auth: {
        username: process.env.VUE_APP_API_SEARCH_USERNAME,
        password: process.env.VUE_APP_API_SEARCH_PASSWORD,
      },
    })
  },
  flushFullMemCache() {
    return $axios.post(`${resource}/util/flushmemcache`, {
      auth: {
        username: process.env.VUE_APP_API_SEARCH_USERNAME,
        password: process.env.VUE_APP_API_SEARCH_PASSWORD,
      },
    })
  },
})
