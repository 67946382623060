<template>
  <div class="app__payment-issues-bookings">
    <div class="md-layout-item">
      <SearchBookingPaymentIssuesForm
        :isFetching="isFetching"
        :initial-form-data="initialSearchBookingsFormData"
        @onFormSubmit="handleFormSubmit"
      />
      <BookingSearchPaymentIssuesTable />
    </div>
  </div>
</template>

<script>
import SearchBookingPaymentIssuesForm from "@/modules/bookings/views/components/forms/SearchBookingPaymentIssuesForm"
import BookingSearchPaymentIssuesTable from "@/modules/bookings/views/components/tables/BookingSearchPaymentIssuesTable"
import {
  BOOKINGS,
  BOOKINGS_FETCH_PAYMENT_ISSUES_LIST,
  BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"

export default {
  name: "SearchBookingsPaymentIssuesPage",
  components: {
    SearchBookingPaymentIssuesForm,
    BookingSearchPaymentIssuesTable,
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    initialSearchBookingsFormData() {
      return this.$store.state[BOOKINGS].searchPaymentIssuesForm
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_PAYMENT_ISSUES_LIST,
      BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM,
    ]),
    async handleFormSubmit(values) {
      this[BOOKINGS_UPDATE_SEARCH_PAYMENT_ISSUES_FORM](values)

      this.isFetching = true
      try {
        await this[BOOKINGS_FETCH_PAYMENT_ISSUES_LIST](values)
        this.isFetching = false
      } catch (e) {
        this.isFetching = false
      }
    },
  },
}
</script>

<style scoped></style>
