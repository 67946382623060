<template>
  <div class="px-5">
    <ProgressSpinner :isProgress="loadingSpecialRegion" />
    <form novalidate class="md-layout mt-4" @submit.prevent>
      <template>
        <md-field class="mb-2">
          <label>Canonical Name</label>
          <md-input
            v-model="selectedSpecialRegion[1].value"
            type="text"
            readonly
            disabled="disabled"
          ></md-input>
        </md-field>
        <md-field class="mb-4">
          <label for="selectedUrl-field">Department Country</label>
          <md-select
            v-model="departmentCountry"
            name="selectedUrl"
            id="selectedUrl-field"
            @md-selected="getCountryDepartments()"
          >
            <md-option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.value"
            >
              {{ country.value }}
            </md-option>
          </md-select>
        </md-field>
        <div class="mb-4 toolbar-field">
          <md-toolbar class="table-width p-0">
            <div class="md-toolbar-row p-0" v-if="departmentList.length">
              <div class="md-toolbar-section-end p-0">
                <div class="md-collapse justify-content-start">
                  <md-list>
                    <md-list-item
                      class="toolbar-border-bottom"
                      :class="{
                        'md-toolbar-row-selected':
                          department.key === entity.key,
                      }"
                      v-for="entity in departmentList"
                      :key="entity.key"
                      :value="entity.value"
                      @click="selectDepartment(entity)"
                    >
                      <p class="md-toolbar-list-text-color m-0">
                        {{ entity.value }}
                      </p>
                    </md-list-item>
                  </md-list>
                </div>
              </div>
            </div>
            <div class="width-100" v-else>
              <p class="text-center md-toolbar-list-text-color m-0">
                Select a country
              </p>
            </div>
          </md-toolbar>
          <div class="p-4">
            <div class="md-layout-item md-size-100 content-right">
              <div
                v-if="department === ''"
                class="mb-2 tooltip-disabled md-raised md-success button-width button-disabled"
              >
                <i class="fas fa-angle-right"></i>
              </div>
              <div
                v-else
                class="mb-2 tooltip md-raised md-success button-width"
                @click="addSpecialRegionsDepartments()"
              >
                <i class="fas fa-angle-right"></i>
                <span class="tooltiptext">Add</span>
              </div>
            </div>
            <div class="md-layout-item md-size-100 content-right">
              <div
                v-if="region === ''"
                class="tooltip-disabled md-raised md-success button-width button-disabled"
              >
                <i class="fas fa-angle-left"></i>
              </div>
              <div
                v-else
                class="tooltip md-raised md-success button-width"
                @click="removeDepartment()"
              >
                <i class="fas fa-angle-left"></i>
                <span class="tooltiptext">Remove</span>
              </div>
            </div>
          </div>
          <md-toolbar
            class="table-width p-0"
            :style="specialRegion.length ? 'flex-flow: nowrap !important;' : ''"
          >
            <div class="md-toolbar-row p-0">
              <div class="md-toolbar-section-end p-0">
                <div class="md-collapse justify-content-start">
                  <md-list>
                    <md-list-item
                      class="toolbar-border-bottom"
                      :class="[
                        {
                          'md-toolbar-row-selected':
                            region.name === entity.name,
                        },
                      ]"
                      v-for="entity in specialRegion"
                      :key="entity.name"
                      :value="entity.name"
                      @click="selectDepartmentToRemove(entity)"
                    >
                      <p class="md-toolbar-list-text-color m-0">
                        {{ entity.name + " (" + entity.country + ")" }}
                      </p>
                    </md-list-item>
                  </md-list>
                </div>
              </div>
            </div>
            <div
              class="width-100"
              v-if="!specialRegion.length && updateButtonPress"
            >
              <p class="text-center md-toolbar-list-text-color-red m-0">
                Department List is required
              </p>
            </div>
          </md-toolbar>
        </div>
        <Collapse
          class="mb-4"
          :collapse="['Name by language']"
          :is-open="false"
          style="width:100%"
        >
          <template slot="md-collapse-pane-1">
            <md-field class="mb-2 mt-4">
              <label>Name EN</label>
              <md-input v-model="canonicalNameEn" type="text"></md-input>
            </md-field>
            <md-field class="mb-2">
              <label>Name FR</label>
              <md-input v-model="canonicalNameFr" type="text"></md-input>
            </md-field>
            <md-field class="mb-2">
              <label>Name IT</label>
              <md-input v-model="canonicalNameIt" type="text"></md-input>
            </md-field>
            <md-field class="mb-2">
              <label>Name DE</label>
              <md-input v-model="canonicalNameDe" type="text"></md-input>
            </md-field>
            <md-field class="mb-4">
              <label>Name NL</label>
              <md-input v-model="canonicalNameNl" type="text"></md-input>
            </md-field>
          </template>
        </Collapse>
        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="updateSpecialRegion()"
          >
            Update
          </md-button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  LANDING_SUPPORTS,
  LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST,
  LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST,
  LANDING_SUPPORTS_UPDATE_SPECIAL_REGION,
  LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID,
} from "@/modules/landing-support/store/mutations-types"
import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import { GEO, GEO_FETCH_COUNTRIES } from "@/modules/geo/store/mutations-types"
import Collapse from "@/views/components/ui/Collapse"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "updateSpecialRegionsForm",
  components: {
    Collapse,
    ProgressSpinner,
  },
  props: [],
  data: () => ({
    loadingSpecialRegion: false,
    canonicalName: "",
    canonicalNameEn: "",
    canonicalNameFr: "",
    canonicalNameIt: "",
    canonicalNameDe: "",
    canonicalNameNl: "",
    departmentCountry: "",
    department: "",
    specialRegion: [],
    departmentList: [],
    lang: "",
    region: "",
    updateButtonPress: false,
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(LANDING_SUPPORTS, {
      fetchSpecialRegionById: LANDING_SUPPORTS_FETCH_SPECIAL_REGION_BY_ID,
      getCountryDepartmentsList: LANDING_SUPPORTS_FETCH_COUNTRY_DEPARTMENT_LIST,
      getSpecialRegionDepartmentsList: LANDING_SUPPORTS_FETCH_DEPARTMENT_SPECIAL_REGION_LIST,
      updateSpecialRegionApi: LANDING_SUPPORTS_UPDATE_SPECIAL_REGION,
    }),
    ...mapActions(GEO, {
      fetchCountriesList: GEO_FETCH_COUNTRIES,
    }),
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
    }),
    //popup initialization with the previously saved data
    async init() {
      this.loadingSpecialRegion = true
      await this.fetchSpecialRegionById(this.selectedSpecialRegion[0].value)
      this.canonicalName = this.selectedSpecialRegionDetail[0].dataItems[0].value
      this.specialRegion = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[2].value
      )
      this.canonicalNameEn = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[1].value
      ).EN
      this.canonicalNameFr = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[1].value
      ).FR
      this.canonicalNameIt = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[1].value
      ).IT
      this.canonicalNameDe = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[1].value
      ).DE
      this.canonicalNameNl = JSON.parse(
        this.selectedSpecialRegionDetail[0].dataItems[1].value
      ).NL
      this.loadingSpecialRegion = false
    },
    //update special Region
    async updateSpecialRegion() {
      this.updateButtonPress = true
      if (!this.specialRegion.length) return
      this.loadingSpecialRegion = true
      let inputData = {
        canonicalName: this.canonicalName,
        departments: this.specialRegion,
        names: [
          {
            value: this.canonicalNameEn,
            lang: "EN",
          },
          {
            value: this.canonicalNameFr,
            lang: "FR",
          },
          {
            value: this.canonicalNameIt,
            lang: "IT",
          },
          {
            value: this.canonicalNameDe,
            lang: "DE",
          },
          {
            value: this.canonicalNameNl,
            lang: "NL",
          },
        ],
      }
      let updateSpecialRegionInput = []
      updateSpecialRegionInput.push(inputData)
      updateSpecialRegionInput.push(this.selectedSpecialRegion[0].value)
      await this.updateSpecialRegionApi(updateSpecialRegionInput)
      this.loadingSpecialRegion = false
      this.$emit("onFormSubmit")
    },
    //Remove Department from Special Region List
    removeDepartment() {
      if (this.region) {
        this.loadingSpecialRegion = true
        const countryListHasDept = this.countryDepartmentList.length
          ? this.countryDepartmentList.filter(
              el => el.value === this.region.name
            )
          : ""
        this.specialRegion = this.specialRegion.filter(
          entity => entity.name !== this.region.name
        )
        if (countryListHasDept.length && this.departmentCountry) {
          let departmentObj = {}
          departmentObj.key = this.region.name
          departmentObj.value = this.region.name
          this.departmentList.push(departmentObj)
          this.departmentList.sort((a, b) => a.value.localeCompare(b.value))
        }
        this.loadingSpecialRegion = false
        this.region = ""
      }
    },
    //Add Department to the Special Region List
    addSpecialRegionsDepartments() {
      if (this.department) {
        this.loadingSpecialRegion = true
        const specialRegionHasDept = this.specialRegion.filter(
          el => el.key === this.department.key
        )
        this.departmentList = this.departmentList.filter(
          entity => entity.key !== this.department.key
        )
        if (specialRegionHasDept) {
          let departmentObj = {}
          departmentObj.name = this.department.value
          departmentObj.country = this.departmentCountry
          this.specialRegion.push(departmentObj)
          this.specialRegion.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.loadingSpecialRegion = false
        this.department = ""
      }
    },
    //Get List of Departments Based on Country
    async getCountryDepartments() {
      this.loadingSpecialRegion = true
      await this.getCountryDepartmentsList(this.departmentCountry)
      this.departmentList = this.countryDepartmentList
      for (let dept of this.specialRegion) {
        this.departmentList = this.departmentList.filter(
          entity => entity.value !== dept.name
        )
      }
      this.loadingSpecialRegion = false
    },
    //set department value
    selectDepartment(entity) {
      this.department = entity
    },
    //set region value
    selectDepartmentToRemove(entity) {
      this.region = entity
    },
  },
  computed: {
    ...mapState(LANDING_SUPPORTS, {
      countryDepartmentList: state => state.countryDepartmentList,
      selectedSpecialRegion: state => state.selectedSpecialRegion,
      selectedSpecialRegionDetail: state => state.selectedSpecialRegionDetail,
    }),
    ...mapState(GEO, {
      countries: state => state.countries,
    }),
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
    }),
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
.table-width {
  width: 35% !important;
  background-color: #fff !important;
}
.button-width {
  width: 110px !important;
}
.md-toolbar-row-selected {
  background: linear-gradient(60deg, #00c8aa, #00c8aa) !important;
  color: #fff !important;
}
.md-toolbar .md-list {
  display: block !important;
}
.md-toolbar-list-text-color {
  color: #000;
}
.md-toolbar-list-text-color-red {
  color: red;
}
.md-toolbar-section-end {
  max-height: 250px;
  overflow: auto;
  display: block;
}
.width-100 {
  width: 100% !important;
}
.md-collapse {
  justify-content: flex-start !important;
  display: block !important;
}
/* .md-toolbar {
  flex-flow: nowrap !important;
} */
.toolbar-border-bottom {
  border-bottom: 1px solid #eee;
}
.md-toolbar-row {
  align-items: flex-start !important;
  align-content: flex-start !important;
  display: inline !important;
}
.toolbar-field {
  display: inline-flex;
  width: 100%;
}
@media (max-width: 991px) {
  .md-toolbar .md-collapse {
    justify-content: flex-start !important;
    display: block !important;
  }
}
@media screen and (max-width: 600px) {
  .table-width {
    width: 100% !important;
  }
  .toolbar-field {
    display: block !important;
  }
  .checkbox-area-sm {
    width: 100% !important;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: #00c8aa !important;
  padding: 9px 30px;
  text-align: center;
  border-radius: 3px;
}
.tooltip-disabled {
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: #00c8aa !important;
  padding: 9px 30px;
  text-align: center;
  border-radius: 3px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.button-disabled {
  opacity: 0.45;
}
</style>
