<template>
  <div class="px-5">
    <ProgressSpinner :isProgress="loadingAdd" />
    <form novalidate class="md-layout mt-4" @submit.prevent>
      <template>
        <md-field v-for="entry in selectedAmenity" :key="entry.value">
          <label>{{ entry.label }}</label>
          <md-input v-model="entry.value" type="text" disabled></md-input>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="closeAmenitiesView()"
          >
            CLOSE
          </md-button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { CAMPSITES } from "@/modules/campsites/store/mutations-types"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "ViewAmenitiesForm",
  components: {
    ProgressSpinner,
  },
  data: () => ({
    label: "",
    loadingAdd: false,
    mobileMenuStyle: "",
    icon: "",
    hasCategories: false,
  }),
  created() {
    this.init()
  },
  methods: {
    // Initialize the Level 2 Items
    async init() {
      this.loadingAdd = true
      try {
        // await this[GET_MOBILE_MENU_STYLES_LIST]()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Mobile Menu Style List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingAdd = false
    },
    // Close Amenities View
    closeAmenitiesView() {
      this.$emit("onFormSubmit")
    },
  },
  computed: {
    ...mapState(CAMPSITES, {
      selectedAmenity: state => state.selectedAmenity,
    }),
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
@media screen and (max-width: 600px) {
  .checkbox-area-sm {
    width: 100% !important;
    flex-basis: 100%;
  }
}
.md-title {
  font-size: 14px !important;
}
</style>
