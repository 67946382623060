<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__logo">
        <img :src="logo" alt="Campsited" />
      </div>
      <div class="footer__copyright">© {{ year }} Campsited.com.</div>
    </div>
  </footer>
</template>
<script>
import logoSrc from "@/assets/images/logo-large-white.svg"

export default {
  name: "ContentFooter",
  computed: {
    logo() {
      return logoSrc
    },
    year() {
      return new Date().getFullYear()
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/md/_colors";

.footer {
  color: #fff;
  background-color: $green-400;
  height: 90px;
}
</style>
