import { render, staticRenderFns } from "./SearchBookingsPage.vue?vue&type=template&id=670dee7e&scoped=true&"
import script from "./SearchBookingsPage.vue?vue&type=script&lang=js&"
export * from "./SearchBookingsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670dee7e",
  null
  
)

export default component.exports