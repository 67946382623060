import { OUTSTANDING_BALANCES_FETCH_LIST } from "@/modules/outstanding-balances/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [OUTSTANDING_BALANCES_FETCH_LIST]({ commit }, params) {
    const response = await this.$repositories.OutstandingBalances.fetchOutstandingBalancesList(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(OUTSTANDING_BALANCES_FETCH_LIST, data)
  },
}

export default actions
