<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Ranking Config</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="normalizedconfigList"
          :export-file-name="rankingConfigList.exportName"
        />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Ranking Config Filter</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="normalizedConfigFilterList"
          :export-file-name="rankingConfigFilterList.exportName"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  RANKING,
  RANKING_FETCH_CONFIG_FILTER_LIST,
  RANKING_FETCH_CONFIG_LIST,
} from "@/modules/ranking/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { normalizeDateInTable } from "@/modules/ranking/utils/ranking-date.utils"
import _ from "lodash"

export default {
  name: "RankingPage",
  components: {
    UniversalTable,
  },
  data() {
    return {
      rankingConfigList: {
        exportName: "Ranking Config",
      },
      rankingConfigFilterList: {
        exportName: "Ranking Config Filter",
      },
    }
  },
  computed: {
    ...mapState(RANKING, {
      configList: state => state.configList,
      configFilterList: state => state.configFilterList,
      normalizedconfigList() {
        if (
          Object.keys(this.configList).length === 0 ||
          !this.configList.rows
        ) {
          return {}
        }

        let data = _.cloneDeep(this.configList)

        data.rows.map(row => {
          row.row.map(item => normalizeDateInTable(item))
        })

        return data
      },
      normalizedConfigFilterList() {
        if (
          Object.keys(this.configFilterList).length === 0 ||
          !this.configFilterList.rows
        ) {
          return {}
        }

        let data = _.cloneDeep(this.configFilterList)

        data.rows.map(row => {
          row.row.map(item => normalizeDateInTable(item))
        })

        return data
      },
    }),
  },
  methods: {
    ...mapActions(RANKING, {
      fetchConfigList: RANKING_FETCH_CONFIG_LIST,
      fetchConfigFilterList: RANKING_FETCH_CONFIG_FILTER_LIST,
    }),
  },
  created() {
    this.fetchConfigList()
    this.fetchConfigFilterList()
  },
}
</script>

<style scoped></style>
