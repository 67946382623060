import {
  RANKING_FETCH_CONFIG_FILTER_LIST,
  RANKING_FETCH_CONFIG_LIST,
} from "@/modules/ranking/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [RANKING_FETCH_CONFIG_FILTER_LIST]({ commit }, params) {
    const response = await this.$repositories.ranking.fetchRankingConfigFilterList(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(RANKING_FETCH_CONFIG_FILTER_LIST, data)
  },
  async [RANKING_FETCH_CONFIG_LIST]({ commit }, params) {
    const response = await this.$repositories.ranking.fetchRankingConfigList(
      params
    )
    const data = getGridDataItemFirstCollection(response)
    commit(RANKING_FETCH_CONFIG_LIST, data)
  },
}

export default actions
