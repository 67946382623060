var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-20 md-medium-size-25 md-small-size-30"},[_c('h4',{staticClass:"items-title md-layout md-alignment-top-center md-title"},[_vm._v(" CS Selected photos ")]),_c('div',{staticClass:"sticky top-items"},[(!_vm.$screen.xxl)?_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-icon-button md-raised icons-top ",class:{
            'md-success': _vm.isChanged,
          },attrs:{"disabled":!_vm.isChanged},on:{"click":_vm.save}},[_c('font-awesome-icon',{attrs:{"icon":'check'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Apply CS Selected photos")])],1),_c('md-button',{staticClass:"md-icon-button md-raised md-success icons-top ",on:{"click":_vm.handleRestore}},[_c('font-awesome-icon',{attrs:{"icon":'undo-alt'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Revert to SH photo order")])],1)],1):_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-button mt-30 button-top",class:{
            'md-success': _vm.isChanged,
          },attrs:{"disabled":!_vm.isChanged},on:{"click":_vm.save}},[_vm._v(" Apply ")]),_c('md-button',{staticClass:"md-button md-success mt-30 button-top",on:{"click":_vm.handleRestore}},[_vm._v(" Restore ")])],1),_c('draggable',{ref:"selectedPhotos",staticClass:"md-gutter md-alignment-center",attrs:{"list":_vm.topItems,"group":"photos","move":_vm.handleMove},on:{"end":_vm.handleTopMoveEnd}},_vm._l((_vm.topItems),function(item,idx){return _c('div',{key:idx,staticClass:"md-layout md-layout-item md-size-100 gallery-item"},[_c('image-component',{attrs:{"item":item,"ready-to-drop":idx === _vm.futureIndex}})],1)}),0)],1)]),(_vm.uploadedPhotos.length >= 1)?[_c('div',{staticClass:"md-layout-item md-size-20 md-medium-size-25 md-small-size-30"},[_c('h4',{staticClass:"items-title md-layout md-alignment-top-center md-title"},[_vm._v(" CS Uploaded Images ")]),_c('div',{staticClass:"sticky top-items"},[(!_vm.$screen.xxl)?_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-icon-button md-raised icons-top ",class:{
              'md-success': _vm.isChangedUploadPhotos,
            },attrs:{"disabled":!_vm.isChangedUploadPhotos},on:{"click":_vm.handleSaveCsUploadedPhotosPosition}},[_c('font-awesome-icon',{attrs:{"icon":'save'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Save positions")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('md-button',{staticClass:"md-icon-button md-raised md-success icons-top",attrs:{"disabled":_vm.uploadingPhoto},on:{"click":_vm.handleUploadImage}},[_c('font-awesome-icon',{attrs:{"icon":'upload'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Upload images")])],1),(_vm.uploadingPhoto)?_c('div',{staticClass:"md-layout md-alignment-center-center",staticStyle:{"position":"absolute","inset":"0","z-index":"6"}},[_c('pulse-loader',{attrs:{"color":"#004cff","size":"10px"}})],1):_vm._e()],1)],1):_c('div',{staticClass:"md-layout buttons-top-items"},[_c('md-button',{staticClass:"md-icon-button md-raised icons-top",class:{
              'md-success': _vm.isChangedUploadPhotos,
            },attrs:{"disabled":!_vm.isChangedUploadPhotos},on:{"click":_vm.handleSaveCsUploadedPhotosPosition}},[_vm._v(" Save ")]),_c('div',{staticStyle:{"position":"relative"}},[_c('md-button',{staticClass:"md-raised md-success icons-top",attrs:{"disabled":_vm.uploadingPhoto},on:{"click":_vm.handleUploadImage}},[_vm._v(" Upload ")]),(_vm.uploadingPhoto)?_c('div',{staticClass:"md-layout md-alignment-center-center",staticStyle:{"position":"absolute","inset":"0"}},[_c('pulse-loader',{staticStyle:{"margin-top":"4px"},attrs:{"color":"#004cff"}})],1):_vm._e()],1)],1),_c('draggable',{staticClass:"md-gutter md-alignment-center",attrs:{"list":_vm.uploadedItems,"group":"photos","move":_vm.handleMoveUploadPhotos},on:{"end":_vm.handleUploadMoveEnd}},_vm._l((_vm.uploadedItems),function(item,idx){return _c('div',{key:idx,staticClass:"md-layout md-layout-item md-size-100 gallery-item",on:{"click":function($event){$event.preventDefault();return _vm.show(item.urlmedium)}}},[_c('image-component',{attrs:{"item":item,"ready-to-drop":idx === _vm.uploadPhotosIndexes.futureIndex},on:{"deleteUploadPhoto":_vm.deleteUploadedPhoto,"changeUploadPhotoStatus":_vm.handleChangeUploadPhotoStatus}})],1)}),0)],1)])]:_vm._e(),(_vm.allItems.length >= 0)?[_c('div',{staticClass:"md-layout-item md-medium-size-75 md-small-size-70",class:{
        'md-size-60': _vm.uploadedPhotos.length,
        'md-size-80': !_vm.uploadedPhotos.length,
      }},[_c('div',{staticClass:"md-layout"},[_c('input',{ref:"files",attrs:{"id":"fileUpload","type":"file","hidden":"","multiple":""},on:{"change":function($event){return _vm.handleFilesUpload()}}}),(!_vm.uploadedPhotos.length)?_c('md-button',{staticClass:"md-success md-icon-button md-raised button-upload",on:{"click":_vm.handleUploadImage}},[_c('font-awesome-icon',{attrs:{"icon":'upload'}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Upload images")])],1):_vm._e(),_c('h4',{staticClass:"items-title md-title"},[_vm._v("Secure Holiday Photos")]),_c('md-button',{staticClass:"md-button md-success mt-30 button-top ml-2",on:{"click":function($event){_vm.showCarousel = true}}},[_vm._v(" View in carousel ")]),_c('md-dialog',{staticClass:"carousel-dialog",attrs:{"md-active":_vm.showCarousel},on:{"update:mdActive":function($event){_vm.showCarousel=$event},"update:md-active":function($event){_vm.showCarousel=$event}}},[_c('ImageCarousel',{attrs:{"items":_vm.allItems}})],1)],1),_c('div',[_c('draggable',{ref:"shPhotos",staticClass:"md-layout",attrs:{"list":_vm.allItems,"group":{ name: 'photos', pull: 'clone', put: false },"move":_vm.handleMove,"handle":".draggable"},on:{"end":_vm.handleDragEnd}},_vm._l((_vm.allItems),function(item,idx){return _c('div',{key:idx,staticClass:"md-layout md-layout-item md-medium-size-25 md-small-size-30 gallery-item",class:{
              'md-size-25': _vm.uploadedPhotos.length,
              'md-size-20': !_vm.uploadedPhotos.length,
            },on:{"click":function($event){$event.preventDefault();return _vm.show(item.urlmedium)}}},[_c('image-component',{attrs:{"editable":"","item":item,"selected":!!item.cs_selected_position},on:{"change":_vm.handleChangePhoto}}),_c('span',[_vm._v(_vm._s(item.caption))])],1)}),0)],1)])]:_vm._e(),_c('viewer',{ref:"viewer",staticClass:"viewer",attrs:{"options":_vm.options,"images":_vm.images},on:{"inited":_vm.inited}},_vm._l((_vm.images),function(src){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:src,attrs:{"src":src}})}),0),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isSave,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isSave=$event},"update:md-active":function($event){_vm.isSave=$event}}},[_c('h4',[_vm._v("CS Selected Photos Successfully saved")])]),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isUploaded,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isUploaded=$event},"update:md-active":function($event){_vm.isUploaded=$event}}},[_c('h4',[_vm._v("CS Uploaded Images Successfully saved")])]),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isRestore,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isRestore=$event},"update:md-active":function($event){_vm.isRestore=$event}}},[_c('h4',[_vm._v("CS Selected Photos reset to SH photo order")])]),_c('md-snackbar',{attrs:{"md-position":_vm.position,"md-duration":_vm.duration,"md-active":_vm.isRestore,"md-persistent":""},on:{"update:mdActive":function($event){_vm.isRestore=$event},"update:md-active":function($event){_vm.isRestore=$event}}},[_c('h4',[_vm._v("CS Selected Photos reset to SH photo order")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }