<template>
  <div v-if="isDataExist">
    <DetailsCollapse
      :is-loading="isFetching"
      :is-open="false"
      :label="storeBookingInvoiceInfo.label"
      :elements="bookingInvoiceElements"
    >
      <div slot="after-content">
        <hr class="hr" />
        <div class="content-right">
          <md-button
            class="md-button md-sm md-info md-theme-default"
            @click="handleUpdateButtonClick"
          >
            Update
          </md-button>
        </div>
      </div>
    </DetailsCollapse>

    <UpdateBookingInvoiceInfoDialog
      :trans-id="transId"
      :is-show-dialog="isUpdateDialogShow"
      :invoice-info="storeBookingInvoiceInfo.dataItems"
      @onDialogClosed="closeUpdateDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_INVOICE_INFO,
} from "@/modules/bookings/store/mutations-types"
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"
import UpdateBookingInvoiceInfoDialog from "@/modules/bookings/views/components/dialogs/UpdateBookingInvoiceInfoDialog"
import { normalizeDateInDetails } from "../../utils/booking-date.utils"
import _ from "lodash"

export default {
  name: "BookingInvoiceInfo",
  props: {
    transId: String,
  },
  components: {
    UpdateBookingInvoiceInfoDialog,
    DetailsCollapse,
  },
  data: () => ({
    isFetching: false,
    isUpdateDialogShow: false,
  }),
  computed: {
    isDataExist() {
      return !!this.storeBookingInvoiceInfo.dataItems
    },
    storeBookingInvoiceInfo() {
      const { invoiceInfo } = this.$store.state[BOOKINGS].item
      return invoiceInfo
    },
    bookingInvoiceElements() {
      const { dataItems } = this.storeBookingInvoiceInfo
      let items = _.cloneDeep(dataItems)
      items = items.map(item => normalizeDateInDetails(item))
      return items
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_INVOICE_INFO]),
    async fetchBookingInvoiceInfo() {
      if (this.transId) {
        this.isFetching = true
        this[BOOKINGS_FETCH_ITEM_INVOICE_INFO]({
          transId: this.transId,
        })
        this.isFetching = false
      }
    },
    handleUpdateButtonClick() {
      this.isUpdateDialogShow = true
    },
    closeUpdateDialog() {
      this.isUpdateDialogShow = false
    },
  },
  mounted() {
    this.fetchBookingInvoiceInfo()
  },
}
</script>

<style scoped></style>
