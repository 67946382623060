import actions from "@/modules/translations/store/actions"
import mutations from "@/modules/translations/store/mutations"

const state = () => ({
  translationsList: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
