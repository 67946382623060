<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Update promo code</md-dialog-title>

      <md-dialog-content>
        <UpdatePromoCodeForm
          :promo-code="promoCode"
          :is-form-sending="isFormSending"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import dialogMixin from "@/mixins/dialog.mixin"
import UpdatePromoCodeForm from "@/modules/promo-codes/views/components/forms/UpdatePromoCodeForm"
import {
  PROMO_CODES,
  PROMO_CODES_UPDATE_ITEM,
} from "@/modules/promo-codes/store/mutations-types"

export default {
  name: "UpdatePromoCodeDialog",
  mixins: [dialogMixin],
  components: {
    UpdatePromoCodeForm,
  },
  props: {
    promoCode: Array,
  },
  data: () => ({
    dialogStyles: {
      "max-width": "800px",
    },
  }),
  methods: {
    ...mapActions(PROMO_CODES, [PROMO_CODES_UPDATE_ITEM]),
    async handleFormSubmit(values) {
      this.isFormSending = true
      try {
        await this[PROMO_CODES_UPDATE_ITEM](values)
        this.isFormSending = false
        this.closeDialog()
      } catch (e) {
        this.isFormSending = false
      }
    },
  },
}
</script>

<style scoped></style>
