export const getRowCellByColumnName = (row, cellKey) => {
  return row.find(item => item.name === cellKey)
}

export const getRowCellsByColumnNames = (row, cellKeys = []) => {
  const result = {}
  cellKeys.forEach(key => {
    const cell = row.find(item => item.name === key)
    if (cell) {
      result[key] = cell
    }
  })
  return result
}
