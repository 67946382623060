export default $axios => resource => ({
  campsitedJobTrigger(params) {
    return $axios.post(`${resource}/job/trigger`, params, {
      auth: {
        username: process.env.VUE_APP_API_JOBS_USERNAME,
        password: process.env.VUE_APP_API_JOBS_PASSWORD,
      },
    })
  },
})
