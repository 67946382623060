<template>
  <md-dialog
    :md-active.sync="isOpen"
    @md-clicked-outside="closeDialog"
    :style="dialogStyles"
    scrollable
  >
    <md-dialog-title class="pl-0 mb-0">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="closeDialog"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <div v-if="isOpen">
      <addCategoriesSmartNavConfigurationForm></addCategoriesSmartNavConfigurationForm>
    </div>
  </md-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog.mixin"
import addCategoriesSmartNavConfigurationForm from "@/modules/smartNav-configuration/views/components/forms/addCategoriesSmartNavConfigurationForm"
export default {
  name: "addCategoriesSmartNavTwoConfigurationDialog",
  props: {
    isShowDialog: Boolean,
    onDialogClose: Function,
  },
  components: {
    addCategoriesSmartNavConfigurationForm,
  },
  mixins: [dialogMixin],
  data: () => ({
    dialogStyles: {
      "max-width": "800px",
      "min-width": "300px",
    },
  }),
}
</script>
