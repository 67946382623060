import actions from "@/modules/reports/store/actions"
import mutations from "@/modules/reports/store/mutations"

const state = () => ({
  reportsList: [],
  reportDetailsMeta: {},
  reportDetailsList: {},
  reportName: "",
  markers: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
