<template>
  <md-card>
    <md-card-content>
      <div
        v-if="dataFetching"
        class="md-layout-item md-size-100 px-0 content-right"
      >
        <md-progress-spinner class="md-primary" md-mode="indeterminate" />
      </div>
      <form v-if="!dataFetching" novalidate @submit.prevent="handleSubmit">
        <div class="row">
          <div v-if="status.value == 'PAYMENT_REQUESTED'">
            <div v-for="(item, index) in detailRows" :key="index" :value="item">
              <div
                v-if="
                  item.name !== 'deposit_to_pay' &&
                    item.name !== 'date_deposit_paid'
                "
              >
                <div class="column">
                  <h6 style="text-transform:Capitalize;">
                    <strong>{{ item.label }}</strong>
                  </h6>
                </div>
                <div class="column">
                  <h6 v-if="item.value.length == 0">-</h6>
                  <h6 v-else>{{ item.value }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="(item, index) in detailRows" :key="index" :value="item">
              <div class="column">
                <h6 style="text-transform:Capitalize;">
                  <strong>{{ item.label }}</strong>
                </h6>
              </div>
              <div class="column">
                <h6 v-if="item.value.length == 0">-</h6>
                <h6 v-else>{{ item.value }}</h6>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 pl-0 py-2"
            v-if="type.value == 'WFA' && status.value == 'PAYMENT_REQUESTED'"
          >
            <md-field
              :class="{
                'md-error md-invalid': amountDepositError && isFormSubmit,
              }"
            >
              <label class="required">Deposit Amount</label>
              <md-input v-model="amountDeposit" type="text">{{
                amountDeposit
              }}</md-input>
              <div class="md-error" v-if="amountDepositError">
                Deposit Amount is required
              </div>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-50 pl-0 py-2"
            v-if="type.value == 'WFA' && status.value == 'PAYMENT_REQUESTED'"
          >
            <md-datepicker
              :md-model-type="String"
              v-model="dateDepositPaid"
              :class="{
                'md-error md-invalid': dateDepositError,
              }"
            >
              <label class="required">Date Deposit Paid</label>
              <div class="md-error" v-if="dateDepositError">
                Date Deposit Paid is required
              </div>
            </md-datepicker>
          </div>
        </div>

        <div class="md-layout-item md-size-100 px-0 content-right">
          <md-button
            v-if="
              type.value == 'WFA' &&
                (status.value == 'SENT' ||
                  status.value == 'CLICKED' ||
                  status.value == 'PAYMENT_REQUESTED' ||
                  status.value == 'CONFIRMED')
            "
            type="submit"
            class="md-raised md-success mt-4"
            @click="handleUpdateStatus"
          >
            {{ fetchText }}
          </md-button>
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="onDialogClose"
          >
            Close
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapActions } from "vuex"
import {
  BOOKINGS,
  BOOKINGS_FETCH_SPECIFIC_PROPOSAL,
  UPDATE_PROPOSAL_STATUS,
} from "@/modules/bookings/store/mutations-types"
import { getItemCollection } from "@/utils/api.utils"

export default {
  name: "ProposalDetail",
  props: {
    onDialogClose: Function,
    editProposalId: String,
  },
  data: () => ({
    specificProposal: null,
    dataFetching: false,
    detailRows: [],
    type: {},
    status: {},
    dateDepositError: "",
    amountDepositError: "",
  }),
  created() {
    this.getSpecificProposal()
  },
  computed: {
    fetchText() {
      if (this.status.value == "SENT" || this.status.value == "CLICKED")
        return "Request payment"
      if (this.status.value == "PAYMENT_REQUESTED") return "Send Confirmation"
      if (this.status.value == "CONFIRMED") return "Send Thank You"
      return "..."
    },
    fetchRequestStatus() {
      if (this.status.value == "SENT" || this.status.value == "CLICKED")
        return "PAYMENT_REQUESTED"
      if (this.status.value == "PAYMENT_REQUESTED") return "CONFIRMED"
      if (this.status.value == "CONFIRMED") return "THANK_YOU"
      return "ERROR"
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_SPECIFIC_PROPOSAL,
      UPDATE_PROPOSAL_STATUS,
    ]),
    async getSpecificProposal() {
      this.dataFetching = true
      await this[BOOKINGS_FETCH_SPECIFIC_PROPOSAL](this.editProposalId)
      this.dataFetching = false
      const response = this.$store.state[BOOKINGS].specificProposal
      const {
        data: {
          result: { dataItemCollectionList },
        },
      } = response

      const [{ dataItems }] = dataItemCollectionList
      this.detailRows = dataItems
      this.type = getItemCollection(this.detailRows, "proposal_type")
      this.status = getItemCollection(this.detailRows, "status")
      this.amountDeposit = getItemCollection(
        this.detailRows,
        "deposit_to_pay"
      ).value
      this.dateDepositPaid = getItemCollection(
        this.detailRows,
        "date_deposit_paid"
      ).value
    },
    async handleSubmit() {},
    async handleUpdateStatus() {
      this.isFormSubmit = true
      let flag = false
      this.isFormValid = true

      if (this.type.value == "WFA" && this.fetchRequestStatus == "CONFIRMED") {
        if (!this.amountDeposit) {
          this.amountDepositError = true
          this.isFormValid = false
          flag = true
        }
        if (!this.dateDepositPaid || this.dateDepositPaid == "") {
          this.dateDepositError = true
          this.isFormValid = false
          flag = true
        }
      }
      if (flag) return

      if (this.isFormValid) {
        let input = {}

        if (this.fetchRequestStatus == "CONFIRMED") {
          input = {
            id: this.editProposalId,
            status: this.fetchRequestStatus,
            amountDeposit: this.amountDeposit,
            dateDeposit: this.dateDepositPaid,
          }
        } else {
          input = {
            id: this.editProposalId,
            status: this.fetchRequestStatus,
          }
        }

        await this[UPDATE_PROPOSAL_STATUS](input)
        this.$emit("onDialogClose", {})
        this.$emit("onFormSubmit")
      }
    },
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.column {
  float: left;
  width: 50%;
  margin-top: -20px;
}
h6 {
  word-break: break-all;
  text-transform: none;
}
.link {
  font-size: 0.75rem;
  font-weight: 500;
  word-break: break-all;
}
</style>
