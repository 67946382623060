export const REPORTS = "REPORTS"

export const REPORTS_FETCH_LIST = "REPORTS_FETCH_LIST"
export const REPORTS_UPDATE_LIST = "REPORTS_UPDATE_LIST"

export const REPORTS_FETCH_DETAILS = "REPORTS_FETCH_DETAILS"
export const REPORTS_UPDATE_DETAILS_LIST = "REPORTS_UPDATE_DETAILS"
export const REPORTS_UPDATE_DETAILS_META = "REPORTS_UPDATE_DETAILS_META"
export const REPORTS_UPDATE_REPORT_NAME = "REPORTS_UPDATE_REPORT_NAME"
export const REPORTS_UPDATE_MAP_MARKERS = "REPORTS_UPDATE_MAP_MARKERS"
