<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Create feed job</md-dialog-title>

      <md-dialog-content>
        <CreateFeedJobForm
          :is-form-sending="isFormSending"
          :on-dialog-close="closeDialog"
          @form-submit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import CreateFeedJobForm from "@/modules/feed-jobs/views/components/forms/CreateFeedJobForm"
import dialogMixin from "@/mixins/dialog.mixin"
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  FEED_JOBS_ADD_ITEM,
} from "@/modules/feed-jobs/store/mutations-types"
export default {
  name: "CreateFeedJobDialog",
  mixins: [dialogMixin],
  components: {
    CreateFeedJobForm,
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      addItem: FEED_JOBS_ADD_ITEM,
    }),
    showAlert(name) {
      this.$swal(name)
    },
    async handleFormSubmit(values) {
      this.isFormSending = true
      try {
        await this.addItem(values)
        if (this.errorsList.status === "NOT_FOUND") {
          this.showAlert(this.errorsList.errors.message)
          this.closeDialog()
        } else if (this.errorsList.status === "NOT_ALLOWED") {
          this.showAlert(this.errorsList.errors.message)
          this.closeDialog()
        } else if (this.errorsList.status === "OK") {
          this.showAlert("The item has been successfully added ")
          this.closeDialog()
        }
        this.closeDialog()
      } catch (e) {
        this.isFormSending = false
      }
    },
  },
  computed: {
    ...mapState(FEED_JOBS, {
      errorsList: state => state.errorsList,
    }),
  },
}
</script>

<style scoped></style>
