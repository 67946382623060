<template>
  <div>
    <div class="md-layout-item md-size-100 px-0 content-right">
      <md-button
        type="submit"
        class="md-raised md-success mt-4"
        @click="openAddProposalModal()"
      >
        Add Proposal
      </md-button>
    </div>

    <AddProposalDialog
      :is-show-dialog="showAddProposalModal"
      @onDialogClosed="closeCreateDialog"
    />

    <DetailProposalDialog
      :is-show-dialog="showViewProposalModal"
      :edit-proposal-id="this.editProposalId"
      @onDialogClosed="closeDetailDialog"
    />

    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Search Proposals</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <form novalidate class="md-layout" @submit.prevent>
          <div class="md-layout-item md-size-30 pl-0">
            <md-field>
              <label>Proposal ID</label>
              <md-input
                type="text"
                v-model="searchId"
                @keypress.enter="searchProposal()"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-30 pl-0">
            <md-field>
              <label>Email</label>
              <md-input
                type="text"
                v-model="searchEmail"
                @keypress.enter="searchProposal()"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-20 pr-0">
            <md-field>
              <label>Agent</label>
              <md-input
                type="text"
                v-model="searchAgent"
                @keypress.enter="searchProposal()"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-20 pr-0" v-if="isLoadType">
            <md-field>
              <label>Proposal Type</label>
              <md-select
                v-model="searchType"
                name="proposal-type"
                id="proposal-type-field"
              >
                <md-option
                  v-for="(type, index) in types"
                  :key="index"
                  :value="type"
                >
                  {{ type }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 px-0 content-right">
            <md-button
              class="md-raised md-success mt-4"
              @click="searchProposal()"
            >
              Search
            </md-button>
          </div>
        </form>
      </md-card-content>
    </md-card>

    <md-card :key="renderKey">
      <md-card-content>
        <div class="md-layout-item md-size-100 px-0 content-top-right">
          <md-button
            class="md-raised md-success mt-4"
            @click="resetTableData()"
          >
            All Proposals
          </md-button>
        </div>
        <div v-if="dataFetching" class="text-center p-5">
          <md-progress-spinner class="md-primary" md-mode="indeterminate" />
        </div>
        <UniversalTable
          v-if="!dataFetching"
          :export-file-name="tableExportFileName"
          :data="tempSearchProposals"
          @navigate-to-row-item="openViewProposalPopup"
          emptyTitleText="No proposals to show"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <div v-if="!!selectedRow">
              <md-button
                class="md-info md-sm"
                @click="openViewProposalModal(selectedRow)"
              >
                View details
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import _ from "lodash"

import {
  BOOKINGS,
  BOOKINGS_FETCH_PROPOSALS,
  BOOKINGS_FETCH_TYPE,
} from "@/modules/bookings/store/mutations-types"

import dialogMixin from "@/mixins/dialog.mixin"
import AddProposalDialog from "@/modules/bookings/views/pages/AddProposalDialog"
import DetailProposalDialog from "@/modules/bookings/views/pages/DetailProposalDialog"
import UniversalTable from "@/views/components/tables/UniversalTable"

export default {
  name: "BookingProposal",
  components: {
    AddProposalDialog,
    DetailProposalDialog,
    UniversalTable,
  },
  mixins: [dialogMixin],
  data: () => ({
    tableExportFileName: "booking_proposals",
    tempSearchProposals: {},
    proposals: [],
    showAddProposalModal: false,
    showViewProposalModal: false,
    searchEmail: null,
    searchAgent: null,
    searchId: null,
    searchType: "Consumer",
    editProposalId: null,
    renderKey: 0,
    dataFetching: false,
    isLoadType: false,
  }),
  computed: {
    ...mapState(BOOKINGS, {
      types: state => state.typeList,
    }),
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_PROPOSALS, BOOKINGS_FETCH_TYPE]),
    openAddProposalModal() {
      this.showAddProposalModal = true
    },
    openViewProposalModal(selectedRow) {
      this.editProposalId = selectedRow[0].value
      this.showViewProposalModal = true
    },
    async closeCreateDialog() {
      this.showAddProposalModal = false
      await this.getBookingProposals()
    },
    async closeDetailDialog() {
      this.showViewProposalModal = false
      this.proposals = []
      await this.getBookingProposals()
    },
    async getBookingProposals() {
      this.dataFetching = true
      let originalStoreProposals = null
      await this[BOOKINGS_FETCH_PROPOSALS]({})
      originalStoreProposals = this.$store.state[BOOKINGS].bookingProposals
      const sortedProposals = _.orderBy(
        originalStoreProposals.rows,
        proposal => parseInt(proposal.row[0].value),
        ["desc"]
      )
      originalStoreProposals.rows = sortedProposals
      this.tempSearchProposals = originalStoreProposals
      this.proposals = { ...this.tempSearchProposals }
      this.dataFetching = false
    },
    searchProposal() {
      this.dataFetching = true

      if (
        this.searchEmail ||
        this.searchAgent ||
        this.searchId ||
        this.searchType
      ) {
        this.tempSearchProposals.rows = this.proposals.rows.filter(proposal => {
          const id = this.getColumnValue(proposal.row, "id")
          const agent = this.getColumnValue(proposal.row, "agent")
          const email = this.getColumnValue(proposal.row, "email_address")
          const type = this.getColumnValue(proposal.row, "proposal_type")

          return (
            (this.searchEmail ? email.includes(this.searchEmail) : true) &&
            (this.searchAgent ? agent.includes(this.searchAgent) : true) &&
            (this.searchId ? id.includes(this.searchId) : true) &&
            (this.searchType ? type.includes(this.searchType) : true)
          )
        })
      } else {
        this.tempSearchProposals = { ...this.proposals }
      }

      setTimeout(() => {
        this.dataFetching = false
      }, 1500)

      this.renderKey++
    },
    resetTableData() {
      this.searchEmail = null
      this.searchAgent = null
      this.searchId = null
      this.searchType = "Consumer"
      this.tempSearchProposals = { ...this.proposals }
    },
    openViewProposalPopup({ row }) {
      this.editProposalId = row[0].value
      this.showViewProposalModal = true
    },
    async getProposalTypes() {
      await this[BOOKINGS_FETCH_TYPE]([])
      this.isLoadType = true
    },
    getColumnValue(arr, columnName) {
      const columnObj = arr.find(obj => obj.name === columnName)
      return columnObj.value
    },
  },
  created() {
    this.getBookingProposals()
    this.getProposalTypes()
  },
}
</script>

<style scoped></style>
