export default $axios => resource => ({
  fetchRankingConfigList(params) {
    return $axios.get(`${resource}/ranking/campsiterankingconfiglist`, {
      params,
    })
  },
  fetchRankingConfigFilterList(params) {
    return $axios.get(`${resource}/ranking/campsiterankingconfigfilterlist`, {
      params,
    })
  },
})
