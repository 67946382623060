<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Search Bookings</h4>
      </div>
    </md-card-header>
    <md-card-content>
      <form novalidate class="md-layout" @submit.prevent="handleSubmit">
        <div
          class="md-layout-item md-size-50 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-3 pl-lg-0"
        >
          <md-field>
            <label>SH Reference</label>
            <md-input v-model="shReference" type="text"></md-input>
          </md-field>

          <md-field>
            <label for="booking-status">Booking status</label>
            <md-select
              v-model="bookingStatuses"
              name="booking-status"
              id="booking-status"
              multiple
            >
              <md-option
                v-for="option in searchStatuses"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </md-option>
            </md-select>
          </md-field>

          <md-datepicker :md-model-type="String" v-model="startDate">
            <label>Created from</label>
          </md-datepicker>
          <md-field>
            <label>Campsite Id</label>
            <md-input v-model="campsiteId" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Customer phone number</label>
            <md-input v-model="customerPhoneNum" type="text"></md-input>
          </md-field>
          <md-checkbox v-model="includeExtendedData">
            Include extended data
          </md-checkbox>
        </div>

        <div
          class="md-layout-item md-size-50 md-small-size-100 mb-xs-4 mb-sm-0 px-0 pr-lg-0 pl-lg-3"
        >
          <md-field>
            <label>Customer Reference</label>
            <md-input v-model="campsitedReference" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Transaction id</label>
            <md-input v-model="transId" type="text"></md-input>
          </md-field>
          <md-datepicker :md-model-type="String" v-model="endDate">
            <label>Created to</label>
          </md-datepicker>
          <md-field>
            <label>Customer email</label>
            <md-input v-model="customerEmail" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Customer last name</label>
            <md-input v-model="customerLastName" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100 px-0 content-right mt-4">
          <md-button class="md-raised md-success" @click="handleGoIssuesPage">
            Payment Issues Page
          </md-button>
          <md-button
            class="md-button md-theme-default md-simple"
            @click="handleClearButtonClick"
          >
            CLEAR
          </md-button>
          <md-button
            type="submit"
            class="md-raised md-success"
            :disabled="isFetching"
          >
            Search
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</template>

<script>
import { isEmpty as _isEmpty } from "lodash"
import { mapActions } from "vuex"
import { DateTime } from "luxon"

import {
  BOOKINGS,
  BOOKINGS_FETCH_STATUSES,
} from "@/modules/bookings/store/mutations-types"
import { toISODate } from "@/utils/date.utils"

const initialFieldsData = {
  transId: "",
  bookingStatuses: [],
  startDate: null,
  endDate: null,
  shReference: "",
  campsitedReference: "",
  customerEmail: "",
  customerPhoneNum: "",
  campsiteId: "",
  customerLastName: "",
  includeExtendedData: false,
}

export default {
  name: "SearchBookingsForm",
  props: {
    isFetching: Boolean,
    onFormSubmit: Function,
    initialFormData: Object,
  },
  data: () => ({
    ...initialFieldsData,
  }),
  computed: {
    initialSearchStatus() {
      return this.firstSearchStatus || ""
    },
    firstSearchStatus() {
      const firstStatus = this.searchStatuses[0]
      return firstStatus?.key
    },
    searchStatuses() {
      return this.$store.state[BOOKINGS].item.statuses
    },
  },
  watch: {
    searchStatuses() {
      this.bookingStatuses = [this.firstSearchStatus]
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_STATUSES]),
    setInitialSearchStatuses() {
      this.bookingStatuses = [this.initialSearchStatus]
    },
    initFormData() {
      const formData = this.initialFormData
      this.setInitialSearchStatuses()

      if (_isEmpty(formData)) return
      Object.keys(formData).forEach(key => {
        if (["startDate", "endDate"].includes(key)) {
          this[key] = DateTime.fromISO(formData[key]).toFormat("LLLL dd, yyyy")
        } else {
          this[key] = formData[key]
        }
      })
    },
    handleSubmit() {
      if (!this.bookingStatuses.toString()) {
        this.setInitialSearchStatuses()
      }

      this.$emit("onFormSubmit", {
        transId: this.transId,
        bookingStatusList: this.bookingStatuses.toString(),
        startDate: this.startDate ? toISODate(this.startDate) : "",
        endDate: this.endDate ? toISODate(this.endDate) : "",
        shReference: this.shReference,
        campsitedReference: this.campsitedReference,
        customerEmail: this.customerEmail,
        customerLastName: this.customerLastName,
        customerPhoneNum: this.customerPhoneNum,
        campsiteId: this.campsiteId,
        includeExtendedData: this.includeExtendedData,
      })
    },
    handleClearButtonClick() {
      Object.keys(initialFieldsData).forEach(initFieldKey => {
        this[initFieldKey] = initialFieldsData[initFieldKey]
      })
    },
    fetchBookingsSearchStatuses() {
      const { statuses } = this.$store.state[BOOKINGS].item

      if (statuses.length === 0) {
        this[BOOKINGS_FETCH_STATUSES]()
      }
    },
    handleGoIssuesPage() {
      this.$router.push("/booking_issues")
    },
  },
  created() {
    this.initFormData()
    this.fetchBookingsSearchStatuses()

    this.$material.locale.dateFormat = "LLLL dd, yyyy"
  },
}
</script>

<style>
.md-select-menu .md-list-item .md-list-item-content span {
  left: 25px !important;
}
</style>
