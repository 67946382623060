export const FEED_JOBS = "FEED_JOBS"

export const FEED_JOBS_FETCH_LIST = "FEED_JOBS_FETCH_LIST"
export const FEED_JOBS_FETCH_ITEMS = "FEED_JOBS_FETCH_ITEMS"

export const FEED_JOBS_ADD_ITEM = "FEED_JOBS_ADD_ITEM"
export const FEED_JOBS_DELETE_ITEM = "FEED_JOBS_DELETE_ITEM"
export const FEED_JOBS_SET_SELECTED_JOB = "FEED_JOBS_SET_SELECTED_JOB"
export const FEED_JOBS_FETCH_ITEM_TYPES_LIST = "FEED_JOBS_FETCH_ITEM_TYPES_LIST"

export const CAMPSITES_UPLOAD_CSV_FILE = "CAMPSITES_UPLOAD_CSV_FILE"

export const FEED_JOBS_FETCH_JOB_NAMES_LIST = "FEED_JOBS_FETCH_JOB_NAMES_LIST"
export const FEED_JOBS_FETCH_ERRORS_LIST = "FEED_JOBS_FETCH_ERRORS_LIST"
