<template>
  <md-dialog
    :md-active.sync="isOpen"
    @md-clicked-outside="closeDialog"
    :style="dialogStyles"
  >
    <md-dialog-title>Update Campsite Details</md-dialog-title>

    <md-dialog-content>
      <UpdateCampsiteForm
        :is-form-sending="isFormSending"
        :actualStatus="status"
        :actualGroupName="groupName"
        :actualEmailAddress="emailAddress"
        :onDialogClose="closeDialog"
        :disableReason="disableReason"
        :comments="comments"
        :ranking="ranking"
        :actualDescription="description"
        :shProperty="shProperty"
        @onFormSubmit="handleUpdateFormSubmit"
      />
    </md-dialog-content>
  </md-dialog>
</template>

<script>
import UpdateCampsiteForm from "@/modules/campsites/views/components/forms/UpdateCampsiteForm"
import { mapActions, mapState } from "vuex"

import {
  CAMPSITES,
  CAMPSITES_UPDATE_DETAIL,
} from "@/modules/campsites/store/mutations-types"

import dialogMixin from "@/mixins/dialog.mixin"
export default {
  name: "UpdateCampsiteDialog",
  components: { UpdateCampsiteForm },
  props: {
    campsiteStatus: String,
    campsiteDetail: null,
  },
  data: () => ({
    statusPaymentFormData: {},
    groupName: "",
    emailAddress: "",
    status: "",
    disableReason: "",
    comments: "",
    ranking: "",
    description: "",
    shProperty: false,
    disableReasonListPluck: [],
  }),
  mixins: [dialogMixin],
  computed: {
    ...mapState(CAMPSITES, {
      dataStatusItem: state => state.dataStatusItem,
    }),
  },
  methods: {
    ...mapActions(CAMPSITES, {
      updateCampsiteDetail: CAMPSITES_UPDATE_DETAIL,
    }),
    async handleUpdateFormSubmit(values) {
      this.isFormSending = true
      this.isFormSending = false

      await this.updateCampsiteDetail({
        campsiteId: this.$route.params.id,
        status: values.campsitesStatus,
        disableReasonCode: values.disableReason,
        comments: values.comments,
        ranking: values.ranking,
        group: values.group,
        email: values.email,
        description: values.description,
      })
      this.closeDialog()
    },
    init() {
      this.disableReasonListPluck["No Availability"] = "NO_AVAILABILITY"
      this.disableReasonListPluck["Not Accepting Bookings"] =
        "NOT_ACCEPTING_BOOKINGS"
    },
    getDetailValue(arr, detailName) {
      const detailObj = arr.find(obj => obj.name === detailName)
      return detailObj?.value || null
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    isShowDialog(val) {
      // ? check dialog is visble
      if (!val) return

      // ? check campsiteDetail is not empty
      if (Object.keys(this.campsiteDetail).length === 0) {
        console.warn("campsiteDetail Object is empty")
        return
      }

      // ? assign values to their data properties
      // * for form input fields
      const campsitesStatus = this.getDetailValue(this.campsiteDetail, "status")
      this.status = campsitesStatus.toUpperCase()

      this.groupName = this.getDetailValue(
        this.campsiteDetail,
        "property_group"
      )
      this.emailAddress = this.getDetailValue(
        this.campsiteDetail,
        "email_address"
      )
      this.ranking = this.getDetailValue(this.campsiteDetail, "ranking")
      this.description = this.getDetailValue(this.campsiteDetail, "description")

      // * for other
      const isShProperty = this.getDetailValue(
        this.campsiteDetail,
        "sh_stay_facility"
      )
      this.shProperty = isShProperty === "true"

      const reason = this.getDetailValue(this.campsiteDetail, "disabled_reason")
      if (this.disableReasonListPluck[reason] !== undefined) {
        this.disableReason = this.disableReasonListPluck[reason]
      } else {
        this.disableReason = "OTHER"
        this.comments = reason
      }
    },
  },
}
</script>

<style scoped></style>
