<template>
  <div>
    <md-card>
      <ProgressSpinner :isProgress="loading" />
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Sync Job Executions</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="normalizedSyncJobsList"
          :export-file-name="dataExportFileName"
          @onRowSelect="handleRowSelect"
        >
          <template slot="header-right-controls" slot-scope="{ selectedRow }">
            <md-button
              v-if="selectedRow"
              class="md-info md-sm level-two-buttons"
              @click="viewJobItems"
            >
              View Job Items
            </md-button>
          </template>
        </UniversalTable>
      </md-card-content>
    </md-card>
    <md-card v-if="isJobItem">
      <ProgressSpinner :isProgress="loadingJobDetail" />
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Job Items</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable :data="jobItems" :export-file-name="dataExportFileName">
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  SYNC_JOBS,
  SYNC_JOBS_FETCH_LIST,
  SYNC_JOBS_ITEMS_FETCH_LIST,
} from "@/modules/sync-jobs/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { normalizeDateInTable } from "@/modules/sync-jobs/utils/sync-jobs-date.utils"
import _ from "lodash"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"

export default {
  name: "SyncJobs",
  components: {
    UniversalTable,
    ProgressSpinner,
  },
  data() {
    return {
      syncJob: null,
      dataExportFileName: "Sync Job Executions",
      loading: false,
      loadingJobDetail: false,
      isJobItem: false,
      selectedJobItem: {},
    }
  },
  async mounted() {
    this.mount()
  },
  computed: {
    ...mapState(SYNC_JOBS, {
      items: state => state.allSyncJobList,
      jobItems: state => state.jobItems,
    }),
    // Normalized Sync Jobs List
    normalizedSyncJobsList() {
      if (Object.keys(this.items).length === 0 || !this.items.rows) {
        return {}
      }

      let data = _.cloneDeep(this.items)

      data.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return data
    },
  },
  methods: {
    ...mapActions(SYNC_JOBS, {
      syncJobsList: SYNC_JOBS_FETCH_LIST,
      fetchJobItems: SYNC_JOBS_ITEMS_FETCH_LIST,
    }),
    handleRowSelect(selectedRow) {
      this.selectedJobItem = selectedRow ? selectedRow : ""
      if (!this.selectedJobItem) this.isJobItem = false
    },
    viewJobItems() {
      const jobId = this.selectedJobItem.find(item => item.name === "job_id")
        ?.value
      this.fetchJobItems(jobId)
      this.isJobItem = true
    },
    // Mount each time code
    async mount() {
      this.loading = true
      try {
        await this.syncJobsList()
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Sync Jobs List",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
