<template>
  <div class="app__search-bookings">
    <div class="md-layout-item">
      <SearchBookingsForm
        :isFetching="isFetching"
        :initial-form-data="initialSearchBookingsFormData"
        @onFormSubmit="handleFormSubmit"
      />
      <BookingsSearchTable />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import SearchBookingsForm from "@/modules/bookings/views/components/forms/SearchBookingsForm"
import BookingsSearchTable from "@/modules/bookings/views/components/tables/BookingsSearchTable"

import {
  BOOKINGS,
  BOOKINGS_FETCH_LIST,
  BOOKINGS_UPDATE_SEARCH_FORM,
} from "@/modules/bookings/store/mutations-types"

export default {
  name: "SearchBookingsPage",
  components: {
    SearchBookingsForm,
    BookingsSearchTable,
  },
  data: () => ({
    isFetching: false,
  }),

  computed: {
    initialSearchBookingsFormData() {
      return this.$store.state[BOOKINGS].searchForm
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_LIST, BOOKINGS_UPDATE_SEARCH_FORM]),
    async handleFormSubmit(values) {
      this[BOOKINGS_UPDATE_SEARCH_FORM](values)

      this.isFetching = true
      try {
        await this[BOOKINGS_FETCH_LIST](values)
        this.isFetching = false
      } catch (e) {
        this.isFetching = false
        this.$swal.fire({
          position: "bottom-end",
          html: e,
          icon: "error",
          title: "Search Bookings",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
  },
}
</script>

<style scoped></style>
