<template>
  <md-card>
    <md-card-content>
      <UniversalTable
        :data="campsitesList"
        :export-file-name="dataExportFileName"
        :empty-title-text="emptySearchTableTitle"
        @navigate-to-row-item="navigateToTowItem"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <div v-if="!!selectedRow">
            <md-button
              class="md-info md-sm"
              @click="handleDetailsButtonClick(selectedRow)"
            >
              View details
              <md-icon>keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </template>
      </UniversalTable>
    </md-card-content>
  </md-card>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import { CAMPSITES } from "@/modules/campsites/store/mutations-types"
import { getRowCellByColumnName } from "@/utils/table.utils"

export default {
  name: "SearchCampsitesTable",
  components: {
    UniversalTable,
  },
  data: () => ({
    dataExportFileName: "campsites",
    emptySearchTableTitle: "Campsites table is empty",
  }),
  computed: {
    campsitesList() {
      return this.$store.state[CAMPSITES].list
    },
  },
  methods: {
    handleDetailsButtonClick(selectedRow) {
      const campsiteIdCell = getRowCellByColumnName(selectedRow, "campsite_id")
      const campsiteId = campsiteIdCell.value

      this.$router.push(`/campsites/${campsiteId}`)
    },
    navigateToTowItem({ row }) {
      const campsiteIdCell = getRowCellByColumnName(row, "campsite_id")
      const campsiteId = campsiteIdCell.value

      this.$router.push(`/campsites/${campsiteId}`)
    },
  },
}
</script>

<style lang="scss" scoped></style>
