<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-green">
      <div class="card-text">
        <h4 class="title">Search promo codes</h4>
      </div>
    </md-card-header>
    <md-card-content>
      <form novalidate class="md-layout" @submit.prevent="handleSubmit">
        <div
          class="md-layout-item md-size-33 md-medium-size-50 md-small-size-100 mb-4 p-0 pr-lg-3"
        >
          <md-field>
            <label>Code label</label>
            <md-input v-model="code" type="text"></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-33 md-medium-size-50 md-small-size-100 mb-4 p-0 pl-lg-3 pr-lg-0 pr-xl-3"
        >
          <md-datepicker :md-model-type="String" v-model="validFrom">
            <label>Valid from</label>
          </md-datepicker>
        </div>
        <div
          class="md-layout-item md-size-33 md-medium-size-50 md-small-size-100 mb-4 p-0 pl-lg-0 pl-xl-3 pr-lg-3 pr-xl-0"
        >
          <md-datepicker :md-model-type="String" v-model="validTo">
            <label>Valid to</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-size-100 px-0 pr-0 content-right">
          <md-button
            type="submit"
            class="md-raised md-success"
            :disabled="isFetching"
          >
            Search
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "SearchPromoCodesForm",
  props: {
    isFetching: Boolean,
    onFormSubmit: Function,
  },
  created() {
    this.$material.locale.dateFormat = "LLLL dd, yyyy"
  },
  data: () => ({
    code: "",
    validFrom: new Date("2020-09-01"),
    validTo: new Date("2020-12-01"),
  }),
  methods: {
    handleSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit("onFormSubmit", {
          promoCodeLabel: this.code,
          validFrom: this.validFrom,
          validTo: this.validTo,
        })
      }
    },
  },
  validations: {},
}
</script>

<style scoped>
.row {
  margin: 0 -15px;
  width: calc(100% + 30px);
}
</style>
