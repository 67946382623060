import { TRANSLATIONS_FETCH_TRANSLATE_LIST } from "@/modules/translations/store/mutations-type"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [TRANSLATIONS_FETCH_TRANSLATE_LIST]({ commit }) {
    const response = await this.$repositories.translations.fetchTranslationsList()
    const data = getGridDataItemFirstCollection(response)
    commit(TRANSLATIONS_FETCH_TRANSLATE_LIST, data)
  },
}

export default actions
