<template>
  <div
    class="image-wrapper"
    :class="wrapperClasses"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div
      @resize="resize"
      ref="item"
      class="image"
      :style="`background-image: url('${item.url}');`"
      :class="imageClasses"
    />

    <font-awesome-icon
      v-show="isUpload"
      class="actions trash"
      :icon="'trash'"
      @click.stop="deleteUploaded"
    />

    <font-awesome-icon
      v-show="hovered & !item.cs_selected_position"
      class="preview"
      :icon="'search'"
    />

    <font-awesome-icon
      v-if="editable"
      class="actions status"
      :icon="isActive ? 'eye' : 'eye-slash'"
      @click.stop="changeStatus"
    />
    <font-awesome-icon
      v-show="isDuplicate"
      class="actions status"
      :icon="'copy'"
    />
    <font-awesome-icon
      v-show="isUpload"
      class="actions status"
      :icon="isActive ? 'eye' : 'eye-slash'"
      @click.stop="updateStatusUploadedPhoto"
    />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    readyToDrop: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    }
  },
  mounted() {
    window.addEventListener("resize", this.resize)
    this.resize()
  },
  computed: {
    isDuplicate() {
      return `${this.item.duplicate}` === "true"
    },
    isUpload() {
      return `${this.item.isUpload}` === "true"
    },
    isActive() {
      return !this.isDuplicate && `${this.item.active}` === "true"
    },
    imageClasses() {
      return {
        draggable: this.isActive,
        active: this.isActive,
      }
    },
    wrapperClasses() {
      return {
        "ready-to-drop": this.readyToDrop,
        selected: this.selected,
      }
    },
  },
  methods: {
    resize() {
      try {
        const height = (this.$refs.item.clientWidth / 16) * 9
        this.$refs.item.style.height = `${height}px`
      } catch (e) {
        console.error("error", e)
      }
    },
    changeStatus() {
      this.$emit("change", {
        ...this.item,
        active: !this.isActive,
      })
    },
    updateStatusUploadedPhoto() {
      this.$emit("changeUploadPhotoStatus", {
        ...this.item,
        active: !this.isActive,
      })
    },
    deleteUploaded() {
      this.$emit("deleteUploadPhoto", this.item)
    },
  },
}
</script>

<style scoped>
.image-wrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  filter: brightness(35%);
}

.preview {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: white;
  font-size: 25px;
  text-shadow: 0 0 2px black;
}

.actions {
  position: absolute;
  color: white;
  font-size: 25px;
}
.status {
  top: 10%;
  right: 10%;
}

.ready-to-drop {
  box-shadow: 0 0 22px #ff0000;
}

.trash {
  top: 10%;
  left: 10%;
  color: red;
}

.active {
  filter: brightness(100%);
}

.selected {
  opacity: 0.3;
}
</style>
