export const PRODUCT_CONFIGURATIONS = "PRODUCT_CONFIGURATIONS"

export const PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST =
  "PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST"
export const UPDATE_PRODUCT_CONFIGURATION = "UPDATE_PRODUCT_CONFIGURATION"
export const GET_PRODUCT_CONFIGURATION = "GET_PRODUCT_CONFIGURATION"
export const SET_IS_PRODUCT_CONFIGURATION = "SET_IS_PRODUCT_CONFIGURATION"
export const GET_PRODUCT_CONFIGURATION_LANGUAGE =
  "GET_PRODUCT_CONFIGURATION_LANGUAGE"
export const GET_PRODUCT_CONFIGURATION_PRODUCT_KEY =
  "GET_PRODUCT_CONFIGURATION_PRODUCT_KEY"
export const REFRESH_PRODUCT_CONFIGURATION_CACHE =
  "REFRESH_PRODUCT_CONFIGURATION_CACHE"
