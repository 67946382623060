import actions from "@/modules/support/store/actions"
import mutations from "@/modules/support/store/mutations"

const state = () => ({
  supportList: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
