export default $axios => resource => ({
  fetchCountryDestinationsList(params) {
    return $axios.get(`${resource}/landingsupport/countrydestinations`, {
      params,
    })
  },
  fetchRegionDestinationsList(params) {
    return $axios.get(`${resource}/landingsupport/regiondestinations`, {
      params,
    })
  },
  fetchDepartmentDestinationsList(params) {
    return $axios.get(`${resource}/landingsupport/departmentdestinations`, {
      params,
    })
  },
  fetchCampingThemesList() {
    return $axios.get(`${resource}/landingsupport/campingthemes`)
  },
  fetchSpecialRegionsList() {
    return $axios.get(`${resource}/landingsupport/specialregions`)
  },
  fetchSpecialRegionById(id) {
    return $axios.get(`${resource}/landingsupport/specialregions/${id}`)
  },
  fetchDepartmentsCountryList(country) {
    return $axios.get(
      `${resource}/landingsupport/departmentsforcountry?country=${country}`
    )
  },
  fetchSpecialRegionsforDepartmentList(department) {
    return $axios.get(
      `${resource}/landingsupport/departmentsforspecialregion?specialRegion=${department}`
    )
  },
  addSpecialRegion(data) {
    return $axios.post(`${resource}/landingsupport/specialregions `, data)
  },
  updateSpecialRegion(data) {
    return $axios.put(
      `${resource}/landingsupport/specialregions/${data.pop()}`,
      data.pop()
    )
  },
})
