<template>
  <div>
    <GoBackBar @onGoBack="handleGoBack" :back-button-text="backButtonText" />

    <div class="mb-4">
      <CampsiteDetails :data="campsiteItem.details" :is-open="true" />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteDataSyncJob v-if="shStayFacility" />
    </div>

    <div class="mb-4">
      <CampsitesItemComments :trans-id="campsiteId" />
    </div>
    <!--this layout add comment  -->

    <div class="md-layout-item mb-4">
      <CampsiteGeneralInfo
        :data="campsiteItem.generalInfo"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteLocationInfo
        :data="campsiteItem.locationInfo"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteWellbeing
        :featureEditable="featureEditable"
        :data="campsiteItem.wellbeing"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteLeisure
        :data="campsiteItem.leisure"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteFoodDrinkInfo
        :data="campsiteItem.foodDrinkInfo"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteFacilities
        :data="campsiteItem.facilities"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item mb-4">
      <CampsiteChildrenActivities
        :data="campsiteItem.childrenActivities"
        :featureEditable="featureEditable"
      />
    </div>

    <div class="md-layout-item">
      <CampsiteAccommodations :data="campsiteItem.accommodations" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  CAMPSITES,
  CAMPSITES_CLEAR_ITEM,
  CAMPSITES_FETCH_ITEM,
} from "@/modules/campsites/store/mutations-types"
import CampsiteDataSyncJob from "@/modules/campsites/views/components/CampsiteDataSyncJob"
import CampsiteDetails from "@/modules/campsites/views/components/CampsiteDetails"
import CampsiteGeneralInfo from "@/modules/campsites/views/components/CampsiteGeneralInfo"
import CampsiteLocationInfo from "@/modules/campsites/views/components/CampsiteLocationInfo"
import CampsiteWellbeing from "@/modules/campsites/views/components/CampsiteWellbeing"
import CampsiteLeisure from "@/modules/campsites/views/components/CampsiteLeisure"
import CampsiteFoodDrinkInfo from "@/modules/campsites/views/components/CampsiteFoodDrinkInfo"
import CampsiteFacilities from "@/modules/campsites/views/components/CampsiteFacilities"
import CampsiteChildrenActivities from "@/modules/campsites/views/components/CampsiteChildrenActivities"
import CampsiteAccommodations from "@/modules/campsites/views/components/CampsiteAccommodations"
import GoBackBar from "@/views/components/ui/GoBackBar"
import CampsitesItemComments from "@/modules/campsites/views/components/CampsitesItemComments"
import { getItemCollection } from "@/utils/api.utils"

export default {
  name: "CampsitePage",
  components: {
    GoBackBar,
    CampsiteAccommodations,
    CampsiteChildrenActivities,
    CampsiteFacilities,
    CampsiteFoodDrinkInfo,
    CampsiteLeisure,
    CampsiteWellbeing,
    CampsiteLocationInfo,
    CampsiteGeneralInfo,
    CampsiteDetails,
    CampsiteDataSyncJob,
    CampsitesItemComments,
  },
  data: () => ({
    backButtonText: "Back to search campsites",
  }),
  computed: {
    campsiteId() {
      return this.$route.params.id
    },
    featureEditable() {
      if (this.campsiteItem.details[0]) {
        const feature = getItemCollection(
          this.campsiteItem.details[0].dataItems,
          "features_editable"
        )
        if (feature && feature.value === "true") {
          return true
        }
      }
      return false
    },
    shStayFacility() {
      if (this.campsiteItem.details[0]) {
        const shStayFacility = getItemCollection(
          this.campsiteItem.details[0].dataItems,
          "sh_stay_facility"
        )
        if (shStayFacility && shStayFacility.value === "true") {
          return true
        }
      }
      return false
    },
    campsiteItem() {
      return this.$store.state[CAMPSITES].item
    },
  },
  methods: {
    ...mapActions(CAMPSITES, [CAMPSITES_FETCH_ITEM, CAMPSITES_CLEAR_ITEM]),

    handleGoBack() {
      this.$router.push("/campsites")
    },
  },
  mounted() {
    this[CAMPSITES_FETCH_ITEM]({ campsiteId: this.campsiteId })
  },
  destroyed() {
    this[CAMPSITES_CLEAR_ITEM]()
  },
}
</script>

<style scoped></style>
