<template>
  <md-button
    class="button_back md-primary md-sm md-simple"
    @click="handleBackButtonClick"
  >
    <md-icon>keyboard_arrow_left</md-icon>
    {{ backButtonText }}
  </md-button>
</template>

<script>
export default {
  name: "GoBackBar",
  props: {
    onGoBack: Function,
    backButtonText: {
      type: String,
      default: "Back",
    },
  },
  methods: {
    handleBackButtonClick() {
      this.$emit("onGoBack")
    },
  },
}
</script>

<style>
.button_back.md-button.md-sm .md-ripple {
  padding-left: 10px !important;
}
</style>
