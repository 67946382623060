import { DateTime } from "luxon"

export const normalizeDateInTable = (item = {}) => {
  if (!item.value) {
    return item
  }
  //Format datetime
  if (
    item.type === "Date" ||
    (item.name === "new_value" &&
      DateTime.fromFormat(item.value.split(" ")[0], "yyyy-LL-dd").isValid)
  ) {
    //prepare date for Luxon
    const dt = item.value.split(" ")[0]
    item.value = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
  } else if (item.type === "Datetime") {
    //prepare date for Luxon
    const dt = item.value
    item.value = DateTime.fromSQL(dt).toFormat("LLLL dd, yyyy HH:mm")
  }
  return item
}

export const normalizeDate = (dateTime = "") => {
  if (!DateTime.fromSQL(dateTime).isValid) return dateTime

  const dt = DateTime.fromSQL(dateTime).toFormat("LLLL dd, yyyy HH:mm")
  return dt
}

export const normalizeDateInDetails = (item = {}) => {
  if (!item.value) {
    return item
  }
  //Format datetime
  if (item.type === "Date") {
    //prepare date for Luxon
    const dt = item.value.split(" ")[0]
    item.normalizedValue = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
  } else if (item.type === "Datetime") {
    //prepare date for Luxon
    const dt = item.value
    item.normalizedValue = DateTime.fromSQL(dt).toFormat("LLLL dd, yyyy HH:mm")
  }
  return item
}
