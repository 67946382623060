import {
  SITEMAP_URLS_FETCH_URLS_LIST,
  SITEMAP_URLS_FETCH_URLS_LEVEL_LIST,
} from "@/modules/sitemap-urls/store/mutations-type"

const mutations = {
  [SITEMAP_URLS_FETCH_URLS_LEVEL_LIST](state, payload) {
    state.sitemapLevels = payload
  },
  [SITEMAP_URLS_FETCH_URLS_LIST](state, payload) {
    state.sitemapUrls = payload
  },
}
export default mutations
