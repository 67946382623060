export default $axios => resource => ({
  fetchActionsList() {
    return $axios.get(`${resource}/techsupport/systemactions`)
  },

  fetchDatasourceStatusDetail() {
    return $axios.get(`${resource}/techsupport/datasourcestatus`)
  },

  changeDatasourceStatus(systemAction) {
    return $axios.put(
      `${resource}/techsupport/datasourcestatus/${systemAction}`
    )
  },
})
