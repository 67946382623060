<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>New Proposal</md-dialog-title>

      <md-dialog-content>
        <AddProposalPage
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import AddProposalPage from "@/modules/bookings/views/pages/AddProposalPage"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "AddProposalDialog",
  props: {
    onDialogClose: Function,
    isShowDialog: Boolean,
  },
  components: {
    AddProposalPage,
  },
  mixins: [dialogMixin],
  methods: {
    async handleFormSubmit() {
      this.closeDialog()
    },
  },
}
</script>

<style scoped></style>
