<template>
  <div class="px-5 width-adjust">
    <ProgressSpinner :isProgress="getLoader" />
    <div v-if="isCategoryGroup" style="overflow-y: auto;overflow-x: hidden;">
      <GoBackBar
        @onGoBack="handleGoBack"
        :back-button-text="'Back'"
        style="margin-left:-20px;"
      />
      <addCategoriesGroupSmartNavConfigurationForm></addCategoriesGroupSmartNavConfigurationForm>
    </div>
    <div v-if="!isCategoryGroup">
      <md-button
        class="md-info md-sm level-two-button float-right"
        @click="openCatgeoryGroup"
      >
        Category Groups
      </md-button>
      <div style="display:flex;">
        <md-dialog-title class="pl-0 pt-0"
          >{{
            showAddCategoryForm || showUpdateCategoryForm
              ? showUpdateCategoryForm
                ? "Update Category"
                : "Add new Category"
              : "Categories"
          }}
        </md-dialog-title>
      </div>
      <form
        novalidate
        class="md-layout"
        @submit.prevent
        v-if="showAddCategoryForm"
      >
        <template>
          <md-field
            :class="{
              'md-error md-invalid': $v.categoryGroup.$error,
            }"
          >
            <label class="required" for="mobileMenuStyle-field"
              >Category Group</label
            >
            <md-select
              v-model="categoryGroup"
              name="categoryGroup"
              id="categoryGroup-field"
            >
              <md-option
                v-for="(group, index) in categoryGroupRefList"
                :key="index"
                :value="group.value"
              >
                {{ group.value }}
              </md-option>
            </md-select>
            <div class="md-error" v-if="!$v.categoryGroup.required">
              Category Group is required.
            </div>
          </md-field>
          <md-field
            class="mb-4"
            :class="{
              'md-error md-invalid': $v.label.$error,
            }"
          >
            <label class="required">Label</label>
            <md-input v-model="label" type="text"></md-input>
            <div class="md-error" v-if="!$v.label.required">
              Category Label is required.
            </div>
          </md-field>
          <md-field>
            <label>Icon key</label>
            <md-input v-model="iconKey" type="text"></md-input>
          </md-field>

          <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
            <md-button
              type="button"
              class="close-button md-raised mt-4 "
              @click="removeForm()"
            >
              Close
            </md-button>
            <md-button
              type="submit"
              class="md-raised md-success mt-4 ml-4"
              @click="addCategoryApi()"
            >
              {{ showUpdateCategoryForm ? "Update" : "Add" }}
            </md-button>
          </div>
        </template>
      </form>
      <div
        class="md-layout md-alignment-right dropdown-position1"
        v-show="!showAddCategoryForm && !showUpdateCategoryForm"
      >
        <div
          class="md-layout-item md-size-25 select-form"
          style="z-index: 999;padding-left:5px;"
        >
          <md-field>
            <label>Choose Category</label>
            <md-select
              v-model="category"
              name="language"
              id="language"
              @input="filterByCategory()"
            >
              <md-option
                v-for="(categoryGroup, index) in categoryGroupRefList"
                :key="index"
                :value="categoryGroup.value"
                >{{ categoryGroup.value }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
      <UniversalTable
        v-show="!showAddCategoryForm && !showUpdateCategoryForm"
        :data="levelTwoCategoriesLocal"
        :export-file-name="productConfigurationTableOptions.exportName"
        :empty-title-text="productConfigurationTableOptions.emptyTitleText"
        @navigate-to-row-item="updateCategory(selectedCategoryLocal)"
        @onRowSelect="handleCategoryRowSelect"
        :key="categoryReRender"
        :levelTwoSelectedItem="selectedCategoryLocal"
        :onAddButtonClick="addCategory"
        class="margin-adjust"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <div class="buttons-direction">
            <md-button
              class="md-info md-sm level-two-buttons"
              @click="addCategory"
            >
              Add
            </md-button>
            <md-button
              v-if="
                !!selectedRow ||
                  !!(Object.entries(selectedCategoryLocal).length || null)
              "
              class="md-info md-sm level-two-buttons"
              @click="updateCategory"
            >
              Update
            </md-button>
            <md-button
              v-if="swappingategoryItemArray"
              class="md-info md-sm level-two-buttons"
              @click="saveCategoryItemPosition()"
            >
              Save
            </md-button>
            <md-button
              v-if="
                !!selectedRow ||
                  !!(Object.entries(selectedCategoryLocal).length || null)
              "
              class="md-info md-sm level-two-buttons"
              @click.stop.prevent="
                moveUpCategoryItem(
                  selectedCategoryLocal ? selectedCategoryLocal : selectedRow
                )
              "
            >
              Move Up
            </md-button>
            <md-button
              v-if="
                !!selectedRow ||
                  !!(Object.entries(selectedCategoryLocal).length || null)
              "
              class="md-info md-sm level-two-buttons"
              @click.stop.prevent="
                moveDownCategoryItem(
                  selectedCategoryLocal ? selectedCategoryLocal : selectedRow
                )
              "
            >
              Move Down
            </md-button>
            <md-button
              v-if="!!selectedRow"
              class="md-info md-sm level-two-buttons"
              @click="removeCategory(selectedRow)"
            >
              Remove
            </md-button>
          </div>
        </template>
      </UniversalTable>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST,
  SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS,
  GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { required } from "vuelidate/lib/validators"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
import GoBackBar from "@/views/components/ui/GoBackBar"
import addCategoriesGroupSmartNavConfigurationForm from "@/modules/smartNav-configuration/views/components/forms/addCategoriesGroupSmartNavConfigurationForm"

export default {
  name: "addCategoriesSmartNavConfigurationForm",
  components: {
    GoBackBar,
    UniversalTable,
    ProgressSpinner,
    addCategoriesGroupSmartNavConfigurationForm,
  },
  data: () => ({
    showAddCategoryForm: false,
    showUpdateCategoryForm: false,
    isCategoryGroup: false,
    selectedCategoryLocal: [],
    levelTwoCategoriesLocal: {},
    swappingategoryItemArray: {},
    label: "",
    iconKey: "",
    category: "",
    categoryReRender: 1,
    categoryGroup: "",
    productConfigurationTableOptions: {
      exportName: "Categories",
      emptyTitleText:
        "Catgories are not available for selected Item.Please add.",
    },
  }),
  async created() {
    this.levelTwoCategoriesLocal = this.levelTwoCategories
    await this[GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST](
      this.selectedLanguage
    )
    this.categoryReRender++
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, [
      ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
      REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
      UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
      GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST,
      SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS,
      GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
    ]),
    handleGoBack() {
      this.isCategoryGroup = false
    },
    // Save New Category of Smart Nav Configuration
    async addCategoryApi() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loadingAdd = true
        let data = {}
        if (!this.showUpdateCategoryForm) {
          data = {
            categoryLabel: this.label,
            categoryGroup: this.categoryGroup,
            iconKey: this.iconKey,
            lang: this.selectedLanguage,
          }
        } else {
          data = {
            categoryLabel: this.label,
            categoryGroup: this.categoryGroup,
            iconKey: this.iconKey,
            lang: this.selectedLanguage,
            id: this.selectedCategoryLocal.find(item => item.name === "id")
              ?.value,
          }
        }
        let final = []
        let input = {}
        input.lang = this.selectedLanguage
        input.category = this.category
        final.push(input)
        final.push(data)
        if (this.showUpdateCategoryForm)
          try {
            await this[UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS](final)
          } catch (e) {
            this.$swal.fire({
              position: "top-end",
              html: e,
              icon: "error",
              title: "Update Category",
              showConfirmButton: false,
              timer: 4000,
              showCloseButton: true,
            })
          }
        else {
          try {
            await this[ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS](final)
          } catch (e) {
            this.$swal.fire({
              position: "top-end",
              html: e,
              icon: "error",
              title: "Add Category",
              showConfirmButton: false,
              timer: 4000,
              showCloseButton: true,
            })
          }
        }
        this.categoryGroup = ""
        this.label = ""
        this.levelTwoCategoriesLocal = this.levelTwoCategories
        this.loadingAdd = false
        this.$v.$reset()
        this.removeForm()
      }
    },
    // Open Add New Category Form
    async addCategory() {
      this.showAddCategoryForm = true
    },
    async openCatgeoryGroup() {
      try {
        await this[GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST](
          this.selectedLanguage
        )
        this.isCategoryGroup = true
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Sitemap Levels",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
    // Close Add New Category Form
    removeForm() {
      this.label = ""
      this.iconKey = ""
      this.selectedCategoryLocal = []
      this.showAddCategoryForm = false
      this.showUpdateCategoryForm = false
    },
    async updateCategory() {
      this.loadingAdd = true
      await this[GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST](
        this.selectedLanguage
      )
      this.label = this.selectedCategoryLocal.find(
        item => item.name === "category_label"
      )?.value
      this.iconKey = this.selectedCategoryLocal.find(
        item => item.name === "icon_key"
      )?.value
      this.categoryGroup = this.selectedCategoryLocal.find(
        item => item.name === "category_group"
      )?.value
      this.showAddCategoryForm = true
      this.showUpdateCategoryForm = true
      this.loadingAdd = false
    },
    removeUpdateForm() {
      this.showUpdateCategoryForm = false
    },
    // Remove selected Category from the list
    async removeCategory(selectedRow) {
      this.loadingAdd = true
      let final = []
      let input = {}
      input.lang = this.selectedLanguage
      input.category = this.category
      final.push(input)
      final.push(selectedRow.find(item => item.name === "id").value)
      try {
        await this[REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS](final)
        this.$swal.fire({
          position: "top-end",
          html: "Category removed successfully",
          icon: "success",
          title: "Remove Category",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Remove Category",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.levelTwoCategoriesLocal = this.levelTwoCategories
      this.loadingAdd = false
    },
    handleCategoryRowSelect(selectedRow) {
      this.selectedCategoryLocal = selectedRow
        ? selectedRow
        : this.selectedCategoryLocal
    },
    // Move Up Category Smart Nav Configuartion Item
    moveUpCategoryItem(selectedRow) {
      for (
        let index = 0;
        index < this.levelTwoCategoriesLocal.rows.length;
        index++
      ) {
        if (index !== 0) {
          if (
            selectedRow.find(item => item.name === "category_label")?.value ===
              this.levelTwoCategoriesLocal.rows[index].row.find(
                item => item.name === "category_label"
              )?.value &&
            this.levelTwoCategoriesLocal.rows[index - 1].row.find(
              item => item.name === "category_group"
            )?.value ===
              this.levelTwoCategoriesLocal.rows[index].row.find(
                item => item.name === "category_group"
              )?.value
          ) {
            const pos = this.levelTwoCategoriesLocal.rows[index].row.findIndex(
              x => x.name == "position"
            )
            let temp = this.levelTwoCategoriesLocal.rows[index - 1].row[pos]
              .value
            this.levelTwoCategoriesLocal.rows[index - 1].row[
              pos
            ].value = this.levelTwoCategoriesLocal.rows[index].row[pos].value
            this.levelTwoCategoriesLocal.rows[index].row[pos].value = temp
            let item = this.levelTwoCategoriesLocal.rows[index - 1]
            this.levelTwoCategoriesLocal.rows[
              index - 1
            ] = this.levelTwoCategoriesLocal.rows[index]
            this.levelTwoCategoriesLocal.rows[index] = item
            this.categoryReRender++
            return
          }
        }
      }
    },
    // Save the updated position of Categories
    async saveCategoryItemPosition() {
      this.loadingAdd = true
      this.swappingategoryItemArray.items = []
      this.swappingategoryItemArray.parentItem = ""
      // // this.selectedParent.find(
      // //   item => item.name === "item_key"
      // // )?.value
      for (
        let index = 0;
        index < this.levelTwoCategoriesLocal.rows.length;
        index++
      ) {
        let item = {
          id: this.levelTwoCategoriesLocal.rows[index].row.find(
            item => item.name === "id"
          )?.value,
          position: this.levelTwoCategoriesLocal.rows[index].row.find(
            item => item.name === "position"
          )?.value,
        }
        this.swappingategoryItemArray.items.push(item)
      }
      const categorySwapInput = []
      let input = {}
      input.lang = this.selectedLanguage
      input.category = this.category
      categorySwapInput.push(input)
      categorySwapInput.push(this.swappingategoryItemArray)
      try {
        await this[SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS](categorySwapInput)
        this.$swal.fire({
          position: "top-end",
          html: "Category item position updated successfully",
          icon: "success",
          title: "Save Category Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Save Category Position",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
      this.loadingAdd = false
      this.levelTwoCategoriesLocal = this.levelTwoCategories
      this.categoryReRender++
      this.swappingategoryItemArray = {}
    },
    // Move Down Category Smart Nav Configuartion Item
    moveDownCategoryItem(selectedRow) {
      for (
        let index = 0;
        index < this.levelTwoCategoriesLocal.rows.length;
        index++
      ) {
        if (index + 1 < this.levelTwoCategoriesLocal.rows.length) {
          if (
            selectedRow.find(item => item.name === "category_label")?.value ===
              this.levelTwoCategoriesLocal.rows[index].row.find(
                item => item.name === "category_label"
              )?.value &&
            this.levelTwoCategoriesLocal.rows[index + 1].row.find(
              item => item.name === "category_group"
            )?.value ===
              this.levelTwoCategoriesLocal.rows[index].row.find(
                item => item.name === "category_group"
              )?.value
          ) {
            const pos = this.levelTwoCategoriesLocal.rows[index].row.findIndex(
              x => x.name == "position"
            )
            const temp = this.levelTwoCategoriesLocal.rows[index + 1].row[pos]
              .value
            this.levelTwoCategoriesLocal.rows[index + 1].row[
              pos
            ].value = this.levelTwoCategoriesLocal.rows[index].row[pos].value
            this.levelTwoCategoriesLocal.rows[index].row[pos].value = temp
            const item = this.levelTwoCategoriesLocal.rows[index + 1]
            this.levelTwoCategoriesLocal.rows[
              index + 1
            ] = this.levelTwoCategoriesLocal.rows[index]
            this.levelTwoCategoriesLocal.rows[index] = item
            this.categoryReRender++
            return
          }
        }
      }
    },
    async filterByCategory() {
      let input = {
        lang: this.selectedLanguage,
        category: this.category,
      }
      await this[GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST](input)
      this.levelTwoCategoriesLocal = this.levelTwoCategories
      this.categoryReRender++
    },
  },
  validations: {
    label: {
      required,
    },
    categoryGroup: {
      required,
    },
  },
  computed: {
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      levelTwoCategories: state => state.levelTwoCategories,
      selectedLanguage: state => state.selectedLanguage,
      categoryGroupRefList: state => state.categoryGroupRefList,
      selectedCategory: state => state.selectedCategory,
    }),
    getLoader() {
      return this.loadingAdd
    },
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
.md-card-actions {
  display: none !important;
}
.width-adjust {
  min-width: 600px;
}
.close-button {
  box-shadow: 0 2px 2px 0 rgb(217 221 220 / 14%),
    0 3px 1px -2px rgb(217 221 220 / 20%), 0 1px 5px 0 rgb(217 221 220 / 12%) !important;
}
@media screen and (max-width: 600px) {
  .width-adjust {
    min-width: 100%;
    display: flex !important;
  }
}
.md-title {
  font-size: 14px !important;
}
.float-right {
  float: right !important;
}
.dropdown-position1 {
  justify-content: left;
  margin-left: 270px;
}
.margin-adjust {
  margin-top: -70px;
}
@media screen and (max-width: 767px) {
  .md-layout-item.md-size-25 {
    min-width: 100%;
    max-width: 100%;
  }
  .margin-adjust {
    margin-top: 0px !important;
  }
  .dropdown-position1 {
    justify-content: center !important;
    margin-left: 0px !important;
  }
}
</style>
