import GuestLayout from "@/views/layouts/GuestLayout"
import LoginPage from "@/modules/auth/views/pages/LoginPage"

export default {
  path: "/",
  component: GuestLayout,
  name: "Authentication",
  redirect: "/login",
  children: [
    {
      path: "/login",
      name: "Login",
      component: LoginPage,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
        requiresRoutApi: "login",
      },
    },
  ],
}
