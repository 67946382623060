<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Outstanding Balances</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <form
          novalidate
          class="md-layout md-alignment-left"
          @submit.prevent="handleSubmit"
        >
          <div class="md-layout-item md-size-25">
            <md-field>
              <label>Campsite id</label>
              <md-input v-model="campsiteId" type="text" />
            </md-field>
          </div>
          <md-button type="submit" class="md-raised md-success button-search">
            Search
          </md-button>
        </form>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-content>
        <UniversalTable
          :data="normalizedBalancesList"
          :export-file-name="balances.exportName"
          :empty-title-text="balances.emptyTitleText"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
import {
  OUTSTANDING_BALANCES,
  OUTSTANDING_BALANCES_FETCH_LIST,
} from "@/modules/outstanding-balances/store/mutations-types"

import { normalizeDateInTable } from "@/modules/outstanding-balances/utils/notifications-date.utils"
import _ from "lodash"

export default {
  name: "OutstandingBalances",
  components: {
    UniversalTable,
  },
  data() {
    return {
      campsiteId: "",
      dateFrom: "",
      dateTo: "",
      balances: {
        exportName: "OutstandingBalances",
        emptyTitleText: "Outstanding  Balances table is empty",
      },
    }
  },
  mounted() {
    this.fetchOutstandingBalancesList()
  },
  methods: {
    ...mapActions(OUTSTANDING_BALANCES, {
      fetchOutstandingBalancesList: OUTSTANDING_BALANCES_FETCH_LIST,
    }),
    async handleSubmit() {
      await this.fetchOutstandingBalancesList({
        campsite_id: this.campsiteId,
      })
    },
  },
  computed: {
    ...mapState(OUTSTANDING_BALANCES, {
      balancesList: state => state.balancesList,
    }),
    normalizedBalancesList() {
      if (
        Object.keys(this.balancesList).length === 0 ||
        !this.balancesList.rows
      ) {
        return {}
      }

      let data = _.cloneDeep(this.balancesList)

      data.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return data
    },
  },
}
</script>

<style scoped>
.button-search {
  height: 35px;
  margin-top: 20px;
}
</style>
