import { get as _get } from "lodash"

import actions from "@/modules/user/store/actions"
import mutations from "@/modules/user/store/mutations"

const state = () => ({
  user: null,
  userEntities: [],
})

const getters = {
  userName: state => _get(state, "user.name", null),
  entities: state =>
    _get(state, "userEntities.[0].dataItems", []).map(entity => entity.name),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
