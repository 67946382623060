import { DateTime } from "luxon"

export const normalizeDateInTable = (item = {}) => {
  //Format datetime
  if (item.type === "Datetime") {
    //prepare date for Luxon
    const dt = item.value
    item.value = DateTime.fromSQL(dt).toFormat("LLLL dd, yyyy HH:mm")
  }
  return item
}
