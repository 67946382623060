<template>
  <div>
    <div v-for="item in insuranceData" :key="item.label" class="mt-4">
      <DetailsCollapse
        class="p-0"
        :is-open="false"
        :is-loading="isFetching"
        :label="item.label"
        :elements="item.dataItems"
      />
    </div>
  </div>
</template>

<script>
import {
  BOOKINGS,
  BOOKINGS_FETCH_WEATHER_INSURANCE,
} from "@/modules/bookings/store/mutations-types"
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"
import { mapState, mapActions } from "vuex"

export default {
  name: "BookingWeatherInsuranceTable",
  components: {
    DetailsCollapse,
  },
  data: () => ({
    tableExportFileName: "booking_weather_Insurance",
    isFetching: false,
    insuranceData: [],
  }),
  props: {
    transId: String,
  },
  computed: {
    ...mapState(BOOKINGS, {
      weatherInsuranceData: state => state.weatherInsuranceData,
    }),
  },
  async created() {
    await this.init()
    this.insuranceData = this.$store.state[BOOKINGS].weatherInsuranceData
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_WEATHER_INSURANCE]),
    async init() {
      this.isFetching = true
      await this[BOOKINGS_FETCH_WEATHER_INSURANCE](this.transId)
      this.isFetching = false
    },
  },
}
</script>

<style lang="scss" scoped>
.md-table + .md-table {
  margin-top: 16px;
}
</style>
