import { render, staticRenderFns } from "./TableFooter.vue?vue&type=template&id=422e9215&scoped=true&"
import script from "./TableFooter.vue?vue&type=script&lang=js&"
export * from "./TableFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422e9215",
  null
  
)

export default component.exports