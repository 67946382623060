export const getInvoiceRowData = (row = []) => {
  const data = {}
  const fieldsAnnotation = {
    commmission_amt: "commissionAmt",
    invoice_due_date: "invoiceDueDate",
    invoice_paid_date: "invoicePaidDate",
    commission_status: "commissionStatus",
    invoice_status: "invoiceStatus",
  }

  row.forEach(field => {
    const isFieldAnnotationExist = fieldsAnnotation[field.name]

    if (isFieldAnnotationExist) {
      const formFieldKey = fieldsAnnotation[field.name]
      data[formFieldKey] = field.value
    }
  })

  return data
}
