<template>
  <div>
    <md-dialog
      :md-active="isShowDialog"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Update campsited adjustment</md-dialog-title>

      <md-dialog-content>
        <UpdateBookingAdjustmentForm
          :adjustmentRow="adjustmentRow"
          :is-form-sending="isFormSending"
          :adjustment-field-list="storeAdjustmentFieldList"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS,
  BOOKINGS_UPDATE_ITEM_ADJUSTMENTS,
} from "@/modules/bookings/store/mutations-types"
import UpdateBookingAdjustmentForm from "@/modules/bookings/views/components/forms/UpdateBookingAdjustmentForm"
import bookingsAdjustments from "@/modules/bookings/mixins/bookingAdjustments.mixin"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "UpdateBookingAdjustmentFieldDialog",
  mixins: [bookingsAdjustments, dialogMixin],
  props: {
    transId: String,
    adjustmentRow: Array,
  },
  components: {
    UpdateBookingAdjustmentForm,
  },
  computed: {
    storeAdjustmentFieldList() {
      return this.$store.state[BOOKINGS].item.adjustmentFields
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_UPDATE_ITEM_ADJUSTMENTS,
      BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS,
    ]),
    fetchAdjustmentFieldsList() {
      if (this.storeAdjustmentFieldList.length === 0) {
        this[BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS]()
      }
    },
    async handleFormSubmit(values) {
      const { id } = this.getAdjustmentRowData(this.adjustmentRow)
      this.isFormSending = true
      await this[BOOKINGS_UPDATE_ITEM_ADJUSTMENTS]({
        id,
        transId: this.transId,
        ...values,
      })
      this.isFormSending = false
      this.closeDialog()
    },
  },
  mounted() {
    this.fetchAdjustmentFieldsList()
  },
}
</script>

<style scoped></style>
