<template>
  <div class="md-layout-item">
    <collapse :collapse="[storeBookingAdjustments.label]" :is-open="false">
      <template slot="md-collapse-pane-1">
        <BookingAdjustmentsTable
          @onAddButtonClick="handleAddButtonClick"
          @onEditButtonClick="handleEditButtonClick"
          @onRemoveButtonClick="handleRemoveButtonClick"
          @onRowSelect="handleRowSelect"
        />
      </template>
    </collapse>

    <CreateBookingAdjustmentDialog
      :trans-id="transId"
      :is-show-dialog="isShowCreateDialog"
      @onDialogClosed="closeCreateDialog"
    />
    <UpdateBookingAdjustmentFieldDialog
      :trans-id="transId"
      :adjustmentRow="adjustmentsSelectedTableRow"
      :is-show-dialog="isShowUpdateDialog"
      @onDialogClosed="closeUpdateDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_ADJUSTMENTS,
  BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS,
} from "@/modules/bookings/store/mutations-types"
import Collapse from "@/views/components/ui/Collapse"
import BookingAdjustmentsTable from "@/modules/bookings/views/components/tables/BookingAdjustmentsTable"
import CreateBookingAdjustmentDialog from "@/modules/bookings/views/components/dialogs/CreateBookingAdjustmentDialog"
import UpdateBookingAdjustmentFieldDialog from "@/modules/bookings/views/components/dialogs/UpdateBookingAdjustmentDialog"
import bookingsAdjustments from "@/modules/bookings/mixins/bookingAdjustments.mixin"

export default {
  name: "BookingItemAdjustments",
  mixins: [bookingsAdjustments],
  components: {
    CreateBookingAdjustmentDialog,
    UpdateBookingAdjustmentFieldDialog,
    BookingAdjustmentsTable,
    Collapse,
  },
  props: {
    transId: String,
  },
  data: () => ({
    adjustmentsSelectedTableRow: null,
    isShowCreateDialog: false,
    isShowUpdateDialog: false,
  }),
  computed: {
    storeBookingAdjustments() {
      return this.$store.state[BOOKINGS].item.adjustments
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_ITEM_ADJUSTMENTS,
      BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS,
    ]),
    handleRowSelect(row) {
      if (row) this.adjustmentsSelectedTableRow = row
    },
    handleAddButtonClick() {
      this.isShowCreateDialog = true
    },
    handleEditButtonClick() {
      this.isShowUpdateDialog = true
    },
    handleRemoveButtonClick() {
      const rowData = this.getAdjustmentRowData(
        this.adjustmentsSelectedTableRow
      )

      this.adjustmentsSelectedTableRow = null
      this[BOOKINGS_REMOVE_ITEM_ADJUSTMENT_FIELDS]({
        ...rowData,
        transId: this.transId,
      })
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false
    },
    closeUpdateDialog(show) {
      this.isShowUpdateDialog = show
    },
    fetchBookingAdjustments() {
      this[BOOKINGS_FETCH_ITEM_ADJUSTMENTS]({
        transId: this.transId,
      })
    },
  },
  mounted() {
    this.fetchBookingAdjustments()
  },
}
</script>

<style scoped></style>
