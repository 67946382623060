export default $axios => resource => ({
  fetchCountries() {
    return $axios.get(`${resource}/countries`)
  },
  fetchCountryRegions(params) {
    return $axios.get(`${resource}/countryregions`, { params })
  },
  fetchRegionDepartments(params) {
    return $axios.get(`${resource}/regiondepartments`, { params })
  },
})
