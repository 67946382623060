var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"width-adjust"},[_c('ProgressSpinner',{attrs:{"isProgress":_vm.getLoader}}),_c('div',{staticStyle:{"display":"flex"}},[_c('md-dialog-title',{staticClass:"pl-0 pt-0"},[_vm._v(_vm._s(_vm.showAddCategoryGroupForm || _vm.showUpdateCategoryGroupForm ? _vm.showUpdateCategoryGroupForm ? "Update Category Group" : "Add Category Group" : "Category Groups")+" ")])],1),(_vm.showAddCategoryGroupForm)?_c('form',{staticClass:"md-layout",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[[_c('md-field',{staticClass:"mb-4",class:{
          'md-error md-invalid': _vm.$v.categoryGroup.$error,
        }},[_c('label',{staticClass:"required"},[_vm._v("Category Group")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.categoryGroup),callback:function ($$v) {_vm.categoryGroup=$$v},expression:"categoryGroup"}}),(!_vm.$v.categoryGroup.required)?_c('div',{staticClass:"md-error"},[_vm._v(" Category Group is required. ")]):_vm._e()],1),_c('div',{staticClass:"md-layout-item md-size-100 px-0 pb-4 content-right"},[_c('md-button',{staticClass:"close-button md-raised mt-4 ",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeForm()}}},[_vm._v(" Close ")]),_c('md-button',{staticClass:"md-raised md-success mt-4 ml-4",attrs:{"type":"submit"},on:{"click":function($event){return _vm.addCategoryGroupApi()}}},[_vm._v(" "+_vm._s(_vm.showUpdateCategoryGroupForm ? "Update" : "Add")+" ")])],1)]],2):_vm._e(),_c('UniversalTable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAddCategoryGroupForm && !_vm.showUpdateCategoryGroupForm),expression:"!showAddCategoryGroupForm && !showUpdateCategoryGroupForm"}],attrs:{"data":_vm.categoryGroupList,"export-file-name":_vm.productConfigurationTableOptions.exportName,"empty-title-text":_vm.productConfigurationTableOptions.emptyTitleText,"onAddButtonClick":_vm.addCategoryGroup,"add-button-text":'Add First Category Group'},on:{"navigate-to-row-item":function($event){return _vm.updateCategory(_vm.selectedCategoryGroup)},"onRowSelect":_vm.handleCategoryRowSelect},scopedSlots:_vm._u([{key:"header-right-controls",fn:function(ref){
        var selectedRow = ref.selectedRow;
return [_c('div',{staticClass:"buttons-direction"},[_c('md-button',{staticClass:"md-info md-sm level-two-buttons",on:{"click":_vm.addCategoryGroup}},[_vm._v(" Add ")]),(
            !!selectedRow ||
              !!(Object.entries(_vm.selectedCategoryGroup).length || null)
          )?_c('md-button',{staticClass:"md-info md-sm level-two-buttons",on:{"click":_vm.updateCategory}},[_vm._v(" Update ")]):_vm._e(),(!!selectedRow)?_c('md-button',{staticClass:"md-info md-sm level-two-buttons",on:{"click":function($event){return _vm.removeCategory(selectedRow)}}},[_vm._v(" Remove ")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }