<template>
  <div class="reports-page">
    <div>
      <div class="header">
        <h3 class="title mt-0">Your tech support options:</h3>
      </div>
      <div class="md-layout">
        <div
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <router-link to="/tech_support/sync_jobs">
            <div class="report-item">
              <md-card class="mt-0">
                <md-card-content>
                  <h4>Sync Jobs</h4>
                </md-card-content>
              </md-card>
            </div>
          </router-link>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <router-link to="/tech_support/system_actions">
            <div class="report-item">
              <md-card class="mt-0">
                <md-card-content>
                  <h4>System Actions</h4>
                </md-card-content>
              </md-card>
            </div>
          </router-link>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <router-link to="/tech_support/sitemap_urls">
            <div class="report-item">
              <md-card class="mt-0">
                <md-card-content>
                  <h4>Sitemap Urls</h4>
                </md-card-content>
              </md-card>
            </div>
          </router-link>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <router-link to="/tech_support/job_schedules">
            <div class="report-item">
              <md-card class="mt-0">
                <md-card-content>
                  <h4>Job Schedules</h4>
                </md-card-content>
              </md-card>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasPermissions } from "@/utils/permissions.utils"
import { USER } from "@/modules/user/store/mutations-types"

export default {
  name: "TechSupportPage",
  methods: {
    hasPermissionsSidebar(route) {
      return hasPermissions(route, this.userEntities)
    },
  },
  computed: {
    userEntities() {
      return this.$store.getters[`${USER}/entities`]
    },
  },
}
</script>
