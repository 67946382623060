<template>
  <div>
    <div class="md-layout-item">
      <collapse :collapse="[storeCampsitesComments.label]" :is-open="false">
        <template slot="md-collapse-pane-1">
          <CampsitesCommentsTable
            @onCreateButtonClick="handleCreateButtonClick"
          />
        </template>
      </collapse>
    </div>

    <CreateCampsitesCommentDialog
      :trans-id="transId"
      :is-show-dialog="isShowCreateDialog"
      @onDialogClosed="closeCreateDialog"
    />
  </div>
</template>

<script>
import Collapse from "@/views/components/ui/Collapse"
import CampsitesCommentsTable from "@/modules/campsites/views/components/tables/CampsitesCommentsTable"

import {
  CAMPSITES,
  CAMPSITES_FETCH_ITEM_COMMENTS,
} from "@/modules/campsites/store/mutations-types"
import { mapActions } from "vuex"
import CreateCampsitesCommentDialog from "@/modules/campsites/views/components/dialogs/CreateCampsitesCommentDialog"

export default {
  name: "CampsitesItemComments",
  props: {
    transId: String,
  },
  components: {
    Collapse,
    CampsitesCommentsTable,
    CreateCampsitesCommentDialog,
  },
  data: () => ({
    isShowCreateDialog: false,
  }),
  computed: {
    storeCampsitesComments() {
      return this.$store.state[CAMPSITES].item.comments
    },
  },
  methods: {
    ...mapActions(CAMPSITES, [CAMPSITES_FETCH_ITEM_COMMENTS]),
    fetchCampsitesItemComments() {
      if (this.transId) {
        this[CAMPSITES_FETCH_ITEM_COMMENTS]({
          campsiteId: this.transId,
        })
      }
    },
    handleCreateButtonClick() {
      this.isShowCreateDialog = true
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false
    },
  },
  mounted() {
    this.fetchCampsitesItemComments()
  },
}
</script>

<style scoped></style>
