<template>
  <div>
    <md-card>
      <ProgressSpinner :isProgress="loading" />
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Sync Job Schedules</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="syncJobSchedules"
          :export-file-name="dataExportFileName"
        >
        </UniversalTable>
      </md-card-content>
    </md-card>
    <md-card>
      <ProgressSpinner :isProgress="loading" />
      <md-card-header class="md-card-header-text md-card-header-green">
        <div class="card-text">
          <h4 class="title">Feed Job Schedules</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <UniversalTable
          :data="feedJobSchedules"
          :export-file-name="dataExportFileName"
        >
        </UniversalTable>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  JOB_SCHEDULES,
  SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST,
} from "@/modules/job-schedules/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"

export default {
  name: "JobSchedules",
  components: {
    UniversalTable,
    ProgressSpinner,
  },
  data() {
    return {
      dataExportFileName: "Job Schedules",
      loading: false,
    }
  },
  async created() {
    await this.created()
  },
  computed: {
    ...mapState(JOB_SCHEDULES, {
      syncJobSchedules: state => state.syncJobSchedules,
      feedJobSchedules: state => state.feedJobSchedules,
    }),
  },
  methods: {
    ...mapActions(JOB_SCHEDULES, {
      fetchJobSchedules: SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST,
    }),
    // Mount each time code
    async created() {
      this.loading = true
      await this.fetchJobSchedules("sync")
      await this.fetchJobSchedules("feed")
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
