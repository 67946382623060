<template>
  <div class="px-5">
    <ProgressSpinner :isProgress="loadingModal" />
    <form novalidate class="md-layout" v-if="!loadindModal" @submit.prevent>
      <md-field>
        <label for="language-field">language</label>
        <md-select v-model="language" name="language" id="language-field">
          <md-option
            v-for="(item, index) in languageList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </md-select>
      </md-field>

      <md-field>
        <label for="product-key-field">Product Key</label>
        <md-select
          v-model="productKey"
          name="product_key"
          id="product-key-field"
        >
          <md-option
            v-for="(item, index) in productKeyList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </md-select>
      </md-field>

      <div class="md-layout-item md-size-100 px-0 content-right">
        <md-button
          class="md-raised md-info mt-4"
          @click="getProductConfigurations()"
        >
          Get Product
        </md-button>
      </div>
      <hr />

      <template v-if="isProductConfiguration">
        <md-field>
          <label class="required">Title</label>
          <md-input v-model="productConfiguration.title" type="text"></md-input>
        </md-field>

        <md-field>
          <label class="required">into Text</label>
          <md-input
            v-model="productConfiguration.introText"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label class="required">Primary CTA Label</label>
          <md-input
            v-model="productConfiguration.primaryCtaLabel"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label class="required">Primary CTA Url</label>
          <md-input
            v-model="productConfiguration.primaryCtaUrl"
            type="text"
          ></md-input>
        </md-field>

        <div class="filter-checkbox">
          <md-checkbox
            class="column"
            v-model="productConfiguration.primaryCtaNewTab"
            value="true"
            >Primary CTA New Tab</md-checkbox
          >
        </div>

        <md-field>
          <label class="required">Secondary CTA Label</label>
          <md-input
            v-model="productConfiguration.secondaryCtaLabel"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label class="required">Secondary CTA Url</label>
          <md-input
            v-model="productConfiguration.secondaryCtaUrl"
            type="text"
          ></md-input>
        </md-field>

        <div class="filter-checkbox">
          <md-checkbox
            class="column"
            v-model="productConfiguration.secondaryCtaNewTab"
            value="true"
            >Secondary CTA New Tab</md-checkbox
          >
        </div>

        <md-field>
          <label>Tooltip Text</label>
          <md-input
            v-model="productConfiguration.tooltipText"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label>Landing Heading</label>
          <md-input
            v-model="productConfiguration.landingHeading"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label>Landing Sub Heading</label>
          <md-input
            v-model="productConfiguration.landingSubHeading"
            type="text"
          ></md-input>
        </md-field>

        <md-field>
          <label class="required">Display Order</label>
          <md-input
            v-model="productConfiguration.displayOrder"
            type="number"
          ></md-input>
        </md-field>

        <md-field>
          <label class="required" for="language-field">Active language</label>
          <md-select
            multiple
            v-model="productConfiguration.activeLangs"
            name="language"
            id="language-field"
          >
            <md-option
              v-for="(item, index) in languageList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </md-option>
          </md-select>
        </md-field>

        <div class="filter-checkbox">
          <md-checkbox
            class="column"
            v-model="productConfiguration.active"
            value="true"
            >Active</md-checkbox
          >
        </div>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="updateProductConfiguration()"
          >
            Save
          </md-button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  PRODUCT_CONFIGURATIONS,
  UPDATE_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION_LANGUAGE,
  GET_PRODUCT_CONFIGURATION_PRODUCT_KEY,
} from "@/modules/product-configuration/store/mutations-type"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
export default {
  name: "UpdateProductConfigurationForm",
  components: {
    ProgressSpinner,
  },
  props: ["editProductConfiguration"],
  data: () => ({
    productKey: "",
    language: "",
    currentProductKey: "",
    currentLanguage: "",
    loadingModal: false,
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(PRODUCT_CONFIGURATIONS, [
      UPDATE_PRODUCT_CONFIGURATION,
      GET_PRODUCT_CONFIGURATION,
      GET_PRODUCT_CONFIGURATION_LANGUAGE,
      GET_PRODUCT_CONFIGURATION_PRODUCT_KEY,
    ]),
    // Initialize the Update Product Configuration
    async init() {
      this.loadindModal = true
      this.getProductConfigurationLanguage()
      this.getProductConfigurationProductKey()
      const [
        { value: productKey },
        { value: language },
      ] = this.editProductConfiguration

      this.productKey = this.currentProductKey = productKey.toUpperCase()
      this.language = this.currentLanguage = language
      this.getProductConfigurations()
      this.loadindModal = false
    },
    // Update Product Configuration
    async updateProductConfiguration() {
      this.loadindModal = true
      this.productConfiguration.activeLangs = this.productConfiguration.activeLangs.filter(
        a => a
      )
      if (
        !this.currentProductKey ||
        !this.currentLanguage ||
        !this.productConfiguration.title ||
        !this.productConfiguration.language ||
        !this.productConfiguration.introText ||
        !this.productConfiguration.primaryCtaLabel ||
        !this.productConfiguration.primaryCtaUrl ||
        !this.productConfiguration.secondaryCtaLabel ||
        !this.productConfiguration.secondaryCtaUrl ||
        !this.productConfiguration.displayOrder ||
        !this.productConfiguration.activeLangs.length != 0
      ) {
        this.showWarningMessage(
          `Fill all the fields marked with (<span style='color:red'>*</span>)`
        )
        return
      }
      const data = {
        productHeading: this.productConfiguration.title,
        productIntroText: this.productConfiguration.introText,
        linkPrimaryCtaLabel: this.productConfiguration.primaryCtaLabel,
        linkPrimaryCtaUrl: this.productConfiguration.primaryCtaUrl,
        primaryUrlOpenInNewTab:
          this.productConfiguration.primaryCtaNewTab == "true"
            ? "true"
            : "false",
        linkSecondaryCtaLabel: this.productConfiguration.secondaryCtaLabel,
        linkSecondaryCtaUrl: this.productConfiguration.secondaryCtaUrl,
        secondaryUrlOpenInNewTab:
          this.productConfiguration.secondaryCtaNewTab == "true"
            ? "true"
            : "false",
        active: this.productConfiguration.active == "true" ? "true" : "false",
        tooltipText: this.productConfiguration.tooltipText,
        landingHeading: this.productConfiguration.landingHeading,
        landingSubheading: this.productConfiguration.landingSubHeading,
        displayOrder: this.productConfiguration.displayOrder,
        activeLangs: this.productConfiguration.activeLangs,
      }
      try {
        await this[UPDATE_PRODUCT_CONFIGURATION]([
          this.language,
          this.productKey,
          data,
        ])
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Update Product Configuration",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
        this.loadindModal = false
      }
      this.loadindModal = false
      this.$emit("onFormSubmit")
    },
    // Show Required warning messages
    showWarningMessage(name) {
      this.$swal(name)
    },
    // Get Product Configuration Detail
    async getProductConfigurations() {
      try {
        await this[GET_PRODUCT_CONFIGURATION]([this.language, this.productKey])
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Product Configuration",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
    // Get Product Configuration Language
    async getProductConfigurationLanguage() {
      try {
        await this[GET_PRODUCT_CONFIGURATION_LANGUAGE]([])
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Product Configuration Language",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
    // Get Product Configuration Prodcut Key
    async getProductConfigurationProductKey() {
      try {
        await this[GET_PRODUCT_CONFIGURATION_PRODUCT_KEY]([])
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Get Product Configuartion Product Key",
          showConfirmButton: false,
          timer: 4000,
          showCloseButton: true,
        })
      }
    },
  },
  computed: {
    ...mapState(PRODUCT_CONFIGURATIONS, {
      productConfiguration: state => state.productConfiguration,
      isProductConfiguration: state => state.isProductConfiguration,
      languageList: state => state.languageList,
      productKeyList: state => state.productKeyList,
    }),
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
</style>
