import {
  SYNC_JOBS_FETCH_LIST,
  SYNC_JOBS_ITEMS_FETCH_LIST,
} from "@/modules/sync-jobs/store/mutations-types"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [SYNC_JOBS_FETCH_LIST]({ commit }) {
    const response = await this.$repositories.syncJobs.fetchList()
    const data = getGridDataItemFirstCollection(response)
    commit(SYNC_JOBS_FETCH_LIST, data)
  },
  async [SYNC_JOBS_ITEMS_FETCH_LIST]({ commit }, payload) {
    const response = await this.$repositories.syncJobs.fetchJobItems(payload)
    const data = getGridDataItemFirstCollection(response)
    commit(SYNC_JOBS_ITEMS_FETCH_LIST, data)
  },
}

export default actions
