<template>
  <md-dialog
    :md-active.sync="value"
    @md-clicked-outside="closeDialog"
    class="dialog"
  >
    <md-dialog-title>
      <b>
        Update Accommodation Details
      </b>
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="closeDialog"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <md-dialog-content class="width-100">
      <form class="md-layout" novalidate @submit.prevent="validateForm">
        <div class="label-field md-layout-item md-size-100  mb-2">
          Lang: {{ form.lang }}
        </div>
        <div class="label-field md-layout-item  mb-2">
          Type: {{ form.type }}
        </div>
        <md-field :class="getValidationClass('name')">
          <label for="name">Name</label>
          <md-input
            name="name"
            id="name"
            autocomplete="given-name"
            v-model="form.name"
            :disabled="inProgress"
          />
          <span class="md-error" v-if="!$v.form.name.required">
            This field is required
          </span>
        </md-field>
        <md-field :class="getValidationClass('description')">
          <label for="description">Description</label>
          <md-textarea
            name="description"
            id="description"
            v-model="form.description"
            :disabled="inProgress"
            md-autogrow
          ></md-textarea>

          <span class="md-error" v-if="!$v.form.description.required">
            This field is required
          </span>
        </md-field>
        <div class="md-layout-item md-size-100 px-0 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            :disabled="inProgress"
          >
            Update
          </md-button>
        </div>
      </form>
    </md-dialog-content>
    <md-progress-bar
      v-if="inProgress"
      class="md-accent"
      md-mode="indeterminate"
    />
  </md-dialog>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import { mapActions } from "vuex"

import {
  UPDATE_ACCOMMODATION_DETAILS,
  CAMPSITES,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "UpdateAccommodationDialog",
  props: {
    value: Boolean,
    details: Array,
  },
  mixins: [validationMixin],

  data: () => ({
    inProgress: false,
    form: {
      name: null,
      description: null,
      type: null,
      lang: "EN",
      propertyId: null,
      accommodationId: null,
    },
  }),
  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(CAMPSITES, {
      updateAccommodationDetails: UPDATE_ACCOMMODATION_DETAILS,
    }),

    validateForm() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.handleFormSubmit()
      }
    },
    async handleFormSubmit() {
      this.inProgress = true

      const payload = {
        form: this.form,
      }
      await this.updateAccommodationDetails(payload)

      this.inProgress = false
      this.closeDialog()
    },
    closeDialog() {
      this.$emit("input", false)
      this.$emit("onClose")
    },
    getDetailValue(arr, detailName) {
      const detailObj = arr.find(obj => obj.name === detailName)
      return detailObj?.value || null
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        }
      }
    },
  },
  watch: {
    value(val) {
      if (!val) return

      // ? assign values to their data properties
      // * for form input fields
      this.form.name = this.getDetailValue(this.details, "name_en")
      this.form.description = this.getDetailValue(
        this.details,
        "description_en"
      )
      this.form.type = this.getDetailValue(this.details, "type")

      // * for other
      this.form.propertyId = this.getDetailValue(this.details, "property_id")
      this.form.accommodationId = this.getDetailValue(
        this.details,
        "accommodation_id"
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}
.label-field {
  font-size: 14px;
  font-weight: 500;
  padding-left: 0;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
