import { DateTime } from "luxon"

export const dateInISOString = date => {
  if (!date) return null
  return new Date(date).toISOString().slice(0, 10)
}

export const toISODate = date => {
  if (!date) return null
  return DateTime.fromFormat(date, "LLLL dd, yyyy").toISODate()
}

export const isValidDate = date => {
  return DateTime.fromFormat(date, "LLLL dd, yyyy").isValid
}
