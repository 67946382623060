<template>
  <div>
    <div class="md-layout-item">
      <collapse :collapse="[storeBookingComments.label]" :is-open="false">
        <template slot="md-collapse-pane-1">
          <BookingCommentsTable
            @onCreateButtonClick="handleCreateButtonClick"
          />
        </template>
      </collapse>
    </div>

    <CreateBookingCommentDialog
      :trans-id="transId"
      :is-show-dialog="isShowCreateDialog"
      @onDialogClosed="closeCreateDialog"
    />
  </div>
</template>

<script>
import Collapse from "@/views/components/ui/Collapse"
import BookingCommentsTable from "@/modules/bookings/views/components/tables/BookingCommentsTable"
import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_COMMENTS,
} from "@/modules/bookings/store/mutations-types"
import { mapActions } from "vuex"
import CreateBookingCommentDialog from "@/modules/bookings/views/components/dialogs/CreateBookingCommentDialog"

export default {
  name: "BookingItemComments",
  props: {
    transId: String,
  },
  components: {
    Collapse,
    BookingCommentsTable,
    CreateBookingCommentDialog,
  },
  data: () => ({
    isShowCreateDialog: false,
  }),
  computed: {
    storeBookingComments() {
      return this.$store.state[BOOKINGS].item.comments
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_COMMENTS]),
    fetchBookingItemComments() {
      if (this.transId) {
        this[BOOKINGS_FETCH_ITEM_COMMENTS]({
          transId: this.transId,
        })
      }
    },
    handleCreateButtonClick() {
      this.isShowCreateDialog = true
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false
    },
  },
  mounted() {
    this.fetchBookingItemComments()
  },
}
</script>

<style scoped></style>
