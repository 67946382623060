import {
  SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST,
  FEED_JOB_SHEDULES_ITEMS_FETCH_LIST,
} from "@/modules/job-schedules/store/mutations-types"

const mutations = {
  [SYNC_JOB_SHEDULES_ITEMS_FETCH_LIST](state, payload) {
    state.syncJobSchedules = payload
  },
  [FEED_JOB_SHEDULES_ITEMS_FETCH_LIST](state, payload) {
    state.feedJobSchedules = payload
  },
}

export default mutations
