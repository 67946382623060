<template>
  <GmapMap
    map-type-id="terrain"
    :center="centerPositions"
    :zoom="5"
    :style="mapStyle"
  >
    <GmapInfoWindow
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
    />
    <GmapMarker
      :key="index"
      v-for="(marker, index) in markers"
      :position="marker.position"
      :label="marker.label"
      :draggable="false"
      @mouseover="toggleInfoWindow(marker, index)"
      @mouseout="infoWinOpen = false"
    />
  </GmapMap>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      infoWinOpen: false,
      currentMarkerId: null,
      infoWindowPos: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    }
  },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    mapStyle: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleInfoWindow: function(marker, index) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      //check if its the same marker that was selected if yes toggle
      if (this.currentMarkerId === index) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMarkerId = index
      }
    },
  },
  computed: {
    centerPositions() {
      return this.markers[0].position || {}
    },
  },
}
</script>

<style scoped></style>
