<template>
  <div v-if="isProgress" class="text-center p-5 spinner-customization">
    <md-progress-spinner class="md-primary" md-mode="indeterminate" />
  </div>
</template>
<script>
export default {
  name: "ProgressSpinner",
  props: {
    isProgress: Boolean,
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
    }
  },
  methods: {},
}
</script>
<style scoped>
.spinner-customization {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
</style>
