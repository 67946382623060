var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu',{attrs:{"title":_vm.userName}}),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Home', icon: 'home', path: '/home' }}}),(_vm.hasPermissionsSidebar('campsites'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Campsites',
        icon: 'place',
        path: '/campsites',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('bookings'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Bookings',
        icon: 'list',
        path: '/bookings',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('proposals'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Proposals',
        icon: 'book',
        path: '/proposals',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('pricing'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Pricing',
        icon: 'money',
        path: '/pricing',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('landing_support'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Landing Support',
        icon: 'help_center',
        path: '/landing_support',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('promo_codes'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Promo codes',
        icon: 'widgets',
        path: '/promo_codes',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('campsite_ranking'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Campsite Ranking',
        icon: 'star_rate',
        path: '/campsite_ranking',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('campsite_extras'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Campsite Extras',
        icon: 'dynamic_feed',
        path: '/campsite_extras',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('translations'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Translations',
        icon: 'translate',
        path: '/translations',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('feed_jobs'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Feed Jobs',
        icon: 'task',
        path: '/feed_jobs',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('notifications'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Notifications',
        icon: 'circle_notifications',
        path: '/notifications',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('outstanding_balances'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Outstanding balances',
        icon: 'paid',
        path: '/outstanding_balances',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('reports'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Reports',
        icon: 'summarize',
        path: '/reports',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('product_configuration'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Product Configuration',
        icon: 'settings',
        path: '/product_configuration',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('smart_nav_configuration'))?_c('sidebar-item',{attrs:{"link":{
        name: 'SmartNav Configuration',
        icon: 'table_view',
        path: '/smart_nav_configuration',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('support'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Support',
        icon: 'support',
        path: '/support',
      }}}):_vm._e(),(_vm.hasPermissionsSidebar('tech_support'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Tech Support',
        icon: 'laptop_mac',
        path: '/tech_support',
      }}}):_vm._e(),_c('li',{staticClass:"hidden-lg",on:{"click":_vm.handleLogoutButtonClick}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_vm._v(" person ")]),_c('p',[_vm._v("Logout")])])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }