import { get as _get } from "lodash"

export const getDataItemCollectionList = response => {
  return _get(response, "data.result.dataItemCollectionList", [])
}

export const getDataItemFirstCollection = response => {
  return _get(response, "data.result.dataItemCollectionList[0]", {})
}

export const getDataItemFirstCollectionWithDataItems = response => {
  return _get(response, "data.result.dataItemCollectionList[0]", {})
}

export const getGridDataItemCollection = response => {
  return _get(response, "data.result.gridDataItemCollectionList", [])
}

export const getGridDataItemFirstCollection = response => {
  return _get(response, "data.result.gridDataItemCollectionList[0]", {})
}

export const getGridDataItemCollectionLabel = response => {
  return _get(response, "data.result.label", "")
}

export const getItemsTypesCollection = response => {
  return _get(response, "data.result.csRefDataList", [])
}

export const transformSegment = ({ row }) => {
  const item = {}
  row.forEach(({ name, value }) => {
    item[name] = value
  })
  return item
}

export const getItemCollection = (row, key) => {
  let item = {}
  row.forEach(element => {
    if (element.name == key || element.itemName == key) {
      item = element
    }
  })
  return item
}
