import clientAxios from "@/config/axios.config"
import {
  axiosJobsApiInstance,
  axiosSearchApiInstance,
  axiosBookingApiInstance,
} from "@/config/axios.config"
import AuthRepository from "@/modules/auth/auth.repository"
import BookingsRepository from "@/modules/bookings/bookings.repository"
import PromoCodesRepository from "@/modules/promo-codes/promo-codes.repository"
import UserRepository from "@/modules/user/user.repository"
import CampsitesRepository from "@/modules/campsites/campsites.repository"
import GeoRepository from "@/modules/geo/geo.repository"
import FeedJobsRepository from "@/modules/feed-jobs/feed-jobs.repository"
import LandingSupportRepository from "@/modules/landing-support/landing-support.repository"
import OutstandingBalancesRepository from "@/modules/outstanding-balances/outstanding-balances.repository"
import TranslationsRepository from "@/modules/translations/translations.repository"
import JobsRepository from "@/modules/jobs/jobs.repository"
import RankingRepository from "@/modules/ranking/ranking.repository"
import NotificationsRepository from "@/modules/notifications/notifications.repository"
import SyncJobsRepository from "@/modules/sync-jobs/sync-jobs.repository"
import JobSchedulesRepository from "@/modules/job-schedules/job-schedules.repository"
import ReportsRepository from "@/modules/reports/reports.repository"
import PricingDataRepository from "@/modules/pricing-data/pricingData.repository"
import ProductConfigurationRepository from "@/modules/product-configuration/productConfigurations.repository"
import SupportRepository from "@/modules/support/supports.repository"
import SystemActionsRepository from "@/modules/system-actions/systemActions.repository.js"
import SmartNavConfigurationRepository from "@/modules/smartNav-configuration/smartNavConfigurations.repository"
import SitemapUrls from "@/modules/sitemap-urls/sitemapUrls.repository"
import CampsiteSearchRepository from "@/modules/campsites/campsitesSearch.repository"
import ProductConfigurationBookingRepository from "@/modules/product-configuration/productConfigurationsBooking.repository"
import CampsiteExtrasRepository from "@/modules/campsite-extras/campsite-extras.repository"

export default {
  auth: AuthRepository(clientAxios)("/campsited/login"),
  bookings: BookingsRepository(clientAxios)("/campsited/ops"),
  promoCodes: PromoCodesRepository(clientAxios)("/campsited/ops/promocodes"),
  user: UserRepository(clientAxios)("/campsited/ops"),
  campsites: CampsitesRepository(clientAxios)("/campsited/ops"),
  campsiteSearch: CampsiteSearchRepository(axiosSearchApiInstance)(
    "/campsited"
  ),
  geo: GeoRepository(clientAxios)("/campsited/ops"),
  feedJobs: FeedJobsRepository(clientAxios)("/campsited/ops"),
  landingSupport: LandingSupportRepository(clientAxios)("/campsited/ops"),
  OutstandingBalances: OutstandingBalancesRepository(clientAxios)(
    "/campsited/ops"
  ),
  translations: TranslationsRepository(clientAxios)("/campsited/ops"),
  jobs: JobsRepository(axiosJobsApiInstance)("/campsited"),
  ranking: RankingRepository(clientAxios)("/campsited/ops"),
  notifications: NotificationsRepository(clientAxios)("/campsited/ops"),
  syncJobs: SyncJobsRepository(clientAxios)("/campsited/ops"),
  jobSchedules: JobSchedulesRepository(clientAxios)("/campsited/ops"),
  reports: ReportsRepository(clientAxios)("/campsited/ops"),
  pricingData: PricingDataRepository(clientAxios)("/campsited/ops"),
  productConfiguration: ProductConfigurationRepository(clientAxios)(
    "/campsited/ops"
  ),
  productConfigurationBooking: ProductConfigurationBookingRepository(
    axiosBookingApiInstance
  )("/campsited/booking"),
  smartNavConfiguration: SmartNavConfigurationRepository(clientAxios)(
    "/campsited/ops"
  ),
  sitemapUrls: SitemapUrls(clientAxios)("/campsited/ops"),
  support: SupportRepository(clientAxios)("/campsited/ops"),
  systemActions: SystemActionsRepository(clientAxios)("/campsited/ops"),
  campsiteExtras: CampsiteExtrasRepository(clientAxios)("/campsited/ops"),
}
