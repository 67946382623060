<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a>
        <span>
          {{ title }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserMenu",
  props: {
    title: {
      type: String,
      default: "User",
    },
    avatar: {
      type: String,
      default: "/img/default-avatar.png",
    },
  },
  data() {
    return {
      isClosed: true,
    }
  },
  methods: {
    clicked(e) {
      e.preventDefault()
    },
  },
}
</script>

<style></style>
