export const GEO = "GEO"

export const GEO_FETCH_COUNTRIES = "GEO_FETCH_COUNTRIES"
export const GEO_UPDATE_COUNTRIES = "GEO_UPDATE_COUNTRIES"

export const GEO_FETCH_REGIONS = "GEO_FETCH_REGIONS"
export const GEO_UPDATE_REGIONS = "GEO_UPDATE_REGIONS"

export const GEO_FETCH_DEPARTMENTS = "GEO_FETCH_DEPARTMENTS"
export const GEO_UPDATE_DEPARTMENTS = "GEO_UPDATE_DEPARTMENTS"
