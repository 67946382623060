<template>
  <md-card>
    <md-card-content>
      <form novalidate class="md-layout" @submit.prevent>
        <div class="md-layout-item md-size-50 pl-0" v-if="isLoadType">
          <md-field>
            <label for="proposal-language-field">Proposal Type</label>
            <md-select
              v-model="selectedType"
              name="proposal-type"
              id="proposal-type-field"
              @md-selected="selectType()"
            >
              <md-option
                v-for="(type, index) in types"
                :key="index"
                :value="type"
              >
                {{ type }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-50 pl-0"></div>
        <div class="md-layout-item md-size-50 pl-0" v-if="isLoadType">
          <md-field>
            <label for="proposal-language-field">Proposal language</label>
            <md-select
              v-model="selectedLanguage"
              name="proposal-language"
              id="proposal-language-field"
            >
              <md-option
                v-for="(language, index) in languageList"
                :key="index"
                :value="language"
              >
                {{ language }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100 pl-0 ">
          <md-field
            :class="{ 'md-error md-invalid': $v.name.$error && isFormSubmit }"
          >
            <label class="required">Customer name</label>
            <md-input v-model="name" type="text"></md-input>
            <div class="md-error" v-if="$v.name.$error && isFormSubmit">
              Customer name is required
            </div>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100 pl-0">
          <md-field
            :class="{ 'md-error md-invalid': $v.email.$error && isFormSubmit }"
          >
            <label class="required">Email Address</label>
            <md-input v-model="email" type="email"></md-input>
            <div class="md-error" v-if="$v.email.$error && isFormSubmit">
              Invalid email address
            </div>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-100 pl-0"
          v-if="selectedType == 'WFA'"
        >
          <md-field
            :class="{
              'md-error md-invalid': phoneError,
            }"
          >
            <label class="required">Phone Number</label>
            <md-input v-model="phone" type="text"></md-input>
            <div class="md-error" v-if="phoneError">
              Phone Number is required
            </div>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-50 pl-0 py-2"
          v-if="selectedType == 'WFA'"
        >
          <md-datepicker
            :md-model-type="String"
            v-model="dateOfBirth"
            :class="{
              'md-error md-invalid': dateOfBirthError,
            }"
          >
            <label class="required">Date of Birth</label>
            <div class="md-error" v-if="dateOfBirthError">
              Date of Birth is required
            </div>
          </md-datepicker>
        </div>
        <div
          class="md-layout-item md-size-100 pl-0"
          v-if="selectedType == 'WFA'"
        >
          <md-field>
            <label class="required">Address</label>
            <md-input v-model="custAddress" type="text"></md-input>
            <div class="md-error" v-if="custAddressError">
              Address is required
            </div>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100 pl-0">
          <md-field>
            <label>Comments</label>
            <md-textarea v-model="data.comments" type="text"></md-textarea>
          </md-field>
        </div>

        <div v-if="selectedType == 'WFA'">
          <div
            v-for="(item, index2) in data.quotations"
            :key="index2"
            class="box-shadow "
          >
            <div>
              <b>Proposal {{ index2 + 1 }}</b>
            </div>
            <div style="display:flex">
              <div class="md-layout-item md-size-50 pl-0 py-2">
                <md-datepicker
                  :md-model-type="String"
                  v-model="data.quotations[index2].startDate"
                  :class="{
                    'md-error md-invalid': priceError[index2],
                  }"
                  @input="updateDates"
                  :md-disabled-dates="disabledDatesStart"
                >
                  <label class="required">Start Date</label>
                  <div class="md-error" v-if="startDateError[index2]">
                    Start Date is required
                  </div>
                </md-datepicker>
              </div>

              <div class="md-layout-item md-size-50 pl-0 py-2">
                <md-datepicker
                  :md-model-type="String"
                  v-model="data.quotations[index2].endDate"
                  :md-disabled-dates="disabledDates"
                >
                  <label class="required">End Date</label>
                </md-datepicker>
              </div>
            </div>

            <div class="md-layout-item md-size-100 pl-0 py-2">
              <md-field>
                <label class="required">Number of Adults</label>
                <md-input
                  v-model="data.quotations[index2].numAdults"
                  type="number"
                  min="1"
                ></md-input>
              </md-field>
            </div>

            <div class="md-layout-item pl-0 py-2 md-size-100">
              <div
                v-for="(item, index) in data.quotations[index2].childrenAges"
                :key="index"
                v-bind="item"
                class="child-row"
              >
                <div>
                  <md-field class="child">
                    <label>Child age</label>
                    <div>
                      <md-select
                        name="currency"
                        id="currency-type-field"
                        v-model="
                          data.quotations[index2].childrenAges[index].age
                        "
                        :key="index"
                        :value="item"
                        class="md-size-80 mr-8"
                      >
                        <md-option
                          v-for="(type, index) in 17"
                          :key="index"
                          :value="type"
                        >
                          {{ type }}
                        </md-option>
                      </md-select>
                    </div>
                  </md-field>
                </div>

                <div class="add-link-icon" @click="removeItem(index2, index)">
                  <md-icon style="margin-top: 20px;cursor:pointer"
                    >delete</md-icon
                  >
                </div>
              </div>
              <div
                class="add-link-icon"
                @click="addItem(index2)"
                style="display:inline"
              >
                Add child
                <md-icon class="plus-icon" style="cursor:pointer">add</md-icon>
              </div>
            </div>

            <div style="display:flex">
              <div class="md-layout-item md-size-50 pl-0 py-2">
                <md-field>
                  <label class="required">Price Currency</label>
                  <md-select
                    v-model="data.quotations[index2].priceCurrency"
                    name="currency"
                    id="currency-type-field"
                  >
                    <md-option
                      v-for="(type, index) in currencyList"
                      :key="index"
                      :value="type"
                    >
                      {{ type }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-size-50 pl-0 py-2">
                <md-field
                  :class="{
                    'md-error md-invalid': priceError[index2],
                  }"
                >
                  <label class="required">Price</label>
                  <md-input
                    v-model="data.quotations[index2].price"
                    type="number"
                    min="1"
                  ></md-input>
                  <div class="md-error" v-if="priceError[index2]">
                    Price is required
                  </div>
                </md-field>
              </div>
            </div>

            <div class="md-layout-item md-size-90 pl-0">
              <md-field
                :class="{
                  'md-error md-invalid': linkError[index2],
                }"
              >
                <label class="required">Proposal Link</label>
                <md-input
                  v-model="data.quotations[index2].link"
                  type="text"
                ></md-input>
                <div class="md-error" v-if="linkError[index2]">
                  Invalid URL link
                </div>
              </md-field>
            </div>

            <div style="display: flex;justify-content: right;">
              <div
                class="add-link-icon"
                @click="removeQuotations(index2)"
                v-if="index2 !== 0"
              >
                Remove this section
                <md-icon style="cursor:pointer">delete</md-icon>
              </div>
            </div>
          </div>
          <div @click="addQuotations" style="cursor:pointer" class="pt-3">
            Add a new section
            <md-icon class="plus-icon">add</md-icon>
          </div>
        </div>

        <div
          class="md-layout-item pl-0 py-2 md-size-100"
          v-if="selectedType !== 'WFA'"
        >
          <div
            v-for="(item, index) in proposalLinks"
            :key="index"
            v-bind="item"
            class="child-row"
          >
            <div>
              <md-field
                class="child"
                :class="{
                  'md-error md-invalid':
                    !proposalLinks[index].link.length && invalidLink[index],
                }"
              >
                <label class="required">Proposal Link</label>
                <md-input
                  v-model="proposalLinks[index].link"
                  type="text"
                ></md-input>
                <div
                  class="md-error"
                  v-if="!proposalLinks[index].link.length && invalidLink[index]"
                >
                  Invalid URL link
                </div>
              </md-field>
            </div>

            <div
              class="add-link-icon"
              @click="removeProposalLink(index)"
              v-if="index !== 0"
            >
              <md-icon style="margin-top: 20px;cursor:pointer">delete</md-icon>
            </div>
          </div>
          <div class="pt-3 add-link-icon d-flex">
            Add another link
            <div @click="addProposalLink" style="cursor:pointer">
              <md-icon class="plus-icon">add</md-icon>
            </div>
          </div>
        </div>

        <div
          v-if="!dataFetching"
          class="md-layout-item md-size-100 px-0 content-right"
        >
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="sendProposal()"
          >
            ADD
          </md-button>
        </div>
      </form>
      <div
        v-if="dataFetching"
        class="md-layout-item md-size-100 px-0 content-right"
      >
        <md-progress-spinner class="md-primary" md-mode="indeterminate" />
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import { mapActions, mapState } from "vuex"
import {
  BOOKINGS,
  BOOKING_SEND_PROPOSAL,
  BOOKINGS_FETCH_LANGUAGE,
  BOOKINGS_FETCH_TYPE,
  BOOKINGS_FETCH_CURRENCE,
  BOOKINGS_FETCH_WFA_LANGUAGE,
} from "@/modules/bookings/store/mutations-types"

export default {
  name: "AddProposal",
  props: {
    onDialogClose: Function,
  },
  data: () => ({
    data: {
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      comments: "",
      lang: "",
      type: "",
      quotations: [
        {
          startDate: new Date().toISOString().substr(0, 10),
          endDate: new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
            .toISOString()
            .substr(0, 10),
          numAdults: 1,
          priceCurrency: "EUR",
          price: 1,
          link: "",
          childrenAges: [{ age: 0 }],
        },
      ],
    },
    languageList: [],
    email: "",
    name: "",
    phone: "",
    dateOfBirth: "",
    custAddress: "",
    phoneError: "",
    dateOfBirthError: "",
    custAddressError: "",
    invalidLink: [false],
    startDateError: [false],
    priceError: [false],
    linkError: [false],
    selectedLanguage: "FR",
    selectedType: "Consumer",
    dataFetching: false,
    isFormSubmit: false,
    isFormValid: true,
    isLoadType: false,
    childrenAges: [[{ age: 0 }]],
    proposalLinks: [{ link: "" }],
  }),
  async created() {
    this.getProposalTypes()
    this.getWfaProposalLanguages()
    await this.getProposalLanguages()
    this.languageList = this.languages
  },
  computed: {
    ...mapState(BOOKINGS, {
      languages: state => state.languageList,
      types: state => state.typeList,
      currencyList: state => state.currencyList,
      wfaLanguageList: state => state.wfaLanguageList,
    }),
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKING_SEND_PROPOSAL,
      BOOKINGS_FETCH_LANGUAGE,
      BOOKINGS_FETCH_TYPE,
      BOOKINGS_FETCH_CURRENCE,
      BOOKINGS_FETCH_WFA_LANGUAGE,
    ]),
    selectType() {
      this.selectedLanguage = ""
      this.languageList = this.languages
      if (this.selectedType == "WFA") {
        this.selectedLanguage = this.wfaLanguageList[0]
        this.languageList = this.wfaLanguageList
      }
    },
    isValidURL(str) {
      return /^http(s)?:\/\/(www\.)?.+\..+$/.test(str)
    },
    async sendProposal() {
      this.isFormSubmit = true
      let flag = false
      this.$v.$touch()
      this.isFormValid = true

      if (this.selectedType != "WFA") {
        for (let index = 0; index < this.proposalLinks.length; index++) {
          if (
            !this.proposalLinks[index].link ||
            !this.isValidURL(this.proposalLinks[index].link)
          ) {
            this.invalidLink[index] = true
            this.isFormValid = false
            return
          }
        }
      } else {
        if (!this.phone) {
          this.phoneError = true
          this.isFormValid = false
          flag = true
        }
        if (!this.dateOfBirth) {
          this.dateOfBirthError = true
          this.isFormValid = false
          flag = true
        }
        if (!this.custAddress) {
          this.custAddressError = true
          this.isFormValid = false
          flag = true
        }
        for (let index = 0; index < this.data.quotations.length; index++) {
          if (!this.data.quotations[index].startDate) {
            this.startDateError[index] = true
            this.isFormValid = false
            flag = true
          }
          if (!this.data.quotations[index].price) {
            this.priceError[index] = true
            this.isFormValid = false
            flag = true
          }
          if (
            !this.data.quotations[index].link ||
            !this.isValidURL(this.data.quotations[index].link)
          ) {
            this.linkError[index] = true
            this.isFormValid = false
            flag = true
          }
          if (!this.data.quotations[index].endDate) {
            let newDate = new Date(this.data.quotations[0].startDate)
            const day = 60 * 60 * 24 * 1000
            let endDate = new Date(newDate.getTime() + day)
            this.data.quotations[index].endDate =
              endDate.getFullYear() +
              "-" +
              ("0" + (endDate.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + endDate.getDate()).slice(-2)
          }
        }
      }
      if (flag) return

      if (!this.$v.$invalid && this.isFormValid) {
        if (this.selectedType != "WFA") {
          this.phone = ""
          this.dateOfBirth = ""
          this.custAddress = ""
          this.startDate = ""
          this.endDate = ""
          this.priceCurrency = ""
          this.price = 0
          this.data.quotations = this.proposalLinks
        } else {
          for (let index = 0; index < this.data.quotations.length; index++) {
            this.data.quotations[index].childrenAges = this.data.quotations[
              index
            ].childrenAges.map(x => x.age)
          }
        }

        this.dataFetching = true
        this.data.lang = this.selectedLanguage
        this.data.type = this.selectedType
        this.data.customerName = this.name
        this.data.customerEmail = this.email
        this.data.customerPhone = this.phone
        this.data.customerDateOfBirth = this.dateOfBirth
        this.data.customerAddress = this.custAddress

        try {
          await this[BOOKING_SEND_PROPOSAL](this.data)
        } catch (e) {
          this.dataFetching = false
          this.$swal.fire({
            position: "bottom-end",
            html: e,
            icon: "error",
            title: "Add Proposal",
            showConfirmButton: false,
            timer: 4000,
            showCloseButton: true,
          })
        }

        if (!this.dataFetching) {
          return
        }
        this.dataFetching = false
        this.$emit("onFormSubmit")
      }
    },
    addLink() {
      if (!this.tempLink) {
        this.showWarningMessage("Enter Link Before Adding")
        return
      }
      this.$v.$touch()
      if (!this.$v.tempLink.$error) {
        if (this.tempLink && !this.tempLinks.includes(this.tempLink)) {
          this.tempLinks.push(this.tempLink)
        } else {
          this.showWarningMessage("Proposal Link Already Added")
        }
        this.tempLink = ""
      }
    },
    removeLink(index) {
      this.tempLinks.splice(index, 1)
    },
    showWarningMessage(name) {
      this.$swal(name)
    },
    async getProposalLanguages() {
      await this[BOOKINGS_FETCH_LANGUAGE]([])
    },
    async getWfaProposalLanguages() {
      await this[BOOKINGS_FETCH_WFA_LANGUAGE]([])
    },
    async getProposalTypes() {
      await this[BOOKINGS_FETCH_TYPE]([])
      await this[BOOKINGS_FETCH_CURRENCE]([])
      this.isLoadType = true
    },
    getAgeValue(index, value) {
      this.childAge[index] = value
    },
    addItem(item) {
      this.data.quotations[item].childrenAges.push({
        value: 0,
      })
    },
    addProposalLink() {
      this.proposalLinks.push({
        link: "",
      })
      this.invalidLink.push(false)
    },
    addQuotations() {
      this.data.quotations.push({
        startDate: "",
        endDate: "",
        numAdults: 1,
        childrenAges: [{ age: 0 }],
        priceCurrency: "EUR",
        price: 1,
        link: "",
      })
      this.startDateError.push(false)
      this.priceError.push(false)
      this.linkError.push(false)
    },
    removeItem(item, index) {
      this.data.quotations[item].childrenAges.splice(index, 1)
    },
    removeProposalLink(index) {
      this.proposalLinks.splice(index, 1)
    },
    removeQuotations(index) {
      this.data.quotations.splice(index, 1)
    },
    disabledDates(date) {
      const concalanderdate = date.toISOString().split("T")[0]
      return concalanderdate < this.data.quotations[0].startDate
    },
    disabledDatesStart(date) {
      const concalanderdate = date.toISOString().split("T")[0]
      let newDate = new Date()
      const day = 60 * 60 * 24 * 1000
      let finaldate = new Date(newDate.getTime() - day)
        .toISOString()
        .substr(0, 10)
      return concalanderdate < finaldate
    },
    updateDates() {
      let newDate = new Date(this.data.quotations[0].startDate)
      const day = 60 * 60 * 24 * 1000
      this.data.quotations[0].endDate = new Date(newDate.getTime() + day)
        .toISOString()
        .substr(0, 10)
    },
  },
  validations: {
    name: {
      required,
    },
    email: { required, email },
  },
}
</script>

<style scoped>
.display-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px 0px;
}

.display-link > div:first-child {
  width: 90%;
  word-break: break-all;
}

.display-link > div:last-child {
  width: 10%;
  text-align: right;
}

.display-link p {
  line-height: normal;
  margin: 0;
}

.display-link > div:last-child i {
  cursor: pointer;
}
.add-link-icon {
  float: "right";
  display: flex;
}
.required:after {
  content: " *";
  color: red;
}
.md-error {
  color: var(--md-theme-default-fieldvariant, #ff1744);
  opacity: 1;
  transform: translateZ(0);
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.child {
  width: 440px;
  display: flex;
  flex-direction: column;
}
.child-row {
  display: flex;
  justify-content: space-between;
}
.plus-icon {
  color: #000;
  font-weight: 700;
}

.box-shadow {
  box-shadow: 0 0px 4px 0 rgb(0 0 0 / 14%);
  padding-right: -12px;
  margin-left: -11px;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
}
</style>
