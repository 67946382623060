<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Add campsited adjustment</md-dialog-title>

      <md-dialog-content>
        <CreateBookingAdjustmentForm
          :is-form-sending="isFormSending"
          :adjustment-field-list="storeAdjustmentFieldList"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import CreateBookingAdjustmentForm from "@/modules/bookings/views/components/forms/CreateBookingAdjustmentForm"
import {
  BOOKINGS,
  BOOKINGS_CREATE_ITEM_ADJUSTMENTS,
  BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS,
} from "@/modules/bookings/store/mutations-types"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "CreateBookingAdjustmentDialog",
  props: {
    transId: String,
    isShowDialog: Boolean,
    onDialogClosed: Function,
  },
  components: {
    CreateBookingAdjustmentForm,
  },
  mixins: [dialogMixin],
  computed: {
    storeAdjustmentFieldList() {
      return this.$store.state[BOOKINGS].item.adjustmentFields
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [
      BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS,
      BOOKINGS_CREATE_ITEM_ADJUSTMENTS,
    ]),
    fetchAdjustmentFieldsList() {
      if (this.storeAdjustmentFieldList.length === 0) {
        this[BOOKINGS_FETCH_ITEM_ADJUSTMENT_FIELDS]()
      }
    },
    async handleFormSubmit(values) {
      this.isFormSending = true
      await this[BOOKINGS_CREATE_ITEM_ADJUSTMENTS]({
        transId: this.transId,
        ...values,
      })
      this.isFormSending = false
      this.closeDialog()
    },
  },

  mounted() {
    this.fetchAdjustmentFieldsList()
  },
}
</script>

<style scoped></style>
