<template>
  <collapse :collapse="[collapseTitle]" :is-open="isOpen">
    <template slot="md-collapse-pane-1">
      <md-dialog class="carousel-dialog" :md-active.sync="showCarousel">
        <ImageCarousel :items="allItems" />
      </md-dialog>
      <div class="md-layout content-right py-4">
        <md-button
          v-if="allItems.length"
          class="md-button md-success"
          @click="showCarousel = true"
        >
          View all accommodation photos
        </md-button>
      </div>
      <UniversalTable
        :export-file-name="exportTableFileName"
        :data="data"
        :data-accommodations="setAccom"
      >
        <template slot="header-right-controls" slot-scope="{ selectedRow }">
          <div v-if="!!selectedRow">
            <md-button
              class="md-info md-sm"
              @click="handleViewButtonClick(selectedRow)"
            >
              View Details
              <md-icon>keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </template>
      </UniversalTable>
    </template>
  </collapse>
</template>

<script>
import UniversalTable from "@/views/components/tables/UniversalTable"
import Collapse from "@/views/components/ui/Collapse"
import { getRowCellByColumnName } from "@/utils/table.utils"
import ImageCarousel from "@/views/components/ui/ImageCarousel"

import LocalStorageService from "@/services/local-storage.service"

import { mapActions, mapState } from "vuex"
import {
  CAMPSITES,
  ACCOMMODATION_FETCH_PHOTOS,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "CampsiteAccommodations",
  LocalStorageService,
  props: {
    isOpen: Boolean,
    data: Object,
  },
  components: {
    Collapse,
    UniversalTable,
    ImageCarousel,
  },
  data: () => ({
    collapseTitle: "Accommodations",
    exportTableFileName: "campsite_accommodations",
    showCarousel: false,
    setAccom: "accommodation",
    resultatsAccommodations: [],
  }),
  async created() {
    await this.fetchPhotos({ campsiteId: this.$route.params.id })
    this.resultatsAccommodations = JSON.parse(
      localStorage.getItem("resultatsAccommodations") || "[]"
    )
  },
  computed: {
    ...mapState(CAMPSITES, {
      accommodationsPhotos: state => state.item.accommodationsPhotos,
    }),
    allItems() {
      return [...this.accommodationsPhotos]
    },
    stayFacilityId() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions(CAMPSITES, {
      fetchPhotos: ACCOMMODATION_FETCH_PHOTOS,
    }),
    handleViewButtonClick(selectedRow) {
      const accommodationIdCell = getRowCellByColumnName(
        selectedRow,
        "accommodation_id"
      )
      const accommodationId = accommodationIdCell.value
      const { stayFacilityId } = this
      this.$router.push({
        path: `/accommodation/${accommodationId}`,
        query: { stayFacilityId },
      })
      localStorage.setItem("accommodationId", accommodationId)
      this.resultatsAccommodations.push(accommodationId)
      localStorage.setItem(
        "resultatsAccommodations",
        JSON.stringify(this.resultatsAccommodations)
      )
    },
  },
}
</script>

<style scoped></style>
