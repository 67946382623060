<template>
  <div class="md-layout-item">
    <collapse :collapse="[storeStatusHistory.label]" :is-open="false">
      <template slot="md-collapse-pane-1">
        <BookingStatusHistoryTable />
      </template>
    </collapse>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import {
  BOOKINGS,
  BOOKINGS_FETCH_ITEM_STATUS_HISTORY,
} from "@/modules/bookings/store/mutations-types"
import Collapse from "@/views/components/ui/Collapse"
import BookingStatusHistoryTable from "@/modules/bookings/views/components/tables/BookingStatusHistoryTable"

export default {
  name: "BookingStatusHistory",
  components: {
    Collapse,
    BookingStatusHistoryTable,
  },
  props: {
    transId: String,
  },
  computed: {
    storeStatusHistory() {
      const { statusHistory } = this.$store.state[BOOKINGS].item
      return statusHistory
    },
  },
  methods: {
    ...mapActions(BOOKINGS, [BOOKINGS_FETCH_ITEM_STATUS_HISTORY]),
    fetchBookingStatusHistory() {
      if (this.transId) {
        this[BOOKINGS_FETCH_ITEM_STATUS_HISTORY]({
          transId: this.transId,
        })
      }
    },
  },
  mounted() {
    this.fetchBookingStatusHistory()
  },
}
</script>

<style scoped></style>
