<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field class="mb-4">
      <label for="promo-field">Promo code</label>
      <md-select v-model="bookingPromo" name="booking-promo" id="promo-field">
        <md-option
          v-for="option in bookingPromoList"
          v-show="option.key"
          :key="option.key"
          :value="option.key"
        >
          {{ option.key }}
        </md-option>
      </md-select>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button
        class="md-raised md-danger"
        v-if="bookingPromo"
        @click="handleRemove"
      >
        Remove
      </md-button>
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="isFormSending"
      >
        Update
      </md-button>
    </div>
  </form>
</template>

<script>
export default {
  name: "BookingPromoCodeForm",
  props: {
    onDialogClose: Function,
    oldBookingStatus: String,
    isFormSending: Boolean,
    bookingPromoList: {
      type: Array,
      default: () => [],
    },
    onFormSubmit: Function,
  },
  data: () => ({
    bookingPromo: null,
  }),
  methods: {
    setInitialFormData() {
      this.bookingPromo = this.oldBookingStatus
    },
    async handleSubmit() {
      this.$emit("onFormSubmit", {
        promoCode: this.bookingPromo,
        isUpdate: true,
        isRemove: false,
      })
    },
    async handleRemove() {
      this.$emit("onFormSubmit", {
        promoCode: this.bookingPromo,
        isUpdate: false,
        isRemove: true,
      })
    },
  },
  mounted() {
    this.setInitialFormData()
  },
}
</script>

<style scoped></style>
