import { DateTime } from "luxon"

export const normalizeDateInTable = (item = {}) => {
  //Format datetime
  if (
    item.type === "Date" ||
    (item.name === "new_value" &&
      DateTime.fromISO(item.value.split(" ")[0]).isValid)
  ) {
    //prepare date for Luxon
    const dt = item.value.split(" ")[0]
    item.value = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
  }
  return item
}

export const normalizeDateInDetails = (item = {}) => {
  if (!item.value) {
    return item
  }
  //Format datetime
  if (item.type === "Date") {
    //prepare date for Luxon
    const dt = item.value.split(" ")[0]
    item.normalizedValue = DateTime.fromISO(dt).toFormat("LLLL dd, yyyy")
  }
  return item
}
