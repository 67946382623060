<template>
  <md-card>
    <md-card-content>
      <UniversalTable
        :export-file-name="tableExportFileName"
        :data="reportDetailsList"
        :empty-title-text="emptyTableText"
      >
      </UniversalTable>
    </md-card-content>
  </md-card>
</template>

<script>
import BookingsTableMixin from "@/modules/bookings/mixins/bookings-table.mixin"
import { REPORTS } from "@/modules/reports/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"

export default {
  name: "ReportsTable",
  mixins: [BookingsTableMixin],
  components: {
    UniversalTable,
  },
  data: () => ({
    tableExportFileName: "report",
    emptyTableText: "Report is empty",
  }),
  computed: {
    reportDetailsList() {
      return this.$store.state[REPORTS].reportDetailsList
    },
  },
}
</script>

<style lang="scss" scoped>
.md-table + .md-table {
  margin-top: 16px;
}
</style>
