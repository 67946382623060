<template>
  <div class="table__header">
    <div class="table__header-right-controls">
      <md-field>
        <label>Per page</label>
        <md-select v-model="pagination.perPage" name="pages">
          <md-option
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
            {{ item }}
          </md-option>
        </md-select>
      </md-field>

      <div class="export-controls ml-3">
        <ExportTableDropdown
          :export-file-name="exportFileName"
          :export-data="exportData"
        />
      </div>
      <div v-if="this.$route.name === 'Bookings'" class="export-controls ml-3">
        <ExportExcelTableDropdown />
      </div>
    </div>

    <div class="text-right">
      <slot />
    </div>
  </div>
</template>

<script>
import ExportTableDropdown from "@/views/components/ui/ExportTableDropdown"
import ExportExcelTableDropdown from "@/views/components/ui/ExportExcelTableDropdown"
export default {
  name: "TableHeader",
  components: { ExportTableDropdown, ExportExcelTableDropdown },
  props: {
    exportFileName: String,
    exportData: Array,
    pagination: {
      type: Object,
      default: () => ({
        perPage: 0,
        perPageOptions: [],
      }),
    },
  },
  created() {
    if (
      sessionStorage.getItem("pagination") &&
      this.exportFileName === "URL Items"
    ) {
      this.pagination.perPage = parseInt(sessionStorage.getItem("pagination"))
    }
    if (
      sessionStorage.getItem("paginationlevel2") &&
      this.exportFileName === "Level 2 Items"
    ) {
      this.pagination.perPage = parseInt(
        sessionStorage.getItem("paginationlevel2")
      )
    }
  },
  mounted() {
    if (
      sessionStorage.getItem("pagination") &&
      this.exportFileName === "URL Items"
    ) {
      this.pagination.perPage = parseInt(sessionStorage.getItem("pagination"))
    }
    if (
      sessionStorage.getItem("paginationlevel2") &&
      this.exportFileName === "Level 2 Items"
    ) {
      this.pagination.perPage = parseInt(
        sessionStorage.getItem("paginationlevel2")
      )
    }
  },
  updated() {
    if (this.exportFileName === "URL Items") {
      sessionStorage.setItem("pagination", this.pagination.perPage)
      sessionStorage.setItem("page", this.exportFileName)
    }
    if (this.exportFileName === "Level 2 Items") {
      sessionStorage.setItem("paginationlevel2", this.pagination.perPage)
      sessionStorage.setItem("pagelevel2", this.exportFileName)
    }
  },
}
</script>

<style scoped lang="scss">
.table__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  &-right-controls {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .md-field {
    width: auto;
  }
}
</style>
