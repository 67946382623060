export default $axios => resource => ({
  fetchProductConfigurationsList() {
    return $axios.get(`${resource}/csproducts`)
  },
  updateProductConfigurations(data, productKey, lang) {
    return $axios.put(
      `${resource}/csproducts/update?product_key=${productKey}&lang=${lang}`,
      data
    )
  },

  getProductConfigurations(productKey, lang) {
    return $axios.get(
      `${resource}/csproducts/view?product_key=${productKey}&lang=${lang}`
    )
  },
  getProductConfigurationLanguages() {
    return $axios.get(`${resource}/static/supportedlanguages`)
  },
  getProductConfigurationProductKey() {
    return $axios.get(`${resource}/static/productkeys`)
  },
})
