export default $axios => resource => ({
  fetchList(params) {
    return $axios.get(`${resource}/feedjob/jobs`, {
      params,
    })
  },
  fetchItems(params) {
    return $axios.get(`${resource}/feedjobexclincl/items`, {
      params,
    })
  },
  addItem(params) {
    return $axios.post(`${resource}/feedjobexclincl/additem`, params)
  },
  removeItem(payload) {
    return $axios.post(`${resource}/feedjobexclincl/removeitem`, payload)
  },
  fetchItemTypesList() {
    return $axios.get(`${resource}/feedjobitemtypes`)
  },
  fetchJobNamesList() {
    return $axios.get(`${resource}/feedjobnames`)
  },
  uploadNewCsvFile(itemType, jobName, formData) {
    return $axios.post(`${resource}/feedjobexclincl/uploadcsvfile`, formData, {
      params: {
        itemType,
        jobName,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
})
