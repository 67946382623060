export const SMARTNAV_CONFIGURATIONS = "SMARTNAV_CONFIGURATIONS"

export const GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST"
export const GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST"
export const GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST"
export const GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST"
export const GET_URLS_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_URLS_SMARTNAV_CONFIGURATIONS_LIST"
export const ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS =
  "ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS"
export const ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS =
  "ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS"
export const ADD_URL_ITEMS_SMARTNAV_CONFIGURATIONS =
  "ADD_URL_ITEMS_SMARTNAV_CONFIGURATIONS"
export const SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION =
  "SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION"
export const SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION =
  "SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION"
export const SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION =
  "SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION"
export const SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION =
  "SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION"
export const SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION =
  "SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION"
export const SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION =
  "SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION"
export const REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS =
  "REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS"
export const REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS =
  "REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS"
export const REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS =
  "REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS"
export const SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION =
  "SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION"
export const SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION =
  "SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION"
export const UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS =
  "UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS"
export const SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS =
  "SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS"
export const SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS =
  "SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS"
export const GET_STATIC_SUPPORTED_LANGUAGES_LIST =
  "GET_STATIC_SUPPORTED_LANGUAGES_LIST"
export const GET_SITEMAP_LEVELS_LIST = "GET_SITEMAP_LEVELS_LIST"
export const GET_MOBILE_MENU_STYLES_LIST = "GET_MOBILE_MENU_STYLES_LIST"
export const UPDATE_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS =
  "UPDATE_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS"
export const SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION =
  "SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION"
export const GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST"
export const SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS =
  "SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS"
export const UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS =
  "UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS"
export const GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST =
  "GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST"
export const ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST =
  "ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST"
export const UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST =
  "UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST"
export const REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST =
  "REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST"
export const GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST =
  "GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST"
export const SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS =
  "SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS"
export const FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS =
  "FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS"
