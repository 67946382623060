export default $axios => resource => ({
  fetchReports() {
    return $axios.get(`${resource}/reports`)
  },
  fetchReportDetails(params) {
    return $axios.get(`${resource}/reports/details`, {
      params,
    })
  },
})
