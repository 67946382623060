import { SUPPORT_FETCH_SUPPORT_LIST } from "@/modules/support/store/mutations-type"
import { getGridDataItemFirstCollection } from "@/utils/api.utils"

const actions = {
  async [SUPPORT_FETCH_SUPPORT_LIST]({ commit }) {
    const response = await this.$repositories.support.fetchSupportList()
    const data = getGridDataItemFirstCollection(response)
    commit(SUPPORT_FETCH_SUPPORT_LIST, data)
  },
}

export default actions
