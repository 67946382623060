<template>
  <div>
    <md-dialog
      :md-active.sync="isOpen"
      @md-clicked-outside="closeDialog"
      :style="dialogStyles"
    >
      <md-dialog-title>Add campsited comment</md-dialog-title>

      <md-dialog-content>
        <CreateCampsitesCommentForm
          :is-form-sending="isFormSending"
          :init-trans-id="transId"
          :onDialogClose="closeDialog"
          @onFormSubmit="handleFormSubmit"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import CreateCampsitesCommentForm from "@/modules/campsites/views/components/forms/CreateCampsitesCommentForm"
import {
  CAMPSITES,
  CAMPSITES_CREATE_ITEM_COMMENT,
} from "@/modules/campsites/store/mutations-types"
import { mapActions } from "vuex"
import dialogMixin from "@/mixins/dialog.mixin"

export default {
  name: "CreatesCampsitesCommentDialog",
  props: {
    transId: String,
    isShowDialog: Boolean,
    onDialogClosed: Function,
  },
  mixins: [dialogMixin],
  components: {
    CreateCampsitesCommentForm,
  },
  methods: {
    ...mapActions(CAMPSITES, [CAMPSITES_CREATE_ITEM_COMMENT]),
    async handleFormSubmit(values) {
      this.isFormSending = true
      await this[CAMPSITES_CREATE_ITEM_COMMENT]({
        campsiteId: this.transId,
        ...values,
      })
      this.isFormSending = false
      this.closeDialog()
    },
  },
}
</script>

<style scoped></style>
