import { render, staticRenderFns } from "./RankingPage.vue?vue&type=template&id=bcf43e48&scoped=true&"
import script from "./RankingPage.vue?vue&type=script&lang=js&"
export * from "./RankingPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf43e48",
  null
  
)

export default component.exports