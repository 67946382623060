<template>
  <drop-down>
    <md-button
      slot="title"
      data-toggle="dropdown"
      class="md-info md-just-icon md-round"
    >
      <md-icon>save_alt</md-icon>
      <md-tooltip md-direction="top">
        Export data to CSV
      </md-tooltip>
    </md-button>

    <ul class="dropdown-menu dropdown-menu-right">
      <li class="md-info">
        <a href="#" class="md-info p-0 m-0">
          <download-csv
            :data="exportData"
            :name="`data_${exportFileName}.csv`"
            class="md-layout-item px-3 py-2"
          >
            Export to CSV
          </download-csv>
        </a>
      </li>
    </ul>
  </drop-down>
</template>

<script>
export default {
  name: "ExportTableDropdown",
  props: {
    exportFileName: {
      type: String,
      default: "booking",
    },
    exportData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped></style>
