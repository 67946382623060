<template>
  <div class="system-actions-page">
    <div class="system-actions-coontainer">
      <div class="table-tab md-card">
        <ProgressSpinner :isProgress="loading" />
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">Sitemap Data</h4>
          </div>
        </md-card-header>
        <div class="search-button md-layout md-alignment-right">
          <div class="md-layout-item md-size-25 select-form">
            <md-field
              :class="{
                'md-error md-invalid': error.language,
              }"
            >
              <label>Choose Language</label>
              <md-select v-model="sitemapUrlsPayload.language" name="language">
                <md-option
                  v-for="langName in supportedLanguages"
                  :key="langName"
                  :value="langName"
                  >{{ langName }}
                </md-option>
              </md-select>
              <div class="md-error" v-if="error.language">
                Language is required
              </div>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-alignment-left">
          <div class="md-layout-item md-size-33 select-form">
            <md-field>
              <label>Country</label>
              <md-select
                v-model="sitemapUrlsPayload.country"
                name="country"
                id="country"
                md-dense
                @input="updateCountryForRegionsList"
                :disabled="paramsSet2"
              >
                <md-option
                  v-for="(country, key) in countries"
                  :key="key"
                  :value="country.key"
                  >{{ country.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 select-form">
            <md-field>
              <label>Region</label>
              <md-select
                v-model="sitemapUrlsPayload.region"
                name="regions"
                id="regions"
                md-dense
                @input="updateRegionForDepartmentList"
                :disabled="paramsSet2"
              >
                <md-option
                  v-for="(region, key) in regions"
                  :key="key"
                  :value="region.key"
                  >{{ region.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 select-form">
            <md-field>
              <label>Department</label>
              <md-select
                v-model="sitemapUrlsPayload.department"
                name="country"
                id="country"
                md-dense
                :disabled="paramsSet2"
              >
                <md-option
                  v-for="(department, key) in departments"
                  :key="key"
                  :value="department.key"
                  >{{ department.value }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-alignment-left">
          <div class="md-layout-item md-size-33 select-form">
            <md-field
              :class="{
                'md-error md-invalid': error.level,
              }"
            >
              <label>Sitemap Level</label>
              <md-select
                v-model="sitemapUrlsPayload.level"
                name="country"
                id="country"
                md-dense
                :disabled="paramsSet1"
              >
                <md-option
                  v-for="(country, key) in sitemapLevels"
                  :key="key"
                  :value="country.key"
                  >{{ country.value }}
                </md-option>
              </md-select>
              <div class="md-error" v-if="error.level">
                Sitemap URL level is required
              </div>
            </md-field>
          </div>
          <div class="md-layout-item md-size-64 select-form">
            <md-field
              class="mr-4"
              :class="{
                'md-error md-invalid': error.level,
              }"
            >
              <label class="required">Url containing text</label>
              <md-input
                v-model="sitemapUrlsPayload.text"
                type="text"
                :disabled="paramsSet1"
              ></md-input>
              <div class="md-error" v-if="error.text">
                Sitemap URL text is required
              </div>
            </md-field>
          </div>
        </div>
        <div class="search-button">
          <md-button
            class="button-search md-button md-theme-default md-simple"
            @click="clearSitemapUrls"
            >CLEAR</md-button
          >
          <md-button
            class="button-search"
            @click="findSitemapUrls"
            :disabled="!sitemapUrlsPayload.language"
            :class="{
              'md-success': sitemapUrlsPayload.language,
            }"
            >SEARCH</md-button
          >
        </div>
        <UniversalTable class="px-4" :data="sitemapUrls"> </UniversalTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SITEMAP_URLS,
  SITEMAP_URLS_FETCH_URLS_LIST,
  SITEMAP_URLS_FETCH_URLS_LEVEL_LIST,
} from "@/modules/sitemap-urls/store/mutations-type"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { mapActions, mapState } from "vuex"
import ProgressSpinner from "@/views/components/ui/ProgressSpinner"
import {
  GEO,
  GEO_FETCH_COUNTRIES,
  GEO_FETCH_REGIONS,
  GEO_FETCH_DEPARTMENTS,
} from "@/modules/geo/store/mutations-types"
import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
} from "@/modules/smartNav-configuration/store/mutations-type"

export default {
  name: "SitemapUrls",
  components: {
    UniversalTable,
    ProgressSpinner,
  },
  data: () => ({
    systemaction: "",
    loading: false,
    loadingActions: false,
    sitemapUrlsPayload: {
      exportName: "Sitemap Urls",
      country: "",
      region: "",
      department: "",
      level: "",
      text: "",
      language: "",
      isFetchCountry: false,
    },
    error: {
      text: false,
      level: false,
      language: false,
    },
    label: "",
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SITEMAP_URLS, {
      fetchSitemapLevels: SITEMAP_URLS_FETCH_URLS_LEVEL_LIST,
      fetchUrls: SITEMAP_URLS_FETCH_URLS_LIST,
    }),
    ...mapActions(GEO, {
      fetchCountriesList: GEO_FETCH_COUNTRIES,
      fetchRegionsList: GEO_FETCH_REGIONS,
      fetchDepartmentList: GEO_FETCH_DEPARTMENTS,
    }),
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      fetchSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
    }),
    // Initialize the System Actions
    async init() {
      this.loading = true
      await this.fetchCountriesList({
        country: this.sitemapUrlsPayload.country,
      })
      await this.fetchSupportedLanguages()
      await this.fetchSitemapLevels()
      this.loading = false
    },
    async updateCountryForRegionsList() {
      this.sitemapUrlsPayload.department = ""
      this.sitemapUrlsPayload.region = ""
      if (this.sitemapUrlsPayload.country) {
        await this.fetchRegionsList({
          country: this.sitemapUrlsPayload.country,
        })
        this.sitemapUrlsPayload.isFetchCountry = true
      }
    },
    async updateRegionForDepartmentList() {
      this.sitemapUrlsPayload.department = ""
      if (this.sitemapUrlsPayload.country) {
        await this.fetchDepartmentList({
          country: this.sitemapUrlsPayload.country,
          region: this.sitemapUrlsPayload.region,
        })
        this.sitemapUrlsPayload.isFetchCountry = true
      }
    },
    findSitemapUrls() {
      this.error.text = false
      this.error.level = false
      this.error.language = false
      if (!this.sitemapUrlsPayload.language) this.error.language = true
      let payload = ""
      payload = payload + `lang=${this.sitemapUrlsPayload.language}`
      if (this.paramsSet2) {
        if (!this.sitemapUrlsPayload.text) {
          this.error.text = true
        }
        if (!this.sitemapUrlsPayload.level) {
          this.error.level = true
        }
        if (this.error.level || this.error.text) return
        payload = payload + `&sitemaplevel=${this.sitemapUrlsPayload.level}`
        payload = payload + `&urltext=${this.sitemapUrlsPayload.text}`
      } else {
        if (this.sitemapUrlsPayload.country)
          payload = payload + `&country=${this.sitemapUrlsPayload.country}`
        if (this.sitemapUrlsPayload.region)
          payload = payload + `&region=${this.sitemapUrlsPayload.region}`
        if (this.sitemapUrlsPayload.department)
          payload =
            payload + `&department=${this.sitemapUrlsPayload.department}`
      }
      this.fetchUrls(payload)
    },
    clearSitemapUrls() {
      this.sitemapUrlsPayload.country = ""
      this.sitemapUrlsPayload.region = ""
      this.sitemapUrlsPayload.department = ""
      this.sitemapUrlsPayload.level = ""
      this.sitemapUrlsPayload.text = ""
      this.sitemapUrlsPayload.language = ""
      this.error = {
        text: false,
        level: false,
        language: false,
      }
    },
  },
  computed: {
    ...mapState(SITEMAP_URLS, {
      sitemapLevels: state => state.sitemapLevels,
      sitemapUrls: state => state.sitemapUrls,
    }),
    ...mapState(GEO, {
      countries: state => state.countries,
      regions: state => state.regions,
      departments: state => state.departments,
    }),
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
    }),
    paramsSet1() {
      return this.sitemapUrlsPayload.country ||
        this.sitemapUrlsPayload.department ||
        this.sitemapUrlsPayload.region
        ? true
        : false
    },
    paramsSet2() {
      return this.sitemapUrlsPayload.text || this.sitemapUrlsPayload.level
        ? true
        : false
    },
  },
}
</script>

<style scoped>
.md-error {
  color: red !important;
}
.search-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}
</style>
