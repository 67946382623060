import actions from "@/modules/geo/store/actions"
import mutations from "@/modules/geo/store/mutations"

const state = () => ({
  countries: [],
  regions: [],
  departments: [],
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
