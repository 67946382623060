<template>
  <div class="md-layout-item">
    <SearchCampsitesForm
      :initial-form-data="initialSearchFormData"
      :countries-list="countries"
      :is-countries-fetching="isCountriesFetching"
      :regions-list="regions"
      :is-regions-fetching="isRegionsFetching"
      :departments-list="departments"
      :is-departments-fetching="isDepartmentsFetching"
      :is-campsites-fetching="isCampsitesFetching"
      @onCountryChange="handleCountryChange"
      @onRegionChange="handleRegionChange"
      @onFormSubmit="handleSearchFormSubmit"
    />

    <SearchCampsitesTable />
  </div>
</template>

<script>
import SearchCampsitesForm from "@/modules/campsites/views/components/forms/SearchCampsitesForm"
import {
  GEO,
  GEO_FETCH_COUNTRIES,
  GEO_FETCH_REGIONS,
  GEO_FETCH_DEPARTMENTS,
} from "@/modules/geo/store/mutations-types"
import { mapActions } from "vuex"
import {
  CAMPSITES,
  CAMPSITES_FETCH_LIST,
  CAMPSITES_UPDATE_SEARCH_FORM,
} from "@/modules/campsites/store/mutations-types"
import SearchCampsitesTable from "@/modules/campsites/views/components/tables/SearchCampsitesTable"

export default {
  name: "SearchCampsitesPage",
  components: { SearchCampsitesTable, SearchCampsitesForm },
  data: () => ({
    isCampsitesFetching: false,
    isCountriesFetching: false,
    isRegionsFetching: false,
    isDepartmentsFetching: false,
  }),
  computed: {
    countries() {
      return this.$store.state[GEO].countries
    },
    regions() {
      return this.$store.state[GEO].regions
    },
    departments() {
      return this.$store.state[GEO].departments
    },
    initialSearchFormData() {
      return this.$store.state[CAMPSITES].searchForm
    },
  },
  methods: {
    ...mapActions(GEO, [
      GEO_FETCH_COUNTRIES,
      GEO_FETCH_REGIONS,
      GEO_FETCH_DEPARTMENTS,
    ]),
    ...mapActions(CAMPSITES, [
      CAMPSITES_FETCH_LIST,
      CAMPSITES_UPDATE_SEARCH_FORM,
    ]),
    async fetchCountries() {
      if (this.countries.length > 0) return

      this.isCountriesFetching = true
      await this[GEO_FETCH_COUNTRIES]()
      this.isCountriesFetching = false
    },
    async fetchCountryRegions(country) {
      this.isRegionsFetching = true
      await this[GEO_FETCH_REGIONS]({ country })
      this.isRegionsFetching = false
    },
    async fetchRegionDepartments({ country, region }) {
      this.isDepartmentsFetching = true
      await this[GEO_FETCH_DEPARTMENTS]({ country, region })
      this.isDepartmentsFetching = false
    },
    handleCountryChange(country) {
      this.fetchCountryRegions(country)
    },
    handleRegionChange(options) {
      this.fetchRegionDepartments(options)
    },
    async handleSearchFormSubmit(values) {
      const campsite = {
        campsiteId: values.campsiteId,
        campsiteName: values.campsiteName,
        department: values.department,
        region: values.region,
        country: values.country,
        group: values.group,
        campsiteEmail: values.campsiteEmail,
        filters: values.filters,
      }
      this[CAMPSITES_UPDATE_SEARCH_FORM](values)
      this.isCampsitesFetching = true
      await this[CAMPSITES_FETCH_LIST](campsite)
      this.isCampsitesFetching = false
    },
  },
  mounted() {
    this.fetchCountries()
  },
}
</script>

<style scoped></style>
