<template>
  <side-bar
    :active-color="sidebarBackground"
    :background-image="sidebarBackgroundImage"
    :data-background-color="sidebarBackgroundColor"
  >
    <user-menu :title="userName"></user-menu>

    <template slot="links">
      <sidebar-item :link="{ name: 'Home', icon: 'home', path: '/home' }">
      </sidebar-item>

      <sidebar-item
        v-if="hasPermissionsSidebar('campsites')"
        :link="{
          name: 'Campsites',
          icon: 'place',
          path: '/campsites',
        }"
      />
      <sidebar-item
        v-if="hasPermissionsSidebar('bookings')"
        :link="{
          name: 'Bookings',
          icon: 'list',
          path: '/bookings',
        }"
      />
      <sidebar-item
        v-if="hasPermissionsSidebar('proposals')"
        :link="{
          name: 'Proposals',
          icon: 'book',
          path: '/proposals',
        }"
      />
      <sidebar-item
        v-if="hasPermissionsSidebar('pricing')"
        :link="{
          name: 'Pricing',
          icon: 'money',
          path: '/pricing',
        }"
      />
      <sidebar-item
        v-if="hasPermissionsSidebar('landing_support')"
        :link="{
          name: 'Landing Support',
          icon: 'help_center',
          path: '/landing_support',
        }"
      />
      <sidebar-item
        v-if="hasPermissionsSidebar('promo_codes')"
        :link="{
          name: 'Promo codes',
          icon: 'widgets',
          path: '/promo_codes',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('campsite_ranking')"
        :link="{
          name: 'Campsite Ranking',
          icon: 'star_rate',
          path: '/campsite_ranking',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('campsite_extras')"
        :link="{
          name: 'Campsite Extras',
          icon: 'dynamic_feed',
          path: '/campsite_extras',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('translations')"
        :link="{
          name: 'Translations',
          icon: 'translate',
          path: '/translations',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('feed_jobs')"
        :link="{
          name: 'Feed Jobs',
          icon: 'task',
          path: '/feed_jobs',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('notifications')"
        :link="{
          name: 'Notifications',
          icon: 'circle_notifications',
          path: '/notifications',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('outstanding_balances')"
        :link="{
          name: 'Outstanding balances',
          icon: 'paid',
          path: '/outstanding_balances',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('reports')"
        :link="{
          name: 'Reports',
          icon: 'summarize',
          path: '/reports',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('product_configuration')"
        :link="{
          name: 'Product Configuration',
          icon: 'settings',
          path: '/product_configuration',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('smart_nav_configuration')"
        :link="{
          name: 'SmartNav Configuration',
          icon: 'table_view',
          path: '/smart_nav_configuration',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('support')"
        :link="{
          name: 'Support',
          icon: 'support',
          path: '/support',
        }"
      />

      <sidebar-item
        v-if="hasPermissionsSidebar('tech_support')"
        :link="{
          name: 'Tech Support',
          icon: 'laptop_mac',
          path: '/tech_support',
        }"
      />

      <li @click="handleLogoutButtonClick" class="hidden-lg">
        <a href="#" class="nav-link">
          <i class="md-icon md-icon-font md-theme-default"> person </i>
          <p>Logout</p>
        </a>
      </li>
    </template>
  </side-bar>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar"

import { hasElement } from "@/utils/dom.utils"
import appSidebarMixin from "@/mixins/app-sidebar.mixin"
import UserMenu from "@/views/components/ui/UserMenu"
import { USER } from "@/modules/user/store/mutations-types"

import { hasPermissions } from "@/utils/permissions.utils"

import { mapActions } from "vuex"
import { AUTH, AUTH_LOGOUT } from "@/modules/auth/store/mutations-types"

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
    document.getElementsByClassName(className)[0].scrollTop = 0
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList
  let isWindows = navigator.platform.startsWith("Win")
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar")
    initScrollbar("sidebar-wrapper")
    initScrollbar("main-panel")

    docClasses.add("perfect-scrollbar-on")
  } else {
    docClasses.add("perfect-scrollbar-off")
  }
}

export default {
  name: "AppSidebar",
  props: {
    backgroundColor: String,
    backgroundImage: String,
  },
  mixins: [appSidebarMixin],
  components: {
    UserMenu,
  },
  computed: {
    userName() {
      return this.$store.getters[`${USER}/userName`]
    },
    userEntities() {
      return this.$store.getters[`${USER}/entities`]
    },
  },
  methods: {
    ...mapActions(AUTH, [AUTH_LOGOUT]),
    hasPermissionsSidebar(route) {
      return hasPermissions(route, this.userEntities)
    },
    async handleLogoutButtonClick() {
      localStorage.removeItem("resultatsAccommodations")
      await this[AUTH_LOGOUT]()
      this.$router.push("/login")
    },
  },
  updated() {
    reinitScrollbar()
  },
  mounted() {
    reinitScrollbar()
  },
}
</script>

<style scoped></style>
