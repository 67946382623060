<template>
  <div>
    <div v-for="item in data" :key="item.label">
      <DetailsCollapse
        :is-open="isOpen"
        :is-loading="false"
        :label="item.label"
        :elements="item.dataItems"
        @navigate-to-dialog="navigateToDialogItem"
        @refresh-cache="refreshCache"
      />
    </div>
    <div>
      <UpdateCampsiteDialog
        :is-show-dialog="isShowCampsiteDialog"
        :campsite-status="''"
        :campsiteDetail="campsiteDetail"
        @onDialogClosed="closeUpdateCampsiteDialog"
      />
    </div>
  </div>
</template>

<script>
import DetailsCollapse from "@/views/components/ui/DetailsCollapse"
import UpdateCampsiteDialog from "@/modules/campsites/views/components/dialogs/UpdateCampsiteDialog"
import {
  CAMPSITES,
  REFRESH_CAMPSITES_CACHE,
} from "@/modules/campsites/store/mutations-types"
import { mapActions } from "vuex"

export default {
  name: "CampsiteDetails",
  props: {
    isOpen: Boolean,
    data: {
      type: Array,
      default: () => ({}),
    },
  },
  data: () => ({
    isShowCampsiteDialog: false,
    isShowCampsiteUpdateGroupDialog: false,
    campsiteDetail: "",
  }),
  methods: {
    ...mapActions(CAMPSITES, [REFRESH_CAMPSITES_CACHE]),
    handlePaymentButtonClick() {
      this.isShowCampsiteDialog = true
    },
    closeUpdateCampsiteDialog() {
      this.isShowCampsiteDialog = false
    },
    navigateToDialogItem(campsiteGroup) {
      this.isShowCampsiteDialog = true
      this.campsiteDetail = campsiteGroup
    },
    async refreshCache() {
      try {
        await this[REFRESH_CAMPSITES_CACHE](this.$route.params.id)
        this.$swal.fire({
          position: "top-end",
          html: "Cache refreshed successfully",
          icon: "success",
          title: "Cache Refresh",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
        })
      } catch (e) {
        this.$swal.fire({
          position: "top-end",
          html: e,
          icon: "error",
          title: "Cache Refresh",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
        })
      }
    },
  },
  components: {
    DetailsCollapse,
    UpdateCampsiteDialog,
  },
}
</script>

<style scoped></style>
