<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <md-field class="mb-4">
      <label for="status-field">Booking status</label>
      <md-select
        v-model="bookingStatus"
        name="booking-status"
        id="status-field"
      >
        <md-option
          v-for="option in bookingStatusesList"
          :key="option.key"
          :value="option.key"
        >
          {{ option.value }}
        </md-option>
      </md-select>
    </md-field>

    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="isFormSending"
      >
        Update status
      </md-button>
    </div>
  </form>
</template>

<script>
export default {
  name: "UpdateBookingStatusForm",
  props: {
    onDialogClose: Function,
    oldBookingStatus: String,
    isFormSending: Boolean,
    bookingStatusesList: {
      type: Array,
      default: () => [],
    },
    onFormSubmit: Function,
  },
  data: () => ({
    bookingStatus: null,
  }),
  methods: {
    setInitialFormData() {
      this.bookingStatus = this.oldBookingStatus
    },
    async handleSubmit() {
      this.$emit("onFormSubmit", {
        bookingStatus: this.bookingStatus,
      })
    },
  },
  mounted() {
    this.setInitialFormData()
  },
}
</script>

<style scoped></style>
