import {
  CAMPSITES_CLEAR_ITEM,
  CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS,
  CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES,
  CAMPSITES_UPDATE_ITEM_DETAILS,
  CAMPSITES_UPDATE_ITEM_FACILITIES,
  CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO,
  CAMPSITES_UPDATE_ITEM_GENERAL_INFO,
  CAMPSITES_UPDATE_ITEM_LEISURE,
  CAMPSITES_UPDATE_ITEM_LOCATION_INFO,
  CAMPSITES_UPDATE_ITEM_WELLBEING,
  CAMPSITES_UPDATE_LIST,
  CAMPSITES_UPDATE_SEARCH_FORM,
  CAMPSITES_UPDATE_PHOTOS,
  CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO,
  CAMPSITES_FETCH_PHOTO_LABEL,
  CAMPSITES_FETCH_UPLOADED_PHOTOS,
  CAMPSITES_UPDATE_UPLOAD_PHOTOS,
  CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS,
  CAMPSITES_RESET_PHOTO_POSITION,
  CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM,
  ACCOMMODATION_FETCH_DETAILS,
  ACCOMMODATION_FETCH_FEATURES,
  ACCOMMODATION_UPDATE_PHOTOS,
  ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO,
  ACCOMMODATION_RESET_PHOTO_POSITION,
  CAMPSITES_DELETE_CS_UPLOADED_PHOTO,
  ACCOMMODATION_UPDATE_PHOTOS_BY_ID,
  CAMPSITES_FETCH_DATA_STATUS,
  CAMPSITES_UPDATE_ITEM_COMMENTS,
  GET_CAMPSITE_GROUP,
  CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM,
  FETCH_STAY_FACILITIES_AMENITIES,
  SET_SELECTED_AMENITY_ROW,
  SET_FACILITIES_AMENITIES_CATEGOIRES,
  SET_FACILITIES_AMENITIES_SUB_CATEGORIES,
  SET_FACILITIES_AMENITIES_LABELS,
  SET_ACCOMMODATION_AMENITIES_CATEGORIES,
  SET_ACCOMMODATION_AMENITIES_LABELS,
  FETCH_ACCOMMODATION_AMENITY_DETAILS,
} from "@/modules/campsites/store/mutations-types"
import { INITIAL_ITEM_STATE } from "@/modules/campsites/store/index"

const mutations = {
  [CAMPSITES_CLEAR_ITEM](state) {
    state.item = { ...INITIAL_ITEM_STATE }
  },
  [CAMPSITES_UPDATE_LIST](state, payload) {
    state.list = payload
  },
  [CAMPSITES_UPDATE_ITEM_DETAILS](state, payload) {
    state.item.details = payload
  },
  [CAMPSITES_UPDATE_ITEM_GENERAL_INFO](state, payload) {
    state.item.generalInfo = payload
  },
  [CAMPSITES_UPDATE_ITEM_LOCATION_INFO](state, payload) {
    state.item.locationInfo = payload
  },
  [CAMPSITES_UPDATE_ITEM_WELLBEING](state, payload) {
    state.item.wellbeing = payload
  },
  [CAMPSITES_UPDATE_ITEM_LEISURE](state, payload) {
    state.item.leisure = payload
  },
  [CAMPSITES_UPDATE_ITEM_FOOD_DRINK_INFO](state, payload) {
    state.item.foodDrinkInfo = payload
  },
  [CAMPSITES_UPDATE_ITEM_FACILITIES](state, payload) {
    state.item.facilities = payload
  },
  [CAMPSITES_UPDATE_ITEM_CHILDREN_ACTIVITIES](state, payload) {
    state.item.childrenActivities = payload
  },
  [CAMPSITES_UPDATE_ITEM_ACCOMMODATIONS](state, payload) {
    state.item.accommodations = payload
  },
  [CAMPSITES_UPDATE_SEARCH_FORM](state, payload) {
    state.searchForm = payload
  },
  [CAMPSITES_UPDATE_PHOTOS](state, payload) {
    state.item.photos = payload.sort((currentItem, nextItem) => {
      if (currentItem.duplicate === "false" && nextItem.duplicate === "true") {
        return -1
      }

      if (currentItem.cs_position < 6 && nextItem.cs_position >= 6) {
        return -1
      }

      return 0
    })
  },
  [CAMPSITES_UPDATE_UPLOAD_PHOTOS](state, payload) {
    state.item.uploadedPhotos = payload
  },
  [CAMPSITES_DELETE_CS_UPLOADED_PHOTO](state, payload) {
    state.item.uploadedPhotos = state.item.uploadedPhotos.filter(
      item => item.key !== payload.photo.key
    )
  },
  [CAMPSITES_UPDATE_ACTIVE_STATUS_PHOTO](state, photo) {
    state.item = {
      ...state.item,
      photos: state.item.photos.map(item => {
        if (item.key === photo.key) {
          return { ...item, active: photo.active }
        }
        return { ...item }
      }),
    }
  },
  [CAMPSITES_UPDATE_ACTIVE_STATUS_UPLOAD_PHOTOS](state, photo) {
    state.item = {
      ...state.item,
      uploadedPhotos: state.item.uploadedPhotos.map(item => {
        if (item.key === photo.key) {
          return { ...item, active: photo.active }
        }
        return { ...item }
      }),
    }
  },
  [CAMPSITES_RESET_PHOTO_POSITION](state, payload) {
    state.item.photos = payload
  },
  [ACCOMMODATION_FETCH_DETAILS](state, payload) {
    state.accommodationDetails = payload
  },
  [ACCOMMODATION_FETCH_FEATURES](state, payload) {
    state.accommodationFeatures = payload
  },
  [ACCOMMODATION_UPDATE_PHOTOS](state, payload) {
    state.item.accommodationsPhotos = payload
  },
  [ACCOMMODATION_UPDATE_PHOTOS_BY_ID](state, payload) {
    state.item.accommodationPhotos = payload
  },
  [ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO](state, photo) {
    state.item = {
      ...state.item,
      accommodationPhotos: state.item.accommodationPhotos.map(item => {
        if (item.key === photo.key) {
          return { ...item, active: photo.active }
        }
        return { ...item }
      }),
    }
  },
  [ACCOMMODATION_RESET_PHOTO_POSITION](state, payload) {
    state.item.accommodationPhotos = payload
  },
  [CAMPSITES_FETCH_DATA_SYNC_JOB_ITEM](state, payload) {
    state.dataSyncJobItem = payload
  },
  [CAMPSITES_FETCH_DATA_SYNC_JOB_DETAIL_ITEM](state, payload) {
    state.dataSyncJobItemDetail = payload
  },
  [CAMPSITES_FETCH_DATA_STATUS](state, payload) {
    state.dataStatusItem = payload
  },
  [CAMPSITES_FETCH_PHOTO_LABEL](state, payload) {
    state.item.photoLabel = payload
  },

  [CAMPSITES_UPDATE_ITEM_COMMENTS](state, payload) {
    state.item.comments = payload
  },
  [FETCH_STAY_FACILITIES_AMENITIES](state, payload) {
    state.selectedAmenity = payload
  },

  [CAMPSITES_FETCH_UPLOADED_PHOTOS](state, payload) {
    payload.map(item => {
      return (item.isUpload = true)
    })
    state.item.uploadedPhotos = payload
  },
  [GET_CAMPSITE_GROUP](state, payload) {
    const {
      data: {
        result: { csRefDataList: groupNames },
      },
    } = payload
    state.groupNames = groupNames
  },
  [SET_SELECTED_AMENITY_ROW](state, payload) {
    state.selectedRow = payload
  },

  [SET_FACILITIES_AMENITIES_CATEGOIRES](state, payload) {
    state.amenitiesCategoires = payload
  },
  [SET_FACILITIES_AMENITIES_SUB_CATEGORIES](state, payload) {
    state.amenitiesSubCategories = payload
  },
  [SET_FACILITIES_AMENITIES_LABELS](state, payload) {
    state.amenitiesLabels = payload
  },

  [SET_ACCOMMODATION_AMENITIES_CATEGORIES](state, payload) {
    state.amenitiesCategoires = payload
  },
  [SET_ACCOMMODATION_AMENITIES_LABELS](state, payload) {
    state.amenitiesLabels = payload
  },
  [FETCH_ACCOMMODATION_AMENITY_DETAILS](state, payload) {
    state.accommodationFeaturDetails = payload
  },
}

export default mutations
