import { get as _get } from "lodash"
import {
  GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION,
  GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST,
  GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
  GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
  GET_URLS_SMARTNAV_CONFIGURATIONS_LIST,
  ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
  ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  ADD_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION,
  SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION,
  SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION,
  SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION,
  SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION,
  REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS,
  REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION,
  SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION,
  UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS,
  SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
  GET_SITEMAP_LEVELS_LIST,
  GET_MOBILE_MENU_STYLES_LIST,
  UPDATE_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS,
  GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST,
  SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS,
  UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS,
  GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
  GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST,
  SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS,
  FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS,
} from "@/modules/smartNav-configuration/store/mutations-type"
import {
  getGridDataItemFirstCollection,
  getDataItemCollectionList,
} from "@/utils/api.utils"

const actions = {
  async [GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST]({ commit }, input) {
    const response = await this.$repositories.smartNavConfiguration.getLevel1Items(
      input
    )
    const data = getGridDataItemFirstCollection(response)
    commit(GET_LEVEL1_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST]({ commit }, input) {
    const response = await this.$repositories.smartNavConfiguration.getLevel2Items(
      input
    )
    const data = getGridDataItemFirstCollection(response)
    commit(GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST](
    { commit },
    input
  ) {
    const response = await this.$repositories.smartNavConfiguration.getLevel2Categories(
      input
    )
    const data = getGridDataItemFirstCollection(response)
    commit(GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST](
    { commit },
    input
  ) {
    const response = await this.$repositories.smartNavConfiguration.getLevel2CategoriesRef(
      input
    )
    const data = _get(response, "data.result.csRefDataList", [])
    commit(GET_LEVEL2_CATEGORIES_REF_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST](
    { commit },
    input
  ) {
    const response = await this.$repositories.smartNavConfiguration.getItemsAssociatedWithLevel(
      input
    )
    const data = getGridDataItemFirstCollection(response)
    commit(GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS]({ commit }) {
    const response = await this.$repositories.smartNavConfiguration.getSyncJobs()
    const data = getDataItemCollectionList(response)
    commit(FETCH_SITEMAP_SYNC_JOBS_SMARTNAV_CONFIGURATIONS, data)
  },
  async [GET_URLS_SMARTNAV_CONFIGURATIONS_LIST]({ commit }, input) {
    const response = await this.$repositories.smartNavConfiguration.getUrlsList(
      input
    )
    const data = _get(response, "data.result.csRefDataList", [])
    commit(GET_URLS_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [GET_STATIC_SUPPORTED_LANGUAGES_LIST]({ commit }) {
    const response = await this.$repositories.smartNavConfiguration.getSupportedLanguages()
    commit(GET_STATIC_SUPPORTED_LANGUAGES_LIST, response.data)
  },
  async [GET_MOBILE_MENU_STYLES_LIST]({ commit }) {
    const response = await this.$repositories.smartNavConfiguration.getMobileMenuStyles()
    const data = _get(response, "data.result.csRefDataList", [])
    commit(GET_MOBILE_MENU_STYLES_LIST, data)
  },
  async [GET_SITEMAP_LEVELS_LIST]({ commit }) {
    const response = await this.$repositories.smartNavConfiguration.getSitemapLevels()
    const data = _get(response, "data.result.csRefDataList", [])
    commit(GET_SITEMAP_LEVELS_LIST, data)
  },
  async [ADD_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.addLevel2Item(data.pop())
    await dispatch(GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data.pop())
  },
  async [ADD_URL_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.addUrlItems(data.pop())
    await dispatch(
      GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [ADD_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.addLevel2Category(data.pop())
    await dispatch(
      GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [UPDATE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.updateLevel2Category(
      data.pop()
    )
    await dispatch(
      GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_ITEM_LEVEL1_SMARTNAV_CONFIGURATION, data)
  },
  async [SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_ITEM_LEVEL2_SMARTNAV_CONFIGURATION, data)
  },
  async [SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION, data)
  },
  async [SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_PARENT_ITEM_SMARTNAV_CONFIGURATION, data)
  },
  async [SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_PARENT_LEVEL_SMARTNAV_CONFIGURATION, data)
  },
  async [SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SELECTED_URL_ITEM_SMARTNAV_CONFIGURATION, data)
  },
  async [SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION]({ commit }, data) {
    commit(SET_LEVEL_TWO_ITEMS_SMARTNAV_CONFIGURATION, data)
  },
  async [SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION](
    { commit },
    data
  ) {
    commit(SET_LEVEL_TWO_ITEMS_DBL_CLICK_SMARTNAV_CONFIGURATION, data)
  },
  async [SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS]({ commit }, data) {
    await commit(SET_SELECTED_CATEGORY_SMARTNAV_CONFIGURATIONS, data)
  },
  async [SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION](
    { commit },
    data
  ) {
    commit(SET_ITEMS_ASSOCIATED_TO_LEVEL_SMARTNAV_CONFIGURATION, data)
  },
  async [REMOVE_LEVEL2_ITEM_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.removeLevel2Item(data.pop())
    await dispatch(GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data.pop())
  },
  async [UPDATE_URL_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.updateUrlItem(data.pop())
    await dispatch(
      GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [REMOVE_LEVEL2_CATEGORY_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.removeLevel2Category(
      data.pop()
    )
    await dispatch(
      GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [REMOVE_URL_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.removeUrlItems(data.pop())
    await dispatch(
      GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [UPDATE_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.updateLevel2Item(data[0])
    await dispatch(GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data[1])
  },
  async [SWAP_URL_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.swapUrlItem(data.pop())
    await dispatch(
      GET_ITEMS_ASSOCIATEDTO_LEVEL_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [SWAP_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.swapLevel2Item(data.pop())
    await dispatch(GET_LEVEL2_ITEMS_SMARTNAV_CONFIGURATIONS_LIST, data.pop())
  },
  async [GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST]({ commit }, input) {
    const response = await this.$repositories.smartNavConfiguration.getCategoriesGroup(
      input
    )
    const data = getGridDataItemFirstCollection(response)
    commit(GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST, data)
  },
  async [ADD_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST]({ dispatch }, input) {
    await this.$repositories.smartNavConfiguration.addCategoryGroup(input.pop())
    await dispatch(
      GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      input.pop()
    )
  },
  async [UPDATE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST](
    { dispatch },
    input
  ) {
    await this.$repositories.smartNavConfiguration.updateCategoryGroup(
      input.pop()
    )
    await dispatch(
      GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      input.pop()
    )
  },
  async [REMOVE_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_LIST](
    { dispatch },
    data
  ) {
    await this.$repositories.smartNavConfiguration.removeCategoryGroup(
      data.pop()
    )
    await dispatch(
      GET_CATEGORIES_GROUP_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
  async [GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST](
    { commit },
    input
  ) {
    const response = await this.$repositories.smartNavConfiguration.getCategoriesGroupRef(
      input
    )
    const data = _get(response, "data.result.csRefDataList", [])
    commit(GET_CATEGORY_GROUP_SMARTNAV_CONFIGURATIONS_REF_LIST, data)
  },
  async [SWAP_CATEGORY_SMARTNAV_CONFIGURATIONS]({ dispatch }, data) {
    await this.$repositories.smartNavConfiguration.swapCategoriesPosition(
      data.pop()
    )
    await dispatch(
      GET_LEVEL2_CATEGORIES_SMARTNAV_CONFIGURATIONS_LIST,
      data.pop()
    )
  },
}

export default actions
