<template>
  <md-card-actions
    style="flex-wrap:wrap;"
    md-alignment="space-between"
    class="mx-0"
  >
    <div>
      <p class="card-category">
        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
      </p>
    </div>
    <pagination
      class="pagination-no-border pagination-success"
      v-model="pagination.currentPage"
      :per-page="pagination.perPage"
      :total="total"
    >
    </pagination>
  </md-card-actions>
</template>

<script>
import Pagination from "@/views/components/ui/Pagination"

export default {
  name: "TableFooter",
  props: {
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Pagination,
  },
}
</script>

<style scoped></style>
