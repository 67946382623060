<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-20 md-medium-size-25 md-small-size-30">
      <h4 class="items-title md-layout md-alignment-top-center md-title">
        CS Selected photos
      </h4>
      <div class="sticky top-items">
        <div v-if="!$screen.xxl" class="md-layout buttons-top-items">
          <md-button
            class="md-icon-button md-raised icons-top "
            @click="save"
            :disabled="!isChanged"
            :class="{
              'md-success': isChanged,
            }"
          >
            <font-awesome-icon :icon="'check'" />
            <md-tooltip md-direction="top">Apply CS Selected photos</md-tooltip>
          </md-button>
          <md-button
            class="md-icon-button md-raised md-success icons-top "
            @click="handleRestore"
          >
            <font-awesome-icon :icon="'undo-alt'" />
            <md-tooltip md-direction="top">Revert to SH photo order</md-tooltip>
          </md-button>
        </div>
        <div v-else class="md-layout buttons-top-items">
          <md-button
            :disabled="!isChanged"
            @click="save"
            class="md-button mt-30 button-top"
            :class="{
              'md-success': isChanged,
            }"
          >
            Apply
          </md-button>
          <md-button
            class="md-button md-success mt-30 button-top"
            @click="handleRestore"
          >
            Restore
          </md-button>
        </div>
        <draggable
          :list="topItems"
          group="photos"
          class="md-gutter md-alignment-center"
          :move="handleTopItemsMove"
          @end="handleTopMoveEnd"
        >
          <div
            class="md-layout md-layout-item md-size-100 gallery-item"
            v-for="(item, idx) in topItems"
            :key="idx"
          >
            <image-component
              :item="item"
              :class="{
                'ready-to-drop': idx === futureIndex,
              }"
            />
          </div>
        </draggable>
      </div>
    </div>
    <div class="md-layout-item md-size-80 md-medium-size-75 md-small-size-70">
      <h4 class="items-title md-title">Secure Holiday Photos</h4>
      <div>
        <draggable
          :list="allItems"
          :group="{ name: 'photos', pull: 'clone', put: false }"
          :move="handleMove"
          class="md-layout"
          @end="handleDragEnd"
          handle=".draggable"
        >
          <div
            class="md-layout md-layout-item md-size-20 md-medium-size-25 md-small-size-30 gallery-item"
            v-for="(item, idx) in allItems"
            :key="idx"
            @click.prevent="show(item.urlmedium)"
          >
            <image-component
              @change="handleChangePhoto"
              editable
              :item="item"
              :class="{
                selected: !!item.cs_selected_position,
              }"
            />
          </div>
        </draggable>
      </div>
    </div>
    <viewer
      :options="options"
      :images="images"
      @inited="inited"
      class="viewer"
      ref="viewer"
    >
      <img v-show="false" v-for="src in images" :src="src" :key="src" />
    </viewer>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isSave"
      md-persistent
    >
      <h4>CS Selected Photos Successfully saved</h4>
    </md-snackbar>
    <md-snackbar
      :md-position="position"
      :md-duration="duration"
      :md-active.sync="isRestore"
      md-persistent
    >
      <h4>CS Selected Photos reset to SH photo order</h4>
    </md-snackbar>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { mapState, mapActions } from "vuex"
import {
  CAMPSITES,
  ACCOMMODATION_FETCH_PHOTOS_BY_ID,
  ACCOMMODATION_RE_SORT_PHOTOS,
  ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO,
  ACCOMMODATION_RESET_PHOTO_POSITION,
  ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS,
} from "@/modules/campsites/store/mutations-types"
import ImageComponent from "@/views/components/ui/ImageComponent"
import Viewer from "v-viewer/src/component"
import "viewerjs/dist/viewer.css"
export default {
  name: "AccommodationPhotosPage",
  components: {
    draggable,
    ImageComponent,
    Viewer,
  },
  data() {
    return {
      movingIndex: undefined,
      futureIndex: undefined,
      images: [],
      options: {
        button: false,
        title: false,
        tooltip: false,
        toolbar: false,
        navbar: false,
        movable: false,
        zoomable: false,
      },
      position: "center",
      duration: 4000,
      isSave: false,
      isRestore: false,
    }
  },
  watch: {
    $refs: {
      deep: true,
    },
  },
  computed: {
    ...mapState(CAMPSITES, {
      itemPhotos: state => state.item.accommodationPhotos,
    }),
    topItems() {
      return this.itemPhotos
        .filter(({ cs_selected_position }) => !!cs_selected_position)
        .sort(
          (a, b) =>
            Number(a.cs_selected_position) - Number(b.cs_selected_position)
        )
    },
    allItems() {
      return [...this.itemPhotos]
    },
    isChanged() {
      return this.itemPhotos.some(({ changed }) => !!changed)
    },
  },
  created() {
    this.fetchPhotos({ accommodationId: this.$route.params.id })
  },
  methods: {
    ...mapActions(CAMPSITES, {
      fetchPhotos: ACCOMMODATION_FETCH_PHOTOS_BY_ID,
      reselectTop: ACCOMMODATION_RE_SORT_PHOTOS,
      updatePhoto: ACCOMMODATION_UPDATE_ACTIVE_STATUS_PHOTO,
      resetPhoto: ACCOMMODATION_RESET_PHOTO_POSITION,
      savePositions: ACCOMMODATION_UPDATE_CS_SELECTED_PHOTOS,
    }),
    handleDragEnd() {
      if ([this.movingIndex, this.futureIndex].includes(undefined)) {
        return false
      }
      const item1 = this.allItems[this.movingIndex]
      const item2 = this.topItems[this.futureIndex]
      const modifiedList = this.allItems.map(item => {
        if (item.key === item1.key) {
          return {
            ...item,
            cs_selected_position: item2.cs_selected_position,
            cs_position: item2.cs_position,
            changed: true,
          }
        } else if (item.key === item2.key) {
          return {
            ...item,
            cs_selected_position: item1.cs_selected_position,
            cs_position: item1.cs_position,
            changed: true,
          }
        }
        return item
      })
      this.movingIndex = undefined
      this.futureIndex = undefined
      this.reselectTop(modifiedList)
    },
    handleTopItemsMove(event) {
      const { index, futureIndex } = event.draggedContext
      this.movingIndex = index
      this.futureIndex =
        futureIndex > this.topItems.length - 1
          ? this.topItems.length - 1
          : futureIndex
      return false
    },
    handleMove(event) {
      if (this.allItems.length === event.relatedContext.list.length) {
        return false
      }
      const { index, futureIndex } = event.draggedContext
      this.movingIndex = index
      this.futureIndex =
        futureIndex > this.topItems.length - 1
          ? this.topItems.length - 1
          : futureIndex
      return false
    },
    handleTopMoveEnd() {
      if ([this.movingIndex, this.futureIndex].includes(undefined)) {
        return false
      }
      const list = [...this.topItems]
      const item1 = list[this.movingIndex]
      const item2 = list[this.futureIndex]
      const modifiedList = this.allItems.map(item => {
        if (item.key === item1.key) {
          return {
            ...item,
            cs_selected_position: item2.cs_selected_position,
            cs_position: item2.cs_position,
            changed: true,
          }
        } else if (item.key === item2.key) {
          return {
            ...item,
            cs_selected_position: item1.cs_selected_position,
            cs_position: item1.cs_position,
            changed: true,
          }
        }
        return item
      })
      this.movingIndex = undefined
      this.futureIndex = undefined
      this.reselectTop(modifiedList)
    },
    handleRestore() {
      this.resetPhoto({ accommodationId: this.$route.params.id })
      this.isRestore = true
    },
    handleChangePhoto(item) {
      this.updatePhoto(item)
    },
    save() {
      const accommodationId = this.$route.params.id
      const items = this.topItems.map(item => {
        return {
          key: item.key,
          position: Number(item.cs_selected_position),
        }
      })
      this.savePositions({ accommodationId, items })
      this.isSave = true
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    show(url) {
      this.images = [url]
      this.$viewer.show()
    },
  },
}
</script>

<style scoped>
.ready-to-drop {
  box-shadow: 0 0 15px #00bbff;
}
.selected {
  opacity: 0.3;
}

.sticky {
  position: sticky;
  top: 0;
  padding: 10px;
}

.gallery-item {
  padding: 10px;
  position: relative;
}

.top-items {
  background: #8080805c;
}

h4.items-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.buttons-top-items {
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
}

.button-top {
  font-size: 11px;
  height: 26px;
}
.icons-top {
  width: 50px;
  height: 25px;
}
</style>
