import {
  SYSTEM_ACTIONS_FETCH_ACTIONS_LIST,
  SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL,
  SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS,
} from "@/modules/system-actions/store/mutations-type"

import {
  getItemsTypesCollection,
  getGridDataItemFirstCollection,
} from "@/utils/api.utils"

const actions = {
  async [SYSTEM_ACTIONS_FETCH_ACTIONS_LIST]({ commit }) {
    const response = await this.$repositories.systemActions.fetchActionsList()
    const data = getItemsTypesCollection(response)
    commit(SYSTEM_ACTIONS_FETCH_ACTIONS_LIST, data)
  },
  async [SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL]({ commit }) {
    const response = await this.$repositories.systemActions.fetchDatasourceStatusDetail()
    const detail = getGridDataItemFirstCollection(response)
    commit(SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL, detail)
  },
  async [SYSTEM_ACTIONS_CHANGE_DATASOURCE_STATUS]({ dispatch }, systemAction) {
    await this.$repositories.systemActions.changeDatasourceStatus(systemAction)
    await dispatch(SYSTEM_ACTIONS_FETCH_DATASOURCE_STATUS_DETAIL)
  },
}

export default actions
