import {
  RANKING_FETCH_CONFIG_LIST,
  RANKING_FETCH_CONFIG_FILTER_LIST,
} from "@/modules/ranking/store/mutations-types"

const mutations = {
  [RANKING_FETCH_CONFIG_LIST](state, payload) {
    state.configList = payload
  },
  [RANKING_FETCH_CONFIG_FILTER_LIST](state, payload) {
    state.configFilterList = payload
  },
}

export default mutations
