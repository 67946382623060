<template>
  <div class="photo-slider">
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="(item, index) in items" :key="index">
        <img
          class="carousel-image"
          :src="item.urlmedium"
          :alt="item.key"
          @click="onclickHandler(item.accommodation_id)"
        />
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity"

export default {
  name: "image-carousel",
  title: "ImageCarousel",
  components: {
    Flickity,
  },
  mounted() {
    setTimeout(() => {
      this.$refs.flickity.resize()
    }, 500)
  },
  data() {
    return {
      flickityOptions: {
        pageDots: false,
        adaptiveHeight: true,
        imagesLoaded: true,
      },
    }
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  methods: {
    onclickHandler(accommodationId) {
      if (!accommodationId) {
        return
      }
      this.$router.push(`/accommodation/${accommodationId}/photos`)
    },
  },
}
</script>

<style lang="scss">
.photo-slider {
  .carousel-cell {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: auto;
  }

  /* no circle */
  .flickity-button {
    background: transparent !important;
  }

  .flickity-button:hover {
    background: transparent;
  }
  /* big previous & next buttons */
  .flickity-prev-next-button {
    width: 100px;
    height: 100px;
  }
  /* icon color */
  .flickity-button-icon {
    fill: #cdcdcd;
  }
  /* hide disabled button */
  .flickity-button:disabled {
    display: none;
  }
  .flickity-enabled {
    position: initial;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
</style>
