<template>
  <div>
    <UniversalTable
      :data="normalizedBookingAdjustments"
      :onAddButtonClick="handleAddButtonClick"
      :export-file-name="tableExportFileName"
      :add-button-text="addAdjustmentButtonText"
      @onRowSelect="handleRowSelect"
      @navigate-to-row-item="handleEditButtonClick"
    >
      <template slot="header-right-controls" slot-scope="{ selectedRow }">
        <md-button
          class="md-button md-sm md-info mr-2"
          @click="handleAddButtonClick"
        >
          Add
        </md-button>
        <md-button
          class="md-button md-sm md-theme-default mr-2"
          :disabled="!selectedRow"
          @click="handleEditButtonClick"
        >
          Edit
        </md-button>
        <md-button
          class="md-button md-sm md-danger"
          :disabled="!selectedRow"
          @click="handleRemoveButtonClick"
        >
          Delete
        </md-button>
      </template>
    </UniversalTable>
  </div>
</template>

<script>
import { BOOKINGS } from "@/modules/bookings/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { normalizeDateInTable } from "../../../utils/booking-date.utils"
import _ from "lodash"

export default {
  name: "BookingAdjustmentsTable",
  props: {
    onRowSelect: Function,
    onAddButtonClick: Function,
    onEditButtonClick: Function,
    onRemoveButtonClick: Function,
  },
  components: {
    UniversalTable,
  },
  data: () => ({
    tableExportFileName: "booking_adjustments",
    addAdjustmentButtonText: "Create first adjustment",
  }),
  computed: {
    bookingAdjustments() {
      return this.$store.state[BOOKINGS].item.adjustments
    },
    normalizedBookingAdjustments() {
      const data = this.$store.state[BOOKINGS].item.adjustments

      if (!data.rows) {
        return data
      }

      const d = _.cloneDeep(data)

      d.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return d
    },
  },
  methods: {
    handleRowSelect(row) {
      this.$emit("onRowSelect", row)
    },
    handleAddButtonClick() {
      this.$emit("onAddButtonClick")
    },
    handleEditButtonClick() {
      this.$emit("onEditButtonClick")
    },
    handleRemoveButtonClick() {
      this.$emit("onRemoveButtonClick")
    },
  },
}
</script>

<style lang="scss" scoped>
.md-table + .md-table {
  margin-top: 16px;
}
</style>
