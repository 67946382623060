import { mapActions } from "vuex"

import { AUTH, AUTH_INIT } from "@/modules/auth/store/mutations-types"
import { USER, USER_FETCH_ENTITIES } from "@/modules/user/store/mutations-types"

export default {
  methods: {
    ...mapActions(AUTH, [AUTH_INIT]),
    ...mapActions(USER, [USER_FETCH_ENTITIES]),
    async initAuth() {
      await this[AUTH_INIT]()
      await this[USER_FETCH_ENTITIES]()
    },
  },
  created() {
    this.initAuth()
  },
}
