<template>
  <div class="reports-page">
    <div>
      <div class="header">
        <h3 class="title mt-0">Your reports:</h3>
      </div>
      <div v-if="haveReports" class="md-layout">
        <div
          v-for="(report, index) in reportsList"
          :key="index"
          class="md-layout-item md-xsmall-size-100 md-medium-size-33 md-size-25 text-center"
        >
          <div class="report-item" @click="navigateToReport(report)">
            <md-card class="mt-0">
              <md-card-content>
                <h4>{{ reportName(report) }}</h4>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
      <div v-else class="md-layout text-center">
        <h3>There are no reports available to view</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import {
  REPORTS,
  REPORTS_FETCH_LIST,
} from "@/modules/reports/store/mutations-types"

export default {
  name: "ReportsPage",
  data: () => ({
    isFetching: false,
  }),
  created() {
    this.fetchReports()
  },
  methods: {
    ...mapActions(REPORTS, {
      fetchReports: REPORTS_FETCH_LIST,
    }),
    reportName({ row }) {
      const { value } = row[1]
      return value
    },
    navigateToReport({ row }) {
      const { value } = row[0]
      if (this.$route.path !== `/reports/${value}`)
        this.$router.push(`/reports/${value}`)
    },
  },
  computed: {
    ...mapState(REPORTS, {
      reportsList: state => state.reportsList,
    }),
    haveReports() {
      return !!this.reportsList.length
    },
  },
}
</script>

<style scoped>
.report-item {
  cursor: pointer;
}
</style>
