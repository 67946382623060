<template>
  <form novalidate class="md-layout" @submit.prevent="handleSubmit">
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field :class="{ 'md-error md-invalid': $v.itemId.$error }">
        <label>Item Id</label>
        <md-input
          ref="itemId"
          type="text"
          v-model="itemId"
          @blur="$v.itemId.$touch"
        />
        <div class="md-error" v-if="!$v.itemId.required">
          Field is required.
        </div>
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field :class="{ 'md-error md-invalid': $v.itemType.$error }">
        <label>Item Type</label>
        <md-select
          v-model="itemType"
          name="item_type"
          id="item_type"
          @blur="$v.itemType.$touch"
          md-dense
        >
          <md-option
            v-for="(item, key) in itemTypesList"
            :key="key"
            :value="item.key"
            >{{ item.value }}</md-option
          >
        </md-select>
        <div class="md-error" v-if="!$v.itemType.required">
          Field is required.
        </div>
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 mb-4 p-0">
      <md-field>
        <label>Job Name</label>
        <md-input type="text" :value="selectedJob" disabled />
      </md-field>
    </div>
    <div class="md-layout-item md-size-100 content-right p-0 mt-4">
      <md-button class="md-raised md-danger md-simple" @click="onDialogClose">
        CANCEL
      </md-button>
      <md-button
        type="submit"
        class="md-raised md-success"
        :disabled="this.$v.$invalid"
      >
        CREATE
      </md-button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { mapActions, mapState } from "vuex"
import {
  FEED_JOBS,
  FEED_JOBS_FETCH_ITEM_TYPES_LIST,
} from "@/modules/feed-jobs/store/mutations-types"
export default {
  name: "CreateFeedJobForm",
  props: {
    onDialogClose: Function,
    isFormSending: Boolean,
    onFormSubmit: Function,
  },
  validations: {
    itemId: {
      required,
    },
    itemType: {
      required,
    },
  },
  data() {
    return {
      itemId: "",
      itemType: null,
    }
  },
  computed: {
    ...mapState(FEED_JOBS, {
      itemTypesList: state => state.itemTypesList,
      selectedJob: state => state.selectedJob,
    }),
  },
  methods: {
    ...mapActions(FEED_JOBS, {
      fetchItemTypesList: FEED_JOBS_FETCH_ITEM_TYPES_LIST,
    }),
    handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const { itemId, itemType, selectedJob } = this
      this.$emit("form-submit", { itemId, itemType, jobName: selectedJob })
    },
  },
  async mounted() {
    await this.fetchItemTypesList()
    this.$refs.itemId.$el.focus()
  },
}
</script>

<style scoped></style>
