import actions from "@/modules/pricing-data/store/actions"
import mutations from "@/modules/pricing-data/store/mutations"

export const initPricingItem = {
  countries: [],
  regionDepartments: [],
  countryRegions: [],
  accommodations: [],
  numberOfNights: [],
  groupNames: [],
  searchFilters: [],
  searchResult: {},
}

const state = () => ({
  item: initPricingItem,
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
