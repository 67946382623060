import actions from "@/modules/notifications/store/actions"
import mutations from "@/modules/notifications/store/mutations"

const state = () => ({
  notificationsList: {},
})

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
