import {
  PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST,
  UPDATE_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION,
  SET_IS_PRODUCT_CONFIGURATION,
  GET_PRODUCT_CONFIGURATION_LANGUAGE,
  GET_PRODUCT_CONFIGURATION_PRODUCT_KEY,
} from "@/modules/product-configuration/store/mutations-type"

const mutations = {
  [PRODUCT_CONFIGURATIONS_FETCH_PRODUCT_CONFIGURATION_LIST](state, payload) {
    state.productConfigurationList = payload
  },
  [UPDATE_PRODUCT_CONFIGURATION](state, payload) {
    state.updateProductConfigurationForm = payload
  },

  [GET_PRODUCT_CONFIGURATION](state, payload) {
    state.productConfiguration = payload
  },

  [SET_IS_PRODUCT_CONFIGURATION](state, payload) {
    state.isProductConfiguration = payload
  },

  [GET_PRODUCT_CONFIGURATION_LANGUAGE](state, payload) {
    state.languageList = payload
  },

  [GET_PRODUCT_CONFIGURATION_PRODUCT_KEY](state, payload) {
    state.productKeyList = payload
  },
}
export default mutations
