<template>
  <div class="px-5">
    <form novalidate class="md-layout" @submit.prevent>
      <template>
        <md-dialog-title class="pl-0 w-100"
          >Extra Id: {{ extrasId }}</md-dialog-title
        >

        <md-dialog-title class="pl-0 mt-2"
          >SH Label: {{ extrasShLabel }}</md-dialog-title
        >

        <md-field class="md-size-30 mt-4">
          <label>Lang</label>
          <md-select
            v-model="language"
            name="language"
            id="language"
            @input="setLanguage"
          >
            <md-option
              v-for="langName in supportedLanguages"
              :key="langName"
              :value="langName"
              >{{ langName }}</md-option
            >
          </md-select>
        </md-field>

        <md-field
          class="mt-4"
          :class="{
            'md-error md-invalid': $v.campsitedLabel.$error,
          }"
        >
          <label class="required">Campsited Label</label>
          <md-input v-model="campsitedLabel" type="text"></md-input>
          <div class="md-error" v-if="!$v.campsitedLabel.required">
            Campsited Label is required.
          </div>
        </md-field>

        <div class="filter-checkbox mt-3">
          <md-checkbox
            class="column checkbox-area-sm"
            v-model="checkoutShow"
            value="true"
          >
            Enabled in checkout (True/False)
          </md-checkbox>
        </div>

        <div class="filter-checkbox mt-3">
          <md-checkbox
            class="column checkbox-area-sm"
            v-model="myBookings"
            value="true"
            >Visible in My Booking (True/False)</md-checkbox
          >
        </div>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="onUpdate()"
          >
            Update
          </md-button>
        </div>
      </template>
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="apiInProgress"
      />
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import {
  SMARTNAV_CONFIGURATIONS,
  GET_STATIC_SUPPORTED_LANGUAGES_LIST,
  SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
} from "@/modules/smartNav-configuration/store/mutations-type"
import {
  CAMPSITE_EXTRAS,
  UPDATE_CAMPSITE_EXTRAS,
} from "@/modules/campsite-extras/store/mutations-types"
import { required } from "vuelidate/lib/validators"

export default {
  name: "updateCampsiteExtrasForm",

  data: () => ({
    label: "",
    apiInProgress: false,
    mobileMenuStyle: "",
    icon: "",
    hasCategories: false,
    language: "",
    extrasId: "",
    extrasShLabel: "",
    campsitedLabel: "",
    checkoutShow: false,
    myBookings: false,
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(SMARTNAV_CONFIGURATIONS, {
      getSupportedLanguages: GET_STATIC_SUPPORTED_LANGUAGES_LIST,
      setSelectedLanguage: SELECTED_LANGUAGE_SMARTNAV_CONFIGURATION,
    }),
    ...mapActions(CAMPSITE_EXTRAS, {
      updateCampsiteExtras: UPDATE_CAMPSITE_EXTRAS,
    }),
    // Initialize the Level 2 Items
    async init() {
      this.apiInProgress = true
      await this.getSupportedLanguages()
      this.language = this.selectedLanguage

      this.updateLabelFields()

      this.extrasId = this.selectedCampsiteExtra.find(
        entity => entity.name === "extra_sh_id"
      ).value
      this.checkoutShow = this.selectedCampsiteExtra.find(
        entity => entity.name === "show_in_checkout"
      ).value
      this.myBookings = this.selectedCampsiteExtra.find(
        entity => entity.name === "show_in_my_booking"
      ).value
      this.apiInProgress = false
    },

    // Add Campsite Extras
    async onUpdate() {
      this.$v.$touch()

      if (!this.$v.$invalid && !this.apiInProgress) {
        this.apiInProgress = true
        if (!this.hasCategories) this.hasCategories = false
        const data = {
          extraShId: this.extrasId,
          extraCsName: this.campsitedLabel,
          lang: this.language,
          showInCheckout: this.checkoutShow,
          showInMyBooking: this.myBookings,
        }
        let final = []
        let input = {}
        input.lang = this.language
        final.push(input)
        final.push(data)
        try {
          await this.updateCampsiteExtras(final)
        } catch (e) {
          this.$swal.fire({
            position: "top-end",
            html: e,
            icon: "error",
            title: "Add Level 2 Item",
            showConfirmButton: false,
            timer: 4000,
            showCloseButton: true,
          })
        }
        this.$v.$reset()
        this.apiInProgress = false
        this.$emit("onFormSubmit")
      }
    },
    setLanguage() {
      this.setSelectedLanguage(this.language)
      this.updateLabelFields()
    },
    updateLabelFields() {
      this.extrasShLabel = this.selectedCampsiteExtra.find(
        entity => entity.name === "sh_name_" + this.language.toLowerCase()
      ).value
      this.campsitedLabel = this.selectedCampsiteExtra.find(
        entity => entity.name === "name_" + this.language.toLowerCase()
      ).value
    },
  },
  validations: {
    campsitedLabel: {
      required,
    },
  },
  computed: {
    ...mapState(CAMPSITE_EXTRAS, {
      selectedCampsiteExtra: state => state.selectedCampsiteExtra,
    }),
    ...mapState(SMARTNAV_CONFIGURATIONS, {
      supportedLanguages: state => state.supportedLanguages,
      selectedLanguage: state => state.selectedLanguage,
    }),
  },
}
</script>
<style scoped>
.required:after {
  content: " *";
  color: red;
}
.md-title {
  margin-bottom: 0px;
  width: 100%;
  padding-top: 0px;
  font-size: 14px !important;
}
@media screen and (max-width: 600px) {
  .checkbox-area-sm {
    width: 100% !important;
    flex-basis: 100%;
  }
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
