import {
  GEO_FETCH_COUNTRIES,
  GEO_FETCH_REGIONS,
  GEO_FETCH_DEPARTMENTS,
  GEO_UPDATE_COUNTRIES,
  GEO_UPDATE_REGIONS,
  GEO_UPDATE_DEPARTMENTS,
} from "@/modules/geo/store/mutations-types"
import { get as _get } from "lodash"

const actions = {
  async [GEO_FETCH_COUNTRIES]({ commit }) {
    const response = await this.$repositories.geo.fetchCountries()
    const countriesList = _get(response, "data.result.csRefDataList", [])

    commit(GEO_UPDATE_COUNTRIES, countriesList)
  },
  async [GEO_FETCH_REGIONS]({ commit }, params) {
    const response = await this.$repositories.geo.fetchCountryRegions(params)
    const countryRegionsList = _get(response, "data.result.csRefDataList", [])

    commit(GEO_UPDATE_REGIONS, countryRegionsList)
  },
  async [GEO_FETCH_DEPARTMENTS]({ commit }, params) {
    const response = await this.$repositories.geo.fetchRegionDepartments(params)
    const departmentsList = _get(response, "data.result.csRefDataList", [])

    commit(GEO_UPDATE_DEPARTMENTS, departmentsList)
  },
}

export default actions
