<template>
  <div class="px-5">
    <div class="label-field pl-0 mb-2">Lang: {{ language }}</div>
    <form novalidate class="md-layout " @submit.prevent>
      <template>
        <md-field :class="showValidationErros()" @md-selected="validate">
          <label>Category</label>
          <md-select
            v-model="category"
            name="catgory"
            id="catgory"
            :disabled="isEdit"
            @md-selected="onCategoryChange"
          >
            <md-option
              v-for="category in getAmenitiesCategoires"
              :key="category"
              :value="category"
              >{{ category }}
            </md-option>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.category"
          >
            This field is required !
          </span>
        </md-field>

        <md-field :class="showValidationErros()">
          <label>Label</label>
          <md-select
            v-model="label"
            name="label"
            id="label"
            @md-selected="validate"
          >
            <template v-if="!loadingLabels">
              <template v-if="categorySelected">
                <md-option value="">
                  None
                </md-option>
                <md-option
                  v-for="label in labelOptions"
                  :key="label"
                  :value="label"
                  >{{ label || "None" }}
                </md-option>
              </template>
              <md-option value="" v-else disabled>
                Select Category
              </md-option>
            </template>
            <md-option value="" v-else disabled>
              Loading Labels
            </md-option>
          </md-select>
          <span
            class="md-error"
            v-if="validation.showErrors && !validation.label"
          >
            This field is required !
          </span>
        </md-field>

        <md-field>
          <label>Description</label>
          <md-input v-model="description" type="text"> </md-input>
        </md-field>

        <div class="md-layout-item md-size-100 px-0 pb-4 content-right">
          <md-button
            type="submit"
            class="md-raised md-success mt-4"
            @click="onSubmit()"
          >
            {{ isEdit ? "Update" : "ADD" }}
          </md-button>
        </div>
      </template>
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="apiInProgress"
      />
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import {
  CAMPSITES,
  ADD_ACCOMMODATION_AMENITIES,
  UPDATE_ACCOMMODATION_AMENITY,
  SET_ACCOMMODATION_AMENITIES_LABELS,
} from "@/modules/campsites/store/mutations-types"

export default {
  name: "UpsertAccommodationFeatureForm",
  props: {
    isEdit: Boolean,
  },

  data: () => ({
    loadingLabels: false,
    apiInProgress: false,

    language: "EN",
    category: "",
    label: "",
    description: "",
    featureKey: "",

    validation: {
      category: false,
      label: false,
      showErrors: false,
    },
  }),
  created() {
    this.init()
  },
  methods: {
    ...mapActions(CAMPSITES, {
      addAccommodationAmenities: ADD_ACCOMMODATION_AMENITIES,
      setAccommodationAmenitiesLabels: SET_ACCOMMODATION_AMENITIES_LABELS,
      updateAccommodationAmenity: UPDATE_ACCOMMODATION_AMENITY,
    }),

    async init() {
      const { isEdit, getAccommodationFeaturDetails } = this
      if (isEdit) {
        this.featureKey = this.getObjectValue(
          getAccommodationFeaturDetails,
          "feature_key"
        )
        const category = this.getObjectValue(
          getAccommodationFeaturDetails,
          "category"
        )
        // * featureKey is missing from the response, needed for update action

        this.category = category
        this.fetchLabels(category)

        this.description = this.getObjectValue(
          getAccommodationFeaturDetails,
          "description"
        )
      }
    },
    // Add Amenities View
    async onSubmit() {
      this.validate()
      if (!this.validation.category || !this.validation.label) return

      const { accommodationId, stayFacilityId } = this
      const payload = {
        accommodationId,
        data: {
          stayFacilityId,
          category: this.category,
          lang: this.language,
          description: this.description,
          name: this.label,
        },
      }
      if (this.isEdit) {
        payload.data.featureKey = this.featureKey
      }

      this.apiInProgress = true
      this.isEdit
        ? await this.updateAccommodationAmenity(payload)
        : await this.addAccommodationAmenities(payload)
      this.apiInProgress = false

      this.$emit("onFormSubmit")
    },
    async onCategoryChange(category) {
      await this.fetchLabels(category)
    },

    async fetchLabels(category) {
      this.loadingLabels = true
      this.label = ""

      await this.setAccommodationAmenitiesLabels({ category: category })

      this.label = this.isEdit
        ? this.getObjectValue(this.getAccommodationFeaturDetails, "label")
        : this.labelOptions[0]
      this.loadingLabels = false
    },
    validate() {
      this.validation.category = !!this.category
      this.validation.label = !!this.label
      this.validation.showErrors = true
    },
    showValidationErros() {
      return { "md-invalid mb-3": this.validation.showErrors }
    },
    getObjectValue(arr, objName) {
      const obj = arr.find(obj => obj.name === objName)
      return obj.value
    },
  },
  computed: {
    ...mapGetters(CAMPSITES, [
      "getAmenitiesCategoires",
      "getAmenitiesLabels",
      "getAccommodationFeaturDetails",
    ]),

    labelOptions() {
      return this.getAmenitiesLabels.filter(item => item !== null)
    },
    categorySelected() {
      return !!this.category.length > 0
    },
    accommodationId() {
      return this.$route.params.id
    },
    stayFacilityId() {
      return this.$route.query.stayFacilityId
    },
  },
}
</script>
<style scoped>
.md-title {
  font-size: 14px !important;
}
.label-field {
  font-size: 14px;
  font-weight: 500;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
