export default {
  methods: {
    getAdjustmentRowData(row = []) {
      const data = {}
      const fieldsAnnotation = {
        id: "id",
        item: "adjustmentField",
        new_value: "newValue",
        comment: "comment",
      }

      row.forEach(field => {
        const isFieldAnnotationExist = fieldsAnnotation[field.name]

        if (isFieldAnnotationExist) {
          const formFieldKey = fieldsAnnotation[field.name]
          data[formFieldKey] = field.value
        }
      })

      return data
    },
  },
}
