<template>
  <UniversalTable
    :export-file-name="tableExportFileName"
    :data="bookingNormalizedStatusHistory"
  />
</template>

<script>
import BookingsTableMixin from "@/modules/bookings/mixins/bookings-table.mixin"
import { BOOKINGS } from "@/modules/bookings/store/mutations-types"
import UniversalTable from "@/views/components/tables/UniversalTable"
import { normalizeDateInTable } from "../../../utils/booking-date.utils"
import _ from "lodash"

export default {
  name: "BookingStatusHistoryTable",
  mixins: [BookingsTableMixin],
  components: {
    UniversalTable,
  },
  data: () => ({
    tableExportFileName: "booking_status_history",
  }),
  computed: {
    bookingStatusHistory() {
      return this.$store.state[BOOKINGS].item.statusHistory
    },
    bookingNormalizedStatusHistory() {
      const data = this.$store.state[BOOKINGS].item.statusHistory

      if (Object.keys(data).length === 0) {
        return {}
      }

      let d = _.cloneDeep(data)

      d.rows.map(row => {
        row.row.map(item => normalizeDateInTable(item))
      })

      return d
    },
  },
}
</script>

<style scoped></style>
